import React from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useLocation } from "react-router-dom";
import createStockTagging from "../firebase/stocks/createStockTagging";

function BarcodeScan() {
  const [data, setData] = React.useState("Not Found");
  const [torchOn, setTorchOn] = React.useState(false);
  const search = useLocation();
  const urlParams = new URLSearchParams(search.search);
  const StoreId = urlParams.get("store");
  let prevArr = [];
  return (
    <div>
      <div>
        <BarcodeScannerComponent
          width={450}
          height={450}
          videoConstraints={{
            facingMode: "environment",
            width: 500,
            height: 1000,
          }}
          torch={torchOn}
          onUpdate={async (err, result) => {
            if (result) {
              if (!prevArr.includes(result.text)) {
                prevArr.push(result.text);
                document.getElementsByClassName(
                  "text"
                )[0].style.backgroundColor = "#68ff5e";
                setData("Scanned Text: " + result.text);
                await createStockTagging(StoreId, result.text);
              } else {
                document.getElementsByClassName(
                  "text"
                )[0].style.backgroundColor = "#fff729";
                setData("Code scanned again: " + result.text);
              }
            } else {
              document.getElementsByClassName("text")[0].style.backgroundColor =
                "white";
            }
          }}
        />
      </div>
      <div>
        <p className="text">{data}</p>
      </div>
      <div>
        <button className="btn box" onClick={() => setTorchOn(!torchOn)}>
          Switch Torch {torchOn ? "Off" : "On"}
        </button>
      </div>
    </div>
  );
}

export default BarcodeScan;
