import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../index";
import checkDiscount from "../discounts/checkDiscount"

export default async function applyDiscount(storeId, cartId, discountCode) {
    const discount = await checkDiscount(storeId, discountCode)
    const docRef = doc(db, "Stores", storeId, "Cart", cartId);
    await updateDoc(docRef, {
        discountCode: discountCode,
        discount: discount.discountPercentage,
    });
    return discount
}
