import React, { useContext, useState } from "react";
import { CurrencyContext } from "../../context/CurrencyContext";
import translate from "../../i18n/translate";
import "../css/TicketContainer.scoped.css";
import ListedTicketGrid from "./ListedTicketGrid";

const ListedTicketContainer = ({ sortBy, dark }) => {
  const [total, setTotal] = useState({
    tv: 0,
    ti: 0,
    tp: 0,
  });

  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  function formatPrice(p) {
    return `$${Number(p.toFixed(2))}`;
  }

  return (
    <div className="inventory-container ">
      <div className="flex al-ce jc-sp headersection">
        <div className="smtext">{translate("summary")}</div>
        <div className="flex al-ce">
          <div className="query flex al-ce">
            <span className="purple">{translate("totalValue")}:</span>
            <span className="queryvalue">${formatPrice(total.tv)}</span>
          </div>
          <div className="query">
            <span className="purple">{translate("totalItems")}:</span>
            <span className="queryvalue">{total.ti}</span>
          </div>
          <div className="query">
            <span className="purple">{translate("totalPayout")}:</span>
            <span className={`queryvalue ${total.tp > 0 ? "green" : "red"}`}>
              ${formatPrice(total.tp)}
            </span>
          </div>
        </div>

        <div className="flex">
          {/*<PriceAlertModal dark={dark} />
      <Button
        text={translate("refresh")}
        imgSrc={
          <img
            src={dark ? refreshIconDark : refreshIcon}
            alt="refresh Icon"
          />
        }
        onClick={async () => {
          setTotal(await updateNormalCount(StoreId));
        }}
      />*/}
        </div>
      </div>
      <ListedTicketGrid
        sortBy={sortBy}
        setTotal={setTotal}
        dataSet={[]}
        dark={dark}
      />
    </div>
  );
};

export default ListedTicketContainer;
