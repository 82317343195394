import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import Close from "../../assets/close.svg";
import CloseDark from "../../assets/closedark.svg";
import { StoreId } from "../../Constants";
import editConsignee from "../../firebase/consignee/editConsignee";
import "../css/AddConsignmentModal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";
export default function EditConsignmentModal({
  isOpen,
  toggleState,
  data,
  updateEditModalData,
  dark,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "32.25rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const [fields, setFields] = useState(data);

  useEffect(() => {
    setFields(data);
  }, [data]);

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={() => toggleState(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="header">
              <div>{translate("editingConsignee")}...</div>
              <img
                onClick={() => {
                  toggleState(false);
                  updateEditModalData({});
                }}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>

            <div className="row">
              <div>
                <div className="label">{translate("firstAndLastName")}</div>
                <Input
                  name="name"
                  type={"text"}
                  value={fields.name}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterName")}
                />
              </div>
            </div>

            <div className="two-grid row">
              <div>
                <div className="label">{translate("consigneeId")}</div>
                <Input
                  name="consigneeId"
                  type={"text"}
                  value={fields.consigneeId}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterId")}
                  readOnly={true}
                />
              </div>

              <div>
                <div className="label">{translate("dateJoin")}</div>
                <Input
                  name="dateJoined"
                  type={"date"}
                  dark={dark}
                  value={fields.dateJoined}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterDate")}
                />
              </div>
            </div>

            <div className="row">
              <div className="label">{translate("address")}</div>
              <Input
                name="address"
                type={"text"}
                value={fields.address}
                updateValue={handleChange}
                placeholder={GetTranslateString("enterAddress")}
              />
            </div>

            <div className="two-grid row">
              <div>
                <div className="label">{translate("country")}</div>
                <Input
                  name="country"
                  type={"text"}
                  value={fields.country}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterCountry")}
                />
              </div>

              <div>
                <div className="label">{translate("city")}</div>
                <Input
                  name="city"
                  type={"text"}
                  value={fields.city}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterCity")}
                />
              </div>
            </div>

            <div className="two-grid row">
              <div>
                <div className="label">{translate("email")}</div>
                <Input
                  name="email"
                  type={"email"}
                  value={fields.email}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterEmail")}
                />
              </div>

              <div>
                <div className="label">{translate("contact")}</div>
                <Input
                  name="contact"
                  type={"text"}
                  value={fields.contact}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterContactNo")}
                />
              </div>
            </div>

            <div className="btns w-full jus-end mt-10">
              <div className="ml-10">
                <Button
                  imgSrc={<img src={dark ? AddDark : Add} alt="" />}
                  onClick={async () => {
                    try {
                      await editConsignee(StoreId, data.id, {
                        name: fields.name.trim(),
                        consigneeId: fields.consigneeId.trim(),
                        dateJoined: fields.dateJoined,
                        address: fields.address.trim(),
                        country: fields.country.trim(),
                        city: fields.city.trim(),
                        email: fields.email.trim(),
                        contact: fields.contact,
                      });
                    } catch (e) {
                      ErrorToast(translate("error"), e.message);
                      return;
                    }
                    SuccessToast(translate("success"), translate("itemEdited"));
                    toggleState(false);
                  }}
                  text={translate("save")}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
