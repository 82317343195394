import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../index";
import encodeKey from "../util/encodeKey";

export default async function editCart(storeId, cartId, itemId) {
  const itemRef = doc(db, "Stores", storeId, "Inventory", itemId);
  const itemSnap = await getDoc(itemRef);
  let data = itemSnap.data();

  const docRef = doc(db, "Stores", storeId, "Cart", cartId);
  const productRef = doc(db, "Stores", storeId, "Products", data.sku);
  const productSnap = await getDoc(productRef);
  let productData = productSnap.data();

  if (data.type === "normal") {
    if (data.platform === "custom") {
      data["costPrice"] = data.purchasePrice;
      data["soldPrice"] = data["price"] =
        productData.sizesCustom[encodeKey(data.size)];
    } else {
      data["costPrice"] = productData.sizes[data.size];
      data["soldPrice"] = data["price"] =
        productData.sizes[data.size] +
        (productData.sizes[data.size] * data.markUp) / 100;
    }
  } else {
    //consignment
    data["costPrice"] = data.payoutPrice;
    data["soldPrice"] = data.price;
  }
  data.id = itemId;
  await updateDoc(docRef, {
    [`items.${data.id}`]: { ...data },
  });
}
