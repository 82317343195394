import { collection, doc, onSnapshot } from "firebase/firestore";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ErrorToast } from "../components/general/ToastNotification";
import { StoreId } from "../Constants";
import { CurrencyContext } from "../context/CurrencyContext";
import createCart from "../firebase/cart/createCart";
import { db } from "../firebase/index";
import batchFetch from "../firebase/util/batchFetch";
import "./css/Sales.scoped.css";
import { useNavigate } from "react-router-dom";
import translate from "../i18n/translate";
import { getAuth, onIdTokenChanged } from "firebase/auth";
import BankAccounts from "../components/wallet/BankAccounts";
import Cashout from "../components/wallet/Cashout";
import RecentActivities from "../components/wallet/RecentActivities";
import "../components/css/Wallet.scoped.css";
import SearchBtn from "../components/general/SearchBtn";
import Topbar from "../components/general/Topbar";
import CashOutModal from "../components/wallet/CashOutModal";
import AddBankAccountModal from "../components/wallet/AddBankAccountModal";
import DeleteBankAccountModal from "../components/wallet/DeleteBankAccountModal";

let bankAccountNames = new Map();

const Wallet = ({ user, dark }) => {
  const cartRef = useRef();
  const [active, setActive] = useState(0);
  const [search, setSearch] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [availableCashout, setAvailableCashout] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [payout, setPayout] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeCart, setActiveCart] = useState("");
  const [cartTotal, setCartTotal] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [cartIds, setCartIds] = useState([]);
  const [data, setData] = useState([]);
  const [searchString, updateSearchString] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteBankAccId, setDeleteBankAccId] = useState("");
  const [deleteBankAccOpen, setDeleteBankAccOpen] = useState(false);

  const isOwner = localStorage.getItem("owner");
  let hideReceiptSettings = localStorage.getItem("hideReceiptSettings");
  const navigate = useNavigate();

  const auth = getAuth();
  // console.log(auth);
  useEffect(() => {
    const authListener = onIdTokenChanged(auth, async (user) => {
      // console.log(auth)
      if (user) {
        const token = await user.getIdToken();
        localStorage.setItem("token", token);
      }
    });
    return () => {
      authListener();
    };
  }, []);

  const [bankData, updateBankData] = useState([]);
  useEffect(() => {
    const bankListener = onSnapshot(
      collection(db, "Stores", StoreId, "BankAccounts"),
      async (snapShot) => {
        let bankAcs = [];
        if (!snapShot.empty) {
          bankAcs = snapShot.docs.map((e) => {
            return {
              ...e.data(),
              id: e.id,
            };
          });
        }
        for (let i = 0; i < bankAcs.length; i++) {
          if (!bankAccountNames.has(bankAcs[i].id)) {
            bankAccountNames.set(bankAcs[i].id, bankAcs[i].accountHolderName);
          }
        }
        updateBankData(bankAcs);
      }
    );
    return () => {
      bankListener();
    };
  }, []);
  useEffect(() => {
    const walletBalanceListener = onSnapshot(
      doc(db, "Stores", StoreId, "Wallet", "test"),
      async (snapShot) => {
        let balance = 0;
        if (!snapShot.empty) {
          balance = snapShot.data().totalBalance / 100;
        }
        setWalletBalance(balance);
      }
    );
    return () => {
      walletBalanceListener();
    };
  }, []);
  useEffect(() => {
    const transactionListener = onSnapshot(
      collection(db, "Stores", StoreId, "Wallet", "test", "Transactions"),
      async (snapShot) => {
        let tx = [];
        if (!snapShot.empty) {
          tx = snapShot.docs.map((e) => {
            return {
              ...e.data(),
              id: e.id,
            };
          });
        }

        let avCash = tx.reduce((acc, next) => {
          return next.dateEnd < Date.now() ? acc + next.creditAmount : acc + 0;
        }, 0);

        setAvailableCashout(avCash / 100);
        // for (let i = 0; i < transactions.length; i++) {
        //   if (transactions[i].dateEnd < Date.now()) {
        //     setAvailableCashout(
        //       availableCashout + transactions[i].creditAmount / 100
        //     );
        //   }
        // }
        setTransactions(tx);
      }
    );
    return () => {
      transactionListener();
    };
  }, []);
  useEffect(() => {
    const payoutListener = onSnapshot(
      collection(db, "Stores", StoreId, "Wallet", "test", "Payouts"),
      async (snapShot) => {
        let payout = [];
        if (!snapShot.empty) {
          payout = snapShot.docs.map((e) => {
            return {
              ...e.data(),
              id: e.id,
            };
          });
        }

        setPayout(payout);
      }
    );
    return () => {
      payoutListener();
    };
  }, []);

  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  return (
    <div className="page walletPage">
      <DeleteBankAccountModal
        dark={dark}
        deleteModal={deleteBankAccOpen}
        toggleOpen={setDeleteBankAccOpen}
        deleteId={deleteBankAccId}
        setDeleteId={setDeleteBankAccId}
      />
      <Topbar
        dark={dark}
        pagename={translate("sales")}
        links={[
          {
            name: translate("summary"),
          },
          {
            name: translate("pos"),
          },
          isOwner
            ? {
                name: translate("receiptSettings"),
              }
            : !hideReceiptSettings && {
                name: "Receipt Settings",
              },
        ]}
        user={user}
        active={active}
        onClick={setActive}
      />
      <div>
        <div className="add-btns px">
          <div className="mr-14">
            <SearchBtn
              dark={dark}
              value={search}
              updateValue={setSearch}
              onEnter={async () => {
                updateSearchString(search);
              }}
            />
          </div>
        </div>

        {active === 0 && (
          <div className="summary px">
            <div className="graph box">
              <div className="headersection">
                <span>{translate("Wallet Balance")}</span>
                <span className="note">
                  Note: Payout for tickets will occur after 3 working days of
                  concert being over for it to be in your payout.
                </span>
              </div>
              <div className="walletDetails">
                <div className="flex al-ce jc-sp">
                  <div className="flex al-ce jc-ce col">
                    <div className="green amt">${walletBalance.toFixed(2)}</div>
                    <div className="subText">Total Balance</div>
                  </div>
                  <div className="flex al-ce jc-ce col">
                    <div className="green amt">
                      ${availableCashout.toFixed(2)}
                    </div>
                    <div className="subText">Available to cash out</div>
                  </div>
                </div>
                <div className="flex al-ce jc-ce mt-10">
                  <div className="flex al-ce jc-ce col">
                    <div className="red amt">$0</div>
                    <div className="subText">Penalties</div>
                  </div>
                </div>
              </div>

              {/* <div className="btn">
                <div>Cash Out</div>
              </div> */}
              <div className="flex al-ce jc-ce mt-20">
                <CashOutModal
                  dark={dark}
                  bankAccounts={bankData}
                  availableCashout={availableCashout}
                />
              </div>
            </div>
            <div className="overview box">
              <div className="headersection flex al-ce jc-sp">
                <div>{translate("Bank Accounts")}</div>
                <AddBankAccountModal dark={dark} />
              </div>

              <BankAccounts
                setDeleteOpen={setDeleteBankAccOpen}
                setDeleteId={setDeleteBankAccId}
                bankAccounts={bankData}
              />
            </div>

            <div className="span2 box">
              <div className="headersection">
                {translate("Recent Activities")}
                {/* <Button
                  onClick={() => {}}
                  imgSrc={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.66675 5.33334C8.66675 4.96515 8.36827 4.66667 8.00008 4.66667C7.63189 4.66667 7.33341 4.96515 7.33341 5.33334V7.33334H5.33341C4.96522 7.33334 4.66675 7.63182 4.66675 8C4.66675 8.36819 4.96523 8.66667 5.33341 8.66667H7.33341V10.6667C7.33341 11.0349 7.63189 11.3333 8.00008 11.3333C8.36827 11.3333 8.66675 11.0349 8.66675 10.6667V8.66667H10.6667C11.0349 8.66667 11.3334 8.36819 11.3334 8C11.3334 7.63182 11.0349 7.33334 10.6667 7.33334H8.66675V5.33334Z"
                        fill="#767BFA"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 8C0 14.588 1.412 16 8 16C14.588 16 16 14.588 16 8C16 1.412 14.588 0 8 0C1.412 0 0 1.412 0 8ZM1.33333 8C1.33333 9.61657 1.42169 10.8246 1.62514 11.74C1.82522 12.6403 2.11845 13.1738 2.47231 13.5277C2.82616 13.8815 3.35966 14.1748 4.25995 14.3749C5.17538 14.5783 6.38343 14.6667 8 14.6667C9.61657 14.6667 10.8246 14.5783 11.74 14.3749C12.6403 14.1748 13.1738 13.8815 13.5277 13.5277C13.8815 13.1738 14.1748 12.6403 14.3749 11.74C14.5783 10.8246 14.6667 9.61657 14.6667 8C14.6667 6.38343 14.5783 5.17538 14.3749 4.25995C14.1748 3.35966 13.8815 2.82616 13.5277 2.47231C13.1738 2.11846 12.6403 1.82522 11.74 1.62514C10.8246 1.42169 9.61657 1.33333 8 1.33333C6.38343 1.33333 5.17538 1.42169 4.25995 1.62514C3.35966 1.82522 2.82616 2.11846 2.47231 2.47231C2.11845 2.82616 1.82522 3.35966 1.62514 4.25995C1.42169 5.17538 1.33333 6.38343 1.33333 8Z"
                        fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                      />
                    </svg>
                  }
                  customClass="pad"
                  text={GetTranslateString("Add New Account")}
                /> */}
              </div>
              <RecentActivities recentActivities={transactions} />
            </div>

            <div className="box cashout">
              <div className="headersection flex al-ce jc-sp">
                <div>{translate("Cash Out Summary")}</div>
              </div>
              <Cashout
                bankAccountNames={bankAccountNames}
                cashoutData={payout}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Wallet;
