import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React, { useContext, useState } from "react";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import Close from "../../assets/close.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import addStaff from "../../firebase/staff/addStaff";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import Loader from "../general/Loader";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

export default function AddStaff({
  setActiveItem,
  setSelectedStaff,
  prefix,
  dark,
}) {
  let localCurrency = localStorage.getItem("currency");
  const { rates } = useContext(CurrencyContext);

  const initialValues = {
    staffName: "",
    staffId: "",
    staffEmail: "",
    dateJoin: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const [isDisabled, updateDisabled] = useState(false);
  const [fields, setFields] = useState(initialValues);
  const [loading, toggleLoading] = useState(false);

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const clearValues = () => {
    setFields(initialValues);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFields(initialValues);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        imgSrc={
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.66675 5.33334C8.66675 4.96515 8.36827 4.66667 8.00008 4.66667C7.63189 4.66667 7.33341 4.96515 7.33341 5.33334V7.33334H5.33341C4.96522 7.33334 4.66675 7.63182 4.66675 8C4.66675 8.36819 4.96523 8.66667 5.33341 8.66667H7.33341V10.6667C7.33341 11.0349 7.63189 11.3333 8.00008 11.3333C8.36827 11.3333 8.66675 11.0349 8.66675 10.6667V8.66667H10.6667C11.0349 8.66667 11.3334 8.36819 11.3334 8C11.3334 7.63182 11.0349 7.33334 10.6667 7.33334H8.66675V5.33334Z"
              fill="#767BFA"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 8C0 14.588 1.412 16 8 16C14.588 16 16 14.588 16 8C16 1.412 14.588 0 8 0C1.412 0 0 1.412 0 8ZM1.33333 8C1.33333 9.61657 1.42169 10.8246 1.62514 11.74C1.82522 12.6403 2.11845 13.1738 2.47231 13.5277C2.82616 13.8815 3.35966 14.1748 4.25995 14.3749C5.17538 14.5783 6.38343 14.6667 8 14.6667C9.61657 14.6667 10.8246 14.5783 11.74 14.3749C12.6403 14.1748 13.1738 13.8815 13.5277 13.5277C13.8815 13.1738 14.1748 12.6403 14.3749 11.74C14.5783 10.8246 14.6667 9.61657 14.6667 8C14.6667 6.38343 14.5783 5.17538 14.3749 4.25995C14.1748 3.35966 13.8815 2.82616 13.5277 2.47231C13.1738 2.11846 12.6403 1.82522 11.74 1.62514C10.8246 1.42169 9.61657 1.33333 8 1.33333C6.38343 1.33333 5.17538 1.42169 4.25995 1.62514C3.35966 1.82522 2.82616 2.11846 2.47231 2.47231C2.11845 2.82616 1.82522 3.35966 1.62514 4.25995C1.42169 5.17538 1.33333 6.38343 1.33333 8Z"
              fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
            />
          </svg>
        }
        text={translate("addStaff")}
      />
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="header">
              <div>{translate("addingNewStaff")}...</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("staffName")}</div>
                <Input
                  name="staffName"
                  type={"text"}
                  value={fields.staffName}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterName")}
                />
              </div>

              <div>
                <div className="label">{translate("staffId")}</div>
                <Input
                  prefix={prefix}
                  name="staffId"
                  type={"text"}
                  value={fields.staffId}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterId")}
                />
              </div>

              <div>
                <div className="label">{translate("email")}</div>
                <Input
                  name="staffEmail"
                  type={"email"}
                  value={fields.staffEmail}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterEmail")}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("dateJoin")}</div>
                <Input
                  name="dateJoin"
                  type={"date"}
                  dark={dark}
                  value={fields.dateJoin}
                  updateValue={handleChange}
                  placeholder={"Jan 00,0000"}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("monday")}</div>
                <Input
                  name="monday"
                  type={"text"}
                  value={fields.monday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>

              <div>
                <div className="label">{translate("tuesday")}</div>
                <Input
                  name="tuesday"
                  type={"text"}
                  value={fields.tuesday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>

              <div>
                <div className="label">{translate("wednesday")}</div>
                <Input
                  name="wednesday"
                  type={"text"}
                  value={fields.wednesday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("thursday")}</div>
                <Input
                  name="thursday"
                  type={"text"}
                  value={fields.thursday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>

              <div>
                <div className="label">{translate("friday")}</div>
                <Input
                  name="friday"
                  type={"text"}
                  value={fields.friday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>

              <div>
                <div className="label">{translate("saturday")}</div>
                <Input
                  name="saturday"
                  type={"text"}
                  value={fields.saturday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("sunday")}</div>
                <Input
                  name="sunday"
                  type={"text"}
                  value={fields.sunday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>
            </div>

            <div className="btns w-full jus-end mt-10">
              <div>
                <Loader loading={loading} />
              </div>

              <div className="ml-10">
                <Button
                  disabled={isDisabled}
                  imgSrc={<img src={dark ? AddDark : Add} alt="add" />}
                  onClick={async () => {
                    updateDisabled(true);
                    try {
                      toggleLoading(true);

                      await addStaff(StoreId, {
                        staffName: fields.staffName.trim(),
                        staffEmail: fields.staffEmail,
                        staffId: fields.staffId.trim(),
                        dateJoin: fields.dateJoin,
                        monday: fields.monday.trim(),
                        tuesday: fields.tuesday.trim(),
                        wednesday: fields.wednesday.trim(),
                        thursday: fields.thursday.trim(),
                        friday: fields.friday.trim(),
                        saturday: fields.saturday.trim(),
                        sunday: fields.sunday.trim(),
                      });
                      setActiveItem();
                      setSelectedStaff({});
                      //   await createExpense(StoreId, {
                      //     name: fields.name.trim(),
                      //     spentDate: fields.spentDate,
                      //     amountSpent: parseFloat(
                      //       (
                      //         fields.amountSpent /
                      //         rates?.[localCurrency?.toUpperCase()]
                      //       ).toFixed(2)
                      //     ),
                      //     tax: parseFloat(fields.tax),
                      //     shipping: parseFloat(
                      //       (
                      //         fields.shipping /
                      //         rates?.[localCurrency?.toUpperCase()]
                      //       ).toFixed(2)
                      //     ),
                      //     duration: fields.duration,
                      //     quantity: parseInt(fields.quantity),
                      //     transactionId: fields.transactionId.trim(),
                      //     notes: fields.notes.trim(),
                      //   });
                    } catch (e) {
                      ErrorToast(translate("error"), e.message);
                      toggleLoading(false);
                      updateDisabled(false);
                      return;
                    }
                    SuccessToast(translate("success"), translate("staffAdded"));
                    handleClose();
                    clearValues();
                    updateDisabled(false);
                    toggleLoading(false);
                  }}
                  text={translate("addNew")}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
