import { doc, getDoc, increment, updateDoc } from "firebase/firestore";
import { db } from "../index";

export default async function editExpense(storeId, expenseId, data) {
  //data.name
  //data.name
  if (!data.name || data.name.length < 3) {
    throw new Error("Name is not a valid value.");
  }

  if (!data.spentDate) {
    throw new Error("Date is not a valid value.");
  }

  if (isNaN(data.amountSpent) || !data.amountSpent) {
    throw new Error("Invalid amount spent value.");
  }

  if (data.shipping) {
    if (isNaN(data.shipping)) {
      throw new Error("Invalid shipping value.");
    }
  }

  if (isNaN(data.tax)) {
    throw new Error("Invalid value.");
  }

  if (isNaN(data.quantity) || !data.quantity) {
    throw new Error("Invalid quantity value.");
  }

  if (!data.duration) {
    throw new Error("Invalid value.");
  }

  const docRef = doc(db, "Stores", storeId, "Expenses", expenseId);
  const docSnap = await getDoc(docRef);

  const counterRef = doc(db, "Stores", storeId);

  const decrementValue = increment(
    // data.amountSpent - docSnap.data().amountSpent
    (data.amountSpent * (data.tax / 100) + data.amountSpent + data.shipping) - (docSnap.data().amountSpent * (docSnap.data().tax / 100) + docSnap.data().amountSpent + docSnap.data().shipping)
  );

  await updateDoc(counterRef, { expenseValue: decrementValue });

  return updateDoc(docRef, { ...data }, { merge: true });
}
