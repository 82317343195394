import "../css/ProductInfo.scoped.css";
import { collection, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import { db } from "../../firebase/index";
import translate from "../../i18n/translate";

const StaffOverviews = () => {
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  const [data, setData] = useState([]);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "Stores", StoreId, "SoldItems"),
      async (snapShot) => {
        const conData = snapShot.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
        setData(conData);
      }
    );
    return () => {
      inventoryListener();
    };
  }, []);

  return (
    <div className="staff-overviews">
      <div className="headersection priceheader flex al-ce jc-sp">
        <div className="smtext">{translate("marketSales")}</div>
        <div className="bold total">
          <span className="query totaltext">{translate("total")}:</span>
          <span className="bold">{data?.length}</span>
        </div>
        <div />
      </div>
      <div className="grid">
        <div className="headerscotainer">
          <div className="headersgrid">
            <div>{translate("id")}</div>
            <div>{translate("name")}</div>
            <div>{translate("email")}</div>
            <div>{translate("totalSales")}</div>
          </div>
        </div>

        <div className="items-container">
          <div className="items">
            {data &&
              data.map((e, i) => (
                <div key={i} className="item">
                  <div>{e.staffId}</div>
                  <div>{e.name}</div>
                  <div>{e.email}</div>
                  <div>
                    {sign[localCurrency]}
                    {parseFloat(
                      (e.total * rates?.[localCurrency?.toUpperCase()]).toFixed(
                        2
                      )
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffOverviews;
