import { getDocs, query, collection, orderBy, where } from "firebase/firestore";
import { db } from "../index";

export default async function consignmentSalesGraph(storeId) {
    const expensesRef = collection(db, "Stores", storeId, "SoldItems");

    let result = {};
    const q = query(
        expensesRef,
        where("type", "==", "consignment"),
        orderBy("dateSold", "desc")
    );

    const graphSnapshot = await getDocs(q);
    if (graphSnapshot.size === 0) {
        return new Error("No sales yet!")
    }

    let count = 0, total = 0;

    for (let data of graphSnapshot.docs) {
        let date = new Date(data.data().dateSold)
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = date.getFullYear();

        date = yyyy + '-' + mm + '-' + dd;

        if (result[date] === undefined) {
            result[date] = data.data().soldPrice
        }
        else {
            result[date] += data.data().soldPrice
        }

        result[date] = parseFloat(result[date].toFixed(2))
        count++; total += result[date];
    }
    return { count, total, result };
}
