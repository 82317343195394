import React, { useContext, useEffect, useState } from "react";
import translate from "../../i18n/translate";
import numberWithCommas from "../utils/numberWithCommas";
import "../css/TicketContainer.scoped.css";
import SearchModal from "../tickets/searchModal";
import TicketGrid from "./TicketGrid";
import axios from "axios";
import { ErrorToast } from "../general/ToastNotification";
import { CurrencyContext } from "../../context/CurrencyContext";

const TicketContainer = ({ isOpen, toggleState, dark }) => {
  const [selectedData, setSelectedData] = useState(null);
  const [addModal, setAddModal] = useState(false);

  const [total, setTotal] = useState({
    tv: 0,
    ti: 0,
    tup: 0,
  });

  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  function formatPrice(p) {
    return `${sign[localCurrency]}${Number(p.toFixed(2))}`;
  }

  return (
    <div>
      <SearchModal
        isOpen={isOpen}
        toggleState={toggleState}
        dark={dark}
        setSelectedData={setSelectedData}
        setAddModal={setAddModal}
      />
      <div className="inventory-container ">
        <div className="flex al-ce jc-sp headersection">
          <div className="smtext">{translate("summary")}</div>
          <div className="flex al-ce">
            <div className="query flex al-ce">
              <span className="purple">{translate("totalValue")}:</span>
              <span className="queryvalue">${total.tv}</span>
            </div>
            <div className="query">
              <span className="purple">{translate("totalItems")}:</span>
              <span className="queryvalue">{total.ti}</span>
            </div>
            <div className="query">
              <span className="purple">
                {translate("totalUnrealizedProfit")}:
              </span>
              <span className={`queryvalue ${total.tup > 0 ? "green" : "red"}`}>
                ${total.tup}
              </span>
            </div>
          </div>

          <div className="flex">
            {/*<PriceAlertModal dark={dark} />
            <Button
              text={translate("refresh")}
              imgSrc={
                <img
                  src={dark ? refreshIconDark : refreshIcon}
                  alt="refresh Icon"
                />
              }
              onClick={async () => {
                setTotal(await updateNormalCount(StoreId));
              }}
            />*/}
          </div>
        </div>
        <TicketGrid
          dark={dark}
          setTotal={setTotal}
          selectedData={selectedData}
          addModal={addModal}
          setAddModal={setAddModal}
        />
      </div>
    </div>
  );
};

export default TicketContainer;
