import React, { useContext } from "react";
import { CurrencyContext } from "../../context/CurrencyContext";
import "../css/StaffDetails.scoped.css";
import numberWithCommas from "../utils/numberWithCommas";
import translate from "../../i18n/translate";
export default function StaffDetails({ selectedStaff }) {
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  return (
    <div className="staff-details">
      <div className="twogrid">
        <div className="flex">
          <span className="query bold">{translate("staffName")}: </span>
          <span className="value">{selectedStaff.staffName}</span>
        </div>

        <div className="flex">
          <span className="query bold">{translate("staffId")}: </span>
          <span className="value">{selectedStaff.staffId}</span>
        </div>

        <div className="flex">
          <span className="query bold">{translate("email")}: </span>
          <span className="value">{selectedStaff.staffEmail}</span>
        </div>

        <div className="flex">
          <span className="query bold">{translate("dateJoined")}: </span>
          <span className="value">
            {new Date(selectedStaff.dateJoin)
              .toDateString()
              .split(" ")
              .slice(1, 4)
              .join(" ")}
          </span>
        </div>
      </div>

      <div className="flex">
        <span className="query bold">{translate("totalNumberOfSales")}: </span>
        <span className="value">
          {numberWithCommas(
            selectedStaff.numberOfSales ? selectedStaff.numberOfSales : 0
          )}
        </span>
      </div>

      <div className="flex">
        <span className="query bold">{translate("totalFigures")}: </span>
        <span className="value">
          {sign[localCurrency]}
          {numberWithCommas(
            selectedStaff.figures ? selectedStaff.figures.toFixed(2) : 0
          )}
        </span>
      </div>

      <div className="flex">
        <span className="query bold">{translate("workingPeriodAssigned")}: </span>
        <span className="value">
          <div className="grid">
            <span>{translate("sunday")}</span>
            <span>{selectedStaff.sunday ? selectedStaff.sunday : "-"}</span>
          </div>
          <div className="grid">
            <span>{translate("monday")}</span>
            <span>{selectedStaff.monday ? selectedStaff.monday : "-"}</span>
          </div>
          <div className="grid">
            <span>{translate("tuesday")}</span>
            <span>{selectedStaff.tuesday ? selectedStaff.tuesday : "-"}</span>
          </div>
          <div className="grid">
            <span>{translate("wednesday")}</span>
            <span>
              {selectedStaff.wednesday ? selectedStaff.wednesday : "-"}
            </span>
          </div>
          <div className="grid">
            <span>{translate("thursday")}</span>
            <span>{selectedStaff.thursday ? selectedStaff.thursday : "-"}</span>
          </div>
          <div className="grid">
            <span>{translate("friday")}</span>
            <span>{selectedStaff.friday ? selectedStaff.friday : "-"}</span>
          </div>
          <div className="grid">
            <span>{translate("saturday")}</span>
            <span>{selectedStaff.saturday ? selectedStaff.saturday : "-"}</span>
          </div>
        </span>
      </div>
    </div>
  );
}
