import { useEffect } from "react";
import "../css/Box.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import { StoreId } from "../../Constants";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import QrSettings from "../../firebase/customization/qrSettings";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

const Background = ({ dark, bg, setBg, data }) => {
  useEffect(() => {
    if (data?.imgUrl) {
      setBg(data.bg);
    }
  }, [data]);

  return (
    <div>
      <div className="label">{translate("background")}</div>

      <div className="box twogrid smbox equal">
        <div>
          <div className="lighttext">{translate("recommendedSize")}: 428x926</div>
          <Input
            name={"bg"}
            dark={false}
            type="text"
            value={bg}
            custom
            updateValue={setBg}
            placeholder={GetTranslateString("enterUrl")}
            customClass={"lightinput"}
          />
        </div>

        <div className="flex al-fe col">
          <div className="upload rect">
            {!bg ? (
              <svg
                width="35"
                height="30"
                viewBox="0 0 35 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0547 8.88496C11.7826 3.73374 16.3968 0 22.037 0C29.154 0 35 5.93827 35 13.1816C35 20.3638 29.1415 26.4696 22.037 26.3858C21.3211 26.3858 20.7407 25.7951 20.7407 25.0665C20.7407 24.3379 21.3211 23.7472 22.037 23.7472C27.5177 23.6757 32.4074 18.829 32.4074 13.1816C32.4074 7.4827 27.4711 2.63632 22.037 2.63632C17.8547 2.63632 14.3583 5.56155 12.9398 9.45494C12.929 9.51876 12.9134 9.58256 12.8928 9.64591L12.8924 9.64728L12.8894 9.65665L12.8758 9.69997C12.8636 9.7393 12.8456 9.79854 12.8236 9.87364C12.7795 10.0245 12.7206 10.2359 12.6619 10.4763C12.5374 10.987 12.4425 11.5161 12.4428 11.8717C12.4436 12.749 12.5314 13.1947 12.8631 14.0049C13.1384 14.6775 12.8259 15.4499 12.1651 15.7301C11.5042 16.0103 10.7453 15.6922 10.4699 15.0197C10.007 13.8888 9.85138 13.0991 9.85024 11.8742C9.85022 11.8564 9.85032 11.8386 9.85051 11.8208C9.49487 11.7527 9.12709 11.717 8.75 11.717C5.52834 11.717 2.59259 14.5596 2.59259 17.7951C2.59259 21.0307 5.85241 23.7472 9.07407 23.7472H11.6667C12.3826 23.7472 12.963 24.3379 12.963 25.0665C12.963 25.7951 12.3826 26.3858 11.6667 26.3858H9.07407C4.24158 26.3858 0 22.6485 0 17.7951C0 12.9418 3.91751 8.78772 8.75 8.78772C9.19284 8.78772 9.62858 8.82088 10.0547 8.88496Z"
                  fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                />
                <path
                  d="M15.9353 16.2179C16.4415 15.7027 17.2623 15.7027 17.7686 16.2179L21.6574 20.1758C22.1637 20.691 22.1637 21.5263 21.6574 22.0415C21.1512 22.5567 20.3304 22.5567 19.8242 22.0415L18.1482 20.3358V27.7051C18.1482 28.4337 17.5679 29.0244 16.8519 29.0244C16.136 29.0244 15.5556 28.4337 15.5556 27.7051V20.3358L13.8797 22.0415C13.3734 22.5567 12.5527 22.5567 12.0464 22.0415C11.5402 21.5263 11.5402 20.691 12.0464 20.1758L15.9353 16.2179Z"
                  fill="#767BFA"
                />
              </svg>
            ) : (
              <img src={bg} alt="not found" />
            )}
          </div>

          <div className="flex">
            <Button
              customClass={"lightish"}
              onClick={() => {
                try {
                  QrSettings(StoreId, {
                    bg: bg.trim(),
                  });
                  SuccessToast(translate("success"), translate("logoUpdated"));
                } catch (e) {
                  ErrorToast(translate("error"), e.message);
                }
              }}
              text={translate("save")}
              imgSrc={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.66675 5.33334C8.66675 4.96515 8.36827 4.66667 8.00008 4.66667C7.63189 4.66667 7.33341 4.96515 7.33341 5.33334V7.33334H5.33341C4.96522 7.33334 4.66675 7.63182 4.66675 8C4.66675 8.36819 4.96523 8.66667 5.33341 8.66667H7.33341V10.6667C7.33341 11.0349 7.63189 11.3333 8.00008 11.3333C8.36827 11.3333 8.66675 11.0349 8.66675 10.6667V8.66667H10.6667C11.0349 8.66667 11.3334 8.36819 11.3334 8C11.3334 7.63182 11.0349 7.33334 10.6667 7.33334H8.66675V5.33334Z"
                    fill="#767BFA"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 8C0 14.588 1.412 16 8 16C14.588 16 16 14.588 16 8C16 1.412 14.588 0 8 0C1.412 0 0 1.412 0 8ZM1.33333 8C1.33333 9.61657 1.42169 10.8246 1.62514 11.74C1.82522 12.6403 2.11845 13.1738 2.47231 13.5277C2.82616 13.8815 3.35966 14.1748 4.25995 14.3749C5.17538 14.5783 6.38343 14.6667 8 14.6667C9.61657 14.6667 10.8246 14.5783 11.74 14.3749C12.6403 14.1748 13.1738 13.8815 13.5277 13.5277C13.8815 13.1738 14.1748 12.6403 14.3749 11.74C14.5783 10.8246 14.6667 9.61657 14.6667 8C14.6667 6.38343 14.5783 5.17538 14.3749 4.25995C14.1748 3.35966 13.8815 2.82616 13.5277 2.47231C13.1738 2.11846 12.6403 1.82522 11.74 1.62514C10.8246 1.42169 9.61657 1.33333 8 1.33333C6.38343 1.33333 5.17538 1.42169 4.25995 1.62514C3.35966 1.82522 2.82616 2.11846 2.47231 2.47231C2.11845 2.82616 1.82522 3.35966 1.62514 4.25995C1.42169 5.17538 1.33333 6.38343 1.33333 8Z"
                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                  />
                </svg>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Background;
