import axios from "axios";
export default async function requestCashout(token, accountId, type, amount) {
  if (!token) {
    throw new Error("Token not provided");
  }

  if (!accountId) {
    throw new Error("Please select a bank account");
  }

  if (!(type === "1" || type === "14")) {
    throw new Error("Request period must be 1 or 14 working day");
  }

  if (amount <= 0) {
    throw new Error("Not enough balance available to cashout");
  }

  console.log({
    token,
    accountId,
    type,
  });

  const resp = await axios.post("https://tickets.katch.gg/payout/request", {
    // const resp = await axios.post("http://localhost:80/payout/request", {
    token,
    accountId,
    type,
  });

  if (!resp.data) {
    throw new Error(resp.error.message);
  }
  if (resp.error) {
    throw new Error(resp.error);
  }

  return resp.data;
}
