import { doc, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import refreshIcon from "../../assets/refresh.svg";
import refreshIconDark from "../../assets/refreshdark.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import { db } from "../../firebase/index";
import updateNormalCount from "../../firebase/util/updateNormalCount";
import "../css/InenvtoryContainer.scoped.css";
import Button from "../general/Button";
import numberWithCommas from "../utils/numberWithCommas";
import PriceAlertModal from "./PriceAlertModal"
import InventoryGrid from "./InventoryGrid";
import translate from "../../i18n/translate";
const InenvtoryContainer = ({ searchString, dark }) => {
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  const [total, setTotal] = useState({
    totalItems: 0,
    totalValue: 0,
  });

  const [totalProfit, setTotalProfit] = useState(0);

  useEffect(() => {
    const cleanup = onSnapshot(doc(db, "Stores", StoreId), (doc) => {
      setTotal(doc.data());
    });
    return () => {
      cleanup();
    };
  }, []);

  return (
    <div className="inventory-container ">
      <div className="flex al-ce jc-sp headersection">
        <div className="smtext">{translate("summary")}</div>
        <div className="flex al-ce">
          <div className="query flex al-ce">
            <span className="purple">{translate("totalValue")}:</span>
            <span className="queryvalue">
              {sign[localCurrency]}
              {numberWithCommas(
                (
                  total.totalValue * rates?.[localCurrency?.toUpperCase()]
                ).toFixed(2)
              )}
            </span>
          </div>
          <div className="query">
            <span className="purple">{translate("totalItems")}:</span>
            <span className="queryvalue">
              {numberWithCommas(total.totalItems)}
            </span>
          </div>
          <div className="query">
            <span className="purple">{translate("totalUnrealizedProfit")}:</span>
            <span
              className={`queryvalue ${totalProfit >= 0 ? "green" : "red"}`}
            >
              {sign[localCurrency]}
              {numberWithCommas(
                (totalProfit * rates?.[localCurrency?.toUpperCase()]).toFixed(2)
              )}
            </span>
          </div>
        </div>
       
        <div className="flex">
          <PriceAlertModal dark={dark} />
          <Button
            text={translate("refresh")}
            imgSrc={
              <img
                src={dark ? refreshIconDark : refreshIcon}
                alt="refresh Icon"
              />
            }
            onClick={async () => {
              setTotal(await updateNormalCount(StoreId));
            }}
          />
        </div>
        
      </div>
      <InventoryGrid
        dark={dark}
        setTotalProfit={setTotalProfit}
        searchString={searchString}
      />
    </div>
  );
};

export default InenvtoryContainer;
