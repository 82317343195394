import {
  getDocs,
  query,
  where,
  limit,
  collection,
  orderBy,
} from "firebase/firestore";
import { db } from "../../index";

export default async function clockInOutDetails(storeId, staffId) {
  const logsRef = collection(db, "Stores", storeId, "Staff", "Shifts", "Logs");

  const q = query(
    logsRef,
    where("staffId", "==", staffId),
    orderBy("date", "desc"),
    limit(7)
  );

  const querySnapshot = await getDocs(q);
  const res = [];
  querySnapshot.docs.forEach((data) => {
    res.push(data.data());
  });

  return res;
}
