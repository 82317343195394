import { format, parseISO, subDays } from "date-fns";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "../css/Graph.scoped.css";
import { useContext } from "react";
import { CurrencyContext } from "../../context/CurrencyContext";
import numberWithCommas from "../utils/numberWithCommas";

export default function Graph({ graphData, dark }) {
  const { rates } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  return (
    <ResponsiveContainer width="100%" height="72%">
      <BarChart data={graphData ? [...graphData] : []}>
        <CartesianGrid opacity={1} stroke={dark ? "#3A4462" : "#F0F0F0"} />
        <defs>
          <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor="#767BFA
"
              stopOpacity={1}
            />
            <stop
              offset="75%"
              stopColor="#767BFA
"
              stopOpacity={0.0}
            />
          </linearGradient>
        </defs>

        <Bar
          dataKey="value"
          stroke="transparent"
          fill="url(#color1)"
          type={"monotone"}
        />

        <XAxis
          fontSize={"0.625rem"}
          dataKey="date"
          tick={{ fill: dark ? "#F6F7FF" : "inherit" }}
          axisLine={false}
          tickLine={false}
          tickFormatter={(str) => {
            const date = parseISO(str);
            if (date.getDate()) {
              return format(date, "MMM, d");
            }
            return "";
          }}
        />

        <YAxis
          tick={{ fill: dark ? "#F6F7FF" : "inherit" }}
          tickCount={4}
          fontSize={"0.625rem"}
          datakey="value"
          axisLine={false}
          tickLine={false}
          tickFormatter={(number) =>
            `${number * rates?.[localCurrency?.toUpperCase()].toFixed(0)}`
          }
        />

        <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
      </BarChart>
    </ResponsiveContainer>
  );
}

function CustomTooltip({ active, payload, label }) {
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  if (active) {
    return (
      <div className="tooltip">
        <h4>{label}</h4>
        <p>
          {sign[localCurrency]}
          {numberWithCommas(
            (payload[0].value * rates?.[localCurrency?.toUpperCase()]).toFixed(
              2
            )
          )}{" "}
          {localCurrency?.toUpperCase()}
        </p>
      </div>
    );
  }
  return null;
}
