import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import React, { useRef, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import translate from "../../i18n/translate";
import Close from "../../assets/close.svg";
import CloseDark from "../../assets/closedark.svg";
import Input from "../general/Input";
import GetTranslateString from "../../i18n/translateString";
import "../css/AddConsignmentModal.scoped.css";
import algoliasearch from "algoliasearch";
import {
  useSearchBox,
  useInfiniteHits,
  Index,
  InstantSearch,
} from "react-instantsearch-hooks";

const data = [1];

export default function SearchModal({
  isOpen,
  toggleState,
  dark,
  setSelectedData,
  setAddModal,
}) {
  const style = {
    position: "absolute",
    top: "5rem",
    left: "50%",
    transform: "translate(-50%, 0%)",
    maxWidth: "40rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const searchClient = algoliasearch(
    "T0D27TL2IS",
    "eb6b6b9540ee65cca3c20bab0f7f0b52"
  );

  return (
    <div>
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={() => toggleState(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="header">
              <div>{translate("search")}</div>
              <img
                onClick={() => {
                  toggleState(false);
                }}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>

            <InstantSearch searchClient={searchClient} indexName={"shows"}>
              <div className="row">
                <SearchInput />
              </div>

              <SearchResults
                dark={dark}
                setSelectedData={setSelectedData}
                toggleState={toggleState}
                setAddModal={setAddModal}
              />
            </InstantSearch>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const SearchResults = ({ dark, setSelectedData, toggleState, setAddModal }) => {
  const { hits, results, isLastPage, showMore } = useInfiniteHits();
  console.log(hits);
  function handleSelect(data) {
    setSelectedData(data);
    toggleState(false);
    setAddModal(true);
  }
  return (
    <div className="search-results">
      {hits?.map((item, index) => {
        return (
          <div
            key={index}
            className="ticket-container"
            onClick={() => handleSelect(item)}
          >
            <div className={`ticket ${dark ? "dark" : ""}`}>
              <img
                className="ticket-img"
                alt="ticket-img"
                src={item?.thumbImg}
              />

              <div className={`ticket-info ${dark ? "dark" : ""}`}>
                <div className="ticket-title">{item.name}</div>
                <div className="ticket-description">
                  {item.venueName}, {item.venueLocation}
                </div>
                <div className="ticket-date">
                  {new Date(item.dateStart).toLocaleString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

function SearchInput() {
  const { query, refine, isSearchStalled } = useSearchBox();
  const [search, setSearch] = useState(query);
  const inputRef = useRef(null);

  function setQuery(newQuery) {
    setSearch(newQuery);
    refine(newQuery);
  }

  if (query !== search && !inputRef.current?.isFocused()) {
    setSearch(query);
  }

  return (
    <div>
      <div className="label">{translate("Search Text")}</div>
      <Input
        ref={inputRef}
        name="search"
        type={"text"}
        value={search}
        updateValue={(e) => {
          setQuery(e.target.value);
        }}
        placeholder={GetTranslateString("search")}
      />
    </div>
  );
}
