import {
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  deleteField,
} from "firebase/firestore";
import { db } from "../index";
import { StoreId } from "../../Constants";
import axios from "axios";

export default async function deleteAsk(token, data) {
  // console.log(data);
  // Input validations
  if (!data.sku) {
    throw new Error("Invalid SKU provided");
  }

  if (!data.askId) {
    throw new Error("Invalid Ask Id provided");
  }

  const resp = await axios.post(
    `https://us-central1-ingenium-337101.cloudfunctions.net/deleteAsk`,
    {
      sku: data.sku,
      token: token,
      askId: data.askId,
    }
  );

  // const resp = await axios.post(
  //   `http://localhost:80/marketplace/delete/ask/${data.askId}`,
  //   {
  //     sku: data.sku,
  //     storeId: StoreId,
  //   }
  // );

  // console.log(resp);

  if (!resp.data.success) {
    // // roll back changes if error in placing ask
    // await deleteDoc(doc(db, "Stores", storeId, "ListedItems", data.id));
    // await setDoc(
    //   doc(db, "Stores", storeId, "Inventory", data.id),
    //   {
    //     askId: deleteField(),
    //     isAsked: deleteField(),
    //     askedPrice: deleteField(),
    //     listedAt: deleteField(),
    //   },
    //   { merge: true }
    // );
    throw new Error(resp.data.error);
  } else {
    await setDoc(
      doc(db, "Stores", StoreId, "Inventory", data.id),
      {
        askId: null,
        isAsked: false,
        askedPrice: null,
        listedAt: null,
      },
      { merge: true }
    );

    await deleteDoc(doc(db, "Stores", StoreId, "ListedItems", data.id));
  }
}
