import React, { useState, useEffect } from "react";
import "../css/ConsigneeTable.scoped.css";
import { db } from "../../firebase";
import { StoreId } from "../../Constants";
import { onSnapshot, collection } from "firebase/firestore";
import translate from "../../i18n/translate";
const ConsigneeTable = ({ updateConsigneeCount }) => {
  const [data, updateData] = useState([]);
  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "Stores", StoreId, "Consignee"),
      async (snapShot) => {
        const conData = snapShot.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
        updateData(conData);
        updateConsigneeCount(conData.length);
      }
    );
    return () => {
      inventoryListener();
    };
  }, [updateConsigneeCount]);
  return (
    <>
      <div className="headersgrid">
        <div>{translate("id")}</div>
        <div>{translate("name")}</div>
        <div>{translate("email")}</div>
      </div>
      <>
        <div className="items-container">
          <div className="items">
            {data.map((consignee) => (
              <div key={consignee.id} className="item">
                <div>{consignee.id}</div>
                <div>{consignee.name}</div>
                <div>{consignee.email}</div>
              </div>
            ))}
          </div>
        </div>
      </>
    </>
  );
};

export default ConsigneeTable;
