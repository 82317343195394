import { collection, onSnapshot } from "firebase/firestore";
import _ from "lodash";
import { useEffect, useState } from "react";
import { searchAllInventory } from "../algolia/index";
import Printer from "../assets/printer.svg";
import Buttons from "../components/general/Button";
import SearchBtn from "../components/general/SearchBtn";
import Topbar from "../components/general/Topbar";
import ProductCardQR from "../components/product/ProductCard";
import ProductCardNormal from "../components/product/ProductCardNoQR";
import { StoreId } from "../Constants";
import { db } from "../firebase/index";
import batchFetch from "../firebase/util/batchFetch"; //
import "./css/ProductLabels.scoped.css";
import { searchProducts } from "../algolia/products";
import PrinterDark from "../assets/printerdark.svg";
import { useNavigate } from "react-router-dom";
import Container from "../components/qrSetting/Container";
import { makeStyles } from "@mui/styles";
import { Menu, MenuItem, Button } from "@mui/material";
import StockContainer from "../components/stocks/StockContainer";
import MissingItemsModals from "../components/stocks/MissingItemsModals";
import { BrowserQRCodeSvgWriter } from "@zxing/browser";
import deleteStockTagging from "../firebase/stocks/deleteStockTagging";
import translate from "../i18n/translate";
import { getAuth, onIdTokenChanged } from "firebase/auth";

const ProductLabels = ({ user, dark }) => {
  // `http://store.ingenium.biz/barcode?store=${StoreId}`;
  const barcodeScan = `https://scan-barcode.netlify.app/?store=${StoreId}`;
  const codeWriter = new BrowserQRCodeSvgWriter();
  const svgElement = codeWriter.write(barcodeScan, 180, 180);
  const svgHtml = svgElement.outerHTML;
  const [active, setActive] = useState(0);
  const [qrData, updateQrData] = useState([]);
  const [data, updateData] = useState([]);
  const [scannedData, updateScannedData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchString, updateSearchString] = useState("");
  const [searchString2, updateSearchString2] = useState("");
  const [searchString3, updateSearchString3] = useState("");
  const isOwner = localStorage.getItem("owner");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let hideProductQr = localStorage.getItem("hideProductQr");
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const useStyles = makeStyles(() => ({
    menuPaper: {
      backgroundColor: dark ? "#353C53" : "#f6f7ff",
      color: !dark ? "#353C53" : "#fff",
      maxWidth: "16.25rem",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      width: "100%",
    },
  }));
  const classes = useStyles();
  const navigate = useNavigate();

  const auth = getAuth();
  // console.log(auth);
  useEffect(() => {
    const authListener = onIdTokenChanged(auth, async (user) => {
      // console.log(auth)
      if (user) {
        const token = await user.getIdToken();
        localStorage.setItem("token", token);
      }
    });
    return () => {
      authListener();
    };
  }, []);

  useEffect(() => {
    let hidden = localStorage.getItem("isProductLabelsHidden");
    if (!isOwner && hidden) {
      navigate("/dash");
    }
  }, [isOwner, navigate]);

  useEffect(() => {
    setSearch("");
  }, [active]);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "Stores", StoreId, "Products"),
      async (snapShot) => {
        let invData = [];
        if (searchString2 === "") {
          invData = snapShot.docs.map((e) => {
            return {
              ...e.data(),
              id: e.id,
            };
          });
        } else {
          let hits = await searchProducts(searchString2);

          invData = hits.map((e) => ({
            ...e,
            id: e.objectID.split("/")[1],
          }));

          snapShot.docChanges().forEach((change) => {
            const changedDoc = change.doc.data();
            const id = change.doc.id;

            if (change.type === "removed") {
              //let us remove from INVdata if this exists
              _.remove(invData, (k) => k.id === id);
            }

            if (change.type === "modified") {
              const exists = _.findIndex(invData, (k) => k.id === id);
              if (exists !== -1) {
                //This element was modified
                invData[exists] = {
                  ...changedDoc,
                  id: id,
                };
              }
            }
          });
        }
        updateQrData(invData);
        // const productRefs = invData.map((data) => data.style.path);
        // const productData = await batchFetch(productRefs);
        // updateData(invData.map((e, i) => ({ ...e, ...productData[i] })));
      }
    );

    return () => {
      inventoryListener();
    };
  }, [searchString2]);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "Stores", StoreId, "Inventory"),
      async (snapShot) => {
        let invData = [];
        if (searchString == "") {
          invData = snapShot.docs.map((e) => {
            return {
              ...e.data(),
              id: e.id,
            };
          });
        } else {
          let hits = await searchAllInventory(searchString);

          invData = hits.map((e) => ({
            ...e,
            id: e.objectID.split("/")[1],
          }));

          snapShot.docChanges().forEach((change) => {
            const changedDoc = change.doc.data();
            const id = change.doc.id;

            if (change.type === "removed") {
              //let us remove from INVdata if this exists
              _.remove(invData, (k) => k.id === id);
            }

            if (change.type === "modified") {
              const exists = _.findIndex(invData, (k) => k.id === id);
              if (exists !== -1) {
                //This element was modified
                invData[exists] = {
                  ...changedDoc,
                  id: id,
                };
              }
            }
          });
        }

        const productRefs = invData
          .filter((e) => e.path)
          .map((data) => (data.style.path ? data.style.path : data.style));

        const productData = await batchFetch(productRefs);
        updateData(invData.map((e, i) => ({ ...e, ...productData[i] })));
      }
    );

    return () => {
      inventoryListener();
    };
  }, [searchString]);

  useEffect(() => {
    const stockTagListener = onSnapshot(
      collection(db, "Stores", StoreId, "StockTagging"),
      async (snapShot) => {
        let stockTagData = [];

        stockTagData = snapShot.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });

        const productRefs = stockTagData.map((data) =>
          data.style.path ? data.style.path : data.style
        );
        const productData = await batchFetch(productRefs);

        const obtainedData = stockTagData.map((e, i) => ({
          ...e,
          title: productData[i].title,
        }));
        updateScannedData(obtainedData);
      }
    );

    return () => {
      stockTagListener();
    };
  }, []);
  return (
    <div className="page">
      <Topbar
        dark={dark}
        pagename={translate("productLabels")}
        links={[
          {
            name: translate("inventoryTrackingCode"),
          },
          {
            name: translate("qrCode"),
          },
          isOwner
            ? {
                name: translate("qrCodeSettings"),
              }
            : !hideProductQr && {
                name: "QR Code Settings",
              },
          {
            name: translate("stockTags"),
          },
        ]}
        active={active}
        onClick={setActive}
        user={user}
      />
      <div className="add-btns px mb-10">
        {active !== 2 && (
          <div className="mr-14">
            <SearchBtn
              dark={dark}
              value={search}
              updateValue={setSearch}
              onEnter={async () => {
                if (active === 0) {
                  updateSearchString(search);
                }
                if (active === 1) {
                  updateSearchString2(search);
                }
                if (active === 3) {
                  updateSearchString3();
                }
              }}
            />
          </div>
        )}
        {active <= 1 && (
          <Buttons
            imgSrc={<img src={dark ? PrinterDark : Printer} alt="printer" />}
            text={translate("printAll")}
            onClick={() =>
              active === 0
                ? window.open("/print", "_blank")
                : window.open("/printqr", "_blank")
            }
          />
        )}
      </div>
      {active === 0 && (
        <div className="productWrapperNormal px">
          {data.map((prod, i) => (
            <ProductCardNormal
              key={i}
              id={prod.id}
              shoeImgnew={prod.image}
              title={prod.name}
              prodTitle={prod.title ? prod.title : prod.name}
              heading={prod.colorway}
              newstyle={prod.sku}
              category={prod.category}
              size={prod.size}
              consignment={prod.consigneeID ? prod.consigneeID : "-"}
              dark={dark}
            />
          ))}
        </div>
      )}

      {active === 1 && (
        <div className="productCardsWrapper px">
          {qrData.map((prod, i) => (
            <ProductCardQR
              key={i}
              shoeImg={prod.image}
              title={prod.title}
              model={prod.colorway}
              number={prod.id}
              category={prod.category}
              QRimg={""}
              dark={dark}
            />
          ))}
        </div>
      )}

      {active === 2 && (
        <div className="content px">
          <div className="box receipt-container">
            <div className="headersection flex al-ce jc-sp pt-0">
              <div>{translate("qrCodeSettings")}</div>
            </div>
            <Container dark={dark} />
          </div>
        </div>
      )}

      {active === 3 && (
        <div className="content px">
          <div className="box receipt-container">
            <div className="headersection flex al-ce jc-sp pt-0">
              <div>{translate("summary")}</div>
              <div className="flex al-ce">
                <div
                  className="mr-14"
                  style={{
                    maxWidth: "none",
                  }}
                >
                  <div className="btn-container">
                    <Button
                      variant="raised"
                      style={{
                        backgroundColor: "transparent",
                        minWidth: "auto",
                        textTransform: "none",
                        padding: "0 1.1rem",
                        whiteSpace: "nowrap",
                        fontSize: "0.9375rem",
                        lineHeight: "1.375rem",
                        fontWeight: 400,
                        maxWidth: "16.25rem",
                      }}
                      id="basic-button"
                      aria-controls="basic-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      className="mr-6"
                    >
                      {open ? (
                        "Scan this for barcode scanner"
                      ) : (
                        <>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.658678 6.63309C0.28029 6.63309 -0.0236346 6.31618 0.00145268 5.93862C0.284709 1.67572 1.67579 0.284692 5.9388 0.00144922C6.31635 -0.0236362 6.63326 0.280288 6.63326 0.658675C6.63326 1.01696 6.35009 1.30981 5.99263 1.33416C5.30176 1.38123 4.71604 1.45807 4.21689 1.569C3.3128 1.76992 2.77645 2.06452 2.42049 2.42047C2.06452 2.77643 1.76992 3.31275 1.569 4.21681C1.45807 4.71594 1.38123 5.30162 1.33417 5.99246C1.30981 6.34992 1.01697 6.63309 0.658678 6.63309Z"
                              fill="#ABAFD1"
                            />
                            <path
                              d="M0.658674 9.30924C0.280287 9.30924 -0.0236373 9.62614 0.001449 10.0037C0.284697 14.2666 1.67577 15.6577 5.9388 15.9409C6.31635 15.966 6.63326 15.6621 6.63326 15.2837C6.63326 14.9254 6.35009 14.6326 5.99263 14.6082C5.30176 14.5612 4.71604 14.4843 4.21689 14.3734C3.3128 14.1725 2.77645 13.8779 2.42049 13.5219C2.06452 13.166 1.76992 12.6296 1.569 11.7256C1.45807 11.2264 1.38123 10.6407 1.33416 9.94987C1.30981 9.59241 1.01696 9.30924 0.658674 9.30924Z"
                              fill="#ABAFD1"
                            />
                            <path
                              d="M15.2841 9.30924C14.9258 9.30924 14.6329 9.59241 14.6086 9.94987C14.5615 10.6407 14.4847 11.2264 14.3737 11.7256C14.1728 12.6296 13.8782 13.166 13.5222 13.5219C13.1663 13.8779 12.6299 14.1725 11.7258 14.3734C11.2267 14.4843 10.641 14.5612 9.9501 14.6082C9.59264 14.6326 9.30947 14.9254 9.30947 15.2837C9.30947 15.6621 9.62638 15.966 10.0039 15.9409C14.267 15.6577 15.658 14.2666 15.9413 10.0037C15.9664 9.62614 15.6624 9.30924 15.2841 9.30924Z"
                              fill="#ABAFD1"
                            />
                            <path
                              d="M16 7.99286C16 7.98564 16 7.97842 16 7.97119C16 7.96396 16 7.95674 16 7.94952V7.99286Z"
                              fill="#ABAFD1"
                            />
                            <path
                              d="M15.2841 6.63309C15.6624 6.63309 15.9664 6.31618 15.9413 5.93863C15.658 1.67572 14.2669 0.284691 10.0039 0.0014489C9.62638 -0.0236364 9.30947 0.280288 9.30947 0.658675C9.30947 1.01696 9.59264 1.30981 9.9501 1.33416C10.641 1.38123 11.2267 1.45807 11.7258 1.569C12.6299 1.76992 13.1663 2.06452 13.5222 2.42047C13.8782 2.77643 14.1728 3.31275 14.3737 4.21681C14.4847 4.71594 14.5615 5.30163 14.6086 5.99246C14.6329 6.34992 14.9258 6.63309 15.2841 6.63309Z"
                              fill="#ABAFD1"
                            />
                            <path
                              d="M7.97137 15.9996L7.90565 15.9996H8.03708L7.97137 15.9996Z"
                              fill="#ABAFD1"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M11.333 7.88538C11.333 9.72629 9.84067 11.2186 7.99976 11.2186C6.15885 11.2186 4.6665 9.72629 4.6665 7.88538C4.6665 6.04448 6.15885 4.55212 7.99976 4.55212C9.84067 4.55212 11.333 6.04448 11.333 7.88538ZM9.99972 7.88538C9.99972 8.98993 9.10431 9.88534 7.99976 9.88534C6.89522 9.88534 5.99981 8.98993 5.99981 7.88538C5.99981 6.78084 6.89522 5.88543 7.99976 5.88543C9.10431 5.88543 9.99972 6.78084 9.99972 7.88538Z"
                              fill="#767BFA"
                            />
                          </svg>
                          <span>{translate("scanItem")}</span>
                        </>
                      )}
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      classes={{ paper: classes.menuPaper }}
                    >
                      <MenuItem
                        className="scan-barcode"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{ __html: svgHtml }}
                          onClick={() => {
                            window.open(barcodeScan);
                          }}
                        />
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
                <div className="mr-14">
                  <Buttons
                    onClick={async () => {
                      await deleteStockTagging(StoreId);
                    }}
                    imgSrc={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.33333 8C1.33333 10.4608 1.94814 12.0616 2.94414 13.0568C3.94036 14.0521 5.54321 14.6667 8.00697 14.6667C10.4707 14.6667 12.0736 14.0521 13.0698 13.0568C13.9729 12.1545 14.5626 10.7542 14.6647 8.66645C14.6827 8.2987 14.9791 8 15.3473 8C15.7155 8 16.0158 8.30038 15.9994 8.6682C15.7813 13.5561 13.1172 16 8.00697 16C2.66899 16 0 13.3333 0 8C0 2.66667 2.66899 0 8.00697 0C12.0164 0 14.5201 1.50449 15.518 4.51348C15.6552 4.9271 15.3301 5.33333 14.8943 5.33333C14.5863 5.33333 14.3204 5.12471 14.2186 4.83398C13.9418 4.04386 13.5501 3.42311 13.0698 2.94322C12.0736 1.94787 10.4707 1.33333 8.00697 1.33333C5.54321 1.33333 3.94036 1.94787 2.94414 2.94322C1.94814 3.93835 1.33333 5.53915 1.33333 8Z"
                          fill={`${dark ? "#5A5D74" : "#ABAFD1"}`}
                        />
                        <path
                          d="M16.0003 0.666667C16.0003 0.298477 15.7018 0 15.3337 0C14.9655 0 14.667 0.298477 14.667 0.666667V4H11.3337C10.9655 4 10.667 4.29848 10.667 4.66667C10.667 5.03486 10.9655 5.33333 11.3337 5.33333H15.3337C15.7018 5.33333 16.0003 5.03486 16.0003 4.66667V0.666667Z"
                          fill="#767BFA"
                        />
                      </svg>
                    }
                    text={translate("resetScan")}
                  />
                </div>
                <div className="mr-14">
                  <MissingItemsModals dark={dark} scannedData={scannedData} />
                </div>
                <div>
                  <Buttons
                    imgSrc={
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.6795 10.0435C15.3007 10.0435 15 9.72656 15 9.34783V9.34783C15 6.85273 14.4009 5.35239 13.556 4.47077C12.7264 3.60503 11.3362 3 9 3C6.66381 3 5.27364 3.60503 4.44398 4.47077C4.11097 4.81825 3.81615 5.26185 3.58115 5.82458C3.40279 6.25167 3.00688 6.56522 2.54405 6.56522V6.56522C1.88828 6.56522 1.39437 5.95565 1.62228 5.34076C2.69489 2.44692 5.15413 1 9 1C14.3333 1 17 3.78261 17 9.34783V9.34783C17 9.73684 16.6787 10.0435 16.2896 10.0435H15.6795ZM14.9155 10.8651V11.3195C14.9155 11.7674 15.2785 12.1304 15.7264 12.1304V12.1304C16.243 12.1304 16.6309 12.6093 16.4676 13.0994C15.4466 16.1636 12.9574 17.6957 9 17.6957C3.66667 17.6957 1 14.913 1 9.34783V9.34783C1 8.95881 1.32134 8.65217 1.71036 8.65217H2.32054C2.69928 8.65217 3 8.96909 3 9.34783V9.34783C3 11.8429 3.59909 13.3433 4.44398 14.2249C5.27364 15.0906 6.66381 15.6957 9 15.6957C11.3362 15.6957 12.7264 15.0906 13.556 14.2249C14.2135 13.5388 14.7222 12.4779 14.9155 10.8651Z"
                          fill={`${dark ? "#5A5D74" : "#ABAFD1"}`}
                        />
                        <path
                          d="M1.69572 1.69568V5.86959H5.86963"
                          stroke="#767BFA"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.3043 17L16.3043 12.8261L12.1304 12.8261"
                          stroke="#767BFA"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                    text={translate("refresh")}
                  />
                </div>
              </div>
            </div>
            <StockContainer dark={dark} scannedData={scannedData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductLabels;
