import {
  collection,
  doc,
  getDoc,
  increment,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { db } from "../index";
import createProduct from "../products/createProduct";
import encodeKey from "./../util/encodeKey";

export default async function batchCreateInventory(storeId, dataArray) {
  const batch = writeBatch(db);
  const stockCount = {};
  const minCPCount = {};
  const condition = {};

  let cache = {};
  for (let data of dataArray) {
    //Implement a caching system to not repeat this code multiple times when not needed.
    if (!cache[data.sku]) {
      const docRef = doc(db, "Stores", storeId, "Products", data.sku);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        createProduct(storeId, data.sku, data);
      } else {
        const productData = docSnap.data();

        if (stockCount[data.sku] === undefined) {
          stockCount[data.sku] = productData.stocks;
        }

        if (minCPCount[data.sku] === undefined) {
          minCPCount[data.sku] = productData.minCP;
          condition[data.sku] = productData.conditionCP;
        }
      }
      cache[data.sku] = true;
    }

    const size = encodeKey(data.size.toString());

    if (stockCount[data.sku] === undefined) stockCount[data.sku] = {};
    if (minCPCount[data.sku] === undefined) minCPCount[data.sku] = {};
    if (condition[data.sku] === undefined) condition[data.sku] = {};

    if (stockCount[data.sku][size] === undefined) {
      stockCount[data.sku][size] = 1;
    } else {
      stockCount[data.sku][size] += 1;
    }
    const listingPrice = data.payoutPrice + (data.fee / 100) * data.payoutPrice;
    if (
      minCPCount[data.sku][size] === undefined ||
      minCPCount[data.sku][size] === Number.MAX_VALUE
    ) {
      minCPCount[data.sku][size] = listingPrice;
      condition[data.sku][size] = data.condition;
    } else {
      if (data.payoutPrice < minCPCount[data.sku][size]) {
        minCPCount[data.sku][size] = listingPrice;
        condition[data.sku][size] = data.condition;
      }
    }

    const nycRef = doc(collection(db, "Stores", storeId, "Inventory"));

    batch.set(nycRef, {
      ...data,
      storeId: storeId,
      type: "consignment",
      style: doc(db, "Stores", storeId, "Products", data.sku),
      price: listingPrice,
    });
    // Update consignee's total items and figures
    const consigneeItemCount = increment(1);
    const consigneePayout = increment(data.payoutPrice);
    updateDoc(doc(db, "Stores", storeId, "Consignee", data.consigneeID), {
      totalItems: consigneeItemCount,
      figures: consigneePayout,
    });
  }

  await batch.commit();

  const counterRef = doc(db, "Stores", storeId);

  const incrementItemCount = increment(dataArray.length);
  const incrementValue = increment(
    dataArray.reduce((tot, data) => {
      return tot + data.payoutPrice;
    }, 0)
  );

  await updateDoc(counterRef, {
    totalConsignment: incrementItemCount,
    totalPayout: incrementValue,
  });

  for (let prod in minCPCount) {
    let minCPUpdater = {};
    let stockUpdater = {};
    let conditionUpdater = {};

    const Ref = doc(db, "Stores", storeId, "Products", prod);
    for (let minCP in minCPCount[prod]) {
      minCPUpdater[`minCP.${encodeKey(minCP)}`] = minCPCount[prod][minCP];
      conditionUpdater[`conditionCP.${encodeKey(minCP)}`] =
        condition[prod][minCP];
    }
    for (let stock in stockCount[prod]) {
      const incrementStocks = increment(stockCount[prod][stock]);
      stockUpdater[`stocksCP.${encodeKey(stock)}`] = incrementStocks;
    }

    await updateDoc(Ref, conditionUpdater);
    await updateDoc(Ref, stockUpdater);
    await updateDoc(Ref, minCPUpdater);
  }
}
