import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import { StoreId } from "../../Constants";
import { db } from "../../firebase";

export default async function deleteTicketAsk(token, ticketId) {
  if (!token) {
    throw new Error("Token not provided");
  }

  if (!ticketId) {
    throw new Error("Ticket id not provided");
  }

  const resp = await axios.post("https://tickets.katch.gg/tickets/deleteAsk", {
    // const resp = await axios.post("http://localhost:8080/tickets/deleteAsk", {
    token,
    ticketId,
  });

  if (!resp.data) {
    throw new Error(resp.error.message);
  }
  if (resp.error) {
    throw new Error(resp.error);
  }

  return resp.data;
}
