import axios from "axios";

export default async function uploadTicket(token, file) {
  let formData = new FormData();
  formData.append("file", file);
  const { data } = await axios.post(
    "https://tickets.katch.gg/product/upload",
    // "http://localhost:8080/product/upload",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": token,
      },
    }
  );
  if (data.error) {
    throw new Error(data.error);
  }

  return data.url;
}
