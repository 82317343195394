import algoliasearch from "algoliasearch/lite";
import { StoreId } from "../Constants";
const client = algoliasearch("A3ZR8GPESC", "1424f32c83fed9bd461ae5b84c2119e5");
const consignee = client.initIndex("consignee-test");

export async function searchConsignee(searchString) {
  const resp = await consignee.search(searchString, {
    filters: `store:"${StoreId}"`,
  });
  return resp.hits;
}
