import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useContext, useEffect, useState } from "react";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import Close from "../../assets/close.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import constants from "../../firebase/constants";
import editConsignment from "../../firebase/inventory/editConsignment";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import Select from "../general/Select";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import EditItemConsignmetnInventory from "./EditItemConsignmetnInventory";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";
import _ from "lodash";

export default function EditConsignMentInventoryModal({
  isOpen,
  toggleState,
  data,
  dark,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const [fields, setFields] = useState(data);
  const [fee, editFee] = useState(data.fee);
  useEffect(() => {
    setFields(data);
    editFee(data.fee);
  }, [data]);

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };
  const {
    rates,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);

  let localCurrency = localStorage.getItem("currency");
  let localSizing = localStorage.getItem("sizing");


  const sizingOptions = {
    "":[{label: "NA", value: ''}],
    collectibles :[{label: "NA", value: ''}],
    arts :[{label: "NA", value: ''}],
    electronics :[{label: "NA", value: ''}],
    streetwear: [
      {
        label: "XXS",
        value: "XXS"
      },
      {
        label: "XS",
        value: "XS"
      },
      {
        label: "S",
        value: "S"
      },
      {
        label: "M",
        value: "M"
      },
      {
        label: "L",
        value: "L"
      },
      {
        label: "XL",
        value: "XL"
      },
      {
        label: "XXL",
        value: "XXL"
      },
      {
        label: "XXXL",
        value: "XXXL"
      },
    ],
    sneakers: [
      {
        label: sizingValues[localSizing]["4"],
        value: 4,
      },
      {
        label: sizingValues[localSizing]["4.5"],
        value: 4.5,
      },
      {
        label: sizingValues[localSizing]["5"],
        value: 5,
      },
      {
        label: sizingValues[localSizing]["5.5"],
        value: 5.5,
      },
      {
        label: sizingValues[localSizing]["6"],
        value: 6,
      },
      {
        label: sizingValues[localSizing]["6.5"],
        value: 6.5,
      },
      {
        label: sizingValues[localSizing]["7"],
        value: 7,
      },
      {
        label: sizingValues[localSizing]["7.5"],
        value: 7.5,
      },
      {
        label: sizingValues[localSizing]["8"],
        value: 8,
      },
      {
        label: sizingValues[localSizing]["8.5"],
        value: 8.5,
      },
      {
        label: sizingValues[localSizing]["9"],
        value: 9,
      },
      {
        label: sizingValues[localSizing]["9.5"],
        value: 9.5,
      },
      {
        label: sizingValues[localSizing]["10"],
        value: 10,
      },
      {
        label: sizingValues[localSizing]["10.5"],
        value: 10.5,
      },
      {
        label: sizingValues[localSizing]["11"],
        value: 11,
      },
      {
        label: sizingValues[localSizing]["11.5"],
        value: 11.5,
      },
      {
        label: sizingValues[localSizing]["12"],
        value: 12,
      },
      {
        label: sizingValues[localSizing]["12.5"],
        value: 12.5,
      },
      {
        label: sizingValues[localSizing]["13"],
        value: 13,
      },
      {
        label: sizingValues[localSizing]["13.5"],
        value: 13.5,
      },
      {
        label: sizingValues[localSizing]["14"],
        value: 14,
      },
      {
        label: sizingValues[localSizing]["14.5"],
        value: 14.5,
      },
      {
        label: sizingValues[localSizing]["15"],
        value: 15,
      },
      {
        label: sizingValues[localSizing]["16"],
        value: 16,
      },
      {
        label: sizingValues[localSizing]["17"],
        value: 17,
      }, 
      {
        label: womenSizingValues[localSizing]["5W"],
        value: "5W",
      },
      {
        label: womenSizingValues[localSizing]["5.5W"],
        value: "5.5W",
      },
      {
        label: womenSizingValues[localSizing]["6W"],
        value: "6W",
      },
      {
        label: womenSizingValues[localSizing]["6.5W"],
        value: "6.5W",
      },
      {
        label: womenSizingValues[localSizing]["7W"],
        value: "7W",
      },
      {
        label: womenSizingValues[localSizing]["7.5W"],
        value: "7.5W",
      },
      {
        label: womenSizingValues[localSizing]["8W"],
        value: "8W",
      },
      {
        label: womenSizingValues[localSizing]["8.5W"],
        value: "8.5W",
      },
      {
        label: womenSizingValues[localSizing]["9W"],
        value: "9W",
      },
      {
        label: womenSizingValues[localSizing]["9.5W"],
        value: "9.5W",
      },
      {
        label: womenSizingValues[localSizing]["10W"],
        value: "10W",
      },
      {
        label: womenSizingValues[localSizing]["10.5W"],
        value: "10.5W",
      },
      {
        label: womenSizingValues[localSizing]["11W"],
        value: "11W",
      },
      {
        label: womenSizingValues[localSizing]["11.5W"],
        value: "11.5W",
      },
      {
        label: womenSizingValues[localSizing]["12W"],
        value: "12W",
      },
      {
        label: womenSizingValues[localSizing]["12.5W"],
        value: "12.5W",
      },
      {
        label: womenSizingValues[localSizing]["13W"],
        value: "13W",
      },
      {
        label: womenSizingValues[localSizing]["13.5W"],
        value: "13.5W",
      },
      {
        label: womenSizingValues[localSizing]["14W"],
        value: "14W",
      },
      {
        label: womenSizingValues[localSizing]["14.5W"],
        value: "14.5W",
      },
      {
        label: womenSizingValues[localSizing]["15W"],
        value: "15W",
      },

      {
        label: girlSizingValues[localSizing]["3.5Y"],
        value: "3.5Y",
      },
      {
        label: girlSizingValues[localSizing]["4Y"],
        value: "4Y",
      },
      {
        label: girlSizingValues[localSizing]["4.5Y"],
        value: "4.5Y",
      },
      {
        label: girlSizingValues[localSizing]["5Y"],
        value: "5Y",
      },
      {
        label: girlSizingValues[localSizing]["5.5Y"],
        value: "5.5Y",
      },
      {
        label: girlSizingValues[localSizing]["6Y"],
        value: "6Y",
      },
      {
        label: girlSizingValues[localSizing]["6.5Y"],
        value: "6.5Y",
      },
      {
        label: girlSizingValues[localSizing]["7Y"],
        value: "7Y",
      },
      { value: "1Y", label: young[localSizing]["1Y"] },
      { value: "1.5Y", label: young[localSizing]["1.5Y"] },
      { value: "2Y", label: young[localSizing]["2Y"] },
      { value: "2.5Y", label: young[localSizing]["2.5Y"] },
      { value: "3Y", label: young[localSizing]["3Y"] },
      { value: "3.5Y", label: young[localSizing]["3.5Y"] },
      { value: "4Y", label: young[localSizing]["4Y"] },
      { value: "4.5Y", label: young[localSizing]["4.5Y"] },
      { value: "5Y", label: young[localSizing]["5Y"] },
      { value: "5.5Y", label: young[localSizing]["5.5Y"] },
      { value: "6Y", label: young[localSizing]["6Y"] },
      { value: "6.5Y", label: young[localSizing]["6.5Y"] },

      { value: "1C", label: toddler[localSizing]["1C"] },
      { value: "2C", label: toddler[localSizing]["2C"] },
      { value: "3C", label: toddler[localSizing]["3C"] },
      { value: "4C", label: toddler[localSizing]["4C"] },
      { value: "5C", label: toddler[localSizing]["5C"] },
      { value: "6C", label: toddler[localSizing]["6C"] },
      { value: "7C", label: toddler[localSizing]["7C"] },
      { value: "8C", label: toddler[localSizing]["8C"] },
      { value: "9C", label: toddler[localSizing]["9C"] },
      { value: "10C", label: toddler[localSizing]["10C"] },
      { value: "10.5C", label: toddler[localSizing]["10.5C"] },
      { value: "11C", label: toddler[localSizing]["11C"] },
      { value: "12C", label: toddler[localSizing]["12C"] },
      { value: "13C", label: toddler[localSizing]["13C"] },
    ]
  
  }


  return (
    <Modal
      className={`modal ${dark ? "dark" : ""}`}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={() => toggleState(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <div className="header">
            <div>{translate("editingConsignmentItems")}</div>
            <img
              onClick={() => toggleState(false)}
              src={dark ? CloseDark : Close}
              alt=""
            />
          </div>
          <div className="three-grid">
            <div>
              <div className="label">{translate("itemName")}</div>
              <Input
                name="name"
                type={"text"}
                value={fields.name}
                updateValue={handleChange}
                placeholder={GetTranslateString("enterName")}
              />
            </div>

            <div>
              <div className="label">{translate("marketIdentifier")}</div>
              <Input
                readOnly={true}
                name="styleCode"
                type={"text"}
                value={fields.styleCode}
                updateValue={handleChange}
                placeholder={GetTranslateString("enterStyleCode")}
              />
            </div>

            <div>
              <div className="label">{translate("dateIn")}</div>
              <Input
                name="dateIn"
                type={"date"}
                dark={dark}
                value={fields.dateIn}
                updateValue={handleChange}
                placeholder={GetTranslateString("enterDate")}
              />
            </div>
          </div>

          <div className="three-grid">
          <div>
              <div className="label">{translate("categories")}</div>
              <Select
                dark={dark}
                name="category"
                value={fields.category}
                updateValue={handleChange}
                options={[
                  {
                    label: "Sneakers",
                    value: "sneakers",
                  },
                  {
                    label: "Streetwear",
                    value: "streetwear",
                  },
                  {
                    label: "Electronics",
                    value: "electronics",
                  },
                  {
                    label: "Arts",
                    value: "arts",
                  },
                  {
                    label: "Collectibles",
                    value: "collectibles",
                  },
                ]}
                placeholderText={GetTranslateString("chooseCategories")}
              />
            </div>
            
            <div>
              <div className="label">{translate("sizing")}</div>
              <Select
                dark={dark}
                disabled={true}
                name="sizing"
                value={(fields.category !== '' && !_.map(sizingOptions[fields.category], 'value').includes(fields.sizing))? '' : fields.sizing}
                render={
                  (fields.sizing !== '')
                    ? (fields.category === "sneakers" && _.map(sizingOptions["sneakers"], 'value').includes(fields.sizing))
                      ? () =>
                        sizingValues[localSizing][fields.sizing]
                          ? sizingValues[localSizing][fields.sizing]
                          : womenSizingValues[localSizing][fields.sizing]
                          ? womenSizingValues[localSizing][fields.sizing]
                          : girlSizingValues[localSizing][fields.sizing]
                          ? girlSizingValues[localSizing][fields.sizing]
                          : toddler[localSizing][fields.sizing]
                          ? toddler[localSizing][fields.sizing]
                          : young[localSizing][fields.sizing]
                      : () => fields.sizing
                    : ""
                }
                updateValue={handleChange}
                options={sizingOptions[fields.category]}
                placeholderText={GetTranslateString("chooseSize")}
                none={false}
              />
            </div>

            <div>
              <div className="label">{translate("condition")}</div>
              <Select
                dark={dark}
                name="condn"
                value={fields.condn}
                updateValue={handleChange}
                options={[
                  {
                    label: GetTranslateString("new"),
                    value: constants.NEW,
                  },
                  {
                    label: GetTranslateString("used"),
                    value: constants.USED,
                  },
                ]}
                placeholderText={GetTranslateString("chooseCondition")}
              />
            </div>
          </div>

          <div className="three-grid">
            <div>
              <div className="label">{translate("consigneeId")}</div>
              <Input
                name="consigneeID"
                type={"text"}
                value={fields.consigneeID}
                updateValue={handleChange}
                placeholder={GetTranslateString("enterId")}
                readOnly={true}
              />
            </div>

            <div>
              <div className="label">{translate("payoutPrice")}</div>
              <Input
                name="payoutPrice"
                type={"number"}
                value={fields.payoutPrice}
                updateValue={handleChange}
                placeholder={GetTranslateString("enterAmount")}
              />
            </div>
          </div>

          <EditItemConsignmetnInventory
            editMarkUp={fee}
            updateEditMarkUp={editFee}
            savedEditItems={data}
          />

          <div className="btns w-full jus-end mt-10">
            <div>
              <Button
                onClick={async () => {
                  try {
                    await editConsignment(StoreId, data.id, {
                      condition: fields.condn,
                      name: fields.name.trim(),
                      dateIn: fields.dateIn,
                      size: fields.sizing,
                      styleCode: fields.styleCode.trim(),
                      category: fields.category,
                      payoutPrice: parseFloat(
                        (
                          fields.payoutPrice /
                          rates?.[localCurrency?.toUpperCase()]
                        ).toFixed(2)
                      ),
                      consigneeID: fields.consigneeID.trim(),
                      fee: parseFloat(fee),
                    });
                    toggleState(false);
                  } catch (e) {
                    ErrorToast(translate("error"), e.message);
                    return;
                  }
                  SuccessToast(translate("success"), translate("itemEdited"));
                  toggleState(false);
                }}
                imgSrc={<img src={dark ? AddDark : Add} alt="add" />}
                text={translate("editItem")}
              />
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
