import algoliasearch from "algoliasearch/lite";
import { StoreId } from "../Constants";
const client = algoliasearch("A3ZR8GPESC", "1424f32c83fed9bd461ae5b84c2119e5");
const inventory = client.initIndex("sales-test");

export async function searchAllSoldInventory(searchString) {
  const resp = await inventory.search(searchString, {
    filters: `store:"${StoreId}"`,
  });
  return resp.hits;
}

export async function searchConsigneeSoldInventory(searchString) {
  const resp = await inventory.search(searchString, {
    filters: `store:"${StoreId}"`,
  });
  return resp.hits.filter((k) => k.type === "consignment");
}
