import React from "react";
import "../css/Wallet.scoped.css";
import { format } from "date-fns";

export default function RecentActivities({ recentActivities }) {
  return (
    <div className="recentActivities">
      <div className="headersgrid">
        <div>Item</div>
        <div>Date Sold</div>
        <div className="center">Payout</div>
        <div className="center">Fees</div>
        <div />
      </div>
      <div className="items">
        {recentActivities.map((recentActivity) => {
          // const formattedDate = format(
          //   new Date(recentActivity.date.seconds * 1000),
          //   "yyyy-MM-dd HH:mm:ss"
          // );
          return (
            <div className="item" key={recentActivity.id}>
              <div>{recentActivity.eventName}</div>
              <div>
                {new Date(recentActivity.date.seconds * 1000).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}
              </div>
              <div className="center">
                ${(recentActivity.creditAmount / 100).toFixed(2)}
              </div>
              <div className="center">
                ${(recentActivity.sellerFees / 100).toFixed(2)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
