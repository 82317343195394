import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import Close from "../../assets/close.svg";
import { StoreId } from "../../Constants";
import clockingOut from "../../firebase/staff/logs/clockingOut";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import { getTodaysDate } from "../utils/getTodayDate";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";
export default function CheckInModal({ green, setGreen, dark }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "32.25rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const initialValues = {
    mail: "",
    staffId: "",
  };
  const [fields, setFields] = useState(initialValues);
  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const clearValues = () => {
    setFields(initialValues);
  };

  useEffect(() => {
    let clockedIn = localStorage.getItem("clockedIn");

    if (clockedIn) {
      setGreen(clockedIn);
    } else {
      localStorage.setItem("clockedIn", "false");
      setGreen("false");
    }
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setFields(initialValues);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        imgSrc={
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.10174 1.19765C8.28826 0.946932 8.29979 0.606843 8.13067 0.344076C7.96155 0.0813097 7.64723 -0.049064 7.34178 0.016859C3.09063 0.934344 0 4.89169 0 9.36628C0 14.5477 4.20041 18.7482 9.38188 18.7482C13.2844 18.7482 16.5274 16.4225 17.942 13.0393C18.0626 12.7508 17.9916 12.4178 17.7638 12.2036C17.536 11.9894 17.1992 11.939 16.9187 12.0771C15.9842 12.5373 15.0191 12.7495 13.8746 12.7495C9.93958 12.7495 6.74961 9.55957 6.74961 5.62454C6.74961 3.99834 7.23791 2.35878 8.10174 1.19765ZM1.5 9.36628C1.5 6.22305 3.29703 3.4119 5.91016 2.1006C5.47282 3.20777 5.24961 4.43095 5.24961 5.62454C5.24961 10.388 9.11115 14.2495 13.8746 14.2495C14.4939 14.2495 15.0807 14.1959 15.6467 14.0826C14.2365 16.0197 11.987 17.2482 9.38188 17.2482C5.02884 17.2482 1.5 13.7193 1.5 9.36628Z"
              fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.6956 1.82038C14.2849 0.710345 12.7149 0.710344 12.3041 1.82038L11.7007 3.45093L10.0702 4.05428C8.96016 4.46503 8.96016 6.03505 10.0702 6.44581L11.7007 7.04916L12.3041 8.67971C12.7149 9.78974 14.2849 9.78974 14.6956 8.67971L15.299 7.04916L16.9295 6.44581C18.0396 6.03505 18.0396 4.46503 16.9295 4.05428L15.299 3.45093L14.6956 1.82038ZM13.0716 4.06851L13.4999 2.9112L13.9281 4.06851C14.0572 4.4175 14.3324 4.69266 14.6814 4.8218L15.8387 5.25004L14.6814 5.67829C14.3324 5.80743 14.0572 6.08258 13.9281 6.43158L13.4999 7.58889L13.0716 6.43158C12.9425 6.08259 12.6673 5.80743 12.3183 5.67829L11.161 5.25004L12.3183 4.8218C12.6673 4.69266 12.9425 4.4175 13.0716 4.06851Z"
              fill="#767BFA"
            />
          </svg>
        }
        text={"Clock Out"}
      />
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="header mb-20">
              <div>Clocking {green === "false" ? "In" : "Out"}... </div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className="mb-20 input">
              <Input
                name="staffId"
                type={"text"}
                value={fields.staffId}
                updateValue={handleChange}
                placeholder={translate("staffId")}
              />
            </div>
            <div className="mb-20 input">
              <Input
                name="mail"
                type={"email"}
                value={fields.mail}
                updateValue={handleChange}
                placeholder={GetTranslateString("email")}
              />
            </div>

            <div className="w-full jc-ce">
              <div className="px-20">
                <Button
                  imgSrc={
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.10174 1.19765C8.28826 0.946932 8.29979 0.606843 8.13067 0.344076C7.96155 0.0813097 7.64723 -0.049064 7.34178 0.016859C3.09063 0.934344 0 4.89169 0 9.36628C0 14.5477 4.20041 18.7482 9.38188 18.7482C13.2844 18.7482 16.5274 16.4225 17.942 13.0393C18.0626 12.7508 17.9916 12.4178 17.7638 12.2036C17.536 11.9894 17.1992 11.939 16.9187 12.0771C15.9842 12.5373 15.0191 12.7495 13.8746 12.7495C9.93958 12.7495 6.74961 9.55957 6.74961 5.62454C6.74961 3.99834 7.23791 2.35878 8.10174 1.19765ZM1.5 9.36628C1.5 6.22305 3.29703 3.4119 5.91016 2.1006C5.47282 3.20777 5.24961 4.43095 5.24961 5.62454C5.24961 10.388 9.11115 14.2495 13.8746 14.2495C14.4939 14.2495 15.0807 14.1959 15.6467 14.0826C14.2365 16.0197 11.987 17.2482 9.38188 17.2482C5.02884 17.2482 1.5 13.7193 1.5 9.36628Z"
                        fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.6956 1.82038C14.2849 0.710345 12.7149 0.710344 12.3041 1.82038L11.7007 3.45093L10.0702 4.05428C8.96016 4.46503 8.96016 6.03505 10.0702 6.44581L11.7007 7.04916L12.3041 8.67971C12.7149 9.78974 14.2849 9.78974 14.6956 8.67971L15.299 7.04916L16.9295 6.44581C18.0396 6.03505 18.0396 4.46503 16.9295 4.05428L15.299 3.45093L14.6956 1.82038ZM13.0716 4.06851L13.4999 2.9112L13.9281 4.06851C14.0572 4.4175 14.3324 4.69266 14.6814 4.8218L15.8387 5.25004L14.6814 5.67829C14.3324 5.80743 14.0572 6.08258 13.9281 6.43158L13.4999 7.58889L13.0716 6.43158C12.9425 6.08259 12.6673 5.80743 12.3183 5.67829L11.161 5.25004L12.3183 4.8218C12.6673 4.69266 12.9425 4.4175 13.0716 4.06851Z"
                        fill={green === "false" ? "#FA7676" : "#767BFA"}
                      />
                    </svg>
                  }
                  onClick={async () => {
                    try {
                      if (green === "true") {
                        await clockingOut(
                          StoreId,
                          fields.staffId.trim(),
                          fields.mail.trim(),
                          getTodaysDate()
                        );
                        SuccessToast(translate("success"), translate("clockedOut"));
                        handleClose();
                        localStorage.setItem("clockedIn", "false");
                        setGreen("false");
                        clearValues();
                      } else {
                        ErrorToast(translate("error"), translate("alreadyClockedOut"));
                        return;
                      }
                    } catch (e) {
                      ErrorToast(translate("error"), e.message);
                    }
                  }}
                  text={
                    green === "false" ? (
                      <span className="red">Clocked Out</span>
                    ) : (
                      "Clock Out"
                    )
                  }
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
