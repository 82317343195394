import { useContext, useState } from "react";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import applyDiscount from "../../firebase/cart/applyDiscount";
import "../css/CartDetails.scoped.css";
import Input from "../general/Input";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

const CartDetails = ({
  activeCart,
  cartTotal,
  setOpen,
  discountPercent,
  setDiscountPercent,
}) => {
  const [discount, setDiscount] = useState("");
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  return (
    <div>
      <div className="flex al-ce jc-sb smbold">
        <div>{translate("subTotal")}</div>
        <div>
          {sign[localCurrency]}
          {parseFloat(cartTotal).toFixed(2)}
        </div>
      </div>
      <div className="flex al-ce jc-sb smbold">
        <div>{translate("addDiscountCode")}</div>
        <div className="al-ce row">
          <Input
            name="discount"
            type={"text"}
            value={discount}
            readOnly={discountPercent !== 0}
            updateValue={(e) => setDiscount(e.target.value)}
            placeholder={GetTranslateString("enterCode")}
          />
          {discountPercent === 0 ? (
            <span
              className="check"
              onClick={() => {
                if (discount) {
                  if (cartTotal !== 0) {
                    applyDiscount(StoreId, activeCart, discount)
                      .then((e) => {
                        if (e.discountPercentage) {
                          setDiscountPercent(
                            parseFloat(
                              (
                                e.discountPercentage *
                                rates?.[localCurrency?.toUpperCase()]
                              ).toFixed(2)
                            )
                          );
                          return SuccessToast(translate("verified"), translate("discountApplied"));
                        } else {
                          setDiscountPercent(0);
                          return ErrorToast(translate("failed"),translate("invalidDiscountCode"));
                        }
                      })
                      .catch((e) => {
                        return ErrorToast(e.message);
                      });
                  } else {
                    return ErrorToast(translate("error"), translate("cartCannotBeEmpty"));
                  }
                } else {
                  return ErrorToast(translate("error"), translate("emptyDiscountField"));
                }
              }}
            >
              {translate("verify")}
            </span>
          ) : (
            <span
              className="check"
              onClick={() => {
                if (setDiscountPercent !== 0) {
                  setDiscountPercent(0);
                  setDiscount("");
                }
              }}
            >
              Delete
            </span>
          )}
        </div>
      </div>
      <div className="flex al-ce jc-sb smbold">
        <div>{translate("discountAmount")}</div>
        <div>
          -{sign[localCurrency]}
          {discountPercent}
        </div>
      </div>
      <div className="flex al-ce jc-sb smbold">
        <div>{translate("tax")}</div>
        <div>-</div>
      </div>
      <div className="flex al-ce jc-sb smbold">
        <div>{translate("total")}</div>
        <div className="bold">
          {sign[localCurrency]}
          {(cartTotal - discountPercent).toFixed(2)}
        </div>
      </div>
      <div className="al-ce jc-sb smbold">
        <div
          className="paybtn"
          onClick={() => {
            if (cartTotal === 0) {
              ErrorToast(translate("error"), "Cart empty.");
            } else {
              setOpen(true);
            }
          }}
        >
          {translate("pay")}
        </div>
      </div>
    </div>
  );
};

export default CartDetails;
