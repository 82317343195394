import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../index";

export default async function updateConsignmentCount(storeId) {
  const q = query(
    collection(db, "Stores", storeId, "Inventory"),
    where("type", "==", "consignment")
  );

  const querySnapshot = await getDocs(q);
  let totalConsignment = 0;
  let totalPayout = 0;
  querySnapshot.forEach((doc) => {
    const docData = doc.data();
    totalConsignment++;
    totalPayout += docData.payoutPrice;
  });

  const docRef = doc(db, "Stores", storeId);
  const result = {
    totalConsignment: totalConsignment,
    totalPayout: totalPayout,
  };
  updateDoc(docRef, result);
  return result;
}
