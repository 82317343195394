
import {
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  deleteField
} from "firebase/firestore";

import { db } from "../index";

export default async function updatePrice(storeId, sku, size, newPrice) {

  if (!sku || sku.length < 2) {
    throw new Error("SKU of this item is not a valid value.");
  }

  if (!size) {
    throw new Error("Size of this item is not a valid value.");
  }


  if (
    !newPrice ||
    newPrice < 0 ||
    isNaN(newPrice)
  ) {
    throw new Error("New price is not valid");
  }


  const productsRef = doc(db, "Stores", storeId, "Products", sku);
  await updateDoc(productsRef, {
    [`sizes.${size}`]: newPrice,
  });

  const notiRef = doc(db, "Stores", storeId, "Notifications", sku);
  await deleteDoc(notiRef)

  const indexRef = doc(db, "Stores", storeId, "PriceAlerts", "Index");
  await updateDoc(indexRef, {
    [`skuIndex.${sku}.sizes.${size}`]: newPrice
  })
}
