import { getDocs, query, collection, orderBy } from "firebase/firestore";
import { db } from "../index";

export default async function expensesGraph(storeId) {
    const expensesRef = collection(db, "Stores", storeId, "Expenses");

    let result = {};
    const q = query(
        expensesRef,
        orderBy("spentDate", "desc")
    );

    const graphSnapshot = await getDocs(q);
    if (graphSnapshot.size === 0) {
        return new Error("No expenses yet!")
    }

    let count = 0, total = 0;
    for (let data of graphSnapshot.docs) {
        if (result[data.data().spentDate] === undefined) {
            result[data.data().spentDate] = data.data().amountSpent * (data.data().tax / 100) + data.data().amountSpent + data.data().shipping
        }
        else {
            result[data.data().spentDate] += data.data().amountSpent * (data.data().tax / 100) + data.data().amountSpent + data.data().shipping
        }
        count++; total += result[data.data().spentDate];
    }

    return { count, total, result };
}
