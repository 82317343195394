import { doc, getDoc } from "firebase/firestore";
import { db } from "../index";

export default async function consigneeIdValidator(storeId, consigneeId) {
  const conRef = doc(db, "Stores", storeId, "Consignee", consigneeId);
  const conSnap = await getDoc(conRef);
  if (!conSnap.exists()) {
    throw new Error("Consignee Id isn't valid!");
  } else {
    return conSnap.data();
  }
}
