import { doc, setDoc } from "firebase/firestore";
import { db } from "../index";

export default async function NavSettings(storeId, data) {
  await setDoc(
    doc(db, "Stores", storeId, "Customization", "NavSettings"),
    {
      ...data,
    },
    { merge: true }
  );
}
