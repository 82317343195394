import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../index";

export async function editStaffSettings(storeId, staffID, data) {
  const docRef = doc(
    db,
    "Stores",
    storeId,
    "Staff",
    "Staffs",
    "Accounts",
    staffID
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return updateDoc(
      docRef,
      {
        settings: { ...data },
      },
      { merge: true }
    );
  } else {
    throw new Error("Staff does not exist.");
  }
}

export async function editAllStaffSettings(storeId, data) {
  await setDoc(
    doc(db, "Stores", storeId, "Customization", "GenStaffSettings"),
    {
      ...data,
    },
    { merge: true }
  );
}
