import "../css/Container.scoped.css";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { StoreId } from "../../Constants";
import Logo from "./Logo";
import Background from "./Background";
import FooterBg from "./FooterBg";
import Details from "./Details";
import SelectLabels from "../general/Select";
import Button from "../general/Button";
import QrSettings from "../../firebase/customization/qrSettings";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import down from "../../assets/down.svg";
import downdark from "../../assets/downdark.svg";
import translate from "../../i18n/translate";
const Container = ({ dark }) => {
  const defaultSetting = {
    currency: "usd",
    sizing: "us",
    fontColor: "#323A52",
    boxBgColor: "#F6F7FF",
    accentColour: "#7C85CD",
  };

  const [imgUrl, setImgUrl] = useState("");
  const [data, setData] = useState();
  const [bg, setBg] = useState("");
  const [footerBg, setFooterBg] = useState("");
  const [storeUrl, setStoreUrl] = useState("");
  const [instaUrl, setInstaUrl] = useState("");

  const [addSettings, setAddSettings] = useState({
    currency: defaultSetting.currency,
    sizing: defaultSetting.sizing,
    fontColor: defaultSetting.fontColor,
    boxBgColor: defaultSetting.boxBgColor,
    accentColour: defaultSetting.accentColour,
  });
  let updateValue = (e) => {
    setAddSettings({
      ...addSettings,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    (async () => {
      let docRef = doc(db, "Stores", StoreId, "Customization", "QrSettings");
      let docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const storeListener = onSnapshot(docRef, async (snapShot) => {
          let data = snapShot.data();
          setData({
            imgUrl: data.imgUrl,
            bg: data.bg,
            footerBg: data.footerBg,
            storeUrl: data.storeUrl,
            instaUrl: data.instaUrl,
          });
          setAddSettings({
            currency: data.currency ? data.currency : defaultSetting.currency,
            sizing: data.sizing ? data.sizing : defaultSetting.sizing,
            fontColor: data.fontColor
              ? data.fontColor
              : defaultSetting.fontColor,
            boxBgColor: data.boxBgColor
              ? data.boxBgColor
              : defaultSetting.boxBgColor,
            accentColour: data.accentColour
              ? data.accentColour
              : defaultSetting.accentColour,
          });
        });

        return () => {
          storeListener();
        };
      }
    })();
  }, []);

  return (
    <div>
      <div className="qr-container">
        <Logo dark={dark} imgUrl={imgUrl} setImgUrl={setImgUrl} data={data} />
        <Background dark={dark} bg={bg} setBg={setBg} data={data} />
        <FooterBg
          dark={dark}
          footerBg={footerBg}
          setFooterBg={setFooterBg}
          data={data}
        />
      </div>

      <div className="qr-container">
        <Details
          dark={dark}
          storeUrl={storeUrl}
          setStoreUrl={setStoreUrl}
          instaUrl={instaUrl}
          setInstaUrl={setInstaUrl}
          data={data}
        />
      </div>

      <div className="wrap mt-custom">
        <div>
          <div className="label">{translate("currency")}</div>
          <SelectLabels
            dark={dark}
            name={"currency"}
            value={addSettings.currency}
            updateValue={(e) => {
              updateValue(e);
            }}
            placeholderText={"Choose Currency"}
            options={[
              {
                label: "CNY",
                value: "cny",
              },
              {
                label: "EUR",
                value: "eur",
              },
              {
                label: "SGD",
                value: "sgd",
              },
              {
                label: "USD",
                value: "usd",
              },
            ]}
          />
        </div>

        <div>
          <div className="label">{translate("sizing")}</div>
          <SelectLabels
            dark={dark}
            name={"sizing"}
            value={addSettings.sizing}
            updateValue={(e) => {
              updateValue(e);
            }}
            placeholderText={"EU/UK/US"}
            options={[
              {
                label: "EU",
                value: "eu",
              },
              {
                label: "UK",
                value: "uk",
              },
              {
                label: "US",
                value: "us",
              },
            ]}
          />
        </div>

        <div>
          <div className="label">{translate("fontColor")}</div>
          {/* <SelectLabels
            dark={dark}
            name={"fontColor"}
            value={addSettings.fontColor}
            updateValue={(e) => {
              updateValue(e);
            }}
            placeholderText={"Select Colour"}
            options={[
              {
                label: "#323A52",
                value: "#323A52",
              },
            ]}
          /> */}

          <div className="color-container">
            <div>{addSettings.fontColor}</div>
            <input
              name={"fontColor"}
              className="colorinput"
              type={"color"}
              value={addSettings.fontColor}
              onChange={(e) => {
                updateValue(e);
              }}
            />
            <img src={dark ? downdark : down} alt="" />
          </div>
        </div>

        <div>
          <div className="label">{translate("boxBackgroundColor")}</div>
          {/* <SelectLabels
            dark={dark}
            name={"boxBgColor"}
            value={addSettings.boxBgColor}
            updateValue={(e) => {
              updateValue(e);
            }}
            placeholderText={"Select Colour"}
            options={[
              {
                label: "#F6F7FF",
                value: "#F6F7FF",
              },
            ]}
          /> */}
          <div className="color-container">
            <div>{addSettings.boxBgColor}</div>
            <input
              name={"boxBgColor"}
              className="colorinput"
              type={"color"}
              value={addSettings.boxBgColor}
              onChange={(e) => {
                updateValue(e);
              }}
            />
            <img src={dark ? downdark : down} alt="" />
          </div>
        </div>

        <div>
          <div className="label">{translate("accentColour")}</div>
          {/* <SelectLabels
            dark={dark}
            name={"accentColour"}
            value={addSettings.accentColour}
            updateValue={(e) => {
              updateValue(e);
            }}
            placeholderText={"Select Colour"}
            options={[
              {
                label: "#7C85CD",
                value: "#7C85CD",
              },
            ]}
          /> */}
          <div className="color-container">
            <div>{addSettings.accentColour}</div>
            <input
              name="accentColour"
              className="colorinput"
              type={"color"}
              value={addSettings.accentColour}
              onChange={(e) => {
                updateValue(e);
              }}
            />
            <img src={dark ? downdark : down} alt="" />
          </div>
        </div>
      </div>

      <div className="wrap">
        <div className="flex btncontainer">
          <div className="mr-20">
            <Button
              onClick={() => {
                try {
                  QrSettings(StoreId, { ...defaultSetting });
                  setAddSettings({ ...defaultSetting });
                  SuccessToast(translate("updated"), translate("defaultsettings"));
                } catch (e) {
                  ErrorToast(translate("error"), e.message);
                }
              }}
              imgSrc={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6667 12.6667C10.2985 12.6667 10 12.3682 10 12V6.66667H11.3333V12C11.3333 12.3682 11.0349 12.6667 10.6667 12.6667Z"
                    fill={dark ? "#5A5D74" : `#ABAFD1`}
                  />
                  <path
                    d="M4.66667 4C4.66667 3.63181 4.96514 3.33333 5.33333 3.33333C5.70152 3.33333 6 3.63181 6 4V9.33333H4.66667L4.66667 4Z"
                    fill={dark ? "#5A5D74" : `#ABAFD1`}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 16C1.412 16 0 14.588 0 8C0 1.412 1.412 0 8 0C14.588 0 16 1.412 16 8C16 14.588 14.588 16 8 16ZM1.62514 11.74C1.42169 10.8246 1.33333 9.61657 1.33333 8C1.33333 6.38343 1.42169 5.17538 1.62514 4.25995C1.82522 3.35966 2.11845 2.82616 2.47231 2.47231C2.82616 2.11846 3.35966 1.82522 4.25995 1.62514C5.17538 1.42169 6.38343 1.33333 8 1.33333C9.61657 1.33333 10.8246 1.42169 11.74 1.62514C12.6403 1.82522 13.1738 2.11846 13.5277 2.47231C13.8815 2.82616 14.1748 3.35966 14.3749 4.25995C14.5783 5.17538 14.6667 6.38343 14.6667 8C14.6667 9.61657 14.5783 10.8246 14.3749 11.74C14.1748 12.6403 13.8815 13.1738 13.5277 13.5277C13.1738 13.8815 12.6403 14.1748 11.74 14.3749C10.8246 14.5783 9.61657 14.6667 8 14.6667C6.38343 14.6667 5.17538 14.5783 4.25995 14.3749C3.35966 14.1748 2.82616 13.8815 2.47231 13.5277C2.11845 13.1738 1.82522 12.6403 1.62514 11.74Z"
                    fill={dark ? "#5A5D74" : `#ABAFD1`}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.6667 3.33337C9.56214 3.33337 8.66671 4.2288 8.66671 5.33337C8.66671 6.43794 9.56214 7.33337 10.6667 7.33337C11.7713 7.33337 12.6667 6.43794 12.6667 5.33337C12.6667 4.2288 11.7713 3.33337 10.6667 3.33337ZM10.6667 4.66671C10.2985 4.66671 10 4.96518 10 5.33337C10 5.70156 10.2985 6.00004 10.6667 6.00004C11.0349 6.00004 11.3334 5.70156 11.3334 5.33337C11.3334 4.96518 11.0349 4.66671 10.6667 4.66671Z"
                    fill="#767BFA"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.33337 12.6667C4.2288 12.6667 3.33337 11.7713 3.33337 10.6667C3.33337 9.56214 4.2288 8.66671 5.33337 8.66671C6.43794 8.66671 7.33337 9.56214 7.33337 10.6667C7.33337 11.7713 6.43794 12.6667 5.33337 12.6667ZM5.33337 11.3334C4.96518 11.3334 4.66671 11.0349 4.66671 10.6667C4.66671 10.2985 4.96518 10 5.33337 10C5.70156 10 6.00004 10.2985 6.00004 10.6667C6.00004 11.0349 5.70156 11.3334 5.33337 11.3334Z"
                    fill="#767BFA"
                  />
                </svg>
              }
              text={translate("default")}
            />
          </div>

          <Button
            onClick={() => {
              try {
                QrSettings(StoreId, { ...addSettings });
                SuccessToast(translate("updated"), translate("customSettings"));
              } catch (e) {
                ErrorToast(translate("error"), e.message);
              }
            }}
            imgSrc={
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.66699 5.33329C8.66699 4.9651 8.36852 4.66663 8.00033 4.66663C7.63214 4.66663 7.33366 4.9651 7.33366 5.33329V7.33329H5.33366C4.96547 7.33329 4.66699 7.63177 4.66699 7.99996C4.66699 8.36815 4.96547 8.66663 5.33366 8.66663H7.33366V10.6666C7.33366 11.0348 7.63214 11.3333 8.00033 11.3333C8.36852 11.3333 8.66699 11.0348 8.66699 10.6666V8.66663H10.667C11.0352 8.66663 11.3337 8.36815 11.3337 7.99996C11.3337 7.63177 11.0352 7.33329 10.667 7.33329H8.66699V5.33329Z"
                  fill="#767BFA"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 8C0 14.588 1.412 16 8 16C14.588 16 16 14.588 16 8C16 1.412 14.588 0 8 0C1.412 0 0 1.412 0 8ZM1.33333 8C1.33333 9.61657 1.42169 10.8246 1.62514 11.74C1.82522 12.6403 2.11845 13.1738 2.47231 13.5277C2.82616 13.8815 3.35966 14.1748 4.25995 14.3749C5.17538 14.5783 6.38343 14.6667 8 14.6667C9.61657 14.6667 10.8246 14.5783 11.74 14.3749C12.6403 14.1748 13.1738 13.8815 13.5277 13.5277C13.8815 13.1738 14.1748 12.6403 14.3749 11.74C14.5783 10.8246 14.6667 9.61657 14.6667 8C14.6667 6.38343 14.5783 5.17538 14.3749 4.25995C14.1748 3.35966 13.8815 2.82616 13.5277 2.47231C13.1738 2.11846 12.6403 1.82522 11.74 1.62514C10.8246 1.42169 9.61657 1.33333 8 1.33333C6.38343 1.33333 5.17538 1.42169 4.25995 1.62514C3.35966 1.82522 2.82616 2.11846 2.47231 2.47231C2.11845 2.82616 1.82522 3.35966 1.62514 4.25995C1.42169 5.17538 1.33333 6.38343 1.33333 8Z"
                  fill={dark ? "#5A5D74" : `#ABAFD1`}
                />
              </svg>
            }
            text={translate("save")}
          />
        </div>
      </div>
    </div>
  );
};

export default Container;
