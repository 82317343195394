import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../index";

export default async function addStaff(storeId, data) {
  if (!data.staffName || data.staffName.length < 3) {
    throw new Error("Name is not a valid value.");
  }

  if (!data.staffId) {
    throw new Error("Consignee id is not a valid value.");
  }

  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (!data.staffEmail.match(validRegex)) {
    throw new Error("Email is not a valid value.");
  }

  const storeRef = doc(db, "Stores", storeId);
  const storeSnap = await getDoc(storeRef);
  const staffID = storeSnap.data().prefix + data.staffId;

  await setDoc(
    doc(db, "Stores", storeId, "Staff", "Staffs", "Accounts", staffID),
    {
      ...data,
      staffId: staffID,
      totalHours: 0,
      clockingIn: "",
      clockingOut: "",
      status: "Active",
      numberOfSales: 0,
      figures: 0,
    }
  );
}
