import {
  addDoc,
  collection,
  doc,
  increment,
  updateDoc,
} from "firebase/firestore";
import { db } from "../index";
export default async function addBankAccount(storeId, data) {
  //data.name
  if (!data.accountHolderName) {
    throw new Error("Account holder name is not a valid value.");
  }

  if (data.accountHolderName.length < 3) {
    throw new Error("Name must contain more than 3 characters");
  }
  if (!data.bankName) {
    throw new Error("Bank name is not a valid value.");
  }

  if (!data.routingNumber) {
    throw new Error("Routing Number /sort code is not a valid value.");
  }

  if (!data.accountNumber) {
    throw new Error("aAccount Number is not a valid value.");
  }

  await addDoc(collection(db, "Stores", storeId, "BankAccounts"), {
    ...data,
    storeId: storeId,
  });
}
