import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../index";
import encodeKey from "../util/encodeKey";
import deleteAsk from "../../firebase/inventory/deleteAsk";

export default async function payCart(storeId, cartId, paymentData) {
  console.log(paymentData);
  await payValidation(storeId, paymentData);

  const cartRef = doc(db, "Stores", storeId, "Cart", cartId);
  const normalStockCount = {};
  const stockCPCount = {};

  const cartSnap = await getDoc(cartRef);
  const obj = cartSnap.data().items;

  // Iterate through all the items in respective cart item
  // Because if we make the transfer and successive items aren't available, we'll have to rollback all changes
  let invRefArr = {};
  await runTransaction(db, async (transaction) => {
    for (let item in obj) {
      const invRef = doc(db, "Stores", storeId, "Inventory", item);
      const invSnap = await transaction.get(invRef);
      invRefArr[item] = invSnap;
      if (!invSnap.exists()) {
        throw new Error("Some item/items are no more available!");
      }
    }

    for (let item in obj) {
      //Add in sold items
      const invRef = doc(db, "Stores", storeId, "Inventory", item);
      // const invSnap = await transaction.get(invRef);
      const soldRef = doc(db, "Stores", storeId, "SoldItems", item);
      await transaction.set(soldRef, {
        // ...invRefArr[item].data(), // add obj[item] (cart me jo item hai) instead of inventory item becoz we need item of price!
        ...obj[item], //cart item
        orderId: cartId,
        storeId: storeId,
        dateSold: Date.now(),
        ...paymentData,
      });
      //Delete from inventory
      await transaction.delete(invRef);

      // Check if normal or consignment item
      if (invRefArr[item].data().type === "normal") {
        //Update stocks in respective product
        const size = encodeKey(invRefArr[item].data().size.toString());
        if (normalStockCount[invRefArr[item].data().sku] === undefined) {
          normalStockCount[invRefArr[item].data().sku] = {};
        }
        if (normalStockCount[invRefArr[item].data().sku][size] === undefined) {
          normalStockCount[invRefArr[item].data().sku][size] = 1;
        } else {
          normalStockCount[invRefArr[item].data().sku][size] += 1;
        }
        //update main STORE ledger
        const decrementCount = increment(-1);
        const decrementValue = increment(
          invRefArr[item].data().purchasePrice * -1
        );
        await transaction.update(doc(db, "Stores", storeId), {
          totalItems: decrementCount,
          totalValue: decrementValue,
        });
      } else {
        const size = encodeKey(invRefArr[item].data().size.toString());
        if (stockCPCount[invRefArr[item].data().sku] === undefined) {
          stockCPCount[invRefArr[item].data().sku] = {};
        }
        if (stockCPCount[invRefArr[item].data().sku][size] === undefined) {
          stockCPCount[invRefArr[item].data().sku][size] = 1;
        } else {
          stockCPCount[invRefArr[item].data().sku][size] += 1;
        }
        //update main STORE ledger
        const decrementCount = increment(-1);
        const decrementValue = increment(
          invRefArr[item].data().payoutPrice * -1
        );
        await transaction.update(doc(db, "Stores", storeId), {
          totalConsignment: decrementCount,
          totalPayout: decrementValue,
        });
      }
    }
  });

  // Send cart data -> orders
  const orderRef = doc(db, "Stores", storeId, "Orders", cartId);

  // Update cart state as completed
  await updateDoc(cartRef, {
    state: "Completed",
    subTotal: paymentData.subTotal,
    total: paymentData.subTotal - paymentData.discountPercent,
    dateSold: Date.now(),
    shippingAddress: paymentData.shippingAddress,
    staffId: paymentData.staffId,
  });

  const order = cartSnap.data();
  order["state"] = "Completed";

  await setDoc(orderRef, {
    ...order,
    ...paymentData,
  });

  //updatestockCount ledger
  for (let prod in normalStockCount) {
    const Ref = doc(db, "Stores", storeId, "Products", prod);
    for (let item in normalStockCount[prod]) {
      const incrementStocks = increment(normalStockCount[prod][item] * -1);
      const q = query(
        collection(db, "Stores", storeId, "Inventory"),
        where("sku", "==", prod),
        where("type", "==", "normal"),
        orderBy("markUp", "asc"),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      let minMarkup = 0;
      const size = encodeKey(item.toString());
      if (querySnapshot.size > 0) {
        const doc = querySnapshot.docs[0];
        minMarkup = doc.data().markUp;
      }
      const updater = {
        [`stocks.${encodeKey(size)}`]: incrementStocks,
        [`markup.${encodeKey(size)}`]: minMarkup,
      };
      await updateDoc(Ref, updater);
    }
  }

  for (let prod in stockCPCount) {
    const Ref = doc(db, "Stores", storeId, "Products", prod);
    for (let item in stockCPCount[prod]) {
      const incrementStocks = increment(stockCPCount[prod][item] * -1);
      const q = query(
        collection(db, "Stores", storeId, "Inventory"),
        where("sku", "==", prod),
        where("type", "==", "consignment"),
        orderBy("price", "asc"),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      let minCP = 0;
      const size = encodeKey(item.toString());
      if (querySnapshot.size > 0) {
        const doc = querySnapshot.docs[0];
        minCP = doc.data().price;
      } else {
        minCP = Number.MAX_VALUE;
      }
      const updater = {
        [`stocksCP.${encodeKey(size)}`]: incrementStocks,
        [`minCP.${encodeKey(size)}`]: minCP,
      };
      await updateDoc(Ref, updater);
    }
  }

  //update discount used count
  if (cartSnap.data().discountCode !== undefined) {
    const incDiscountUsed = increment(1);

    await updateDoc(
      doc(db, "Stores", storeId, "Discounts", cartSnap.data().discountCode),
      {
        used: incDiscountUsed,
      }
    );
  }

  // delete ask if any item is listed in Katch
  let token = localStorage.getItem("token");
  for (let item in invRefArr) {
    // console.log(item);
    const itemData = invRefArr[item].data();
    // console.log(itemData);
    if (itemData.isAsked) {
      await deleteAsk(token, {
        storeId,
        id: invRefArr[item].id,
        sku: itemData.sku,
        askId: itemData.askId,
      });
    }
  }

  //update staff ledgers
  const staffRef = doc(
    db,
    "Stores",
    storeId,
    "Staff",
    "Staffs",
    "Accounts",
    paymentData.staffId
  );
  const staffSnap = await getDoc(staffRef);

  if (staffSnap.exists()) {
    const incSales = increment(1);
    const incFigures = increment(paymentData.subTotal);
    await updateDoc(staffRef, {
      numberOfSales: incSales,
      figures: incFigures,
    });
  }
}

async function payValidation(storeId, data) {
  if (!data.userName || data.userName.length < 3) {
    throw new Error("Name is not a valid value.");
  }

  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!data.email.match(validRegex)) {
    throw new Error("Email is not a valid email.");
  }

  const staffRef = doc(
    db,
    "Stores",
    storeId,
    "Staff",
    "Staffs",
    "Accounts",
    data.staffId
  );
  const staffSnap = await getDoc(staffRef);

  if (!data.staffId || !staffSnap.exists()) {
    throw new Error("Invalid staff ID");
  }

  if (data.paymentMethod === "card") {
    if (!data.cc || data.cc.toString().length !== 4) {
      throw new Error("Invalid CC digits");
    }
  }

  if (!data.paymentMethod) {
    throw new Error("Please select payment method.");
  }

  if (
    !data.contact ||
    !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
      data.contact
    )
  ) {
    throw new Error("Contact is not a valid value.");
  }
  if (!data.shippingAddress) {
    throw new Error("Shipping Address can't be empty");
  }
}
