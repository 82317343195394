import React from "react";
import "../css/StaffDetails.scoped.css";
import translate from "../../i18n/translate";
export default function WorkPerfomance({ perfomance }) {
  console.log(perfomance)
  function calculatePercantage(curr, total) {
    const percentage = Number((curr * 100 / total).toFixed(2));
    console.log(percentage);
    if(isNaN(percentage) || percentage === Infinity){
      return 0;
    }
    return percentage;
  }
  const dailyPercentage = calculatePercantage(perfomance?.daily, perfomance?.dailyTotal);
  const weeklyPercentage = calculatePercantage(perfomance?.weekly, perfomance?.weeklyTotal);
  const monthlyPercentage = calculatePercantage(perfomance?.monthly, perfomance?.monthlyTotal);
  const yearlyPercentage = calculatePercantage(perfomance?.yearly, perfomance?.yearlyTotal);
  return (
    <div className="work">
      <div className="flex">
        <div className="left-grid">
          <span className="query bold">{translate("dailyWorkingHours")}:</span>
          <span className="value">{perfomance?.daily}</span>
        </div>

        <div className="three-grid">
          <span className="hrs">HRS</span>
          <span className={
            (dailyPercentage === 100)
              ? "green bold percent" 
              : (dailyPercentage < 100 && dailyPercentage >= 50)
                ? "yellow bold percent"
                : "red bold percent"
          }>{dailyPercentage+"%"}</span>
          <span>{translate("fulfilled")}</span>
        </div>
      </div>
      <div className="flex">
        <div className="left-grid">
          <span className="query bold">{translate("weeklyWorkingHours")}:</span>
          <span className="value">{perfomance?.weekly}</span>
        </div>

        <div className="three-grid">
          <span className="hrs">HRS</span>
          <span className={
            (weeklyPercentage === 100)
              ? "green bold percent" 
              : (weeklyPercentage < 100 && weeklyPercentage >= 50)
                ? "yellow bold percent"
                : "red bold percent"
          }>{weeklyPercentage+"%"}</span>
          <span>{translate("fulfilled")}</span>
        </div>
      </div>
      <div className="flex">
        <div className="left-grid">
          <span className="query bold">{translate("monthlyWorkingHours")}:</span>
          <span className="value">{perfomance?.monthly}</span>
        </div>

        <div className="three-grid">
          <span className="hrs">HRS</span>
          <span className={
            (monthlyPercentage === 100)
              ? "green bold percent" 
              : (monthlyPercentage < 100 && monthlyPercentage >= 50)
                ? "yellow bold percent"
                : "red bold percent"
          }>{monthlyPercentage+"%"}</span>
          <span>{translate("fulfilled")}</span>
        </div>
      </div>
      <div className="flex">
        <div className="left-grid">
          <span className="query bold">{translate("yearlyWorkingHours")}:</span>
          <span className="value">{perfomance?.yearly}</span>
        </div>

        <div className="three-grid">
          <span className="hrs">HRS</span>
          <span className={
            (yearlyPercentage === 100)
              ? "green bold percent" 
              : (yearlyPercentage < 100 && yearlyPercentage >= 50)
                ? "yellow bold percent"
                : "red bold percent"
          }>{yearlyPercentage+"%"}</span>
          <span>{translate("fulfilled")}</span>
        </div>
      </div>
    </div>
  );
}
