import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import sortIcon from "../assets/sort.svg";
import importIcon from "../assets/import.svg";
import importIconDark from "../assets/importdark.svg";
import Button from "../components/general/Button";
import SearchBtn from "../components/general/SearchBtn";
import { ErrorToast } from "../components/general/ToastNotification";
import Topbar from "../components/general/Topbar";
import InventoryContainer from "../components/inventory/InventoryContainer";
import SmallSelect from "../components/general/SmallSelect";
import Modal from "../components/inventory/Modal";
import { StoreId } from "../Constants";
import bulkImport from "../firebase/util/bulkImport";
import translate from "../i18n/translate";
import add from "../assets/add.svg";
import ListedContainer from "../components/inventory/ListedContainer";
import addDark from "../assets/addark.svg";
import SearchModal from "../components/tickets/searchModal";
import TicketContainer from "../components/inventory/TicketContainer";

const Inventory = ({ user, loadingScreen, setLoadingScreen, dark }) => {
  const [search, setSearch] = useState("");
  const [searchString, updateSearchString] = useState("");
  const [active, setActive] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [importData, setImportData] = useState();
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [catagories, updateCatagories] = useState("shoes");
  const isOwner = localStorage.getItem("owner");
  let hideInvImport = localStorage.getItem("hideInvImport");
  let hideInvBtns = localStorage.getItem("hideInvBtns");

  const navigate = useNavigate();

  const auth = getAuth();

  console.log(auth);

  useEffect(() => {
    const authListener = onIdTokenChanged(auth, async (user) => {
      // console.log("token refreshed");
      if (user) {
        const token = await user.getIdToken(true);
        // console.log(token, "token updated");

        // localStorage.setItem("token", token);
      }
    });
    return () => {
      authListener();
    };
  }, []);

  useEffect(() => {
    let hidden = localStorage.getItem("isInventoryHidden");
    if (!isOwner && hidden) {
      navigate("/dash");
    }
  }, [isOwner, navigate]);

  return (
    <div className="page">
      <Topbar
        dark={dark}
        pagename={translate("inventory")}
        links={[
          {
            name: translate("summary"),
          },
          {
            name: translate("Listed and Sold"),
          },
          {
            name: "Tickets",
          },
        ]}
        active={active}
        onClick={setActive}
        user={user}
      />
      <div>
        <div className="add-btns px">
          <div className="mr-14">
            <SearchBtn
              dark={dark}
              value={search}
              updateValue={setSearch}
              onEnter={async () => {
                updateSearchString(search);
              }}
            />
          </div>

          {active === 2 && (
            <Button
              text={translate("Add New")}
              imgSrc={<img src={dark ? addDark : add} alt="import icon" />}
              onClick={() => {
                setSearchModalOpen(true);
              }}
            />
          )}

          {isOwner ? (
            <>
              {active === 0 && (
                <div className="mr-14">
                  <Button
                    text={translate("import")}
                    imgSrc={
                      <img
                        src={dark ? importIconDark : importIcon}
                        alt="import icon"
                      />
                    }
                    onClick={async () => {
                      let [fileHandle] = await window.showOpenFilePicker({
                        types: [
                          {
                            description: "CSV",
                            accept: {
                              "csv/*": [".csv"],
                            },
                          },
                        ],
                        excludeAcceptAllOption: true,
                        multiple: false,
                      });

                      const fileData = await fileHandle.getFile();
                      const fileText = await fileData.text();
                      if (fileText) {
                        let data = fileText.split("\r\n");
                        data = data.filter((e) => e !== "");
                        let spiltData = data.map((e) => e.split(","));
                        let lastData = spiltData
                          .map((e) => ({
                            name: e[0].trim(),
                            sku: e[1].trim(),
                            size: parseFloat(e[3]),
                            category: e[4].trim(),
                            condition: e[5].trim(),
                            purchasePrice: parseFloat(e[6]),
                            purchaseDate: e[2].trim(),
                            platform: e[7].trim(),
                            lastSale: parseFloat(e[10]),
                            markUp: parseFloat(e[9].trim()),
                            quantity: parseInt(e[8].trim()),
                          }))
                          .filter((e) => {
                            if (
                              e.name &&
                              e.sku &&
                              e.size &&
                              e.category &&
                              e.condition &&
                              e.purchasePrice &&
                              e.purchaseDate &&
                              e.platform &&
                              e.markUp &&
                              e.quantity
                            ) {
                              if (e.platform === "custom" && !e.lastSale) {
                                return false;
                              } else {
                                return true;
                              }
                            } else {
                              return false;
                            }
                          });

                        if (lastData) {
                          (async () => {
                            setLoadingScreen(true);
                            try {
                              await bulkImport(StoreId, lastData);
                            } catch (e) {
                              ErrorToast(translate("error"), e.message);
                            } finally {
                              setLoadingScreen(false);
                            }
                          })();
                        }
                      }
                    }}
                  />
                </div>
              )}

              {active === 1 && (
                <div className="mr-14">
                  <SmallSelect
                    dark={dark}
                    name="sortBy"
                    value={sortBy}
                    customIcon={sortIcon}
                    updateValue={(e) => setSortBy(e.target.value)}
                    options={[
                      {
                        label: "Sort by Listed",
                        value: "listed",
                      },
                      {
                        label: "Sort by Sold",
                        value: "sold",
                      },
                      {
                        label: "All",
                        value: "all",
                      },
                    ]}
                    placeholderText={"Sort by..."}
                    none={false}
                    month
                  />
                </div>
              )}
            </>
          ) : (
            !hideInvImport && (
              <div className="mr-14">
                <Button
                  text={translate("import")}
                  imgSrc={
                    <img
                      src={dark ? importIconDark : importIcon}
                      alt="import icon"
                    />
                  }
                  onClick={async () => {
                    let [fileHandle] = await window.showOpenFilePicker({
                      types: [
                        {
                          description: "CSV",
                          accept: {
                            "csv/*": [".csv"],
                          },
                        },
                      ],
                      excludeAcceptAllOption: true,
                      multiple: false,
                    });

                    const fileData = await fileHandle.getFile();
                    const fileText = await fileData.text();
                    if (fileText) {
                      let data = fileText.split("\r\n");
                      data = data.filter((e) => e !== "");
                      let spiltData = data.map((e) => e.split(","));
                      let lastData = spiltData
                        .map((e) => ({
                          name: e[0].trim(),
                          sku: e[1].trim(),
                          size: parseFloat(e[3]),
                          category: e[4].trim(),
                          condition: e[5].trim(),
                          purchasePrice: parseFloat(e[6]),
                          purchaseDate: e[2].trim(),
                          platform: e[7].trim(),
                          lastSale: parseFloat(e[10]),
                          markUp: parseFloat(e[9].trim()),
                          quantity: parseInt(e[8].trim()),
                        }))
                        .filter((e) => {
                          if (
                            e.name &&
                            e.sku &&
                            e.size &&
                            e.category &&
                            e.condition &&
                            e.purchasePrice &&
                            e.purchaseDate &&
                            e.platform &&
                            e.markUp &&
                            e.quantity
                          ) {
                            if (e.platform === "custom" && !e.lastSale) {
                              return false;
                            } else {
                              return true;
                            }
                          } else {
                            return false;
                          }
                        });

                      if (lastData) {
                        (async () => {
                          setLoadingScreen(true);
                          try {
                            await bulkImport(StoreId, lastData);
                          } catch (e) {
                            ErrorToast(translate("error"), e.message);
                          } finally {
                            setLoadingScreen(false);
                          }
                        })();
                      }
                    }
                  }}
                />
              </div>
            )
          )}

          {active === 0 ? (
            isOwner ? (
              <Modal
                dark={dark}
                importData={importData}
                setImportData={setImportData}
                setLoadingScreen={setLoadingScreen}
              />
            ) : (
              !hideInvBtns && (
                <Modal
                  dark={dark}
                  importData={importData}
                  setImportData={setImportData}
                  setLoadingScreen={setLoadingScreen}
                />
              )
            )
          ) : (
            <></>
          )}
          {active === 1 ? (
            <SmallSelect
              dark={dark}
              name="categories"
              value={catagories}
              updateValue={(e) => updateCatagories(e.target.value)}
              options={[
                {
                  label: "Shoes",
                  value: "shoes",
                },
                {
                  label: "Tickets",
                  value: "tickets",
                },
              ]}
              placeholderText={"Categories..."}
              none={false}
              month
            />
          ) : (
            <></>
          )}
        </div>
        <div className="content px">
          {active === 0 && (
            <InventoryContainer searchString={searchString} dark={dark} />
          )}
          {active === 1 && (
            <ListedContainer
              searchString={searchString}
              dark={dark}
              sortBy={sortBy}
              catagories={catagories}
            />
          )}
          {active === 2 && (
            <TicketContainer
              isOpen={searchModalOpen}
              toggleState={setSearchModalOpen}
              dark={dark}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Inventory;
