import {
    doc,
    deleteDoc,
    deleteField,
    updateDoc
} from "firebase/firestore";
import { db } from "../index";

export default async function removeFromCart(storeId, cartId, itemId) {
    const docRef = doc(db, "Stores", storeId, "Cart", cartId);

    await updateDoc(docRef, {
        [`items.${itemId}`]: deleteField()
    });
}
