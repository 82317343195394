import "../css/Button.scoped.css";

const Button = ({ disabled, onClick, imgSrc, text, customClass }) => {
  return (
    <div
      role={"button"}
      className={`btn ${customClass ? customClass : ""} ${
        disabled ? "disabled" : ""
      }`}
      onClick={() => onClick()}
    >
      {imgSrc ? imgSrc : null}
      <span className="text">{text}</span>
    </div>
  );
};

export default Button;
