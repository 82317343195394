import {
  doc,
  getDoc,
  updateDoc,
  increment,
  query,
  where,
  limit,
  collection,
  getDocs,
  orderBy,
} from "firebase/firestore";

import { db } from "../index";

import { platforms, conditions } from "./../constants";
import encodeKey from "../util/encodeKey";

/**
 * Edits an inventory item
 * @param {*} storeId
 * @param {*} itemId
 * @param {} data
 */

export default async function editInventory(storeId, itemId, data) {
  //data.condition

  if (!data.condition || !conditions.includes(data.condition)) {
    throw new Error("Condition of this item is not a valid value.");
  }
  //data.name

  if (!data.name || data.name.length < 3) {
    throw new Error("Name of this item is not a valid value.");
  }

  //data.platform
  if (!data.platform || !platforms.includes(data.platform)) {
    throw new Error("Platform of this item is not a valid value.");
  }

  if (!data.purchaseDate) {
    throw new Error("Purchase date of this item is not a valid date.");
  }

  //data.size
  if (!data.size) {
    throw new Error("Size is not a valid value.");
  }

  //data.purchasePrice

  if (
    !data.purchasePrice ||
    data.purchasePrice < 0 ||
    isNaN(data.purchasePrice)
  ) {
    throw new Error("Purchase price is not valid");
  }

  if ((!data.markUp === undefined) || (data.markUp === null) || isNaN(data.markUp)) {
    throw new Error("MarkUp is not valid");
  }
  if (data.platform === "custom" && !data.lastSale) {
    throw new Error("Last sale price cannot be null");
  }

  const docRef = doc(db, "Stores", storeId, "Inventory", itemId);
  const docSnap = await getDoc(docRef);
  const docData = docSnap.data();

  const counterRef = doc(db, "Stores", storeId);

  const decrementValue = increment(
    data.purchasePrice - docSnap.data().purchasePrice
  );

  await updateDoc(counterRef, { totalValue: decrementValue });

  await updateDoc(docRef, {
    ...data,
  });

  const inventoryRef = collection(db, "Stores", storeId, "Inventory");
  const stocksRef = doc(db, "Stores", storeId, "Products", docData.sku);

  const q = query(
    inventoryRef,
    where("sku", "==", docData.sku),
    where("type", "==", "normal"),
    orderBy("markUp", "asc"),
    limit(1)
  );

  const querySnapshot = await getDocs(q);

  let minMarkup = 0;
  let condition = "";

  if (querySnapshot.size > 0) {
    const doc = querySnapshot.docs[0];
    minMarkup = doc.data().markUp;
    condition = doc.data().condition;
  }

  //Lets figure out the new mark up price.

  let updater;
  if (data.platform === "custom") {
    updater = {
      [`markup.${encodeKey(data.size)}`]: minMarkup,
      [`sizesCustom.${encodeKey(data.size)}`]: data.lastSale,
    };
  } else {
    updater = {
      [`markup.${encodeKey(data.size)}`]: minMarkup,
      [`sizesCustom.${encodeKey(data.size)}`]: 0,
    };
  }
  updater[`condition.${encodeKey(data.size)}`] = condition;
  updater[`platform.${encodeKey(data.size)}`] = data.platform;
  await updateDoc(stocksRef, updater);

  // const productSnap = await getDoc(docData.style);
  // const productData = productSnap.data();

  // if (
  //   !productData.markup[encodeKey(data.size)] ||
  //   productData.markup[encodeKey(data.size)] > data.markUp ||
  //   productData.markup[encodeKey(data.size)] === 0
  // ) {
  //   await updateDoc(docData.style, {
  //     [`markup.${encodeKey(data.size)}`]: data.markUp,
  //   });
  // }

  await updateDoc(docRef, {
    lastUpdated: Date.now(),
  });
}
