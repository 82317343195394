import { useContext, useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import Delete from "../assets/delete.svg";
import download from "../assets/import.svg";
import Categories from "../components/dashAdmin/Categories";
import DiscountSummary from "../components/dashAdmin/DiscountSummary";
import AddStaff from "../components/dashOwner/AddStaffModal";
import EditStaff from "../components/dashOwner/EditSatffModal";
import { default as MarketSales } from "../components/dashOwner/MarketSales";
import TransactionHistory from "../components/dashOwner/TransactionHistory";
import Button from "../components/general/Button";
import DeleteConfirm from "../components/general/DeleteConfirm";
import Graph from "../components/general/Graph";
import LineGraph from "../components/general/LineGraph";
import SearchBtn from "../components/general/SearchBtn";
import SmallSelect from "../components/general/SmallSelect";
import Topbar from "../components/general/Topbar";
import CheckInOutDetails from "../components/staffs/CheckInOutDetails";
import StaffDetails from "../components/staffs/StaffDetails";
import StaffSummary from "../components/staffs/StaffSummary";
import WorkPerfomance from "../components/staffs/WorkPerfomance";
import { CurrencyContext } from "../context/CurrencyContext";
import "./css/DashOwner.scoped.css";
import "./css/Home.scoped.css";
import deleteStaff from "../firebase/staff/deleteStaff";
import { ErrorToast } from "../components/general/ToastNotification";
import { collection, onSnapshot } from "firebase/firestore";
import { StoreId } from "../Constants";
import { db } from "../firebase/index";
import profitLossGraph from "../firebase/graphs/profitLossGraph";
import expenseGraph from "../firebase/graphs/expensesGraph";
import inventoryGraph from "../firebase/graphs/inventoryGraph";
import numberWithCommas from "../components/utils/numberWithCommas";
import editStaffStatus from "../firebase/staff/editStaffStatus";
import AddCode from "../components/dashOwner/AddCode";
import DeleteDark from "../assets/deletedark.svg";
import { CSVLink } from "react-csv";
import { formatAMPM } from "../components/utils/getTime";
import translate from "../i18n/translate";
import GetTranslateString from "../i18n/translateString";

const DashOwner = ({ user, dark }) => {
  const [time, setTime] = useState("monthly");
  const { rates, sign } = useContext(CurrencyContext);
  const [active, setActive] = useState(0);
  const [activeItem, setActiveItem] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState({});
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [status, setStatus] = useState("");
  const [changedStatus, setChangedStatus] = useState("");
  const [type, setType] = useState("All");
  const [logData, setLogData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [data, updateData] = useState([]);
  const [displayData, updateDisplayData] = useState([]);
  const [perfomance, setPerformance] = useState();
  const [updated, setUpdated] = useState(Date.now());
  const [graphData, setGraphData] = useState();
  const [graphData1, setGraphData1] = useState();
  const [graphData2, setGraphData2] = useState();
  const [totalSales, setTotalSales] = useState(0);
  const [totalSales1, setTotalSales1] = useState(0);
  const [totalSales2, setTotalSales2] = useState(0);
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [prefix, setPrefix] = useState("");

  const auth = getAuth();
  // console.log(auth);
  useEffect(() => {
    // (async () => {
    //   await new Promise((r) => setTimeout(r, 2000));
    //   if (auth.currentUser === null && localStorage.getItem("loggedIn")) {
    //     localStorage.removeItem("token");
    //     localStorage.removeItem("store");
    //     localStorage.removeItem("email");
    //     localStorage.removeItem("name");
    //     localStorage.removeItem("owner");
    //     localStorage.removeItem("loggedIn");
    //     window.location.href = "/login";
    //   }
    // })();

    const authListener = onIdTokenChanged(auth, async (user) => {
      // console.log(auth)
      if (user) {
        const token = await user.getIdToken(true);
        localStorage.setItem("token", token);
      }
    });
    return () => {
      authListener();
    };
  }, []);

  useEffect(() => {
    if (changedStatus) {
      if (selectedStaff.staffId && status) {
        editStaffStatus(StoreId, selectedStaff.staffId, status);
      }
    }
  }, [changedStatus]);

  useEffect(() => {
    setChangedStatus("");
    setDeleteId(selectedStaff?.staffId);
  }, [selectedStaff]);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "Stores", StoreId, "Staff", "Staffs", "Accounts"),
      async (snapShot) => {
        const conData = snapShot.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
        updateData(conData);
        updateDisplayData(conData);

        const storeRef = doc(db, "Stores", StoreId);
        const storeSnap = await getDoc(storeRef);
        setPrefix(storeSnap.data().prefix);
      }
    );
    return () => {
      inventoryListener();
    };
  }, []);

  useEffect(() => {
    let getData = async () => {
      let { count, total, result: data } = await profitLossGraph(StoreId);
      if (data) {
        let filterData =
          Object.keys(data)?.length > 5
            ? Object.keys(data)
                .slice(0, 5)
                .reverse()
                .map((e) => ({
                  date: e,
                  value: data[e],
                }))
            : Object.keys(data)
                .reverse()
                .map((e) => ({
                  date: e,
                  value: data[e],
                }));
        setTotalSales(Object.values(data)?.reduce((a, b) => a + b), 0);
        setGraphData(filterData);
      } else {
        setTotalSales(0);
        setGraphData();
      }
    };
    getData();
  }, []);

  useEffect(() => {
    let getData = async () => {
      let { count, total, result: data } = await expenseGraph(StoreId);
      if (data) {
        setCount(count);
        let filterData =
          Object.keys(data)?.length > 5
            ? Object.keys(data)
                .slice(0, 5)
                .reverse()
                .map((e) => ({
                  date: e,
                  value: data[e],
                }))
            : Object.keys(data)
                .reverse()
                .map((e) => ({
                  date: e,
                  value: data[e],
                }));
        setTotalSales1(Object.values(data)?.reduce((a, b) => a + b), 0);
        setGraphData1(filterData);
      } else {
        setTotalSales1(0);
        setGraphData1();
      }
    };
    getData();
  }, []);

  useEffect(() => {
    let getData = async () => {
      let { count, total, result: data } = await inventoryGraph(StoreId);
      if (data) {
        setCount1(count);
        let filterData =
          Object.keys(data)?.length > 5
            ? Object.keys(data)
                .slice(0, 5)
                .reverse()
                .map((e) => ({
                  date: e,
                  value: data[e],
                }))
            : Object.keys(data)
                .reverse()
                .map((e) => ({
                  date: e,
                  value: data[e],
                }));
        setTotalSales2(Object.values(data)?.reduce((a, b) => a + b), 0);
        setGraphData2(filterData);
      } else {
        setTotalSales2(0);
        setGraphData2();
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (selectedStaff.staffId && data) {
      setSelectedStaff(
        ...data.filter((e) => e.staffId === selectedStaff.staffId)
      );
    }
  }, [updated]);

  let types = ["All", "Active", "Expired", "Scheduled"];
  let localCurrency = localStorage.getItem("currency");

  useEffect(() => {
    updateDisplayData(
      data?.filter(
        (e) => e.id.toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
    );
  }, [search]);

  return (
    <div className="page">
      <Topbar
        dark={dark}
        links={[
          {
            name: translate("storeInformations"),
          },
          {
            name: translate("staffs"),
          },
        ]}
        active={active}
        onClick={setActive}
        pagename={
          active === 0 ? translate("dashboard") : translate("staffOverviews")
        }
        user={user}
      />
      <div>
        <div className="add-btns mb-0 px">
          <div className="mr-14">
            <SmallSelect
              dark={dark}
              name="time"
              value={time}
              updateValue={(e) => setTime(e.target.value)}
              options={[
                {
                  label: translate("daily"),
                  value: "daily",
                },
                {
                  label: translate("weekly"),
                  value: "weekly",
                },
                {
                  label: translate("monthly"),
                  value: "monthly",
                },
                {
                  label: translate("yearly"),
                  value: "yearly",
                },
              ]}
              placeholderText={GetTranslateString("categories")}
              none={false}
              month
            />
          </div>

          <div className="mr-14">
            <SearchBtn dark={dark} value={search} updateValue={setSearch} />
          </div>

          {active === 1 && (
            <AddStaff
              dark={dark}
              setActiveItem={setActiveItem}
              setSelectedStaff={setSelectedStaff}
              prefix={prefix}
            />
          )}
        </div>
        {active === 0 && (
          <div className="content">
            <div className="pr">
              <div className="threegrid">
                <div className="leftcontainer span-2">
                  <div className="left px pt-10 pb-10">
                    <div className="box">
                      <Categories />
                    </div>
                    <div className="graph box">
                      <div className="headersection">
                        {translate("profitsLosses")}
                      </div>
                      <div className="flex al-fe headers px-20">
                        <span className="bold black">
                          {localCurrency?.toUpperCase()}:
                        </span>
                        <span className="green bold">
                          {" "}
                          +{sign[localCurrency]}
                          {numberWithCommas(
                            totalSales
                              ? (
                                  totalSales *
                                  rates?.[localCurrency?.toUpperCase()]
                                ).toFixed(0)
                              : 0
                          )}
                        </span>
                        <span className="itemssold">
                          {translate("inLastMonth")}...
                        </span>
                      </div>
                      {graphData && <Graph graphData={graphData} dark={dark} />}
                    </div>
                    <div className="graph box">
                      <div className="headersection">
                        {translate("totalExpenses")}
                      </div>
                      <div className="flex al-fe headers px-20">
                        <span className="bold black">
                          {localCurrency?.toUpperCase()}:
                        </span>
                        <span className="bold">
                          {" "}
                          {sign[localCurrency]}
                          {numberWithCommas(
                            totalSales1
                              ? (
                                  totalSales1 *
                                  rates?.[localCurrency?.toUpperCase()]
                                ).toFixed(0)
                              : 0
                          )}
                        </span>
                        <span className="itemssold">
                          {count} {translate("items")}
                        </span>
                      </div>
                      {graphData1 && (
                        <LineGraph graphData={graphData1} dark={dark} />
                      )}
                    </div>
                    <div className="graph box">
                      <div className="headersection">
                        {translate("totalInventory")}
                      </div>
                      <div className="flex al-fe headers px-20">
                        <span className="bold black">
                          {localCurrency?.toUpperCase()}:
                        </span>
                        <span className="bold">
                          {" "}
                          {sign[localCurrency]}
                          {numberWithCommas(
                            totalSales2
                              ? (
                                  totalSales2 *
                                  rates?.[localCurrency?.toUpperCase()]
                                ).toFixed(0)
                              : 0
                          )}
                        </span>
                        <span className="itemssold">
                          {count1} {translate("items")}
                        </span>
                      </div>
                      {graphData2 && (
                        <LineGraph graphData={graphData2} dark={dark} />
                      )}
                    </div>

                    <div className="box span-2">
                      <div className="headersection flex al-ce jc-sp">
                        <div className="smtext">
                          {translate("discountSummary")}
                        </div>
                        <div className="flex al-ce jc-sp center">
                          {types.map((e) => (
                            <div
                              onClick={() => setType(e)}
                              className={`type ${type === e ? "active" : ""}`}
                              key={e}
                            >
                              {translate(e.toLowerCase())}
                            </div>
                          ))}
                        </div>
                        <div className="flex al-ce">
                          <div className="mr-14">
                            <SearchBtn
                              dark={dark}
                              value={search1}
                              updateValue={setSearch1}
                            />
                          </div>
                          <AddCode dark={dark} />
                        </div>
                      </div>

                      <DiscountSummary
                        type={type}
                        del
                        search={search1}
                        edit
                        dark={dark}
                      />
                    </div>
                  </div>
                </div>

                <div className="right">
                  <div className="box">
                    <MarketSales />
                  </div>

                  <div className="box">
                    <TransactionHistory />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {active === 1 && (
          <div className="content px">
            <DeleteConfirm
              dark={dark}
              isOpen={deleteOpen}
              toggleOpen={setDeleteOpen}
              msg={"Staff"}
              deleteId={deleteId}
              setDeleteId={setDeleteId}
              func={deleteStaff}
              addFunc={() => {
                setSelectedStaff({});
                setActiveItem();
              }}
            />

            <div className="threegrid pt-10">
              <div className="box span-2 col-span">
                <div className="headersection">{translate("staffSummary")}</div>
                <StaffSummary
                  selectedStaff={selectedStaff}
                  setPerformance={setPerformance}
                  active={activeItem}
                  updateActive={setActiveItem}
                  setSelectedStaff={setSelectedStaff}
                  setLogData={setLogData}
                  status={status}
                  displayData={displayData}
                  setStatus={setStatus}
                />
              </div>
              <div className="box col-span">
                <div className="headersection flex al-ce jc-sp">
                  <div className="smtext">
                    {translate("clockInAndOutDetails")}
                  </div>
                  {selectedStaff.staffId ? (
                    <CSVLink
                      filename={`${selectedStaff.staffId}-Clocking.csv`}
                      data={[
                        [
                          "CLocked In",
                          "Clocked Out",
                          "Date",
                          "Email",
                          "Staff Id",
                          "Total Time",
                        ],
                        ...logData.map((e) => [
                          e.clockingIn
                            ? formatAMPM(new Date(e.clockingIn))
                            : "-",
                          e.clockingOut
                            ? formatAMPM(new Date(e.clockingOut))
                            : "-",
                          new Date(e["date"])
                            .toDateString()
                            .split(" ")
                            .slice(1, 4)
                            .join(" ") ?? "",
                          e["email"] ?? "",
                          e["staffId"] ?? "",
                          e["totalTime"] ?? "",
                        ]),
                      ]}
                    >
                      <Button
                        onClick={() => {}}
                        text={GetTranslateString("download")}
                        imgSrc={<img src={download} alt="download" />}
                      />
                    </CSVLink>
                  ) : null}
                </div>
                <CheckInOutDetails logData={logData} />
                <div className="overflow mt-10">
                  <div>
                    <div className="headersection flex al-ce jc-sp">
                      <div className="smtext">{translate("staffDetails")}</div>
                      <SmallSelect
                        dark={dark}
                        value={status}
                        updateValue={(e) => {
                          if (selectedStaff.staffId) {
                            setStatus(e.target.value);
                            setChangedStatus(e.target.value);
                          } else {
                            ErrorToast(
                              translate("error"),
                              translate("selectAStaff")
                            );
                          }
                        }}
                        placeholderText={GetTranslateString("status")}
                        options={[
                          {
                            label: translate("active"),
                            value: "Active",
                          },
                          {
                            label: translate("onLeave"),
                            value: "On Leave",
                          },
                          {
                            label: translate("resigned"),
                            value: "Resigned",
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <StaffDetails
                    selectedStaff={selectedStaff}
                    setLogData={setLogData}
                  />
                  <div className="headersection flex al-ce">
                    <div className="smtext">
                      {translate("workingPerformance")}
                    </div>
                  </div>
                  <WorkPerfomance perfomance={perfomance} />
                </div>

                <div className="flex al-ce jc-fe pt-10 pr">
                  <div className="mr-14">
                    <EditStaff
                      dark={dark}
                      setUpdated={setUpdated}
                      data={selectedStaff}
                      prefix={prefix}
                    />
                  </div>
                  <Button
                    imgSrc={
                      <img src={dark ? DeleteDark : Delete} alt="delete" />
                    }
                    text={translate("delete")}
                    onClick={() => {
                      if (deleteId) {
                        setDeleteOpen(true);
                      } else {
                        ErrorToast(
                          translate("error"),
                          translate("pleaseSelectAStaff")
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashOwner;
