import Logo from "./Logo";
import Details from "./Details";
import Preview from "./Preview";
import "../css/Container.scoped.css";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { StoreId } from "../../Constants";

const Container = ({ dark }) => {
  const [imgUrl, setImgUrl] = useState("");
  const [data, setData] = useState();
  const [storeUrl, setStoreUrl] = useState("");
  const [instaUrl, setInstaUrl] = useState("");

  useEffect(() => {
    (async () => {
      let docRef = doc(
        db,
        "Stores",
        StoreId,
        "Customization",
        "ReceiptSettings"
      );
      let docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const storeListener = onSnapshot(docRef, async (snapShot) => {
          let data = snapShot.data();
          setData({
            imgUrl: data.imgUrl,
            storeUrl: data.storeUrl,
            instaUrl: data.instaUrl,
          });
          localStorage.setItem("instaUrl", data.instaUrl);
          localStorage.setItem("storeUrl", data.storeUrl);
        });

        return () => {
          storeListener();
        };
      }
    })();
  }, []);

  return (
    <div className="receipt-container">
      <Logo dark={dark} data={data} imgUrl={imgUrl} setImgUrl={setImgUrl} />
      <Details
        dark={dark}
        instaUrl={instaUrl}
        setInstaUrl={setInstaUrl}
        setStoreUrl={setStoreUrl}
        storeUrl={storeUrl}
        data={data}
      />
      <Preview
        dark={dark}
        imgUrl={imgUrl}
        instaUrl={instaUrl}
        storeUrl={storeUrl}
      />
    </div>
  );
};

export default Container;
