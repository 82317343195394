import { doc, getDoc, onSnapshot } from "firebase/firestore";
import ReactQrCode from "qrcode.react";
import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import Printer from "../../assets/printer.svg";
import PrinterDark from "../../assets/printerdark.svg";
import { StoreId } from "../../Constants";
import { db } from "../../firebase";
import translate from "../../i18n/translate";
import "../css/ProductCard.scoped.css";
import Button from "../general/Button";

export default function ProductCard({
  shoeImg,
  title,
  model,
  number,
  QRimg,
  category,
  dark,
}) {
  const componentRef = useRef();
  const [logo, setLogo] = useState("");

  useEffect(() => {
    (async () => {
      let docRef = doc(db, "Stores", StoreId, "Customization", "QrSettings");
      let docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const storeListener = onSnapshot(docRef, async (snapShot) => {
          let data = snapShot.data();
          setLogo(data.imgUrl);
        });

        return () => {
          storeListener();
        };
      }
    })();
  }, []);

  return (
    <>
      <div className="productWrapper">
        <div className="productBoxWrapper">
          <div className={`categoryBox ${category}`}>
            {category?.charAt(0)?.toUpperCase() + category?.slice(1)
              ? category?.charAt(0)?.toUpperCase() + category?.slice(1)
              : "Unknown"}
          </div>
          <div className="whiteBoxWrap">
            <img className="shoeImg" alt="shoe" src={shoeImg} />
            <div className="prodTitle">{title}</div>
            <div className="model">{model}</div>
            <div className="style">
              <span>{translate("style")}: </span>
              {number}
            </div>
            <div className="qr">
              {/* <div className="title">text</div> */}

              <div style={{ display: "flex", alignItems: "center" }}>
                <ReactQrCode
                  value={`https://qrcode.ingenium.biz/${StoreId}/${number}`}
                  size={100}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  renderAs="svg"
                  level="H"
                  includeMargin={false}
                  imageSettings={{
                    src: logo,
                    height: logo ? 22 : 0,
                    width: logo ? 22 : 0,
                    excavate: logo,
                  }}
                />
              </div>

              <div style={{ display: "none", alignItems: "center" }}>
                <div ref={componentRef}>
                  <ReactQrCode
                    value={`https://qrcode.ingenium.biz/${StoreId}/${number}`}
                    size={300}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    renderAs="svg"
                    level="H"
                    includeMargin={false}
                    imageSettings={{
                      src: logo,
                      height: logo ? 48 : 0,
                      width: logo ? 48 : 0,
                      excavate: logo,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="BtnforCard">
              <ReactToPrint
                bodyClass="qrprint"
                trigger={() => (
                  <Button
                    customClass={"lightbtn"}
                    imgSrc={
                      <img src={dark ? PrinterDark : Printer} alt="printer" />
                    }
                    text={translate("print")}
                  />
                )}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
