import algoliasearch from "algoliasearch/lite";
import { StoreId } from "../Constants";
const client = algoliasearch("A3ZR8GPESC", "1424f32c83fed9bd461ae5b84c2119e5");
const products = client.initIndex("products-test");

export async function searchProducts(searchString) {
  const resp = await products.search(searchString, {
    filters: `store:"${StoreId}"`,
  });
  return resp.hits;
}
