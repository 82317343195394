import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import produce from "immer";
import React, { useContext, useEffect, useState } from "react";
import { CurrencyContext } from "../../context/CurrencyContext";
import "../css/NewItemInventoryGrid.scoped.css";
import Input from "../general/Input";
import translate from "../../i18n/translate";
export default function BasicTable({ savedItems, updateSavedItems }) {
  const nextItemsState = (i) => (quantity, markUp) => {
    let nextState = produce(savedItems, (draftState) => {
      draftState[i].quantity = parseInt(quantity);
      draftState[i].markUp = parseInt(markUp);
    });
    updateSavedItems(nextState);
  };

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 260 }} className="table">
      <Table stickyHeader sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead>
          <TableRow className="shadow-none">
          <TableCell align="left">{translate("items")}</TableCell>
            <TableCell align="left">{translate("sku")}</TableCell>
            <TableCell align="left">{translate("size")}</TableCell>
            <TableCell align="left">{translate("platform")}</TableCell>
            <TableCell align="left">{translate("lastSale")}</TableCell>
            <TableCell align="center">{translate("markUp")}(%)</TableCell>
            <TableCell align="left">{translate("finalPrice")}</TableCell>
            <TableCell align="center">{translate("quantity")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {savedItems.map((row, i) => (
            <TabeleRowComponent
              row={{
                imgSrc: row.image,
                SKU: row.sku,
                Size: row.size,
                category: row.category,
                Platform: row.platform,
                LastSale: row.lastSale ? row.lastSale : row.initLastSale,
              }}
              updateItems={nextItemsState(i)}
              key={i}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

let TabeleRowComponent = ({ updateItems, row }) => {
  const [quantity, setQuantity] = useState(0);
  const [markUp, setMarkUp] = useState(0);
  const {
    sign,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  let localSizing = localStorage.getItem("sizing");

  useEffect(() => {
    updateItems(quantity ?? 1, markUp ?? 0);
  }, [quantity, markUp, updateItems]);

  return (
    <TableRow
      key={row.name}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell className="sku">
        {<img src={row.imgSrc} alt="img" />}
      </TableCell>
      <TableCell align="left">{row.SKU}</TableCell>
      <TableCell align="left">
        {(row.category === "sneakers") 
        ? sizingValues[localSizing][row.Size]
          ? sizingValues[localSizing][row.Size]
          : womenSizingValues[localSizing][row.Size]
          ? womenSizingValues[localSizing][row.Size]
          : girlSizingValues[localSizing][row.Size]
          ? girlSizingValues[localSizing][row.Size]
          : toddler[localSizing][row.Size]
          ? toddler[localSizing][row.Size]
          : young[localSizing][row.Size]
        : row.Size}
      </TableCell>
      <TableCell align="left" className="capitalize">
        {row.Platform}
      </TableCell>
      <TableCell align="left">
        {sign[localCurrency]}
        {(row.LastSale ? row.LastSale : 0).toFixed(2)}
      </TableCell>
      <TableCell align="center">
        <Input
          name={"markUp"}
          type={"number"}
          value={markUp}
          updateValue={setMarkUp}
          placeholder={"%"}
          customClass={"sm"}
          custom={true}
        />
      </TableCell>
      <TableCell align="left">
        {sign[localCurrency]}
        {(row.LastSale + (markUp / 100) * row.LastSale).toFixed(2)}
      </TableCell>
      <TableCell align="center">
        <Input
          name="quantity"
          type={"number"}
          value={quantity}
          updateValue={setQuantity}
          placeholder={"Q"}
          customClass={"sm"}
          custom={true}
        />
      </TableCell>
    </TableRow>
  );
};
