import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { parseInt } from "lodash";
import React, { useContext, useState } from "react";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import Close from "../../assets/close.svg";
import Delete from "../../assets/delete.svg";
import DeleteDark from "../../assets/deletedark.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import createExpense from "../../firebase/expense/createExpense";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import Loader from "../general/Loader";
import Select from "../general/Select";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";
import Popup from "../general/Popup";
import requestCashout from "../../functions/wallet/requestCashout";

export default function CashOutModal({ dark, bankAccounts, availableCashout }) {
  let localCurrency = localStorage.getItem("currency");
  const { rates } = useContext(CurrencyContext);

  const initialValues = {
    account: "",
    cashOutType: "",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const [isDisabled, updateDisabled] = useState(false);
  const [fields, setFields] = useState(initialValues);
  const [loading, toggleLoading] = useState(false);

  const handleChange = (e) => {
    // console.log(fields);
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const clearValues = () => {
    setFields(initialValues);
  };

  const [open, setOpen] = React.useState(false);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [popupText, setPopupText] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFields(initialValues);
  };

  let token = localStorage.getItem("token");

  const cashOutOptions = [
    {
      label: translate("1 Working Day Cash Out"),
      value: "1 Working Day",
      fees: 2,
    },
    {
      label: translate("14 Working Day Cash Out"),
      value: "14 Working Day",
      fees: 0,
    },
  ];

  const bankAccountsList = bankAccounts.map((bankAccount) => {
    return {
      label: `${bankAccount?.bankName} - ${bankAccount?.accountNumber}`,
      value: bankAccount?.id,
    };
  });

  let fees =
    availableCashout *
    (Number(
      cashOutOptions.find((e) => e.value === fields.cashOutType)?.fees ?? 2
    ) /
      100);

  return (
    <div>
      <Button
        customClass={"cashout"}
        onClick={() => {
          if (bankAccounts.length < 0) {
            setPopupText("Please add a bank account");
            setPopupOpen(true);
            return;
          }

          if (availableCashout <= 0) {
            setPopupText("Not enoungh balance available to cash out");
            setPopupOpen(true);
            return;
          }
          handleOpen();
        }}
        text={translate("Cash Out")}
      />
      <Popup text={popupText} show={popupOpen} setShow={setPopupOpen} />
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="header">
              <div>{translate("Requesting Cash Out")}...</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>

            <div className="three-grid">
              <div className={"span2"}>
                <div className="label">{translate("Account")}</div>
                <Select
                  dark={dark}
                  name="account"
                  value={fields.account}
                  updateValue={handleChange}
                  options={bankAccountsList}
                  placeholderText={GetTranslateString("Choose bank name")}
                  none={false}
                />
              </div>

              <div>
                <div className="note purple">
                  Note: Payout for tickets will occur after 3 working days of
                  concert being over for it to be in your payout.
                </div>
              </div>
            </div>
            <div className="three-grid">
              <div className={"span2"}>
                <div className="label">{translate("Cash Out Type")}</div>
                <Select
                  dark={dark}
                  name="cashOutType"
                  value={fields.cashOutType}
                  updateValue={handleChange}
                  options={cashOutOptions}
                  placeholderText={GetTranslateString("Choose cash out type")}
                  none={false}
                />
                <div>
                  <div className=" label note red">
                    <span>1 working day = 2% Fee of Cash Out Amount</span>
                    <span>14 working days = No Fee</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="breakup">
              <div className="fees">
                <div>Ammount</div>
                <div className="green">${availableCashout}</div>
              </div>
              <div className="fees">
                <div>
                  {fields.cashOutType || "1 working day"} - Service Fees (
                  {Number(
                    cashOutOptions.find((e) => e.value === fields.cashOutType)
                      ?.fees ?? 2
                  ).toFixed(1)}
                  %)
                </div>
                <div className="red" style={{ fontWeight: "300" }}>
                  -$
                  {fees.toFixed(2)}
                </div>
              </div>

              {/* <div className="fees">
                <div>Penalty amount</div>
                <div className="red" style={{ fontWeight: "300" }}>
                  ${0}
                </div>
              </div> */}

              <div className="fees total mt-10">
                <div className="total">New Wallet Balance</div>
                <div className="purple">
                  ${(availableCashout - fees).toFixed(2)}
                </div>
              </div>
            </div>

            <div className="btns w-full jus-end mt-10">
              <div>
                <Loader loading={loading} />
              </div>

              <div className="ml-10">
                <Button
                  customClass={"conf-cashout"}
                  disabled={isDisabled}
                  imgSrc={<img src={dark ? AddDark : Add} alt="add" />}
                  onClick={async () => {
                    try {
                      toggleLoading(true);
                      updateDisabled(true);
                      await requestCashout(
                        token,
                        fields.account,
                        fields.cashOutType
                          ? fields.cashOutType?.split(" ")[0]
                          : "",

                        availableCashout - fees
                      );
                    } catch (e) {
                      ErrorToast(translate("error"), e.message);
                      toggleLoading(false);
                      updateDisabled(false);
                      return;
                    }
                    SuccessToast(
                      translate("success"),
                      translate("Cash out request sent")
                    );
                    handleClose();
                    clearValues();
                    updateDisabled(false);
                    toggleLoading(false);
                  }}
                  text={translate("Confirm Cash Out")}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
