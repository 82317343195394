import React from "react";
import "../css/Checkbox.scoped.css";
import { Checkbox } from "@mui/material";

export default function CustomCheckbox({ checked, handleChange, dark, label }) {
  return (
    <div className="flex al-ce">
      <Checkbox
        checked={checked}
        onChange={handleChange}
        inputProps={{
          "aria-label": "controlled",
        }}
        icon={
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1.5"
              y="1.5"
              width="20"
              height="20"
              rx="3.5"
              stroke={!dark ? "#ABAFD1" : `#5A5D74`}
              strokeWidth="3"
            />
          </svg>
        }
        checkedIcon={
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1.5"
              y="1.5"
              width="20"
              height="20"
              rx="3.5"
              stroke={!dark ? "#ABAFD1" : `#5A5D74`}
              strokeWidth="3"
            />
            <rect x="5" y="5" width="13" height="13" rx="3" fill="#767BFA" />
          </svg>
        }
      />
      <div className="text">{label}</div>
    </div>
  );
}
