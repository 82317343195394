import axios from "axios";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../index";
import encodeKey from "../util/encodeKey";
import constants from "../constants"

export default async function createUIProduct(storeId, styleCode, data) {
  //Check the doc does not exist before making it.
  if (data.platform === "custom" && !data.lastSale) {
    throw new Error("Last sale price cannot be null");
  }

  const token = localStorage.getItem("token")

  const API_ROUTE = (data.platform === constants.GOAT) ? `https://us-central1-ingenium-337101.cloudfunctions.net/goat?term=${styleCode}` : `https://us-central1-ingenium-337101.cloudfunctions.net/stockx?term=${styleCode}`;

  // await axios.get(API_ROUTE)

  const response = await axios.get(API_ROUTE);

  if (response.data.error) {
    throw new Error(response.data.message);
  }

  //Let the source of truth for the style code be from STOCKX
  const serverStyleCode = response.data.styleCode.replace("/", "|").replace(" ", "-");

  const docRef = doc(db, "Stores", storeId, "Products", serverStyleCode);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    let stocks = {};
    let stocksCP = {};
    let markup = {};
    let sizes = {};
    let minCP = {};
    let sizesCustom = {};
    let platform = {};
    let condition = {};
    let conditionCP = {};

    for (let item in response.data.sizes) {
      const encodedItem = encodeKey(item);
      sizes[encodedItem] = response.data.sizes[encodedItem];
      stocks[encodedItem] = 0;
      stocksCP[encodedItem] = 0;
      markup[encodedItem] = 0;
      minCP[encodedItem] = Number.MAX_VALUE;
      sizesCustom[encodedItem] = 0;
      platform[encodedItem] = "stockx";
      condition[encodedItem] = "";
      conditionCP[encodedItem] = "";
    }

    if (data.platform === "custom") {
      sizesCustom[encodeKey(data.size)] = data.lastSale;
    } else {
      console.log({
        token: token,
        platform: data.platform,
        store: storeId,
        data: {
          ...response.data
        }
      })
      const addProdRoute = `https://us-central1-ingenium-337101.cloudfunctions.net/addProduct`;
      const addProdResp = await axios.post(addProdRoute, {
        token: token,
        platform: data.platform,
        store: storeId,
        data: {
          ...response.data,
          styleCode: serverStyleCode,
        }
      })
    }



    platform[encodeKey(data.size)] = data.platform;

    await setDoc(doc(db, "Stores", storeId, "Products", serverStyleCode), {
      ...response.data,
      image: response.data.image.split('&').slice(0, 4).join('&'),
      styleCode: serverStyleCode,
      category: data.category,
      stocks,
      stocksCP,
      markup,
      minCP,
      sizesCustom,
      platform,
      condition,
      conditionCP,
    });
  } else {
    let updater = {};
    if (data.platform === "custom") {
      updater[`sizesCustom.${encodeKey(data.size)}`] = data.lastSale;
    }
    if (data.platform !== docSnap.data().platform[encodeKey(data.size)]) {
      updater[`platform.${encodeKey(data.size)}`] = data.platform;
    }
    if (updater) {
      await updateDoc(docRef, updater);
    }
  }

  //return
  return { ...response.data, styleCode: serverStyleCode};
}
