import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import _, { isNull } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import Close from "../../assets/close.svg";
import Delete from "../../assets/delete.svg";
import DeleteDark from "../../assets/deletedark.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import constants from "../../firebase/constants";
import batchCreateInventory from "../../firebase/inventory/batchCreateInventory";
import createUIproduct from "../../firebase/products/createUIproduct";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input, { AskInput } from "../general/Input";
import Loader from "../general/Loader";
import Select from "../general/Select";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import NewItemInventoryGrid from "./NewItemInventoryGrid";
import CloseDark from "../../assets/closedark.svg";
import eventMap from "../../assets/eventMap.png";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";
import placeAsk from "../../firebase/inventory/placeAsk";
import updateAsk from "../../firebase/inventory/updateAsk";
import getShippingFees from "../utils/getShippingFees";
import axios from "axios";
import editTicketAsk from "../../functions/tickets/editTicketAsk";
import placeTicketAsk from "../../functions/tickets/placeTicketAsk";

export default function TicketAskModal({
  dark,
  isOpen,
  toggleState,
  mode,
  data,
}) {
  // console.log(data);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  let localSizing = localStorage.getItem("sizing");
  let token = localStorage.getItem("token");

  let fee = 10;

  const [fields, setFields] = useState({
    price: data.price,
    expiry: "",
    processingFee: Number((data.price * 0.01 * fee).toFixed(2)),
    // verificationFee: Number((data.price * 0.06).toFixed(2)),
    // shippingFee: Number((0).toFixed(2)),
  });
  const [discount, setDiscount] = useState("");
  const [loading, toggleLoading] = useState(false);
  const [askBidData, setAskBidData] = useState({
    lowestAsk: -1,
    highestBid: -1,
  });
  const [tradeLoading, setTradeLoading] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setTradeLoading(true);
  //       // console.log(data)
  //       if (data.sku && data.size) {
  //         const resp = await axios.get(
  //           `https://katch-358301.ew.r.appspot.com/marketplace/price/${
  //             data.sku
  //           }?size=${data.size}`
  //         );

  //         // console.log(resp)
  //         if (resp.data && resp.data.success) {
  //           setAskBidData({
  //             lowestAsk: resp.data.lowestAsk,
  //             highestBid: resp.data.highestBid,
  //           });
  //         }
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     } finally {
  //       setTradeLoading(false);
  //     }
  //   })();
  // }, [isOpen, data]);

  useEffect(() => {
    // console.log(data);
    setFields({
      price: data.price,
      expiry: "",
      processingFee: Number((data.price * 0.01 * fee).toFixed(2)),
      // verificationFee: Number((data.price * 0.06).toFixed(2)),
      // shippingFee: Number((0).toFixed(2)),
    });
  }, [isOpen, data]);
  // console.log(data);

  let payout = Number(fields.price - (fields.price * 0.01 * fee).toFixed(2));

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       toggleLoading(true);
  //       const resp = await getShippingFees(StoreId);

  //       if (!resp.total && !resp.success) {
  //         ErrorToast(translate("error"), resp.error);
  //       }
  //       setFields({ ...fields, shippingFee: resp.total });
  //     } catch (e) {
  //       ErrorToast(translate("error"), "Service not available on your address");
  //       console.log(e);
  //     } finally {
  //       toggleLoading(false);
  //     }
  //   })();
  // }, [data]);

  const handleChange = (e) => {
    // console.log(e.target);
    if (e.target.name === "price") {
      setFields({
        ...fields,
        price: e.target.value,
        processingFee: Number(
          (parseFloat(e?.target?.value || 0) * 0.01 * fee).toFixed(2)
        ),
      });
      // fees["verificationFee"] = Number(
      //   (parseFloat(e.target.value) * 0.05).toFixed(2)
      // );
    } else {
      setFields({ ...fields, [e.target.name]: e.target.value ?? 0 });
    }
  };

  // console.log(fields)

  return (
    <div>
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={() => toggleState(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="header">
              <div>{mode === "place" ? "Placing" : "Updating"} Ask...</div>
              <img
                onClick={() => toggleState(false)}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className={"three-grid"}>
              <div className={"span2"}>
                <div className="label">Item</div>
                <ReadOnlyField text={data?.eventName} />
              </div>
              <div>
                <div className="label">Venue</div>
                <ReadOnlyField
                  text={data?.venueName + ", " + data?.venueLocation}
                />
              </div>
            </div>
            <div className="three-grid">
              <div>
                <div className="label">Category</div>
                <ReadOnlyField text={data?.section} />
              </div>
              <div>
                <div className="label">Row</div>
                <ReadOnlyField text={"Row " + data?.row} />
              </div>
              {/* <div className={"flex al-ce jc-ce"}>
                <img src={data.image} alt="img" className={"gridImg"} />
              </div> */}
              <div>
                <div className="label">Ticket Type</div>
                <ReadOnlyField text={"E-TIcket"} />
              </div>
            </div>
            <div className="three-grid">
              <div>
                <div className="label">Quantity</div>
                <ReadOnlyField text={data?.quantity} />
              </div>
              <div>
                <div className="label">Purchased Price</div>
                <ReadOnlyField
                  text={
                    // sign[localCurrency] +
                    `$${data?.purchasedPrice?.toFixed(2)}`
                  }
                />
              </div>
              {/* <div className={"flex al-ce jc-ce"}>
                <img src={data.image} alt="img" className={"gridImg"} />
              </div> */}
              <div />
            </div>
            <div className="three-grid">
              <div className="span2">
                <div>
                  <div style={{ display: "flex" }}>
                    <div className="label">{translate("Seat Numbers")}</div>
                  </div>
                </div>
                <div
                  className="upload-ticket-container"
                  style={{ paddingLeft: 0 }}
                >
                  <div className="list-item upload-ticket-box">
                    {data?.seatNumbers
                      ?.slice(0, Math.ceil(data?.quantity / 2))
                      .map((d, i) => <span>Seat Number {d}</span>)}
                  </div>
                  {data?.quantity > 1 && (
                    <div className="list-item upload-ticket-box">
                      {data?.seatNumbers
                        ?.slice(Math.ceil(data?.quantity / 2))
                        .map((d, i) => <span>Seat Number {d}</span>)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="three-grid">
              <div>
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  src={data?.venueImg}
                  alt="event map"
                />
              </div>
              <div className="span2">
                <div className={"col-grid"}>
                  <div className="mb-20">
                    <AskInput
                      prefix={"US$"}
                      dark={dark}
                      name="price"
                      type={"number"}
                      value={fields.price}
                      updateValue={handleChange}
                      placeholder={GetTranslateString(
                        "Enter Price for total tickets"
                      )}
                    />
                  </div>

                  <div className="fees">
                    <div>Selling Price:</div>
                    <div>
                      {sign[localCurrency]}
                      {fields.price || 0}
                    </div>
                  </div>
                  <div className="fees">
                    <div>Verification Fee (10%):</div>
                    <div>
                      -$
                      {fields.processingFee || 0}
                    </div>
                  </div>

                  <div className="fees">
                    <div>Shipping Fee:</div>
                    <div>
                      {/* -{sign[localCurrency]}
                      {fields.shippingFee} */}
                      Free
                    </div>
                  </div>
                  <div className="fees">
                    {/* <div>Discount Code::</div>
                    <div>
                      <Input
                        customClass={"discount"}
                        custom={true}
                        dark={dark}
                        name="discount"
                        type={"text"}
                        value={discount.toUpperCase()}
                        updateValue={setDiscount}
                        placeholder={GetTranslateString("Code Here")}
                      />
                    </div> */}
                  </div>
                  <div className="fees total mt-10">
                    <div className="total">Total Payout (inc.all fees)*</div>
                    <div>
                      {/* {sign[localCurrency]} */}${(payout || 0).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full jus-end">
              <div>
                <Loader loading={loading} />
              </div>

              <div className="mt-10 px-20">
                <Button
                  disabled={loading}
                  imgSrc={<img src={dark ? AddDark : Add} alt="add" />}
                  onClick={async () => {
                    try {
                      toggleLoading(true);
                      // console.log(fields);
                      if (payout < 0) {
                        return ErrorToast("Error", "Enter a valid ask price");
                      }

                      // if (fields.shippingFee === 0) {
                      //   return ErrorToast(
                      //     "Error",
                      //     "Couldn't resolve shipping fees"
                      //   );
                      // }
                      if (fields.price === 0) {
                        return ErrorToast("Error", "Ask price not given");
                      }

                      if (mode === "update") {
                        await editTicketAsk(
                          token,
                          data._id,
                          Number(fields.price)
                        );
                      } else if (mode === "place") {
                        await placeTicketAsk(
                          token,
                          data._id,
                          Number(fields.price)
                        );
                      }

                      SuccessToast(
                        "Success",
                        `Ask ${
                          mode === "place" ? "placed" : "Updated"
                        } for this item`
                      );
                      toggleState(false);
                      // setPrice("");
                    } catch (e) {
                      // console.log(e);
                      ErrorToast("Error", "Some error occurred");
                    } finally {
                      toggleLoading(false);
                    }
                  }}
                  text={translate(
                    mode === "place" ? "Place Ask" : "Update Ask"
                  )}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const ReadOnlyField = ({ text }) => {
  return <div className={"readOnly"}>{text}</div>;
};
