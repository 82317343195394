import {
  addDoc,
  collection,
  doc,
  increment,
  updateDoc,
} from "firebase/firestore";
import { db } from "../index";
export default async function createExpense(storeId, data) {
  //data.name
  if (!data.name || data.name.length < 3) {
    throw new Error("Name is not a valid value.");
  }

  if (!data.spentDate) {
    throw new Error("Date is not a valid value.");
  }

  if (isNaN(data.amountSpent) || !data.amountSpent) {
    throw new Error("Invalid amount spent value.");
  }

  if (data.shipping) {
    if (isNaN(data.shipping)) {
      throw new Error("Invalid shipping value.");
    }
  }

  if (isNaN(data.quantity) || !data.quantity) {
    throw new Error("Invalid quantity value.");
  }

  if (isNaN(data.tax)) {
    throw new Error("Invalid value.");
  }

  await addDoc(collection(db, "Stores", storeId, "Expenses"), {
    ...data,
    storeId: storeId,
  });

  const counterRef = doc(db, "Stores", storeId);

  const incrementCount = increment(1);
  const incrementValue = increment(
    data.amountSpent * (data.tax / 100) + data.amountSpent + data.shipping
  );
  await updateDoc(counterRef, {
    expenseItems: incrementCount,
    expenseValue: incrementValue,
  });
}

// const expense = {
//     name: "abc",
//     spentDate: "",
//     amountSpent: 125,
//     tax: "",
//     shipping: "",
//     duration: "",
//     quantity: 1,
//     transactionId: 123456789,
//     notes: "",
// }
