import { writeBatch, collection, doc, deleteDoc, getDocs} from "firebase/firestore";
import { db } from "../index";

export default async function deleteStockTagging(StoreId) {
  //Get all documents of the StockTagging collection
  const stockSnapshot = await getDocs(collection(db,"Stores", StoreId, "StockTagging"));

  
  const batch = writeBatch(db);
  stockSnapshot.forEach((doc) => {
    batch.delete(doc.ref);
  });
  await batch.commit();
}
