const constants = {
  NEW: "new",
  USED: "used",
  STOCKX: "stockx",
  GOAT: "goat",
  CUSTOM: "custom",
};

export const platforms = ["stockx", "custom", "goat"];
export const conditions = ["new", "used"];

export default constants;
