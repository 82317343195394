import { collection, onSnapshot } from "firebase/firestore";
import "../css/ConsigneeTable.scoped.css";
import { db } from "../../firebase/index";
import { StoreId } from "../../Constants";
import {  useEffect, useState } from "react";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translate";


const SalesCategories = () => {
  const [soldCategories, updateSoldCategories] = useState({});
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const soldListener = onSnapshot(
      collection(db, "Stores", StoreId, "SoldItems"),
      async (snapShot) => {
        const soldData = snapShot.docs.map((e) => {
          return {
            ...e.data(),
          };
        });

        let categoryCount = soldCategories;
        let count = 0
        soldData.forEach((item) => {
          if(categoryCount[item.category] === undefined) {
            categoryCount[item.category] = 1;
          } else {
            categoryCount[item.category] += 1;
          }
          count += 1;
        });
        setTotal(count);
        updateSoldCategories(categoryCount);
      }
    );
    return () => {
      soldListener();
    }
  }, [])

  const colours = {
    sneakers: "blue",
    streetwear: "green",
    collectibles: "brown",
    arts: "pink",
    electronics: "orange",
    watches: "lightgreen"
  }

  return (
    <>
      <div className="headersgrid consignment categories">
        <div>{translate("categories")}</div>
        <div>{translate("totalSales")} </div>
        <div />
      </div>
      <>
        <div className="items-container">
          {Object.keys(soldCategories).map((e,i) => (
            <div className="items">
              <div className="item consignment">
                <div className={`type ${colours[e]}`}>
                  <span>{e.charAt(0).toUpperCase() + e.slice(1)}</span>
                </div>
                <div>{soldCategories[e]}</div>
                <div className="bold">{((soldCategories[e] * 100)/total).toFixed(1)+"% "}{GetTranslateString("ofTotal")}</div>
              </div>
            </div>
          ))}

        </div>
      </>
    </>
  );
};

export default SalesCategories;
