import { format, parseISO, subDays } from "date-fns";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "../css/Graph.scoped.css";
import numberWithCommas from "../utils/numberWithCommas";
import { useContext } from "react";
import { CurrencyContext } from "../../context/CurrencyContext";

// const data = [];
// for (let num = 4; num >= 0; num--) {
//   data.push({
//     date: subDays(new Date(), num)
//       .toISOString()
//       .substr(0, 10),
//     value: 1 + Math.random() * 10000,
//   });
// }

export default function Graph({ graphData, percent, color, dark }) {
  const { rates } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  return (
    <ResponsiveContainer width="100%" height="72%">
      <AreaChart data={graphData ? [...graphData] : []}>
        <CartesianGrid opacity={1} stroke={dark ? "#3A4462" : "#F0F0F0"} />
        <defs>
          <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor={color ? color : "#77F64A"}
              stopOpacity={1}
            />
            <stop
              offset="75%"
              stopColor={color ? color : "#77F64A"}
              stopOpacity={0.0}
            />
          </linearGradient>
        </defs>

        <Area
          isAnimationActive={true}
          dataKey="value"
          stroke="transparent"
          fill="url(#color)"
          type={"monotone"}
        />

        <XAxis
          tick={{ fill: dark ? "#F6F7FF" : "inherit" }}
          fontSize={"0.625rem"}
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tickFormatter={(str) => {
            const date = parseISO(str);
            if (date.getDate()) {
              return format(date, "MMM, d");
            }
            return "";
          }}
        />

        <YAxis
          tick={{ fill: dark ? "#F6F7FF" : "inherit" }}
          tickCount={4}
          fontSize={"0.625rem"}
          datakey="value"
          axisLine={false}
          tickLine={false}
          tickFormatter={(number) =>
            `${numberWithCommas(
              !percent
                ? number * rates?.[localCurrency?.toUpperCase()].toFixed(0)
                : number
            )}`
          }
        />

        {<Tooltip content={percent ? <PercentTooltip /> : <CustomTooltip />} />}
      </AreaChart>
    </ResponsiveContainer>
  );
}

function CustomTooltip({ active, payload, label }) {
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  if (active) {
    return (
      <div className="tooltip">
        <h4>{label}</h4>
        <p>
          {sign[localCurrency]}
          {payload
            ? numberWithCommas(
                (
                  payload[0].value * rates?.[localCurrency?.toUpperCase()]
                ).toFixed(2)
              )
            : ""}

          {localCurrency.toUpperCase()}
        </p>
      </div>
    );
  }
  return null;
}

function PercentTooltip({ active, payload, label }) {
  if (active) {
    return (
      <div className="tooltip">
        <h4>{label}</h4>
        <p>{payload ? numberWithCommas(payload[0].value.toFixed(2)) : ""}%</p>
      </div>
    );
  }
  return null;
}
