import axios from "axios";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../index";
import constants from "../constants";

import encodeKey from "../util/encodeKey";

export default async function createProduct(storeId, styleCode, data) {
  //Check the doc does not exist before making it.

  const API_ROUTE = (data.platform === constants.GOAT) ? `https://us-central1-ingenium-337101.cloudfunctions.net/goat?term=${styleCode}` : `https://us-central1-ingenium-337101.cloudfunctions.net/stockx?term=${styleCode}`;

  // await axios.get(API_ROUTE)
  const response = await axios.get(API_ROUTE);

  //Let the source of truth for the style code be from STOCKX
  const serverStyleCode = response.data.styleCode.replace("/", "|");

  const docRef = doc(db, "Stores", storeId, "Products", serverStyleCode);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    let stocks = {};
    let stocksCP = {};
    let markup = {};
    let sizes = {};
    let minCP = {};
    let sizesCustom = {};
    let platform = {};
    let condition = {};
    let conditionCP = {};

    for (let item in response.data.sizes) {
      const encodedItem = encodeKey(item);
      sizes[encodedItem] = response.data.sizes[encodedItem];
      stocks[encodedItem] = 0;
      stocksCP[encodedItem] = 0;
      markup[encodedItem] = 0;
      minCP[encodedItem] = Number.MAX_VALUE;
      sizesCustom[encodedItem] = 0;
      platform[encodedItem] = "stockx";
      condition[encodedItem] = "";
      conditionCP[encodedItem] = "";
    }

    await setDoc(doc(db, "Stores", storeId, "Products", serverStyleCode), {
      ...response.data,
      image: response.data.image
        .split("&")
        .slice(0, 4)
        .join("&"),
      styleCode: serverStyleCode,
      category: data.category,
      stocks,
      stocksCP,
      markup,
      minCP,
      sizesCustom,
      platform,
      condition,
      conditionCP,
      storeId: storeId,
    });
  }

  //return
  return { ...response.data, category: data.category, styleCode: serverStyleCode };
}
