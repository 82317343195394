import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import produce from "immer";
import React, { useContext, useEffect, useState } from "react";
import { CurrencyContext } from "../../context/CurrencyContext";
import "../css/NewItemInventoryGrid.scoped.css";
import Input from "../general/Input";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

export default function ConsignmentNewItemInventoryGrid({
  savedItems,
  updateSavedItems,
}) {
  const nextItemsState = (i) => (quantity, fee) => {
    let nextState = produce(savedItems, (draftState) => {
      draftState[i].quantity = parseInt(quantity);
      draftState[i].fee = parseInt(fee);
    });
    updateSavedItems(nextState);
  };
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 260 }} className="table">
      <Table stickyHeader sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead>
          <TableRow className="shadow-none">
            <TableCell align="left">{translate("items")}</TableCell>
            <TableCell align="left">{translate("sku")}</TableCell>
            <TableCell align="left">{translate("size")}</TableCell>
            <TableCell align="left">{translate("payoutPrice")}</TableCell>
            <TableCell align="center">{translate("fee")}%</TableCell>
            <TableCell align="left">{translate("listingPrice")}</TableCell>
            <TableCell align="center">{translate("quantity")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {savedItems.map((row, i) => (
            <TabeleRowComponent
              row={{
                imgSrc: row.image,
                SKU: row.sku,
                Size: row.size,
                category: row.category,
                PayoutPrice: row.payoutPrice,
              }}
              updateItems={nextItemsState(i)}
              key={i}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

let TabeleRowComponent = ({ updateItems, row }) => {
  const [quantity, setQuantity] = useState(0);
  const [fee, setFee] = useState(0);
  useEffect(() => {
    updateItems(quantity ?? 1, fee ?? 0);
  }, [quantity, fee, updateItems]);

  const {
    rates,
    sign,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  let localSizing = localStorage.getItem("sizing");

  return (
    <TableRow
      key={row.name}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell className="sku">
        {<img src={row.imgSrc} alt="change" />}
      </TableCell>
      <TableCell align="left">{row.SKU}</TableCell>
      <TableCell align="left">
        {(row.category === "sneakers") 
          ? sizingValues[localSizing][row.Size]
            ? sizingValues[localSizing][row.Size]
            : womenSizingValues[localSizing][row.Size]
            ? womenSizingValues[localSizing][row.Size]
            : girlSizingValues[localSizing][row.Size]
            ? girlSizingValues[localSizing][row.Size]
            : toddler[localSizing][row.Size]
            ? toddler[localSizing][row.Size]
            : young[localSizing][row.Size]
          : row.category}
      </TableCell>
      <TableCell align="left">
        {sign[localCurrency]}
        {(row.PayoutPrice * rates?.[localCurrency?.toUpperCase()]).toFixed(2)}
      </TableCell>
      <TableCell align="center">
        <Input
          name={"fee"}
          type={"number"}
          value={fee}
          updateValue={setFee}
          placeholder={"%"}
          customClass={"sm"}
          custom={true}
        />
      </TableCell>
      <TableCell align="left">
        {sign[localCurrency]}
        {(
          (row.PayoutPrice + (row.PayoutPrice * fee) / 100) *
          rates?.[localCurrency?.toUpperCase()]
        ).toFixed(2)}
      </TableCell>
      <TableCell align="center">
        <Input
          name="quantity"
          type={"number"}
          value={quantity}
          updateValue={setQuantity}
          placeholder={"Q"}
          customClass={"sm"}
          custom={true}
        />
      </TableCell>
    </TableRow>
  );
};
