import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState, useContext } from "react";
import "../css/NewItemInventoryGrid.scoped.css";
import Input from "../general/Input";
import { CurrencyContext } from "../../context/CurrencyContext";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

export default function BasicTable({
  editMarkUp,
  updateEditMarkUp,
  savedEditItems,
}) {
  //   const nextItemsState = (i) => (quantity, markUp) => {
  //     let nextState = produce(savedEditItems, (draftState) => {
  //       draftState[i].quantity = parseInt(quantity);
  //       draftState[i].markUp = parseInt(markUp);
  //     });
  //     updateEditSavedItems(nextState);
  //   };
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 260 }} className="table">
      <Table stickyHeader sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead>
          <TableRow className="shadow-none">
          <TableCell align="left">{translate("items")}</TableCell>
            <TableCell align="left">{translate("sku")}</TableCell>
            <TableCell align="left">{translate("size")}</TableCell>
            <TableCell align="left">{translate("payoutPrice")}</TableCell>
            <TableCell align="center">{translate("fee")}%</TableCell>
            <TableCell align="left">{translate("listingPrice")}</TableCell>
            <TableCell align="center">{translate("quantity")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {savedEditItems.map((row, i) => ( */}
          <TabeleRowComponent
            row={{
              imgSrc: savedEditItems.imgSrc,
              SKU: savedEditItems.styleCode,
              Size: savedEditItems.sizing,
              payoutPrice: savedEditItems.payoutPrice,
              editMarkUp: editMarkUp,
              updateEditMarkUp: updateEditMarkUp,
            }}
            //   updateItems={nextItemsState(i)}
            // key={i}
          />
          {/* ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

let TabeleRowComponent = ({ /* updateItems,  */ row }) => {
  const [quantity, setQuantity] = useState(0);

  //   useEffect(() => {
  //     updateItems(quantity ?? 1, markUp ?? 0);
  //   }, [quantity, markUp, updateItems]);

  const {
    sign,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);
  let localSizing = localStorage.getItem("sizing");
  let localCurrency = localStorage.getItem("currency");

  return (
    <TableRow
      key={row.name}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell className="sku">
        {<img src={row.imgSrc} alt="img" />}
      </TableCell>
      <TableCell align="left">{row.SKU}</TableCell>
      <TableCell align="left">
        {sizingValues[localSizing][row.Size]
          ? sizingValues[localSizing][row.Size]
          : womenSizingValues[localSizing][row.Size]
          ? womenSizingValues[localSizing][row.Size]
          : girlSizingValues[localSizing][row.Size]
          ? girlSizingValues[localSizing][row.Size]
          : toddler[localSizing][row.Size]
          ? toddler[localSizing][row.Size]
          : young[localSizing][row.Size]}
      </TableCell>

      <TableCell align="left">
        {" "}
        {sign[localCurrency]}
        {row.payoutPrice}
      </TableCell>
      <TableCell align="center">
        <Input
          name={"markUp"}
          type={"number"}
          value={row.editMarkUp}
          updateValue={row.updateEditMarkUp}
          placeholder={"%"}
          customClass={"sm"}
          custom={true}
        />
      </TableCell>
      <TableCell align="left">
        {sign[localCurrency]}
        {(row.payoutPrice + (row.editMarkUp / 100) * row.payoutPrice).toFixed(
          2
        )}
      </TableCell>
      <TableCell align="center">
        <Input
          name="quantity"
          type={"number"}
          value={1}
          updateValue={setQuantity}
          placeholder={"Q"}
          customClass={"sm"}
          custom={true}
        />
      </TableCell>
    </TableRow>
  );
};
