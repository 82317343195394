import axios from "axios";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
// import { countries } from "./coutryToCode";
import _ from "lodash";

export default async function createShipping(
  StoreId,
  itemId,
  itemName,
  orderId
) {
  const addrSnap = await getDoc(doc(db, "Stores", StoreId));

  const addressData = addrSnap.data();

  // const country = _.find(
  //   countries,
  //   (c) => c.Name.toLowerCase() === addressData.country.toLowerCase()
  // );

  const origin = {
    fullName: addressData.fullName,
    line1: addressData.address ?? "",
    line2: addressData.address ?? "",
    city: addressData.city ?? "",
    state: addressData.state ?? "",
    postalCode: addressData.postalCode ?? "",
    countryCode: addressData.country,
    companyName: "INKTVO",
    email: "inktvo@gmail.com",
    phone: "+918910551953",
  };

  // console.log(origin);

  let resp = await axios.post(
    "https://katch-358301.ew.r.appspot.com/shipping/create",
    {
      internalToken: "QVH$BxHlnM7F$LD0AZ5%w&6UG6L8I%t&0MZf1Jd$nbiBFmgV#n",
      productName: itemName,
      storeName: addressData.name,
      origin,
      destination: {
        line1: "61 Ubi Ave 2",
        line2: "#07-04A",
        city: "Singapore",
        state: "",
        fullName: "Jay Markwell",
        companyName: "INKTVO",
        email: "inktvo@gmail.com",
        phone: "+918910551953",
        postalCode: "408898",
        countryCode: "SG",
      },
    }
  );

  // console.log(resp);
  // console.log("shipped");

  if (!resp.data.error && resp.data.HasErrors === "false") {
    console.log("updating doc");
    await updateDoc(doc(db, "Stores", StoreId, "ListedItems", itemId), {
      shipped: true,
      shippingId: resp.data.ID,
    });
    // console.log("updated doc");
  }

  window.open(
    "https://katch-358301.ew.r.appspot.com/shipping/label/" + resp.data.ID
  );

  await axios.post(
    "https://katch-358301.ew.r.appspot.com/shipping/shippingId",
    {
      orderId,
      type: "toKatch",
      shippingId: resp.data.ID,
      sellerAddress: {
        ...origin,
      },
    }
  );

  return resp.data;
}
