
import {
  doc,
  getDoc,
  deleteDoc,
  deleteField
} from "firebase/firestore";

import { db } from "../index";

export default async function updatePrice(storeId, sku, size) {

  if (!sku || sku.length < 2) {
    throw new Error("SKU of this item is not a valid value.");
  }

  if (!size) {
    throw new Error("Size of this item is not a valid value.");
  }

  const notiRef = doc(db, "Stores", storeId, "Notifications", sku);
  await deleteDoc(notiRef)

}
