import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import _, { isNull } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import Close from "../../assets/close.svg";
import Delete from "../../assets/delete.svg";
import DeleteDark from "../../assets/deletedark.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import constants from "../../firebase/constants";
import batchCreateInventory from "../../firebase/inventory/batchCreateInventory";
import createUIproduct from "../../firebase/products/createUIproduct";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input, { AskInput } from "../general/Input";
import Loader from "../general/Loader";
import Select from "../general/Select";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import NewItemInventoryGrid from "./NewItemInventoryGrid";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";
import placeAsk from "../../firebase/inventory/placeAsk";
import updateAsk from "../../firebase/inventory/updateAsk";
import getShippingFees from "../utils/getShippingFees";
import axios from "axios";

export default function AskModal({ dark, isOpen, toggleState, mode, data }) {
  // console.log(data);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const {
    rates,
    sign,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  let localSizing = localStorage.getItem("sizing");
  let token = localStorage.getItem("token");

  const [fields, setFields] = useState({
    price: data.price * rates?.[localCurrency?.toUpperCase()],
    expiry: "",
    processingFee: Number(
      (data.price * rates?.[localCurrency?.toUpperCase()] * 0.05).toFixed(2)
    ),
    // verificationFee: Number((data.price * 0.06).toFixed(2)),
    shippingFee: Number((0).toFixed(2)),
  });
  const [discount, setDiscount] = useState("");
  const [loading, toggleLoading] = useState(false);
  const [askBidData, setAskBidData] = useState({
    lowestAsk: -1,
    highestBid: -1,
  });
  const [tradeLoading, setTradeLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setTradeLoading(true);
        // console.log(data)
        if (data.sku && data.size) {
          const resp = await axios.get(
            `https://katch-358301.ew.r.appspot.com/marketplace/price/${
              data.sku
            }?size=${data.size}`
          );

          // console.log(resp)
          if (resp.data && resp.data.success) {
            setAskBidData({
              lowestAsk: resp.data.lowestAsk,
              highestBid: resp.data.highestBid,
            });
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setTradeLoading(false);
      }
    })();
  }, [isOpen, data]);

  useEffect(() => {
    // console.log(data);
    setFields({
      price: data.price * rates?.[localCurrency?.toUpperCase()],
      expiry: "",
      processingFee: Number(
        (data.price * rates?.[localCurrency?.toUpperCase()] * 0.05).toFixed(2)
      ),
      // verificationFee: Number((data.price * 0.06).toFixed(2)),
      shippingFee: Number((0).toFixed(2)),
    });
  }, [isOpen, data]);
  // console.log(data);

  let payout = Number(
    fields.price - (fields.price * 0.05 + fields.shippingFee).toFixed(2)
  );

  useEffect(() => {
    (async () => {
      try {
        toggleLoading(true);
        const resp = await getShippingFees(StoreId);

        if (!resp.total && !resp.success) {
          ErrorToast(translate("error"), resp.error);
        }
        setFields({ ...fields, shippingFee: resp.total });
      } catch (e) {
        ErrorToast(translate("error"), "Service not available on your address");
        console.log(e);
      } finally {
        toggleLoading(false);
      }
    })();
  }, [data]);

  const handleChange = (e) => {
    // console.log(e.target);
    if (e.target.name === "price") {
      setFields({
        ...fields,
        price: e.target.value,
        processingFee: Number(
          (parseFloat(e?.target?.value || 0) * 0.05).toFixed(2)
        ),
      });
      // fees["verificationFee"] = Number(
      //   (parseFloat(e.target.value) * 0.05).toFixed(2)
      // );
    } else {
      setFields({ ...fields, [e.target.name]: e.target.value ?? 0 });
    }
  };

  // console.log(fields)

  return (
    <div>
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={() => toggleState(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="header">
              <div>{mode === "place" ? "Placing" : "Updating"} Ask...</div>
              <img
                onClick={() => toggleState(false)}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className={"three-grid"}>
              <div className={"span2"}>
                <div className="label">Item</div>
                <ReadOnlyField text={data.name} />
              </div>
              <div>
                <div className="label">Style</div>
                <ReadOnlyField text={data.sku} />
              </div>
            </div>
            <div className="three-grid">
              <div>
                <div className="label">Size</div>
                <ReadOnlyField
                  text={
                    data.category === "sneakers"
                      ? sizingValues[localSizing][data.size]
                        ? sizingValues[localSizing][data.size]
                        : womenSizingValues[localSizing][data.size]
                        ? womenSizingValues[localSizing][data.size]
                        : girlSizingValues[localSizing][data.size]
                        ? girlSizingValues[localSizing][data.size]
                        : toddler[localSizing][data.size]
                        ? toddler[localSizing][data.size]
                        : young[localSizing][data.size]
                      : data.size
                  }
                />
              </div>
              <div>
                <div className="label">Condition</div>
                <ReadOnlyField text={data.invCondition} />
              </div>
              <div className={"flex al-ce jc-ce"}>
                <img src={data.image} alt="img" className={"gridImg"} />
              </div>
            </div>
            <div className="three-grid">
              {/* <div>
                <div className="label">Asking Price</div>
                <Input
                  custom
                  name="askPrice"
                  type={"number"}
                  value={price}
                  updateValue={setPrice}
                  placeholder={"Enter Ask"}
                />
              </div> */}

              <div className="span2">
                <div className={"col-grid"}>
                  <div className={"flex jc-se mb-10"}>
                    {tradeLoading ? (
                      <Loader loading={tradeLoading} />
                    ) : (
                      <>
                        <div>
                          Highest Bid:
                          <span className="sm-bold">
                            {sign[localCurrency]}
                            {askBidData.highestBid !== -1
                              ? (
                                  askBidData.highestBid *
                                  rates?.[localCurrency?.toUpperCase()]
                                ).toFixed(2)
                              : "Not found"}
                          </span>
                        </div>
                        <div>
                          Lowest Ask:
                          <span className="sm-bold">
                            {sign[localCurrency]}
                            {askBidData.lowestAsk !== -1
                              ? (
                                  askBidData.lowestAsk *
                                  rates?.[localCurrency?.toUpperCase()]
                                ).toFixed(2)
                              : "Not found"}
                          </span>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="mb-20">
                    <AskInput
                      prefix={sign[localCurrency]}
                      dark={dark}
                      name="price"
                      type={"number"}
                      value={fields.price}
                      updateValue={handleChange}
                      placeholder={GetTranslateString("Enter Price")}
                    />
                  </div>

                  <div className="fees">
                    <div>Selling Price:</div>
                    <div>
                      {sign[localCurrency]}
                      {fields.price || 0}
                    </div>
                  </div>
                  <div className="fees">
                    <div>Verification Fee (5%):</div>
                    <div>
                      -{sign[localCurrency]}
                      {fields.processingFee || 0}
                    </div>
                  </div>

                  <div className="fees">
                    <div>Shipping Fee:</div>
                    <div>
                      -{sign[localCurrency]}
                      {fields.shippingFee}
                      {/* Free */}
                    </div>
                  </div>
                  <div className="fees">
                    {/* <div>Discount Code::</div>
                    <div>
                      <Input
                        customClass={"discount"}
                        custom={true}
                        dark={dark}
                        name="discount"
                        type={"text"}
                        value={discount.toUpperCase()}
                        updateValue={setDiscount}
                        placeholder={GetTranslateString("Code Here")}
                      />
                    </div> */}
                  </div>
                  <div className="fees total mt-10">
                    <div className="total">Total Payout (inc.all fees)*</div>
                    <div>
                      {sign[localCurrency]}
                      {(payout || 0).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-grid"}>
                <div className="mb-20">
                  <div className="label">{translate("platform")}</div>
                  <Select
                    dark={dark}
                    name="platform"
                    value={"katch"}
                    updateValue={() => {}}
                    options={[
                      {
                        label: "KATCH",
                        value: "katch",
                      },
                    ]}
                    placeholderText={GetTranslateString("Choose Platform")}
                    none={false}
                  />
                </div>
                <div>
                  {/* <div className="label">{translate("Ask Expiry")}</div>
                  <Select
                    dark={dark}
                    name="expiry"
                    value={fields.expiry}
                    updateValue={handleChange}
                    options={[
                      {
                        label: "1 Day",
                        value: 1,
                      },
                      {
                        label: "7 Days",
                        value: 7,
                      },
                      {
                        label: "14 Days",
                        value: 14,
                      },
                      {
                        label: "1 Month",
                        value: 30,
                      },
                    ]}
                    placeholderText={GetTranslateString("Choose Ask Expiry")}
                    none={false}
                  /> */}
                </div>

                <div
                  className="w-full jus-end mt-10 mb-10"
                  style={{ marginTop: "auto" }}
                >
                  <div>
                    <Loader loading={loading} />
                  </div>

                  <div className="ml-10">
                    <Button
                      disabled={loading}
                      imgSrc={<img src={dark ? AddDark : Add} alt="add" />}
                      onClick={async () => {
                        try {
                          toggleLoading(true);
                          // console.log(fields);
                          if (payout < 0) {
                            return ErrorToast(
                              "Error",
                              "Enter a valid ask price"
                            );
                          }

                          if (fields.shippingFee === 0) {
                            return ErrorToast(
                              "Error",
                              "Couldn't resolve shipping fees"
                            );
                          }
                          if (fields.price === 0) {
                            return ErrorToast("Error", "Ask price not given");
                          }

                          await placeAsk(token, mode, {
                            ...data,
                            processingFee: Number(
                              (
                                fields.processingFee /
                                rates?.[localCurrency?.toUpperCase()]
                              ).toFixed(2)
                            ),
                            shippingFee: fields.shippingFee,
                            payout: Number(
                              (
                                payout / rates?.[localCurrency?.toUpperCase()]
                              ).toFixed(2)
                            ),
                            price: Number(
                              (
                                fields.price /
                                rates?.[localCurrency?.toUpperCase()]
                              ).toFixed(2)
                            ),
                          });

                          SuccessToast(
                            "Success",
                            `Ask ${
                              mode === "place" ? "placed" : "Updated"
                            } for this item`
                          );
                          toggleState(false);
                          // setPrice("");
                        } catch (e) {
                          // console.log(e);
                          ErrorToast("Error", e.message);
                        } finally {
                          toggleLoading(false);
                        }
                      }}
                      text={translate(
                        mode === "place" ? "Place Ask" : "Update Ask"
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const ReadOnlyField = ({ text }) => {
  return <div className={"readOnly"}>{text}</div>;
};
