import { useContext, useEffect, useState } from "react";
import checked from "../../assets/checked.svg";
import greenCheck from "../../assets/greenChecked.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import updatePayout from "../../firebase/cart/updatePayout";
import "../css/SalesDetails.scoped.css";
import Input from "../general/Input";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import translate from "../../i18n/translate";
const ConsignmentSalesDetails = ({ selectedSaleSummary }) => {
  const [payout, setPayout] = useState();
  const [imgType, setImgType] = useState(checked);
  const isOwner = localStorage.getItem("owner");
  let hidePayoutConfimation = localStorage.getItem("hidePayoutConfimation");

  useEffect(() => {
    if (selectedSaleSummary?.payoutUpdated && selectedSaleSummary?.costPrice) {
      setImgType(greenCheck);
    } else {
      setImgType(checked);
    }
  }, [selectedSaleSummary]);

  useEffect(() => {
    setPayout(
      selectedSaleSummary?.payoutUpdated && selectedSaleSummary?.costPrice
        ? parseFloat(
            (
              selectedSaleSummary?.costPrice *
              rates?.[localCurrency?.toUpperCase()]
            ).toFixed(2)
          )
        : ""
    );
  }, [selectedSaleSummary]);

  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  return (
    <>
      {selectedSaleSummary && (
        <div className="pt-4 consignment">
          <div className="twogrid px-20 mb-10">
            <img
              className="shoeimg"
              src={selectedSaleSummary?.image ? selectedSaleSummary?.image : ""}
              alt=""
            />
            <div>
              <div className="name">{selectedSaleSummary?.name}</div>
              {/* <div className="label">{Beluga Reflective}</div> */}
              <div>
                <span className="label">{translate("style")}:</span>
                <span className="value">{selectedSaleSummary?.sku}</span>
              </div>
              <div className="flex">
                <div>
                  <span className="label">{translate("condition")}:</span>
                  <span className="value">
                    {selectedSaleSummary.condition?.toUpperCase()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="px-20 mb-10">
            <div className="twogrid equal mb-10">
              <div>
                <span className="label">{translate("listingPrice")}:</span>
                <span className="value">
                  {sign[localCurrency]}
                  {(
                    selectedSaleSummary?.price *
                    rates?.[localCurrency?.toUpperCase()]
                  ).toFixed(2)}
                </span>
              </div>
              <div>
                <span className="label">{translate("profitsLosses")}:</span>
                <span
                  className={`value ${
                    selectedSaleSummary?.soldPrice -
                      selectedSaleSummary?.costPrice >=
                    0
                      ? "green"
                      : "red"
                  }`}
                >
                  {selectedSaleSummary?.soldPrice -
                    selectedSaleSummary?.costPrice >=
                  0
                    ? "+"
                    : "-"}
                  {sign[localCurrency]}
                  {(
                    (selectedSaleSummary?.soldPrice -
                      selectedSaleSummary?.costPrice) *
                    rates?.[localCurrency?.toUpperCase()]
                  ).toFixed(2)}
                </span>
              </div>
            </div>
            <div className="twogrid equal">
              <div>
                <span className="label">{translate("soldDate")}:</span>
                <span className="value">
                  {new Date(selectedSaleSummary?.dateSold)
                    .toDateString()
                    .split(" ")
                    .slice(1, 4)
                    .join(" ")}
                </span>
              </div>
              <div>
                <span className="label">{translate("soldBy")}:</span>
                <span className="value green">0001-G</span>
              </div>
            </div>
          </div>
          <div className="px-20 mb-10">
            <span className="label">{translate("purchasedBy")}:</span>
            <span className="value">{selectedSaleSummary?.email}</span>
          </div>
          <div className="px-20 mb-10">
            <span className="label">{translate("paymentMethod")}:</span>
            <span className="value capit">
              {selectedSaleSummary?.paymentMethod?.split("_").join(" ")}
            </span>
          </div>

          <div className="px-20 mb-10 flex">
            <span className="label">{translate("payout")}:</span>
            <div
              style={{
                maxWidth: "150px",
                width: "100%",
                marginRight: "14px",
              }}
            >
              <Input
                name={"payout"}
                customClass={"smtext payout"}
                type={"number"}
                placeholder={`${sign[localCurrency]}70`}
                value={payout}
                updateValue={(e) => setPayout(e.target.value)}
              />
            </div>

            {isOwner ? (
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  try {
                    updatePayout(
                      StoreId,
                      selectedSaleSummary?.id,
                      parseFloat(
                        (
                          payout / rates?.[localCurrency?.toUpperCase()]
                        ).toFixed(2)
                      )
                    );
                    SuccessToast(translate("successfullyUpdated"), payout);
                    if (payout && payout != 0) {
                      setImgType(greenCheck);
                    } else {
                      setImgType(checked);
                    }
                  } catch (e) {
                    ErrorToast(e.message);
                  }
                }}
                src={imgType}
                alt=""
              />
            ) : (
              !hidePayoutConfimation && (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    try {
                      updatePayout(
                        StoreId,
                        selectedSaleSummary?.id,
                        parseFloat(
                          (
                            payout / rates?.[localCurrency?.toUpperCase()]
                          ).toFixed(2)
                        )
                      );
                      SuccessToast(translate("successfullyUpdated"), payout);
                      if (payout && payout != 0) {
                        setImgType(greenCheck);
                      } else {
                        setImgType(checked);
                      }
                    } catch (e) {
                      ErrorToast(e.message);
                    }
                  }}
                  src={imgType}
                  alt=""
                />
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ConsignmentSalesDetails;
