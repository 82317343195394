import batchCreateInventory from "../inventory/batchCreateInventory";
import _ from "lodash";

export default async function bulkImport(storeId, rawData) {
  await rawData.forEach((element) => {
    if (!element.sku) {
      throw new Error("StyleCode of this item is not a valid value.");
    }

    if (!element.condition) {
      throw new Error("Condition of this item is not a valid value.");
    }

    if (!element.name || element.name.length < 3) {
      throw new Error("Name of this item is not a valid value.");
    }

    if (!element.platform) {
      throw new Error("Platform of this item is not a valid value.");
    }

    if (!element.purchaseDate) {
      throw new Error("Purchase date of this item is not a valid date.");
    }

    if (!element.size) {
      throw new Error("Size is not a valid value.");
    }

    if (
      !element.purchasePrice ||
      element.purchasePrice < 0 ||
      isNaN(element.purchasePrice)
    ) {
      throw new Error("Purchase price is not valid");
    }

    if (!element.markUp || isNaN(element.markUp)) {
      throw new Error("MarkUp is not valid");
    }
  });

  let batchInput = [];
  for (let item of rawData) {
    const inputObj = _.omit(item, "quantity");
    for (let i = 0; i < item.quantity; i++) {
      batchInput.push(inputObj);
    }
  }
  await batchCreateInventory(storeId, batchInput);
}
