import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../index";

export default async function createCart(storeId, cartId) {
  const docRef = doc(db, "Stores", storeId, "Cart", cartId);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    await setDoc(docRef, {
      state: "Pending",
      items: {},
    });
  } else {
    throw new Error("cart id exists!");
  }
  // const batch = writeBatch(db);

  // const docRef = doc(db, "Stores", storeId, "Cart", id);
  // dataArray.forEach(data => {
  //     batch.set(docRef, {
  //         ...data,
  //     });
  // });
  // batch.set(docRef, )

  // await batch.commit();
}
