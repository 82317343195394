import { doc, updateDoc } from "firebase/firestore";
import { db } from "../index";

export default async function updatePayout(storeId, id, data) {
  const soldRef = doc(db, "Stores", storeId, "SoldItems", id);
  await updateDoc(soldRef, {
    costPrice: data,
    payoutUpdated: data ? true : false,
  });
}
