import { doc, setDoc } from "firebase/firestore";
import { db } from "../index";

export default async function updatePriceAlerts(storeId, data) {
  // Input validations
  if (!data.alertType) {
    throw new Error("Please choose an Alert Type");
  }

  if (!data.priceUpBy || isNaN(data.priceUpBy)) {
    throw new Error("Invalid value for Price Up By (%)");
  }

  if (!data.priceDownBy || isNaN(data.priceDownBy)) {
    throw new Error("Invalid value for Price Up By (%)");
  }

  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!data.email || !data.email.match(validRegex)) {
    throw new Error("Email is not a valid value.");
  }

  console.log(data);

  if (
    !data.phoneNumber ||
    !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
      data.phoneNumber
    )
  ) {
    throw new Error("Phone Number is not valid.");
  }

  await setDoc(
    doc(db, "Stores", storeId, "PriceAlerts", "Settings"),
    {
      ...data,
    },
    { merge: true }
  );
}
