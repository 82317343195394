import { getDocs, query, collection, orderBy, where } from "firebase/firestore";
import { db } from "../index";

export default async function inventoryGraph(storeId) {
    const expensesRef = collection(db, "Stores", storeId, "Inventory");

    let result = {};
    const q = query(
        expensesRef,
        where("type", "==", "normal"),
        orderBy("purchaseDate", "desc")
    );

    const graphSnapshot = await getDocs(q);
    if (graphSnapshot.size === 0) {
        return new Error("No inventory yet!");
    }

    let count = 0, total = 0;
    for (let data of graphSnapshot.docs) {
        if (result[data.data().purchaseDate] === undefined) {
            result[data.data().purchaseDate] = data.data().purchasePrice;
        } else {
            result[data.data().purchaseDate] += data.data().purchasePrice;
        }
        result[data.data().purchaseDate] = parseFloat(
            result[data.data().purchaseDate].toFixed(2)
        );
        count++; total += result[data.data().purchaseDate];
    }
    
    return { count, total, result };
}
