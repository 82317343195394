import { da } from "date-fns/locale";
import {
  collection,
  doc,
  getDoc,
  writeBatch,
  updateDoc,
  increment,
  arrayUnion,
  setDoc,
} from "firebase/firestore";
import { db } from "../index";
import createUIProduct from "./../products/createUIproduct";
import encodeKey from "./../util/encodeKey";

export default async function batchCreateInventory(storeId, dataArray) {
  const batch = writeBatch(db);
  const stockCount = {};
  const markupCount = {};
  const condition = {};
  let indexUpdater = {}
  const indexRef = doc(db, "Stores", storeId, "PriceAlerts", "Index")
  const indexSnap = await getDoc(indexRef);

  if(indexSnap.exists()) {
    indexUpdater = {...indexSnap.data().skuIndex};
  }

  let cache = {};
  let sku = '';
  for (let data of dataArray) {
    if (isNaN(data.markUp)) {
      throw new Error("Markup is not a valid number");
    }
    if (!cache[data.searchTerm]) {
      //Implement a caching system to not eat this code multiple times when not needed.
      

      // if (!docSnap.exists()) {
      const createdProduct = await createUIProduct(storeId, data.searchTerm, data);
      sku = createdProduct.styleCode;
      // } else {
      const docRef = doc(db, "Stores", storeId, "Products", sku);
      console.log(docRef)
      const docSnap = await getDoc(docRef);
      const productData = docSnap.data();
      console.log(productData)
      if (stockCount[data.sku] === undefined) {
        markupCount[data.sku] = productData.markup
          ? productData.markup
          : data.markUp;
        condition[data.sku] = productData.condition;
      }

      // if (markupCount[data.sku] === undefined) {
      //   stockCount[data.sku] = productData.stocks;
      // }
      // }
      cache[data.searchTerm] = true;
    }
    console.log(data.size.toString())
    const size = encodeKey(data.size.toString());

    if (stockCount[data.sku] === undefined) stockCount[data.sku] = {};
    if (markupCount[data.sku] === undefined) markupCount[data.sku] = {};
    if (condition[data.sku] === undefined) condition[data.sku] = {};

    if (stockCount[data.sku][size] === undefined) {
      stockCount[data.sku][size] = 1;
    } else {
      stockCount[data.sku][size] += 1;
    }

    if (
      markupCount[data.sku][size] === undefined ||
      markupCount[data.sku][size] === 0
    ) {
      markupCount[data.sku][size] = data.markUp;
      condition[data.sku][size] = data.condition;
    } else {
      if (data.markUp < markupCount[data.sku][size]) {
        markupCount[data.sku][size] = data.markUp;
        condition[data.sku][size] = data.condition;
      }
    }

    const nycRef = doc(collection(db, "Stores", storeId, "Inventory"));

    // console.log(doc(db, "Stores", storeId, "Products", data.sku));
    // console.log(data.sku);

    batch.set(nycRef, {
      ...data,
      storeId: storeId,
      type: "normal",
      style: doc(db, "Stores", storeId, "Products", sku),
    });


    if(indexUpdater.sku !== undefined) {
      indexUpdater[sku] = {
        platform: data.platform,
        sizes: {
          ...indexUpdater.sku.sizes,
          [data.size.toString()]: data.initLastSale
        }
      }
    } else {
      indexUpdater[sku] = {
        platform: data.platform,
        sizes: {
          [data.size.toString()]: data.initLastSale
        }
      }
    }

  }
  await batch.commit();


  await setDoc(indexRef, {
    skuIndex: {
      ...indexUpdater
    }
  }, {merge: true})

  const counterRef = doc(db, "Stores", storeId);

  const incrementItemCount = increment(dataArray.length);
  const incrementValue = increment(
    dataArray.reduce((tot, data) => {
      return tot + data.purchasePrice;
    }, 0)
  );

  await updateDoc(counterRef, {
    totalItems: incrementItemCount,
    totalValue: incrementValue,
  });

  for (let prod in markupCount) {
    let markupUdater = {};
    let stockUpdater = {};
    let conditionUpdater = {};
    const Ref = doc(db, "Stores", storeId, "Products", prod);
    for (let markup in markupCount[prod]) {
      console.log(markup);
      markupUdater[`markup.${encodeKey(markup)}`] = markupCount[prod][markup];
      conditionUpdater[`condition.${encodeKey(markup)}`] =
        condition[prod][markup];
    }
    for (let stock in stockCount[prod]) {
      console.log(stock);
      const incrementStocks = increment(stockCount[prod][stock]);
      stockUpdater[`stocks.${encodeKey(stock)}`] = incrementStocks;
    }

    await updateDoc(Ref, conditionUpdater);
    await updateDoc(Ref, stockUpdater);
    await updateDoc(Ref, markupUdater);
  }
}
