import { doc, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import refreshIcon from "../assets/refresh.svg";
import AddConsignmentModal from "../components/consignee/AddConsignmentModal";
import ConsigneeSummaryTable from "../components/consignee/ConsigneeSummaryTable";
import AddConsignmentInventoryModal from "../components/consignmentInventory/AddConsignmentInventoryModal";
import ConsignmentInventoryGrid from "../components/consignmentInventory/ConsignmentInventoryGrid";
import ConsigneeTable from "../components/consignmentSummary/ConsigneeTable";
import ConsignmentSalesDetails from "../components/consignmentSummary/ConsignmentSalesDetails";
import ConsignmentSalesTable from "../components/consignmentSummary/ConsignmentSalesTable";
import ConsignmentTable from "../components/consignmentSummary/ConsignmentTableCategories";
import Button from "../components/general/Button";
import Graph from "../components/general/Graph";
import SearchBtn from "../components/general/SearchBtn";
import Topbar from "../components/general/Topbar";
import numberWithCommas from "../components/utils/numberWithCommas";
import { StoreId } from "../Constants";
import { CurrencyContext } from "../context/CurrencyContext";
import { db } from "../firebase";
import updateConsignmentCount from "../firebase/util/updateConsignmentCount";
import "./css/Consignment.scoped.css";
import SmallSelect from "../components/general/SmallSelect";
import consignmentSalesGraph from "../firebase/graphs/consignmentSalesGraph";
import refreshIconDark from "../assets/refreshdark.svg";
import { useNavigate } from "react-router-dom";
import { transform } from "lodash";
import translate from "../i18n/translate";
import GetTranslateString from "../i18n/translateString";
import { getAuth, onIdTokenChanged } from "firebase/auth";

const Consignment = ({ user, dark }) => {
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  const [active, setActive] = useState(0);
  const [consigneeCount, updateConsigneeCount] = useState(0);
  const [total, setTotal] = useState({
    totalConsignment: 0,
    totalPayout: 0,
  });
  const [selectedSaleSummary, setSelectedSaleSummary] = useState();
  const [search, setSearch] = useState("");
  const [totalProfit, setTotalProfit] = useState(0);
  const componentRef = useRef();
  const [searchString, updateSearchString] = useState("");
  const [searchString1, updateSearchString1] = useState("");
  const [searchString2, updateSearchString2] = useState("");
  const [time, setTime] = useState("monthly");
  const [graphData, setGraphData] = useState();
  const [totalSales, setTotalSales] = useState(0);
  const [count, setCount] = useState(0);

  const isOwner = localStorage.getItem("owner");
  let hideConsigneeBtns = localStorage.getItem("hideConsigneeBtns");
  let hideConsigneeInvBtns = localStorage.getItem("hideConsigneeInvBtns");

  const navigate = useNavigate();

  const auth = getAuth();
  // console.log(auth);
  useEffect(() => {
    const authListener = onIdTokenChanged(auth, async (user) => {
      // console.log(auth)
      if (user) {
        const token = await user.getIdToken();
        localStorage.setItem("token", token);
      }
    });
    return () => {
      authListener();
    };
  }, []);

  useEffect(() => {
    let hidden = localStorage.getItem("isConsignmentHidden");
    if (!isOwner && hidden) {
      navigate("/dash");
      console.log(isOwner);
    }
  }, [isOwner, navigate]);

  useEffect(() => {
    let getData = async () => {
      let { count, result: data } = await consignmentSalesGraph(StoreId);
      if (data) {
        setCount(count);

        let filterData =
          Object.keys(data)?.length > 5
            ? Object.keys(data)
                .slice(0, 5)
                .reverse()
                .map((e) => ({
                  date: e,
                  value: data[e],
                }))
            : Object.keys(data)
                .reverse()
                .map((e) => ({
                  date: e,
                  value: data[e],
                }));
        setTotalSales(Object.values(data).reduce((a, b) => a + b), 0);
        setGraphData(filterData);
      } else {
        setTotalSales();
        setGraphData();
      }
    };
    getData();
  }, []);

  // useEffect(() => {
  //   setSearch("");
  // }, [active]);

  useEffect(() => {
    const cleanup = onSnapshot(doc(db, "Stores", StoreId), (doc) => {
      setTotal(doc.data());
    });
    return () => {
      cleanup();
    };
  }, []);

  return (
    <div className="page">
      <Topbar
        dark={dark}
        pagename={translate("consignment")}
        links={[
          {
            name: translate("summary"),
          },
          {
            name: translate("consignee"),
          },
          {
            name: translate("inventory"),
          },
        ]}
        active={active}
        onClick={setActive}
        user={user}
      />

      <div>
        <div className="add-btns px">
          {active === 0 && (
            <div className="mr-14">
              <SmallSelect
                dark={dark}
                name="time"
                value={time}
                updateValue={(e) => setTime(e.target.value)}
                options={[
                  {
                    label: translate("daily"),
                    value: "daily",
                  },
                  {
                    label: translate("weekly"),
                    value: "weekly",
                  },
                  {
                    label: translate("monthly"),
                    value: "monthly",
                  },
                  {
                    label: translate("yearly"),
                    value: "yearly",
                  },
                ]}
                placeholderText={GetTranslateString("categories")}
                none={false}
                month
              />
            </div>
          )}

          <div className="mr-14">
            <SearchBtn
              dark={dark}
              value={search}
              updateValue={setSearch}
              onEnter={async () => {
                if (active === 0) {
                  updateSearchString(search);
                } else if (active === 1) {
                  updateSearchString1(search);
                } else {
                  updateSearchString2(search);
                }
              }}
            />
          </div>

          {active === 1 &&
            (isOwner ? (
              <div className="mr-14">
                <AddConsignmentModal dark={dark} />
              </div>
            ) : (
              !hideConsigneeBtns && <AddConsignmentModal dark={dark} />
            ))}

          {active === 2 &&
            (isOwner ? (
              <div className="mr-14">
                <AddConsignmentInventoryModal dark={dark} />
              </div>
            ) : (
              !hideConsigneeInvBtns && (
                <AddConsignmentInventoryModal dark={dark} />
              )
            ))}
        </div>

        {active === 0 && (
          <div className="summary px">
            <div className="graph box">
              <div className="headersection">
                {translate("totalConsignmentSales")}
              </div>
              <div className="flex al-fe headers px-20">
                <span className="bold black">
                  {localCurrency?.toUpperCase()}:
                </span>
                <span className="green bold">
                  {" "}
                  +{sign[localCurrency]}
                  {numberWithCommas(
                    totalSales
                      ? (
                          totalSales * rates?.[localCurrency?.toUpperCase()]
                        ).toFixed(0)
                      : 0
                  )}
                </span>
                <span className="itemssold">
                  {count} {translate("itemssold")}
                </span>
              </div>
              {graphData && <Graph graphData={graphData} dark={dark} />}
            </div>
            <div className="overview box">
              <div className="headersection flex al-ce jc-sp">
                <div>{translate("consigneeOverviews")}</div>
                <div className="total">
                  <span className="bold">{translate("total")}:</span>
                  <span className="value">{consigneeCount}</span>
                </div>
              </div>
              <ConsigneeTable updateConsigneeCount={updateConsigneeCount} />
            </div>
            <div className="categories box">
              <div className="headersection">
                {translate("consignmentPopularCategories")}
              </div>
              <ConsignmentTable />
            </div>

            <div className="span2 box">
              <div className="headersection">
                {translate("consignmentSalesSummary")}
              </div>
              <ConsignmentSalesTable
                searchString={searchString}
                setSelectedSaleSummary={setSelectedSaleSummary}
              />
            </div>

            <div className="box">
              <div className="headersection flex al-ce jc-sp">
                <div>{translate("consignmentSalesDetails")}</div>
                <div className="total">
                  <span>{translate("orderId")}:</span>
                  <span className="ml-5">0001</span>
                </div>
                <ReactToPrint
                  bodyClass="print2"
                  trigger={() => (
                    <div style={{ cursor: "pointer" }}>
                      <svg
                        width="24"
                        height="26"
                        viewBox="0 0 24 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.5455 11.9167C19.148 11.9167 19.6364 11.4316 19.6364 10.8333C19.6364 10.235 19.148 9.75 18.5455 9.75C17.943 9.75 17.4546 10.235 17.4546 10.8333C17.4546 11.4316 17.943 11.9167 18.5455 11.9167Z"
                          fill="#767BFA"
                        />
                        <path
                          d="M19.9444 6.73275C19.3608 6.59655 18.6994 6.48945 17.9511 6.40684C17.9734 6.12282 17.986 5.78934 17.986 5.39684C17.986 3.24117 17.6051 2.86587 17.3388 2.68618C17.1035 2.52744 16.6452 2.33329 15.7287 2.19587C14.8207 2.05974 13.6167 2 12 2C10.3833 2 9.17933 2.05974 8.27134 2.19587C7.35476 2.33329 6.89652 2.52744 6.66121 2.68618C6.39486 2.86587 6.01399 3.24117 6.01399 5.39684C6.01399 5.78934 6.02661 6.12282 6.04888 6.40683C5.30061 6.48944 4.63916 6.59654 4.05556 6.73274C4.01688 6.32697 4 5.88278 4 5.39684C4 0.952542 5.412 0 12 0C18.588 0 20 0.952542 20 5.39684C20 5.88278 19.9831 6.32698 19.9444 6.73275Z"
                          fill="#767BFA"
                        />
                        <path
                          d="M19.9444 6.73275C19.3608 6.59655 18.6994 6.48945 17.9511 6.40684C17.9734 6.12282 17.986 5.78934 17.986 5.39684C17.986 3.24117 17.6051 2.86587 17.3388 2.68618C17.1035 2.52744 16.6452 2.33329 15.7287 2.19587C14.8207 2.05974 13.6167 2 12 2C10.3833 2 9.17933 2.05974 8.27134 2.19587C7.35476 2.33329 6.89652 2.52744 6.66121 2.68618C6.39486 2.86587 6.01399 3.24117 6.01399 5.39684C6.01399 5.78934 6.02661 6.12282 6.04888 6.40683C5.30061 6.48944 4.63916 6.59654 4.05556 6.73274C4.01688 6.32697 4 5.88278 4 5.39684C4 0.952542 5.412 0 12 0C18.588 0 20 0.952542 20 5.39684C20 5.88278 19.9831 6.32698 19.9444 6.73275Z"
                          stroke="#767BFA"
                        />
                        <path
                          d="M2.27663 16.915C2.08355 16.1816 2.01399 15.1287 2.01399 13.4872C2.01399 10.2481 2.55419 9.44237 3.29359 8.99752C3.80808 8.68798 4.66266 8.41143 6.14643 8.23444C7.61323 8.05948 9.51175 8.00012 12 8.00012C14.7435 8.00012 16.7672 8.0597 18.2822 8.23524C19.8241 8.4139 20.606 8.69083 21.0245 8.96198C21.3363 9.16399 21.5573 9.42862 21.7234 10.0594C21.9165 10.7929 21.986 11.8457 21.986 13.4872C21.986 15.1287 21.9165 16.1816 21.7234 16.915C21.5573 17.5458 21.3363 17.8105 21.0245 18.0125C20.7351 18.2 20.2719 18.3902 19.5039 18.5489C19.5465 18.9977 19.5649 19.4927 19.5649 20.0383C19.5649 20.223 19.5628 20.402 19.5584 20.5753C23.5193 19.8722 24 18.0293 24 13.4872C24 6.93601 23 6.00012 12 6.00012C2 6.00012 0 6.93601 0 13.4872C0 18.0787 0.491212 19.912 4.5721 20.5978C4.56724 20.4175 4.56494 20.2311 4.56494 20.0383C4.56494 19.503 4.58268 19.0164 4.62363 18.5744C3.77874 18.4109 3.28056 18.2101 2.97551 18.0125C2.66371 17.8105 2.44269 17.5458 2.27663 16.915Z"
                          fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.36353 20.0417C4.36353 25.007 5.7059 26 11.969 26C18.2321 26 19.6363 25.007 19.6363 20.0417C19.6363 17.3055 19.6363 15.8258 18.8937 15.0256C18.0193 14.0834 16.1153 14.0834 11.969 14.0834C7.86634 14.0834 5.97679 14.0834 5.10654 15.0187C4.36353 15.8172 4.36353 17.2975 4.36353 20.0417ZM6.37751 20.0417C6.37751 22.5377 6.77089 23.0841 7.05854 23.3076C7.25233 23.4582 7.64181 23.6541 8.47738 23.7958C9.31301 23.9375 10.435 24 11.969 24C13.5026 24 14.6295 23.9376 15.4722 23.7955C16.3151 23.6534 16.7168 23.4559 16.9209 23.2995C17.2198 23.0704 17.6223 22.5196 17.6223 20.0417C17.6223 18.49 17.6174 17.5424 17.5225 16.8782C17.4818 16.5939 17.4357 16.4513 17.409 16.3889C17.3245 16.3492 17.0272 16.238 16.2272 16.169C15.2572 16.0852 13.9245 16.0834 11.969 16.0834C10.0287 16.0834 8.7084 16.0852 7.74796 16.1688C6.96859 16.2367 6.6749 16.3449 6.58974 16.3842C6.56302 16.4474 6.51706 16.5911 6.47666 16.876C6.38228 17.5415 6.37751 18.4905 6.37751 20.0417ZM17.3867 16.348C17.3867 16.348 17.39 16.3564 17.3991 16.3674C17.3916 16.3526 17.3867 16.348 17.3867 16.348ZM6.61232 16.3423C6.61232 16.3423 6.60762 16.3467 6.60041 16.361C6.60908 16.3503 6.61232 16.3423 6.61232 16.3423Z"
                          fill="#767BFA"
                        />
                      </svg>
                    </div>
                  )}
                  content={() => componentRef.current}
                />
              </div>
              <div ref={componentRef}>
                <ConsignmentSalesDetails
                  selectedSaleSummary={selectedSaleSummary}
                />
              </div>
            </div>
          </div>
        )}

        {active === 1 && (
          <div className="px">
            <div className="summary-table">
              <div className="headersection">
                {translate("consigneesSummary")}
              </div>
              <ConsigneeSummaryTable searchString={searchString1} dark={dark} />
            </div>
          </div>
        )}

        {active === 2 && (
          <div className="px">
            <div className="summary-table">
              <div className="flex al-ce jc-sp headersection">
                <div className="smtext">{translate("summary")}</div>
                <div className="flex al-ce">
                  <div className="query flex al-ce">
                    <span className="purple">{translate("totalPayout")}:</span>
                    <span className="queryvalue red">
                      {sign[localCurrency]}
                      {numberWithCommas(
                        (
                          total.totalPayout *
                          rates?.[localCurrency?.toUpperCase()]
                        ).toFixed(2)
                      )}
                    </span>
                  </div>
                  <div className="query">
                    <span className="purple">{translate("totalItems")}:</span>
                    <span className="queryvalue">
                      {numberWithCommas(total.totalConsignment)}
                    </span>
                  </div>
                  <div className="query">
                    <span className="purple">
                      {translate("totalUnrealizedProfit")}:
                    </span>
                    <span className="queryvalue green">
                      {sign[localCurrency]}
                      {numberWithCommas(
                        (
                          totalProfit * rates?.[localCurrency?.toUpperCase()]
                        ).toFixed(2)
                      )}
                    </span>
                  </div>
                </div>
                <Button
                  text={translate("refresh")}
                  imgSrc={
                    <img
                      src={dark ? refreshIconDark : refreshIcon}
                      alt="refresh Icon"
                    />
                  }
                  onClick={async () => {
                    setTotal(await updateConsignmentCount(StoreId));
                  }}
                />
              </div>
              <ConsignmentInventoryGrid
                dark={dark}
                setTotalProfit={setTotalProfit}
                searchString={searchString2}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Consignment;
