import React from "react";
import "../css/StaffSumamry.scoped.css";
import { formatAMPM } from "../utils/getTime";
import translate from "../../i18n/translate";
export default function CheckInOutDetails({ logData }) {
  return (
    <div className="check-details">
      <div className="headersgrid">
        <div>{translate("data")}</div>
        <div>{translate("timeIn")}</div>
        <div>{translate("timeOut")}</div>
      </div>
      <div className="items">
        {logData?.map((e, i) => (
          <div key={i} className="item">
            <div>
              {new Date(e.date)
                .toDateString()
                .split(" ")
                .slice(1, 4)
                .join(" ")}
            </div>
            <div>{formatAMPM(new Date(e.clockingIn))}</div>
            <div>
              {e.clockingOut ? formatAMPM(new Date(e.clockingOut)) : "-"}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
