import { collection, onSnapshot } from "firebase/firestore";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { v4 as uuidv4 } from "uuid";
import { searchAllInventory } from "../algolia/index";
import Delete from "../assets/delete.svg";
import DeleteDark from "../assets/deletedark.svg";
import Graph from "../components/general/Graph";
import SearchBtn from "../components/general/SearchBtn";
import { ErrorToast } from "../components/general/ToastNotification";
import Topbar from "../components/general/Topbar";
import CartDetails from "../components/sales/CartDetails";
import CartGrid from "../components/sales/CartGrid";
import SalesCategories from "../components/sales/SalesCategories";
import PaymentModal from "../components/sales/PaymentModal";
import PosItem from "../components/sales/PosItem";
import SalesDetails from "../components/sales/SalesDetails";
import SalesTable from "../components/sales/SalesTable";
import { StoreId } from "../Constants";
import { CurrencyContext } from "../context/CurrencyContext";
import createCart from "../firebase/cart/createCart";
import { db } from "../firebase/index";
import batchFetch from "../firebase/util/batchFetch";
import "./css/Sales.scoped.css";
import SmallSelect from "../components/general/SmallSelect";
import salesGraph from "../firebase/graphs/salesGraph";
import numberWithCommas from "../components/utils/numberWithCommas";
import DeleteConfirm from "../components/general/DeleteConfirm";
import Container from "../components/receipt/Container";
import { useNavigate } from "react-router-dom";
import Button from "../components/general/Button";
import translate from "../i18n/translate";
import GetTranslateString from "../i18n/translateString";
import { getAuth, onIdTokenChanged } from "firebase/auth";

const Sales = ({ user, dark }) => {
  const componentRef = useRef();
  const cartRef = useRef();
  const [selectedSaleSummary, setSelectedSaleSummary] = useState();
  const [active, setActive] = useState(0);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [qrData, updateQrData] = useState([]);
  const [activeCart, setActiveCart] = useState("");
  const [cartTotal, setCartTotal] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [cartIds, setCartIds] = useState([]);
  const [data, setData] = useState([]);
  const [searchString, updateSearchString] = useState("");
  const [searchString2, updateSearchString2] = useState("");
  const [time, setTime] = useState("monthly");
  const [graphData, setGraphData] = useState();
  const [graphData2, setGraphData2] = useState();
  const [totalSales, setTotalSales] = useState(0);
  const [totalGrowth, setTotalGrowth] = useState(0);
  const [count, setCount] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const isOwner = localStorage.getItem("owner");
  let hideReceiptSettings = localStorage.getItem("hideReceiptSettings");
  const navigate = useNavigate();

  const auth = getAuth();
  // console.log(auth);
  useEffect(() => {
    const authListener = onIdTokenChanged(auth, async (user) => {
      // console.log(auth)
      if (user) {
        const token = await user.getIdToken();
        localStorage.setItem("token", token);
      }
    });
    return () => {
      authListener();
    };
  }, []);

  useEffect(() => {
    let hidden = localStorage.getItem("isSalesHidden");
    if (!isOwner && hidden) {
      navigate("/dash");
    }
  }, [isOwner, navigate]);

  useEffect(() => {
    let getData = async () => {
      let { count, total, result: data } = await salesGraph(StoreId);

      if (data) {
        setCount(count);
        let filterData =
          Object.keys(data)?.length > 5
            ? Object.keys(data)
                .slice(0, 5)
                .reverse()
                .map((e) => ({
                  date: e,
                  value: data[e],
                }))
            : Object.keys(data)
                .reverse()
                .map((e) => ({
                  date: e,
                  value: data[e],
                }));

        setTotalSales(Object.values(data).reduce((a, b) => a + b), 0);
        setGraphData(filterData);

        let arr = Object.keys(data).reverse();

        let newObj = {};

        for (let i = 1; i < arr.length; i++) {
          newObj[arr[i]] = parseFloat(
            (
              ((data[arr[i]] - data[arr[i - 1]]) / data[arr[i - 1]]) *
              100
            ).toFixed(2)
          );
        }

        setTotalGrowth(
          Object.values(newObj ? newObj : {}).reduce((a, b) => a + b, 0)
        );

        let filterData1 =
          Object.keys(newObj)?.length > 5
            ? Object.keys(newObj)
                .map((e) => ({
                  date: e,
                  value: newObj[e],
                }))
                .slice(
                  Object.keys(newObj).length - 5,
                  Object.keys(newObj).length
                )
            : Object.keys(newObj).map((e) => ({
                date: e,
                value: newObj[e],
              }));

        setGraphData2(filterData1);
      } else {
        setTotalSales();
        setGraphData();
        setGraphData2();
      }
    };
    getData();
  }, []);

  // useEffect(() => {
  //   let getData = async () => {
  //     let { count, total, result: data } = await salesGraph(StoreId);

  //     let arr = Object.keys(data).reverse();

  //     let newObj = {};
  //     for (let i = 1; i < arr.length; i++) {
  //       newObj[arr[i]] = parseFloat(
  //         (
  //           ((data[arr[i]] - data[arr[i - 1]]) / data[arr[i - 1]]) *
  //           100
  //         ).toFixed(2)
  //       );
  //     }

  //     setTotalGrowth(
  //       Object.values(newObj ? newObj : {}).reduce((a, b) => a + b, 0)
  //     );

  //     let filterData =
  //       Object.keys(newObj)?.length > 5
  //         ? Object.keys(newObj)
  //             .map((e) => ({
  //               date: e,
  //               value: newObj[e],
  //             }))
  //             .slice(Object.keys(newObj).length - 5, Object.keys(newObj).length)
  //         : Object.keys(newObj).map((e) => ({
  //             date: e,
  //             value: newObj[e],
  //           }));

  //     setGraphData2(filterData);
  //   };
  //   getData();
  // }, []);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "Stores", StoreId, "Inventory"),
      async (snapShot) => {
        let invData = [];
        if (searchString == "") {
          invData = snapShot.docs.map((e) => {
            return {
              ...e.data(),
              invCondition: e.data().condition,
              id: e.id,
            };
          });
        } else {
          let hits = await searchAllInventory(searchString);

          invData = hits.map((e) => ({
            ...e,
            id: e.objectID.split("/")[1],
          }));

          snapShot.docChanges().forEach((change) => {
            const changedDoc = change.doc.data();
            const id = change.doc.id;

            if (change.type === "removed") {
              //let us remove from INVdata if this exists
              _.remove(invData, (k) => k.id === id);
            }

            if (change.type === "modified") {
              const exists = _.findIndex(invData, (k) => k.id === id);
              if (exists !== -1) {
                //This element was modified
                invData[exists] = {
                  ...changedDoc,
                  id: id,
                };
              }
            }
          });
        }

        updateQrData(invData);
        const productRefs = invData
          .filter((e) => e.style)
          .map((data) => (data.style.path ? data.style.path : data.style));
        // console.log(productRefs);
        const productData = await batchFetch(productRefs);
        // console.log("Prod data", productData);
        setData(invData?.map((e, i) => ({ ...e, ...productData[i] })));
      }
    );

    return () => {
      inventoryListener();
    };
  }, [searchString]);

  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  let createNewCart = () => {
    let newCart = uuidv4();
    setCartTotal(0);
    setDiscountPercent(0);
    setCartIds([]);
    createCart(StoreId, newCart);
    setActiveCart(newCart);
    localStorage.setItem("cart", newCart);
  };

  let deleCart = () => {
    let newCart = uuidv4();
    setActiveCart(newCart);
    localStorage.setItem("cart", newCart);
    if (setDiscountPercent !== 0) {
      setDiscountPercent(0);
    }
    try {
      createCart(StoreId, newCart);
    } catch (e) {
      setCartIds([]);
      ErrorToast("Create cart Failed", e.message);
    }
    setDeleteOpen(false);
  };

  useEffect(() => {
    const cart = localStorage.getItem("cart");
    if (cart) {
      setActiveCart(cart);
    } else {
      createNewCart();
    }
  }, []);

  return (
    <div className="page">
      <DeleteConfirm
        dark={dark}
        isOpen={deleteOpen}
        toggleOpen={setDeleteOpen}
        deleteId={null}
        customFunc={deleCart}
        msg={"cart"}
      />

      <Topbar
        dark={dark}
        pagename={translate("sales")}
        links={[
          {
            name: translate("summary"),
          },
          {
            name: translate("pos"),
          },
          isOwner
            ? {
                name: translate("receiptSettings"),
              }
            : !hideReceiptSettings && {
                name: "Receipt Settings",
              },
        ]}
        user={user}
        active={active}
        onClick={setActive}
      />
      <div>
        <div className="add-btns px">
          {active === 0 && (
            <div className="mr-14">
              <SmallSelect
                dark={dark}
                name="time"
                value={time}
                updateValue={(e) => setTime(e.target.value)}
                options={[
                  {
                    label: translate("daily"),
                    value: "daily",
                  },
                  {
                    label: translate("weekly"),
                    value: "weekly",
                  },
                  {
                    label: translate("monthly"),
                    value: "monthly",
                  },
                  {
                    label: translate("yearly"),
                    value: "yearly",
                  },
                ]}
                placeholderText={GetTranslateString("categories")}
                none={false}
                month
              />
            </div>
          )}

          {active !== 2 && (
            <div className="mr-14">
              <SearchBtn
                dark={dark}
                value={search}
                updateValue={setSearch}
                onEnter={async () => {
                  if (active === 0) {
                    updateSearchString2(search);
                  } else {
                    updateSearchString(search);
                  }
                }}
              />
            </div>
          )}

          {active === 1 && (
            <Button
              customClass={"px-1"}
              imgSrc={
                <svg
                  width="20"
                  height="12"
                  viewBox="0 0 24 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-v-960e3f96=""
                >
                  <path
                    d="M7.09774 7.70711C6.69102 7.31658 6.69102 6.68342 7.09774 6.29289C7.50445 5.90237 8.16387 5.90237 8.57059 6.29289C10.5939 8.2357 13.4061 8.2357 15.4294 6.29289C15.8361 5.90237 16.4956 5.90237 16.9023 6.29289C17.309 6.68342 17.309 7.31658 16.9023 7.70711C14.0655 10.431 9.93453 10.431 7.09774 7.70711Z"
                    fill="#767BFA"
                    data-v-960e3f96=""
                  />
                  <path
                    d="M16.9023 6.04289C17.309 6.43342 17.309 7.06658 16.9023 7.45711C16.4956 7.84763 15.8361 7.84763 15.4294 7.45711C13.4061 5.5143 10.5939 5.5143 8.57059 7.45711C8.16387 7.84763 7.50445 7.84763 7.09774 7.45711C6.69102 7.06658 6.69102 6.43342 7.09774 6.04289C9.93453 3.31904 14.0655 3.31904 16.9023 6.04289Z"
                    fill="#767BFA"
                    data-v-960e3f96=""
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.441643 5.89359C-0.147214 6.53437 -0.147214 7.46563 0.441643 8.10641C2.25469 10.0793 6.54403 14 12 14C17.456 14 21.7453 10.0793 23.5584 8.10641C24.1472 7.46563 24.1472 6.53437 23.5584 5.89359C21.7453 3.92067 17.456 0 12 0C6.54403 0 2.25469 3.92067 0.441643 5.89359ZM12 2C7.68339 2 4.04578 5.05757 2.20582 7C4.04578 8.94243 7.68339 12 12 12C16.3166 12 19.9542 8.94243 21.7942 7C19.9542 5.05757 16.3166 2 12 2Z"
                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                    data-v-960e3f96=""
                  />
                </svg>
              }
              text={translate("customerSideScreen")}
              onClick={() => window.open("/preview", "_blank")}
            />
          )}
        </div>

        {active === 0 && (
          <div className="summary px">
            <div className="graph box">
              <div className="headersection">{translate("totalSales")}</div>
              <div className="flex al-fe headers px-20">
                <span className="bold black">
                  {localCurrency?.toUpperCase()}:
                </span>
                <span className="green bold">
                  {" "}
                  +{sign[localCurrency]}
                  {numberWithCommas(
                    totalSales
                      ? (
                          totalSales * rates?.[localCurrency?.toUpperCase()]
                        ).toFixed(0)
                      : 0
                  )}
                </span>
                <span className="itemssold">
                  {count} {translate("itemsSold")}.
                </span>
              </div>
              {graphData && <Graph graphData={graphData} dark={dark} />}
            </div>
            <div className="overview box">
              <div className="headersection flex al-ce jc-sp">
                <div>{translate("growth")}</div>
              </div>
              <div className="flex al-fe headers px-20">
                <span className="green bold">
                  {totalGrowth >= 0 ? "+" : null}
                  {numberWithCommas(totalGrowth ? totalGrowth.toFixed(0) : 0)}%
                </span>
                <span className="itemssold">{translate("inLastMonth")}</span>
              </div>
              {graphData2 && (
                <Graph graphData={graphData2} percent dark={dark} />
              )}
            </div>
            <div className="categories box">
              <div className="headersection">
                {translate("popularCategories")}
              </div>
              <SalesCategories />
            </div>

            <div className="span2 box">
              <div className="headersection">{translate("salesSummary")}</div>
              <SalesTable
                searchString={searchString2}
                setSelectedSaleSummary={setSelectedSaleSummary}
              />
            </div>

            <div className="box">
              <div className="headersection flex al-ce jc-sp">
                <div>{translate("salesDetails")}</div>
                <div className="total">
                  <span>{translate("orderId")}:</span>
                  <span className="ml-5">0001</span>
                </div>
                <ReactToPrint
                  bodyClass="print2"
                  trigger={() => (
                    <div style={{ cursor: "pointer" }}>
                      <svg
                        width="24"
                        height="26"
                        viewBox="0 0 24 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.5455 11.9167C19.148 11.9167 19.6364 11.4316 19.6364 10.8333C19.6364 10.235 19.148 9.75 18.5455 9.75C17.943 9.75 17.4546 10.235 17.4546 10.8333C17.4546 11.4316 17.943 11.9167 18.5455 11.9167Z"
                          fill="#767BFA"
                        />
                        <path
                          d="M19.9444 6.73275C19.3608 6.59655 18.6994 6.48945 17.9511 6.40684C17.9734 6.12282 17.986 5.78934 17.986 5.39684C17.986 3.24117 17.6051 2.86587 17.3388 2.68618C17.1035 2.52744 16.6452 2.33329 15.7287 2.19587C14.8207 2.05974 13.6167 2 12 2C10.3833 2 9.17933 2.05974 8.27134 2.19587C7.35476 2.33329 6.89652 2.52744 6.66121 2.68618C6.39486 2.86587 6.01399 3.24117 6.01399 5.39684C6.01399 5.78934 6.02661 6.12282 6.04888 6.40683C5.30061 6.48944 4.63916 6.59654 4.05556 6.73274C4.01688 6.32697 4 5.88278 4 5.39684C4 0.952542 5.412 0 12 0C18.588 0 20 0.952542 20 5.39684C20 5.88278 19.9831 6.32698 19.9444 6.73275Z"
                          fill="#767BFA"
                        />
                        <path
                          d="M19.9444 6.73275C19.3608 6.59655 18.6994 6.48945 17.9511 6.40684C17.9734 6.12282 17.986 5.78934 17.986 5.39684C17.986 3.24117 17.6051 2.86587 17.3388 2.68618C17.1035 2.52744 16.6452 2.33329 15.7287 2.19587C14.8207 2.05974 13.6167 2 12 2C10.3833 2 9.17933 2.05974 8.27134 2.19587C7.35476 2.33329 6.89652 2.52744 6.66121 2.68618C6.39486 2.86587 6.01399 3.24117 6.01399 5.39684C6.01399 5.78934 6.02661 6.12282 6.04888 6.40683C5.30061 6.48944 4.63916 6.59654 4.05556 6.73274C4.01688 6.32697 4 5.88278 4 5.39684C4 0.952542 5.412 0 12 0C18.588 0 20 0.952542 20 5.39684C20 5.88278 19.9831 6.32698 19.9444 6.73275Z"
                          stroke="#767BFA"
                        />
                        <path
                          d="M2.27663 16.915C2.08355 16.1816 2.01399 15.1287 2.01399 13.4872C2.01399 10.2481 2.55419 9.44237 3.29359 8.99752C3.80808 8.68798 4.66266 8.41143 6.14643 8.23444C7.61323 8.05948 9.51175 8.00012 12 8.00012C14.7435 8.00012 16.7672 8.0597 18.2822 8.23524C19.8241 8.4139 20.606 8.69083 21.0245 8.96198C21.3363 9.16399 21.5573 9.42862 21.7234 10.0594C21.9165 10.7929 21.986 11.8457 21.986 13.4872C21.986 15.1287 21.9165 16.1816 21.7234 16.915C21.5573 17.5458 21.3363 17.8105 21.0245 18.0125C20.7351 18.2 20.2719 18.3902 19.5039 18.5489C19.5465 18.9977 19.5649 19.4927 19.5649 20.0383C19.5649 20.223 19.5628 20.402 19.5584 20.5753C23.5193 19.8722 24 18.0293 24 13.4872C24 6.93601 23 6.00012 12 6.00012C2 6.00012 0 6.93601 0 13.4872C0 18.0787 0.491212 19.912 4.5721 20.5978C4.56724 20.4175 4.56494 20.2311 4.56494 20.0383C4.56494 19.503 4.58268 19.0164 4.62363 18.5744C3.77874 18.4109 3.28056 18.2101 2.97551 18.0125C2.66371 17.8105 2.44269 17.5458 2.27663 16.915Z"
                          fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.36353 20.0417C4.36353 25.007 5.7059 26 11.969 26C18.2321 26 19.6363 25.007 19.6363 20.0417C19.6363 17.3055 19.6363 15.8258 18.8937 15.0256C18.0193 14.0834 16.1153 14.0834 11.969 14.0834C7.86634 14.0834 5.97679 14.0834 5.10654 15.0187C4.36353 15.8172 4.36353 17.2975 4.36353 20.0417ZM6.37751 20.0417C6.37751 22.5377 6.77089 23.0841 7.05854 23.3076C7.25233 23.4582 7.64181 23.6541 8.47738 23.7958C9.31301 23.9375 10.435 24 11.969 24C13.5026 24 14.6295 23.9376 15.4722 23.7955C16.3151 23.6534 16.7168 23.4559 16.9209 23.2995C17.2198 23.0704 17.6223 22.5196 17.6223 20.0417C17.6223 18.49 17.6174 17.5424 17.5225 16.8782C17.4818 16.5939 17.4357 16.4513 17.409 16.3889C17.3245 16.3492 17.0272 16.238 16.2272 16.169C15.2572 16.0852 13.9245 16.0834 11.969 16.0834C10.0287 16.0834 8.7084 16.0852 7.74796 16.1688C6.96859 16.2367 6.6749 16.3449 6.58974 16.3842C6.56302 16.4474 6.51706 16.5911 6.47666 16.876C6.38228 17.5415 6.37751 18.4905 6.37751 20.0417ZM17.3867 16.348C17.3867 16.348 17.39 16.3564 17.3991 16.3674C17.3916 16.3526 17.3867 16.348 17.3867 16.348ZM6.61232 16.3423C6.61232 16.3423 6.60762 16.3467 6.60041 16.361C6.60908 16.3503 6.61232 16.3423 6.61232 16.3423Z"
                          fill="#767BFA"
                        />
                      </svg>
                    </div>
                  )}
                  content={() => componentRef.current}
                />
              </div>
              <div ref={componentRef}>
                <SalesDetails selectedSaleSummary={selectedSaleSummary} />
              </div>
            </div>
          </div>
        )}

        {active === 1 && (
          <>
            <PaymentModal
              dark={dark}
              open={open}
              setOpen={setOpen}
              activeCart={activeCart}
              cartTotal={cartTotal}
              discountPercent={discountPercent}
              createNewCart={createNewCart}
              cartRef={cartRef}
            />
            <div className="pos">
              <div className="px product-container">
                <div className="product-items">
                  {data.map((e, i) => (
                    <PosItem
                      key={i}
                      data={e}
                      type={e.category}
                      activeCart={activeCart}
                      cartIds={cartIds}
                      dark={dark}
                    />
                  ))}
                </div>
              </div>
              <div className="cart" ref={cartRef}>
                <div className="headersection flex al-ce jc-sp">
                  <div>{translate("cart")}</div>
                  <div>
                    <span>{translate("orderId")}: </span>
                    <span>0001</span>
                  </div>
                  <div onClick={() => setDeleteOpen(true)}>
                    <img
                      style={{ cursor: "pointer" }}
                      src={dark ? DeleteDark : Delete}
                      alt=""
                    />
                  </div>
                </div>
                <CartGrid
                  activeCart={activeCart}
                  setCartTotal={setCartTotal}
                  setCartIds={setCartIds}
                  cartIds={cartIds}
                  setDiscountPercent={setDiscountPercent}
                />
                <CartDetails
                  activeCart={activeCart}
                  cartTotal={cartTotal}
                  setOpen={setOpen}
                  discountPercent={discountPercent}
                  setDiscountPercent={setDiscountPercent}
                />
              </div>
            </div>
          </>
        )}

        {active === 2 && (
          <div className="content px">
            <div className="box receipt-container">
              <div className="headersection flex al-ce jc-sp pt-0">
                <div>{translate("receiptSettings")}</div>
              </div>
              <Container dark={dark} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sales;
