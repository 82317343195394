import { doc, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import cross from "../../assets/close.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import removeFromCart from "../../firebase/cart/removeFromCart";
import { db } from "../../firebase/index";
import "../css/CartGrid.scoped.css";
import translate from "../../i18n/translate";
const CartGrid = ({
  activeCart,
  setCartTotal,
  setCartIds,
  cartIds,
  setDiscountPercent,
}) => {
  const [data, updateData] = useState([]);
  const {
    rates,
    sign,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  let localSizing = localStorage.getItem("sizing");

  useEffect(() => {
    if (cartIds.length < 1) {
      setDiscountPercent(0);
    }
  }, [cartIds]);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      doc(db, "Stores", StoreId, "Cart", activeCart),
      async (snapShot) => {
        if (snapShot.data()) {
          updateData(snapShot.data().items);
        }
      }
    );
    return () => {
      inventoryListener();
    };
  }, [activeCart]);

  useEffect(() => {
    setCartTotal(
      Object.keys(data).reduce(
        (prev, next) =>
          parseFloat(
            prev + data[next].price * rates?.[localCurrency?.toUpperCase()]
          ),
        0
      )
    );
    setCartIds(Object.keys(data).map((e) => data[e].id));
  }, [data]);

  return (
    <div>
      <div className="headers-grid">
        <div>{translate("item")}</div>
        <div>{translate("sku")}</div>
        <div>{translate("size")}</div>
        <div>{translate("consignment")}</div>
        <div>{translate("price")}</div>
        <div />
      </div>
      <div className="items">
        {Object.keys(data).map((e, i) => (
          <div key={i} className="item">
            <img src={data[e].image} className="shoeimg" alt="" />
            <div>{data[e].sku}</div>
            <div>
              {(data[e].category === "sneakers") 
                ? sizingValues[localSizing][data[e].size]
                  ? sizingValues[localSizing][data[e].size]
                  : womenSizingValues[localSizing][data[e].size]
                  ? womenSizingValues[localSizing][data[e].size]
                  : girlSizingValues[localSizing][data[e].size]
                  ? girlSizingValues[localSizing][data[e].size]
                  : toddler[localSizing][data[e].size]
                  ? toddler[localSizing][data[e].size]
                  : young[localSizing][data[e].size]
                :data[e].size}
            </div>
            <div>{data[e].consigneeID ? data[e].consigneeID : "-"}</div>
            <div>
              {sign[localCurrency]}
              {(data[e].price * rates?.[localCurrency?.toUpperCase()]).toFixed(
                2
              )}
            </div>
            <img
              onClick={() => {
                setCartIds(cartIds.filter((k) => k !== e));
                removeFromCart(StoreId, activeCart, e);
              }}
              src={cross}
              className="close"
              alt={"del"}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CartGrid;
