import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
// import { countries } from "./coutryToCode";
import _ from "lodash";

export default async function getShippingFees(StoreId) {
  const addrSnap = await getDoc(doc(db, "Stores", StoreId));

  const addressData = addrSnap.data();

  // const country = _.find(
  //   countries,
  //   (c) => c.Name.toLowerCase() === addressData.country.toLowerCase()
  // );

  const origin = {
    line1: addressData.address ?? "",
    line2: addressData.address ?? "",
    city: addressData.city ?? "",
    state: addressData.state ?? "",
    postalCode: addressData.postalCode ?? "",
    countryCode: addressData.country,
  };

  // console.log(origin);

  let resp = await axios.post(
    "https://katch-358301.ew.r.appspot.com/shipping/calculate",
    // "http://localhost:80/shipping/calculate",
    {
      origin,
      destination: {
        line1: "61 Ubi Ave 2",
        line2: "#07-04A",
        city: "Singapore",
        state: "",
        postalCode: "408898",
        countryCode: "SG",
      },
    }
  );

  // console.log(resp);

  return resp.data;
}
