import React, { useContext, useEffect, useState } from "react";
import translate from "../../i18n/translate";
import DeleteConfirm from "../general/DeleteConfirm";
import AskModal from "./AskModal";
import TicketAskModal from "./TicketAskModal";
import "../css/TicketGrid.scoped.css";
import Button from "../general/Button";
import NewTicketModal from "./NewTicketModal";
import { Collapse } from "@mui/material";
import { ErrorToast } from "../general/ToastNotification";
import axios from "axios";
import { CurrencyContext } from "../../context/CurrencyContext";
import { doc, onSnapshot } from "firebase/firestore";
import { StoreId } from "../../Constants";
import { db } from "../../firebase";
import deleteTicket from "../../functions/tickets/deleteTicket";

const TicketGrid = ({
  dark,
  setTotal,
  selectedData,
  addModal,
  setAddModal,
}) => {
  const [ticketsData, setTicketsData] = useState([]);
  const [katchStatus, setKatchStatus] = useState(false);

  const [refetch, setRefetch] = useState(false);

  const toggleRefetch = () => {
    setRefetch(!refetch);
  };

  const token = localStorage.getItem("token");

  useEffect(() => {
    (async () => {
      try {
        const resp = await axios.post(
          "https://tickets.katch.gg/tickets/inventory",
          // "http://localhost:8080/tickets/inventory",
          {
            token,
          }
        );

        console.log(resp.data);
        if (resp.data.error) {
          throw new Error(resp.data.error);
        }
        setTicketsData(resp.data);
        let totalValue = resp.data.reduce((acc, next) => {
          return next.status === 2
            ? acc
            : next.status === 1
            ? acc + next.price
            : acc + next.purchasedPrice;
        }, 0);
        let totalUnrealizedProfit = resp.data.reduce((acc, next) => {
          return next.status !== 1
            ? acc
            : acc + (next.price - next.purchasedPrice);
        }, 0);
        setTotal((prev) => ({
          ...prev,
          ti: resp.data.filter((e) => e.status !== 2).length,
          tv: totalValue,
          tup: totalUnrealizedProfit,
        }));
      } catch (e) {
        ErrorToast("Error", "Some error occurred while fetching tickets");
      }
    })();
  }, [refetch]);

  useEffect(() => {
    const storeListener = onSnapshot(
      doc(db, "Stores", StoreId),
      async (snapShot) => {
        setKatchStatus(snapShot.data().katch ?? false);
      }
    );
    return () => {
      storeListener();
    };
  }, []);

  const [askMode, updateAskMode] = useState({});
  const [isAskModalOpen, updateIsAskModalOpen] = useState(false);
  const [askModalData, updateAskModalData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  function toggleAddModal() {
    setAddModal(!addModal);
  }

  return (
    <div>
      <DeleteConfirm
        dark={dark}
        isOpen={deleteModal}
        toggleOpen={setDeleteModal}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        func={async (storeId, deleteId) => {
          await deleteTicket(token, deleteId);
          toggleRefetch();
        }}
        msg={`ticket`}
      />
      <NewTicketModal
        dark={dark}
        isOpen={addModal}
        toggleState={(state) => {
          toggleAddModal(state);
          toggleRefetch();
        }}
        searchData={selectedData}
      />
      <TicketAskModal
        mode={askMode}
        dark={dark}
        isOpen={isAskModalOpen}
        toggleState={(state) => {
          updateIsAskModalOpen(state);
          toggleRefetch();
        }}
        data={askModalData}
      />
      <div className="headers-grid">
        <div>{translate("Ticket Category")}</div>
        <div>{translate("Name")}</div>
        <div style={{ textAlign: "center" }}>{translate("Purchase Price")}</div>
        <div style={{ textAlign: "center" }}>{translate("Time")}</div>
        <div style={{ textAlign: "center" }}>{translate("Venue")}</div>
        <div style={{ textAlign: "center" }}>{translate("Seat")}</div>
        <div style={{ textAlign: "center" }}>{translate("Listing Price")}</div>
        <div />
        <div />
      </div>
      <div className="items-container">
        <div className="items">
          {ticketsData
            .filter((e) => e.status !== 2)
            .map((item, index) => {
              return (
                <ItemGrid
                  data={item}
                  key={index}
                  dark={dark}
                  toggleAddModal={toggleAddModal}
                  updateAskMode={updateAskMode}
                  updateAskModalData={updateAskModalData}
                  setIsAskModalOpen={updateIsAskModalOpen}
                  setDeleteModal={setDeleteModal}
                  setDeleteId={setDeleteId}
                  katchStatus={katchStatus}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TicketGrid;

const ItemGrid = ({
  data,
  dark,
  toggleAddModal,
  setDeleteModal,
  setDeleteId,
  setIsAskModalOpen,
  updateAskMode,
  updateAskModalData,
}) => {
  const [toggle, setToggle] = useState(false);
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  function formatPrice(p) {
    return `$${Number(p.toFixed(2))}`;
  }

  return (
    <div className="item-grid" style={{ alignItems: "baseline" }}>
      <div>{data.category}</div>
      <div>{data.eventName}</div>
      <div style={{ textAlign: "center" }}>
        {formatPrice(data.purchasedPrice)}
      </div>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>{new Date(data.dateStart).toLocaleString()}</div>
        <Collapse in={toggle}>
          <>
            <div className="date">
              From{" "}
              {new Date(data.dateStart).toLocaleString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
            <div className="date">
              To{" "}
              {new Date(data.dateEnd).toLocaleString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
          </>
        </Collapse>
      </div>
      <div style={{ textAlign: "center" }}>
        {data.venueName}
        <Collapse in={toggle}>
          <div className="purple">{data.venueLocation}</div>
        </Collapse>
      </div>
      <div style={{ textAlign: "center" }}>
        <div>{data.section}</div>
        <Collapse in={toggle}>
          <>
            <div>Row: {data.row}</div>
            {data.seatNumbers.map((e) => (
              <div className="purple">Seat Number: {e}</div>
            ))}
          </>
        </Collapse>
      </div>
      <div style={{ textAlign: "center" }}>
        {data.status !== 2
          ? data.status === 0
            ? "Not Listed"
            : formatPrice(data.price)
          : "Sold"}
      </div>
      <div className="flex  jc-ce">
        <div className="btnWrap">
          <Button
            onClick={() => {
              updateAskMode(data.status === 1 ? "update" : "place");
              updateAskModalData({
                ...data,
              });
              setIsAskModalOpen(true);
            }}
            customClass={`ask-btn padding-side ${
              data.status === 1 ? "asked" : ""
            }`}
            text={data.status === 1 ? "Edit Ask" : "Ask"}
          />
        </div>

        <svg
          onClick={() => {
            if (data.status === 1) {
              return ErrorToast(
                "Error",
                "Delete the Ask for this ticket before deleting"
              );
            }
            setDeleteId(data._id);
            setDeleteModal(true);
          }}
          className="ml-10"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.15298 6.92168C7.81296 6.58166 7.26168 6.58166 6.92166 6.92168C6.58164 7.2617 6.58164 7.81298 6.92166 8.15299L8.76868 9.99999L6.92164 11.847C6.58162 12.187 6.58162 12.7383 6.92164 13.0783C7.26166 13.4183 7.81294 13.4183 8.15296 13.0783L10 11.2313L11.847 13.0782C12.187 13.4183 12.7383 13.4183 13.0783 13.0782C13.4183 12.7382 13.4183 12.1869 13.0783 11.8469L11.2313 9.99999L13.0783 8.15307C13.4183 7.81306 13.4183 7.26178 13.0783 6.92176C12.7382 6.58174 12.187 6.58174 11.8469 6.92176L10 8.76868L8.15298 6.92168Z"
            fill="#FA7676"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 10C0 18.235 1.765 20 10 20C18.235 20 20 18.235 20 10C20 1.765 18.235 0 10 0C1.765 0 0 1.765 0 10ZM1.66667 10C1.66667 12.0207 1.77712 13.5308 2.03142 14.6751C2.28153 15.8004 2.64807 16.4673 3.09039 16.9096C3.5327 17.3519 4.19958 17.7185 5.32494 17.9686C6.46923 18.2229 7.97929 18.3333 10 18.3333C12.0207 18.3333 13.5308 18.2229 14.6751 17.9686C15.8004 17.7185 16.4673 17.3519 16.9096 16.9096C17.3519 16.4673 17.7185 15.8004 17.9686 14.6751C18.2229 13.5308 18.3333 12.0207 18.3333 10C18.3333 7.97929 18.2229 6.46923 17.9686 5.32494C17.7185 4.19958 17.3519 3.5327 16.9096 3.09039C16.4673 2.64807 15.8004 2.28153 14.6751 2.03142C13.5308 1.77712 12.0207 1.66667 10 1.66667C7.97929 1.66667 6.46923 1.77712 5.32494 2.03142C4.19958 2.28153 3.5327 2.64807 3.09039 3.09039C2.64807 3.5327 2.28153 4.19958 2.03142 5.32494C1.77712 6.46923 1.66667 7.97929 1.66667 10Z"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
        </svg>
        <svg
          className="ml-10"
          onClick={() => setToggle(!toggle)}
          style={{
            transform: !toggle ? "rotate(180deg)" : "rotate(0deg)",
            transition: "300ms",
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 10C20 18.235 18.235 20 10 20C1.765 20 1.54301e-07 18.235 8.74228e-07 10C1.59415e-06 1.765 1.765 -1.59415e-06 10 -8.74228e-07C18.235 -1.54301e-07 20 1.765 20 10ZM18.3333 10C18.3333 12.0207 18.2229 13.5308 17.9686 14.6751C17.7185 15.8004 17.3519 16.4673 16.9096 16.9096C16.4673 17.3519 15.8004 17.7185 14.6751 17.9686C13.5308 18.2229 12.0207 18.3333 10 18.3333C7.97929 18.3333 6.46923 18.2229 5.32494 17.9686C4.19958 17.7185 3.5327 17.3519 3.09039 16.9096C2.64807 16.4673 2.28153 15.8004 2.03143 14.6751C1.77712 13.5308 1.66667 12.0207 1.66667 10C1.66667 7.97929 1.77712 6.46923 2.03143 5.32494C2.28153 4.19958 2.64807 3.5327 3.09039 3.09039C3.5327 2.64807 4.19958 2.28153 5.32494 2.03142C6.46923 1.77712 7.97929 1.66667 10 1.66667C12.0207 1.66667 13.5308 1.77712 14.6751 2.03143C15.8004 2.28153 16.4673 2.64807 16.9096 3.09039C17.3519 3.5327 17.7185 4.19958 17.9686 5.32494C18.2229 6.46923 18.3333 7.97929 18.3333 10Z"
            fill="#ABAFD1"
          />
          <path
            d="M9.41077 7.74408C9.7362 7.41864 10.2638 7.41864 10.5893 7.74408L13.9226 11.0774C14.248 11.4028 14.248 11.9305 13.9226 12.2559C13.5972 12.5814 13.0695 12.5814 12.7441 12.2559L10 9.51184L7.25594 12.2559C6.93051 12.5814 6.40287 12.5814 6.07743 12.2559C5.75199 11.9305 5.75199 11.4028 6.07743 11.0774L9.41077 7.74408Z"
            fill="#767BFA"
          />
        </svg>
      </div>
    </div>
  );
};
