import {
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  increment,
  query,
  where,
  limit,
  collection,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { db } from "../index";

import encodeKey from "../util/encodeKey";

export default async function deleteInventory(storeId, itemId) {
  const docRef = doc(db, "Stores", storeId, "Inventory", itemId);
  const docSnap = await getDoc(docRef);

  const docData = docSnap.data();

  const counterRef = doc(db, "Stores", storeId);

  const stocksRef = doc(db, "Stores", storeId, "Products", docData.sku);
  const incrementStocks = increment(-1);

  const decrementCount = increment(-1);
  const decrementValue = increment(docData.purchasePrice * -1);

  await deleteDoc(docRef);
  await updateDoc(counterRef, {
    totalItems: decrementCount,
    totalValue: decrementValue,
  });

  const inventoryRef = collection(db, "Stores", storeId, "Inventory");

  const q = query(
    inventoryRef,
    where("sku", "==", docData.sku),
    where("type", "==", "normal"),
    orderBy("markUp", "asc"),
    limit(1)
  );

  const querySnapshot = await getDocs(q);

  let minMarkup = 0;
  let condition = "";

  const size = encodeKey(docData.size.toString());

  if (querySnapshot.size > 0) {
    const doc = querySnapshot.docs[0];
    minMarkup = doc.data().markUp;
    condition = doc.data().condition
  }

  //Lets figure out the new mark up price.

  const updater = {
    [`stocks.${encodeKey(size)}`]: incrementStocks,
    [`markup.${encodeKey(size)}`]: minMarkup,
    [`condition.${encodeKey(size)}`]: condition,
  };

  await updateDoc(stocksRef, updater);
}
