import {
  getDocs,
  getDoc,
  query,
  where,
  limit,
  collection,
  doc,
  orderBy,
} from "firebase/firestore";
import { db } from "../../index";


export default async function dailyPerformance(storeId, staffId) {
  const logsRef = collection(db, "Stores", storeId, "Staff", "Shifts", "Logs");

  let result = {};
  const daily = query(
    logsRef,
    where("staffId", "==", staffId),
    orderBy("date", "desc"),
    limit(1)
  );

  const dailySnapshot = await getDocs(daily);
  if (dailySnapshot.size === 0) {
      return new Error("No logs yet!")
  }
  const dailyHours = Number(dailySnapshot.docs[0].data().totalTime);
  // console.log(dailyHours);
  result["daily"] = dailyHours;

  //weekly performance
  await weekly(logsRef, staffId).then((weekly) => {
    result["weekly"] = weekly;
  });

  //monthly performance
  await monthly(logsRef, staffId).then((monthly) => {
    result["monthly"] = monthly;
  });

  //yearly performance
  await yearly(logsRef, staffId).then((yearly) => {
    result["yearly"] = yearly;
  });

  const accountRef = doc(db, "Stores", storeId, "Staff", "Staffs", "Accounts", staffId);
  const accountQuery = query(
    accountRef
  );
  const accountData = await getDoc(accountQuery);
  //console.log(accountData);
  return {...result, ...totalWorkingHours(accountData.data())};
}

const weekly = async (logsRef, staffId) => {
  const weekly = query(
    logsRef,
    where("staffId", "==", staffId),
    orderBy("date", "desc"),
    limit(7)
  );

  const weeklySnapshot = await getDocs(weekly);
  const weeklyData = [];
  weeklySnapshot.docs.forEach((data) => {
    weeklyData.push(data.data());
  });

  const weeeklyHours = weeklyData.reduce((weeeklyHours, data) => {
    weeeklyHours += Number(data.totalTime);
    return weeeklyHours;
  }, 0);

  // console.log(weeeklyHours);
  return weeeklyHours;
};

const monthly = async (logsRef, staffId) => {
  const monthly = query(
    logsRef,
    where("staffId", "==", staffId),
    orderBy("date", "desc"),
    limit(30)
  );

  const monthlySnapshot = await getDocs(monthly);
  const monthlyData = [];
  monthlySnapshot.docs.forEach((data) => {
    monthlyData.push(data.data());
  });

  const monthlyHours = monthlyData.reduce((monthlyHours, data) => {
    monthlyHours += Number(data.totalTime);
    return monthlyHours;
  }, 0);
  // console.log(monthlyHours);
  return monthlyHours;
};

const yearly = async (logsRef, staffId) => {
  const yearly = query(
    logsRef,
    where("staffId", "==", staffId),
    orderBy("date", "desc"),
    limit(30)
  );

  const yearlySnapshot = await getDocs(yearly);
  const yearlyData = [];
  yearlySnapshot.docs.forEach((data) => {
    yearlyData.push(data.data());
  });

  const yearlyHours = yearlyData.reduce((yearlyHours, data) => {
    yearlyHours += Number(data.totalTime);
    return yearlyHours;
  }, 0);
  // console.log(yearlyHours);
  return yearlyHours;
};

function validTimeRange(str) {
  let regex = /(0?[0-9]|1[0-9]|2[0-3]):(0?[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])+[ap]m-(0?[0-9]|1[0-9]|2[0-3]):(0?[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])+[ap]m/i;
  return regex.test(str);
}
function timeDiff(timeRange) {
  const [start, end] = timeRange.split('-');
  const startTime = new Date(2000, 0, 1, (start.slice(-2) === 'pm')?parseInt(start.split(':')[0])+12:parseInt(start.split(':')[0]), parseInt(start.split(':')[1].slice(0,2)));
  const endTime = new Date(2000, 0, 1, (end.slice(-2) === 'pm')?parseInt(end.split(':')[0])+12:parseInt(end.split(':')[0]), parseInt(end.split(':')[1].slice(0,2)));
  return (endTime-startTime)/3600000;
}
function totalWorkingHours(data) {
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  
  const today = data[days[(new Date()).getDay()]];
  const dailyTotal = (validTimeRange(today)) ? timeDiff(today) : 0;
  
  let weeklyTotal = 0;
  days.forEach((day) => {
    weeklyTotal += (validTimeRange(data[day])) ? timeDiff(data[day]) : 0;
  })
  let monthlyTotal = weeklyTotal * 4;
  let yearlyTotal = weeklyTotal * 52;
  return {
    dailyTotal: dailyTotal,
    weeklyTotal: weeklyTotal,
    monthlyTotal: monthlyTotal,
    yearlyTotal: yearlyTotal
  }
}
