import React, { useContext, useState, useEffect } from "react";
import { CurrencyContext } from "../../context/CurrencyContext";
import "../css/StaffSumamry.scoped.css";
import numberWithCommas from "../utils/numberWithCommas";
import { StoreId } from "../../Constants";
import checkInOutDetails from "../../firebase/staff/logs/clockInOutDetails";
import dailyPerformance from "../../firebase/staff/work/dailyPerformance";
import translate from "../../i18n/translate";
export default function StaffSumarry({
  setPerformance,
  active,
  updateActive,
  setSelectedStaff,
  setLogData,
  displayData,
  setStatus,
}) {
  const { sign } = useContext(CurrencyContext);
  // const [data, updateData] = useState([]);

  let localCurrency = localStorage.getItem("currency");

  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  return (
    <div>
      <div className="headersgrid">
        <div>{translate("id")}</div>
        <div>{translate("clockedInTime")}</div>
        <div>{translate("clockedOutTime")}</div>
        <div>{translate("totalHours")}</div>
        <div>{translate("numberOfSales")}</div>
        <div>{translate("figures")}</div>
        <div>{translate("status")}</div>
      </div>

      <div className="items">
        {displayData.map((e, i) => (
          <div
            key={e.staffId}
            onClick={async () => {
              setStatus(e.status);
              setSelectedStaff(e);
              updateActive(i);
              setLogData(await checkInOutDetails(StoreId, e.staffId));
              setPerformance(await dailyPerformance(StoreId, e.staffId));
            }}
            className={`${active === i ? "active-item" : ""} item`}
          >
            <div>{e.staffId}</div>
            <div>{e.clockingIn ? formatAMPM(new Date(e.clockingIn)) : "-"}</div>
            <div>
              {e.clockingOut ? formatAMPM(new Date(e.clockingOut)) : "-"}
            </div>
            <div>{e.totalHours}</div>
            <div className="center">{e.numberOfSales}</div>
            <div>
              {sign[localCurrency]}
              {numberWithCommas(e.figures.toFixed(2))}
            </div>
            <div className={`${e.status === "Active" ? "green" : "red"}`}>
              {e.status}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
