import { Backdrop, Box, Fade, Modal } from "@mui/material";
import Close from "../../assets/close.svg";
import "../css/Modal.scoped.css";
import CloseDark from "../../assets/closedark.svg";
import Input from "../general/Input";
import Select from "../general/Select";
import GetTranslateString from "../../i18n/translateString";
import translate from "../../i18n/translate";
import Loader from "../general/Loader";
import seatLookup from "../../assets/seatLookup.png";
import Button from "../general/Button";
import addDark from "../../assets/addark.svg";
import add from "../../assets/add.svg";
import { useContext, useState } from "react";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import addNewTicketItem from "../../functions/tickets/addNewTicketItem";
import { CurrencyContext } from "../../context/CurrencyContext";
import uploadTicket from "../../functions/tickets/uploadTicket";

const NewTicketModal = ({ dark, isOpen, toggleState, searchData }) => {
  const [overview, setOverview] = useState({
    tCategory: searchData?.eventType,
    name: searchData?.name,
    purDate: "",
    dStart: searchData?.dateStart,
    dEnd: searchData?.dateEnd,
    venue: searchData?.venueName,
    categories: "",
    row: "",
    ticketType: "",
    quantity: 1,
    pPrice: "",
  });

  function handleChange(e) {
    if (e.target.name === "quantity") {
      return setOverview({
        ...overview,
        [e.target.name]:
          e.target.value && e.target.value > 0
            ? parseInt(e.target.value)
            : null,
      });
    } else {
      return setOverview({ ...overview, [e.target.name]: e.target.value });
    }
  }

  const [uploads, setUploads] = useState([{ file: "", path: "" }]);
  const [uploadLoading, setUploadLoading] = useState(false);

  const { rates, sign } = useContext(CurrencyContext);

  let localCurrency = localStorage.getItem("currency");
  let token = localStorage.getItem("token");

  const [tickets, setTickets] = useState([""]);

  const [loading, toggleLoading] = useState(false);

  const handleTicketChange = (index, num) => {
    let newTickets = [...tickets];
    newTickets[index] = num;
    setTickets(newTickets);
  };

  const handleUpload = async (index) => {
    if (!loading) {
      let newUploads = uploads;
      toggleLoading(true);
      let [fileHandle] = await window.showOpenFilePicker({
        types: [
          {
            description: "Images",
            accept: {
              "application/pdf": [".pdf"],
            },
          },
        ],
        excludeAcceptAllOption: true,
        multiple: false,
      });
      toggleLoading(false);
      const fileData = await fileHandle.getFile();

      toggleLoading(true);
      const path = await uploadTicket(token, fileData);

      newUploads[index] = {
        file: fileData,
        path,
      };
      setUploads([...newUploads]);
      toggleLoading(false);
    }
  };

  const UploadPDF = ({ index }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            cursor: "pointer",
            color: "#767BFA",
            maxWidth: "20ch",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          key={index}
          onClick={async () => {
            try {
              handleUpload(index);
            } catch (e) {
              toggleLoading(false);
              ErrorToast("Error", e.message);
            } finally {
              toggleLoading(false);
            }
          }}
        >
          {uploads[index]?.file
            ? uploads[index].file.name
            : `Upload Ticket ${index + 1}`}
        </div>
        <Input
          name="name"
          type={"number"}
          customClass="upload-ticket-input"
          value={tickets[index]}
          updateValue={(e) => {
            handleTicketChange(index, e.target.value);
          }}
          placeholder={GetTranslateString("Input Seat Number")}
        />
      </div>
    );
  };

  const handleClose = () => {
    toggleState(false);
    toggleLoading(false);
    setTickets([""]);
    setUploads([{ file: "", path: "" }]);
    setOverview({
      ...overview,
      purDate: "",
      categories: "",
      row: "",
      ticketType: "",
      quantity: 1,
      pPrice: "",
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "90%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    overflowY: "scroll",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  return (
    <div>
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="header">
              <div>Add New Item...</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div>
              <div className="three-grid">
                <div>
                  <div className="label">{translate("Event Category")}</div>
                  {/* <Select
                    name="tCategory"
                    disabled={true}
                    type={"text"}
                    value={searchData?.eventType || overview.tCategory}
                    dark={dark}
                    updateValue={handleChange}
                    placeholder={GetTranslateString("Ticket category")}
                    options={[
                      {
                        label: searchData?.eventType || "",
                        value: searchData?.eventType || "",
                      },
                    ]}
                  /> */}
                  <ReadOnlyField text={searchData?.eventType} />
                </div>

                <div>
                  <div className="label">{translate("Name")}</div>
                  {/* <Input
                    readOnly={true}
                    name="name"
                    type={"text"}
                    dark={dark}
                    value={searchData?.name}
                    updateValue={handleChange}
                    placeholder={GetTranslateString("Name")}
                  /> */}

                  <ReadOnlyField text={searchData?.name} />
                </div>

                <div>
                  <div className="label">{translate("purchasedDate")}</div>
                  <Input
                    name="purDate"
                    type={"date"}
                    dark={dark}
                    // value={fields.purDate}
                    updateValue={handleChange}
                    placeholder={GetTranslateString("enterDate")}
                  />
                </div>
              </div>
              <div className="three-grid">
                <div>
                  <div className="label">{translate("Venue")}</div>
                  {/* <Input
                    name="venue"
                    readOnly={true}
                    type={"text"}
                    value={overview.venue}
                    updateValue={handleChange}
                    placeholder={GetTranslateString("Venue")}
                  /> */}
                  <ReadOnlyField text={searchData?.venueName} />
                </div>

                <div>
                  <div className="label">{translate("Date start")}</div>
                  {/* <Input
                    readOnly={true}
                    name="dStart"
                    type={"date"}
                    value={overview.dStart}
                    updateValue={handleChange}
                    placeholder={GetTranslateString("Date Start")}
                  /> */}
                  <ReadOnlyField
                    text={new Date(searchData?.dateStart).toLocaleString(
                      "en-US",
                      {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  />
                </div>

                <div>
                  <div className="label">{translate("Date End")}</div>
                  {/* <Input
                    readOnly={true}
                    name="dEnd"
                    type={"date"}
                    dark={dark}
                    value={overview.dEnd}
                    updateValue={handleChange}
                    placeholder={GetTranslateString("Date End")}
                  /> */}
                  <ReadOnlyField
                    text={new Date(searchData?.dateEnd).toLocaleString(
                      "en-US",
                      {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  />
                </div>
              </div>
              <div className="form-image-container">
                <div className={"span2"}>
                  <img
                    src={searchData?.venueImg}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                </div>

                <div className="image-side-input">
                  <div>
                    <div className="label">{translate("Categories")}</div>
                    <Select
                      name="categories"
                      dark={dark}
                      value={overview.categories}
                      updateValue={handleChange}
                      placeholderText={GetTranslateString("Categories")}
                      options={
                        searchData?.categories.map((d) => ({
                          label: d,
                          value: d,
                        })) || []
                      }
                    />
                  </div>
                  <div>
                    <div className="label">{translate("Row")}</div>
                    <Input
                      name="row"
                      type={"text"}
                      //   value={fields.styleCode}
                      updateValue={handleChange}
                      placeholder={GetTranslateString("Enter Row no.")}
                    />
                  </div>
                  <div>
                    <div className="label">{translate("Section")}</div>
                    <Input
                      name="section"
                      type={"text"}
                      //   value={fields.styleCode}
                      updateValue={handleChange}
                      placeholder={GetTranslateString("Enter Section")}
                    />
                  </div>
                  <div>
                    <div className="label">{translate("Ticket Type")}</div>
                    <Select
                      name="ticketType"
                      dark={dark}
                      value={overview.ticketType}
                      updateValue={handleChange}
                      placeholderText={GetTranslateString("Ticket Type")}
                      options={[
                        {
                          label: GetTranslateString("E-Ticket"),
                          value: "E-Ticket",
                        },
                        // {
                        //   label: GetTranslateString("Paper Ticket"),
                        //   value: "paperTicket",
                        // },
                      ]}
                    />
                  </div>{" "}
                  <div>
                    <div className="label">{translate("Choose Quantity")}</div>
                    {/* <Select
                      name="quantity"
                      dark={"dark"}
                      //   value={fields.styleCode}
                      updateValue={handleChange}
                      placeholder={GetTranslateString("Choose Quantity")}
                      options={[
                        {
                          label: GetTranslateString("1"),
                          value: "1",
                        },
                        {
                          label: GetTranslateString("2"),
                          value: "2",
                        },
                      ]}
                    /> */}
                    <Input
                      name="quantity"
                      dark={dark}
                      type={"number"}
                      value={overview.quantity}
                      updateValue={handleChange}
                      placeholder={GetTranslateString(
                        "Enter Number of tickets"
                      )}
                    />
                  </div>{" "}
                  <div>
                    <div className="label">
                      {translate("Purchase Price(US$)")}
                    </div>
                    <Input
                      name="pPrice"
                      dark={dark}
                      type={"number"}
                      value={overview.pPrice}
                      updateValue={handleChange}
                      placeholder={GetTranslateString("Enter Amount")}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <div className="label">
                    {translate("Upload E-Ticket (pdf)*")}
                  </div>
                  <div className="ml-10" style={{ color: "#767BFA" }}>
                    Upload your ticket according to number
                  </div>
                </div>
              </div>
              <div className="upload-ticket-container">
                <div className="list-item upload-ticket-box">
                  {Array.from(
                    Array(
                      !overview.quantity
                        ? 1
                        : overview.quantity > 8
                        ? 4
                        : Math.ceil(overview.quantity / 2)
                    ).keys()
                  ).map((d, i) => {
                    return <UploadPDF index={d} />;
                  })}
                </div>
                {overview.quantity > 1 && (
                  <div className="list-item upload-ticket-box">
                    {Array.from(
                      Array(
                        overview.quantity > 8
                          ? 4
                          : Math.floor(overview.quantity / 2)
                      ).keys()
                    ).map((d, i) => {
                      return (
                        <UploadPDF
                          index={
                            Math.ceil(
                              (overview.quantity > 8 ? 8 : overview.quantity) /
                                2
                            ) + d
                          }
                        />
                      );
                    })}
                  </div>
                )}
              </div>
              {overview.quantity > 8 && (
                <div className="three-grid">
                  <span className=" span2 red">
                    Max 8 tickets can be uploaded
                  </span>
                </div>
              )}

              <div className="btn-container">
                <div>
                  <Loader loading={loading} />
                </div>
                <Button
                  text={translate("Add New")}
                  imgSrc={<img src={dark ? addDark : add} alt="import icon" />}
                  onClick={async () => {
                    try {
                      if (!loading) {
                        toggleLoading(true);
                        await addNewTicketItem(token, {
                          showId: searchData.id,
                          category: overview.categories,
                          purchasedPrice: Number(
                            parseFloat(overview.pPrice).toFixed(2)
                          ),
                          purchasedDate: overview.purDate,
                          quantity: parseInt(overview.quantity),
                          uploadedTickets: uploads,
                          seatNumbers: tickets,
                          row: overview.row,
                          section: overview.section,
                          ticketType: "E-Ticket",
                        });

                        handleClose();
                        SuccessToast("Success", "New ticket item added");
                      }
                    } catch (e) {
                      ErrorToast(
                        "Error",
                        // "Some error occurred while adding the ticket"
                        e.message
                      );
                    } finally {
                      toggleLoading(false);
                    }
                  }}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

const ReadOnlyField = ({ text }) => {
  return <div className={"readOnly"}>{text}</div>;
};

export default NewTicketModal;
