import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useContext, useEffect, useState } from "react";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import Close from "../../assets/close.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import editExpense from "../../firebase/expense/editExpense";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import Select from "../general/Select";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

export default function EditExpenseModal({
  isOpen,
  toggleState,
  data,
  dark,
  updateEditModalData,
}) {
  const [fields, setFields] = useState(data);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  useEffect(() => {
    setFields(data);
  }, [data]);
  const { rates } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={() => toggleState(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="header">
              <div>{translate("editingExpenseItems")}...</div>
              <img
                onClick={() => {
                  toggleState(false);
                  updateEditModalData({});
                }}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("expenseName")}</div>
                <Input
                  name="name"
                  type={"text"}
                  value={fields.name}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterName")}
                />
              </div>

              <div>
                <div className="label">{translate("spentDate")}</div>
                <Input
                  name="spentDate"
                  type={"date"}
                  dark={dark}
                  value={fields.spentDate}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterDate")}
                />
              </div>

              <div>
                <div className="label">{translate("amountSpent")}</div>
                <Input
                  name="amountSpent"
                  type={"number"}
                  value={fields.amountSpent}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterAmount")}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div>
                  <div className="label">{translate("tax")}</div>
                  <Input
                    name="tax"
                    type={"number"}
                    value={fields.tax}
                    updateValue={handleChange}
                    placeholder={GetTranslateString("enterAmount")}
                  />
                </div>
              </div>

              <div>
                <div className="label">{translate("shipping")}</div>
                <Input
                  name="shipping"
                  type={"number"}
                  value={fields.shipping}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterAmount")}
                />
              </div>

              <div>
                <div className="label">{translate("duration")}</div>
                <Select
                  dark={dark}
                  name="duration"
                  value={fields.duration}
                  updateValue={handleChange}
                  options={[
                    {
                      label: translate("weekly"),
                      value: "Weekly",
                    },
                    {
                      label: translate("monthly"),
                      value: "Monthly",
                    },
                    {
                      label: translate("oneTime"),
                      value: "One-time",
                    },
                  ]}
                  placeholderText={GetTranslateString("chooseDuration")}
                  none={false}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("quantity")}</div>
                <Input
                  name="quantity"
                  type={"text"}
                  value={fields.quantity}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterAmount")}
                />
              </div>

              <div>
                <div className="label">{translate("transactionId")}</div>
                <Input
                  name="transactionId"
                  type={"text"}
                  value={fields.transactionId}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterInfo")}
                />
              </div>

              <div>
                <div className="label">{translate("notes")}</div>
                <Input
                  name="notes"
                  type={"text"}
                  value={fields.notes}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterNotes")}
                />
              </div>
            </div>

            <div className="btns w-full jus-end mt-10">
              <div className="ml-10">
                <Button
                  imgSrc={<img src={dark ? AddDark : Add} alt="" />}
                  onClick={async () => {
                    try {
                      await editExpense(StoreId, data.id, {
                        name: fields.name.trim(),
                        spentDate: fields.spentDate,
                        amountSpent: parseFloat(
                          (
                            fields.amountSpent /
                            rates?.[localCurrency?.toUpperCase()]
                          ).toFixed(2)
                        ),
                        tax: parseFloat(fields.tax),
                        shipping: parseFloat(
                          (
                            fields.shipping /
                            rates?.[localCurrency?.toUpperCase()]
                          ).toFixed(2)
                        ),
                        duration: fields.duration,
                        quantity: parseInt(fields.quantity),
                        transactionId: fields.transactionId.trim(),
                        notes: fields.notes.trim(),
                      });
                    } catch (e) {
                      ErrorToast(translate("error"), e.message);
                      return;
                    }
                    SuccessToast(translate("success"), translate("itemEdited"));
                    toggleState(false);
                  }}
                  text={translate("save")}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
