import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../index";

export default async function createDiscount(
  storeId,
  discountCode,
  discountPercentage,
  data
) {
  //Check the doc does not exist before making it.

  if (!discountCode) {
    throw new Error("Name field cannot be empty.");
  }

  if (!discountPercentage && isNaN(discountPercentage)) {
    throw new Error("Value must me a number.");
  }

  if (!data.requirementsAmt && isNaN(data.requirementsAmt)) {
    throw new Error("Requirements Amt must me a number.");
  }

  if (!data.startDate) {
    throw new Error("Start Date must be specified.");
  }

  if (!data.endDate) {
    throw new Error("End Date must be specified.");
  }

  let endDate = new Date(data.endDate.replace("-", ","));
  endDate = endDate.getTime() + hoursToMills(data.endTime);

  let startDate = new Date(data.startDate.replace("-", ","));
  startDate = startDate.getTime() + hoursToMills(data.startTime);

  if (endDate < startDate) {
    throw new Error("End Date must be greater than start date.");
  }

  const docRef = doc(db, "Stores", storeId, "Discounts", discountCode);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    await setDoc(doc(db, "Stores", storeId, "Discounts", discountCode), {
      ...data,
      discountPercentage: discountPercentage,
    });
  }
}

function hoursToMills(time) {
  let timeParts = time.split(":");
  return ((+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000));
}