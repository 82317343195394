import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import produce from "immer";
import React, { useContext, useEffect, useState } from "react";
import { CurrencyContext } from "../../context/CurrencyContext";
import "../css/NewPriceItems.scoped.css";
import cross from "../../assets/red-close.svg";
import UpArrow from "../../assets/uparrow.svg"
import DownArrow from "../../assets/downarrow.svg"
import Button from "../general/Button";
import Input from "../general/Input";
import translate from "../../i18n/translate";
import { doc, collection, onSnapshot, getDoc, setDoc } from "firebase/firestore";
import updatePrice from "../../firebase/priceAlert/updatePrice";
import deleteAlert from "../../firebase/priceAlert/deleteAlert"
import { db } from "../../firebase/index";
import { StoreId } from "../../Constants";
import { SuccessToast, ErrorToast } from "../general/ToastNotification";

export default function BasicTable({ settings }) {
  const {
    rates,
    sign,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  let localSizing = localStorage.getItem("sizing");

  const [notifications, setNotifications] = useState([]);
  const [products, updateProducts] = useState([]);

  const alertTime = {
    daily: 24*3600000,
    monthly: 30*24*3600000,
    threeMonths: 3*30*24*3600000,
    sixMonths: 6*30*24*3600000,
    yearly: 365*24*3600000
  }

  useEffect(() => {
    const updateListener = onSnapshot(
      collection(db, "Stores", StoreId, "Notifications"),
      async (snapShot) => {
        let notData = [];
        snapShot.docs.forEach((e) => {
          let sArr = []
          Object.keys(e.data().sizes).forEach((s) => {
            sArr.push({
              timestamp: e.data().timestamp,
              size: s,
              newLastSale: e.data().sizes[s].newLastSale,
              oldLastSale: e.data().sizes[s].oldLastSale,
              platform: e.data().platform,
              image: e.data().image,
              sku: e.id
            })
          })
          notData.push(...sArr);
        })

        const today = (new Date()).getTime();
        let dateNotified = null;
        if (!settings.lastNotifiedDate) {
          const storeRef = doc(db, "Stores", StoreId);
          const storeSnap = await getDoc(storeRef);
          dateNotified = storeSnap.data().dateJoined;
        } else {
          dateNotified = settings.lastNotifiedDate;
        }
        // console.log(dateNotified)
        // console.log((today - dateNotified) >= alertTime[settings.alertType])
        // if ((today - dateNotified) >= alertTime[settings.alertType]) {
          // notData = notData.filter((e) => (today - e.timestamp) >= alertTime[settings.alertType])
          setNotifications(notData);

          await setDoc(doc(db, "Stores", StoreId, "PriceAlerts", "Settings"), {
            lastNotifiedDate: today
          },
            { merge: true })
        // }


      }
    )
    return () => {
      updateListener();
    }
  }, [])

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 260, overflow: "auto" }} className="table">
      <Table stickyHeader sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead>
          <TableRow className="shadow-none">
          <TableCell align="left">{translate("items")}</TableCell>
            <TableCell align="left">{translate("sku")}</TableCell>
            <TableCell align="left">{translate("size")}</TableCell>
            <TableCell align="left">{translate("platform")}</TableCell>
            <TableCell align="center">{translate("Old Last Sale")}</TableCell>
            <TableCell align="center">{translate("New Last Sale")}</TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications.map((item, i) => (
            <TableRow
            key={i}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell className="sku">
              {<img src={item.image} alt="img" />}
            </TableCell>
            <TableCell align="left">{item.sku}</TableCell>
            <TableCell align="left">
            {item.size}
            </TableCell>
            <TableCell align="left" className="capitalize">
              {item.platform}
            </TableCell>
            <TableCell align="center">
              {sign[localCurrency]}
              {(item.oldLastSale * rates?.[localCurrency?.toUpperCase()]).toFixed(
                2
              )}
            </TableCell>
            <TableCell align="center" className={(item.oldLastSale > item.newLastSale)? "red": "green"}>
              <img src={(item.oldLastSale > item.newLastSale) ? DownArrow : UpArrow} alt="arrow" height="12" style={{paddingRight: "0.465rem"}}/> 
              {sign[localCurrency]}
              {(item.newLastSale * rates?.[localCurrency?.toUpperCase()]).toFixed(
                2
              )}
            </TableCell>
            <TableCell align="center">
              <Button
                customClass={"priceUpdate-btn"}
                onClick={async () => {
                  try {
                    await updatePrice(StoreId, item.sku, item.size, item.newLastSale);
                    SuccessToast("Success!!", "Price updated successfully")
                  } catch (e){
                    ErrorToast("Price Update Error!!", e.message)
                  }
                }}
                text={"Update"}
              />
            </TableCell>
            <TableCell align="center">
              <img
                onClick={async () => {
                  try {
                    await deleteAlert(StoreId, item.sku, item.size);
                  } catch (e){
                    ErrorToast("Error!!", e.message)
                  }
                }}
                src={cross}
                className="close"
                alt={"del"}
                width="18px"
              />
            </TableCell>
          </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


