import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

const CurrencyContext = createContext();
const { Provider } = CurrencyContext;

const CurrencyProvider = ({ children }) => {
  const [rates, setRates] = useState();

  const sizingValues = {
    us: {
      "4": 4,
      "4.5": 4.5,
      "5": 5,
      "5.5": 5.5,
      "6": 6,
      "6.5": 6.5,
      "7": 7,
      "7.5": 7.5,
      "8": 8,
      "8.5": 8.5,
      "9": 9,
      "9.5": 9.5,
      "10": 10,
      "10.5": 10.5,
      "11": 11,
      "11.5": 11.5,
      "12": 12,
      "12.5": 12.5,
      "13": 13,
      "13.5": 13.5,
      "14": 14,
      "14.5": 14.5,
      "15": 15,
      "16": 16,
      "17": 17,
    },

    uk: {
      "4": 4 - 0.5,
      "4.5": 4.5 - 0.5,
      "5": 5 - 0.5,
      "5.5": 5.5 - 0.5,
      "6": 6 - 0.5,
      "6.5": 6.5 - 0.5,
      "7": 7 - 0.5,
      "7.5": 7.5 - 0.5,
      "8": 8 - 0.5,
      "8.5": 8.5 - 0.5,
      "9": 9 - 0.5,
      "9.5": 9.5 - 0.5,
      "10": 10 - 0.5,
      "10.5": 10.5 - 0.5,
      "11": 11 - 0.5,
      "11.5": 11.5 - 0.5,
      "12": 12 - 0.5,
      "12.5": 12.5 - 0.5,
      "13": 13 - 0.5,
      "13.5": 13.5 - 0.5,
      "14": 14 - 0.5,
      "14.5": 14.5 - 0.5,
      "15": 15 - 0.5,
      "16": 16 - 0.5,
      "17": 17 - 0.5,
    },

    eu: {
      "4": 36,
      "4.5": 36.5,
      "5": 37.5,
      "5.5": 38,
      "6": 38.5,
      "6.5": 39,
      "7": 40,
      "7.5": 40.5,
      "8": 41,
      "8.5": 42,
      "9": 42.5,
      "9.5": 43,
      "10": 44,
      "10.5": 44.5,
      "11": 45,
      "11.5": 46,
      "12": 46.5,
      "12.5": 47,
      "13": 47.5,
      "13.5": 48,
      "14": 48.5,
      "14.5": 49,
      "15": 49.5,
      "16": 50.5,
      "17": 51.5,
    },
  };

  const womenSizingValues = {
    us: {
      "5W": "5W",
      "5.5W": "5.5W",
      "6W": "6W",
      "6.5W": "6.5W",
      "7W": "7W",
      "7.5W": "7.5W",
      "8W": "8W",
      "8.5W": "8.5W",
      "9W": "9W",
      "9.5W": "9.5W",
      "10W": "10W",
      "10.5W": "10.5W",
      "11W": "11W",
      "11.5W": "11.5W",
      "12W": "12W",
      "12.5W": "12.5W",
      "13W": "13W",
      "13.5W": "13.5W",
      "14W": "14W",
      "14.5W": "14.5W",
      "15W": "15W",
    },

    uk: {
      "5W": (5 - 2.5).toString() + "W",
      "5.5W": (5.5 - 2.5).toString() + "W",
      "6W": (6 - 2.5).toString() + "W",
      "6.5W": (6.5 - 2.5).toString() + "W",
      "7W": (7 - 2.5).toString() + "W",
      "7.5W": (7.5 - 2.5).toString() + "W",
      "8W": (8 - 2.5).toString() + "W",
      "8.5W": (8.5 - 2.5).toString() + "W",
      "9W": (9 - 2.5).toString() + "W",
      "9.5W": (9.5 - 2.5).toString() + "W",
      "10W": (10 - 2.5).toString() + "W",
      "10.5W": (10.5 - 2.5).toString() + "W",
      "11W": (11 - 2.5).toString() + "W",
      "11.5W": (11.5 - 2.5).toString() + "W",
      "12W": (12 - 2.5).toString() + "W",
      "12.5W": (12.5 - 2.5).toString() + "W",
      "13W": (13 - 2.5).toString() + "W",
      "13.5W": (13.5 - 2.5).toString() + "W",
      "14W": (14 - 2.5).toString() + "W",
      "14.5W": (14.5 - 2.5).toString() + "W",
      "15W": (15 - 2.5).toString() + "W",
    },

    eu: {
      "5W": "35.5W",
      "5.5W": "36W",
      "6W": "36.5W",
      "6.5W": "37.5W",
      "7W": "38W",
      "7.5W": "38.5W",
      "8W": "39W",
      "8.5W": "40W",
      "9W": "40.5W",
      "9.5W": "41W",
      "10W": "42W",
      "10.5W": "42.5W",
      "11W": "43W",
      "11.5W": "44W",
      "12W": "44.5W",
      "12.5W": "45W",
      "13W": "45.5W",
      "13.5W": "46W",
      "14W": "47W",
      "14.5W": "47.5W",
      "15W": "48W",
    },
  };

  const girlSizingValues = {
    us: {
      "3.5Y": "3.5Y",
      "4Y": "4Y",
      "4.5Y": "4.5Y",
      "5Y": "5Y",
      "5.5Y": "5.5Y",
      "6Y": "6Y",
      "6.5Y": "6.5Y",
      "7Y": "7Y",
    },

    uk: {
      "3.5Y": (3.5 - 0.5).toString() + "Y",
      "4Y": (4 - 0.5).toString() + "Y",
      "4.5Y": (4.5 - 0.5).toString() + "Y",
      "5Y": (5 - 0.5).toString() + "Y",
      "5.5Y": (5.5 - 0.5).toString() + "Y",
      "6Y": (6 - 0.5).toString() + "Y",
      "6.5Y": (6.5 - 0.5).toString() + "Y",
      "7Y": (7 - 0.5).toString() + "Y",
    },

    eu: {
      "3.5Y": "35.5Y",
      "4Y": "36Y",
      "4.5Y": "36.5Y",
      "5Y": "37.5Y",
      "5.5Y": "38Y",
      "6Y": "38.5Y",
      "6.5Y": "39Y",
      "7Y": "40Y",
    },
  };

  const toddler = {
    us: {
      "1C": "1C",
      "2C": "2C",
      "3C": "3C",
      "4C": "4C",
      "5C": "5C",
      "6C": "6C",
      "7C": "7C",
      "8C": "8C",
      "9C": "9C",
      "10C": "10C",
      "10.5C": "10.5C",
      "11C": "11C",
      "12C": "12C",
      "13C": "13C",
    },
    uk: {
      "1C": ".5C",
      "2C": "1.5C",
      "3C": "2.5C",
      "4C": "3.5C",
      "5C": "4.5C",
      "6C": "5.5C",
      "7C": "6.5C",
      "8C": "7.5C",
      "9C": "8.5C",
      "10C": "9.5C",
      "10.5C": "10C",
      "11C": "10.5C",
      "12C": "11.5C",
      "13C": "12.5C",
    },
    eu: {
      "1C": "16C",
      "2C": "17C",
      "3C": "18.5C",
      "4C": "19.5C",
      "5C": "21C",
      "6C": "22C",
      "7C": "23.5C",
      "8C": "25C",
      "9C": "26C",
      "10C": "27C",
      "10.5C": "27.5C",
      "11C": "28C",
      "12C": "29.5C",
      "13C": "31C",
    },
  };

  const young = {
    us: {
      "1Y": "1Y",
      "1.5Y": "1.5Y",
      "2Y": "2Y",
      "2.5Y": "2.5Y",
      "3Y": "3Y",
      "3.5Y": "3.5Y",
      "4Y": "4Y",
      "4.5Y": "4.5Y",
      "5Y": "5Y",
      "5.5Y": "5.5Y",
      "6Y": "6Y",
      "6.5Y": "6.5Y",
      "7Y": "7Y",
    },

    uk: {
      "1Y": "13.5C",
      "1.5Y": "1Y",
      "2Y": "1.5Y",
      "2.5Y": "2Y",
      "3Y": "2.5Y",
      "3.5Y": "3Y",
      "4Y": "3.5Y",
      "4.5Y": "4Y",
      "5Y": "4.5Y",
      "5.5Y": "5Y",
      "6Y": "5.5Y",
      "6.5Y": "6Y",
      "7Y": "6.5Y",
    },

    eu: {
      "1Y": "32Y",
      "1.5Y": "33Y",
      "2Y": "33.5",
      "2.5Y": "34Y",
      "3Y": "35Y",
      "3.5Y": "35.5Y",
      "4Y": "36Y",
      "4.5Y": "36.5Y",
      "5Y": "37.5Y",
      "5.5Y": "38Y",
      "6Y": "38.5Y",
      "6.5Y": "39Y",
      "7Y": "40Y",
    },
  };

  const sign = {
    usd: "$",
    cny: "¥",
    sgd: "S$",
    eur: "€",
  };
  useEffect(() => {
    axios
      .get(
        "https://v6.exchangerate-api.com/v6/0a54db330af5ccfc150879e8/latest/USD/"
      )
      .then((response) => {
        setRates(response.data.conversion_rates);
      });
  }, []);

  return (
    <Provider
      value={{
        rates: rates,
        sign: sign,
        sizingValues: sizingValues,
        womenSizingValues: womenSizingValues,
        girlSizingValues: girlSizingValues,
        toddler: toddler,
        young: young,
      }}
    >
      {children}
    </Provider>
  );
};

export { CurrencyContext, CurrencyProvider };
