import { collection, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { db } from "../../firebase/index";
import { CurrencyContext } from "../../context/CurrencyContext";
import { StoreId } from "../../Constants";
import batchFetch from "../../firebase/util/batchFetch";
import "../css/StocksContainer.scoped.css";
import createStockTagging from "../../firebase/stocks/createStockTagging";
import translate from "../../i18n/translate";
export default function StockContainer({ dark, scannedData }) {
  return (
    <div>
      <div className="headers-grid">
        <div className="left">{translate("name")}</div>
        <div>{translate("sku")}</div>
        <div>{translate("size")}</div>
        <div>{translate("consignment")}</div>
        <div>{translate("barcodeTags")}</div>
        <div>{translate("lastScannedDate")}</div>
        <div>{translate("scanned")}</div>
        <div>{translate("sold")}</div>
      </div>
      <div className="items-container">
        <div className="items">
          {scannedData.map((e, i) => (
            <StockGrid key={i} item={e} dark={dark} />
          ))}
        </div>
      </div>
    </div>
  );
}

let StockGrid = ({ item, dark }) => {
  const {
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);

  let localSizing = localStorage.getItem("sizing");

  const name =
    item._highlightResult && item._highlightResult.name
      ? item._highlightResult.name.value
      : item.name;

  let changeDateFormat = (date) => {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var d = date.split("-");
    return months[Number(d[1]) - 1] + " " + d[2] + ", " + d[0];
  };

  return (
    <div className="item-grid">
      <div dangerouslySetInnerHTML={{ __html: name }} />
      <div className="capitalize">{item.sku}</div>
      <div>
        {item.category === "sneakers"
          ? sizingValues[localSizing][item.size]
            ? sizingValues[localSizing][item.size]
            : womenSizingValues[localSizing][item.size]
            ? womenSizingValues[localSizing][item.size]
            : girlSizingValues[localSizing][item.size]
            ? girlSizingValues[localSizing][item.size]
            : toddler[localSizing][item.size]
            ? toddler[localSizing][item.size]
            : young[localSizing][item.size]
          : item.size}
      </div>
      <div>{item.consignment + "/" + item.totalInventory}</div>
      <div>{item.title + "-" + item.size}</div>

      <div>{changeDateFormat(item.lastScanDate)}</div>
      <div>{item.scanned.length + "/" + item.totalInventory}</div>
      <div>{item.totalSoldItems}</div>
    </div>
  );
};
