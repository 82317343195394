import {
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  deleteField,
} from "firebase/firestore";
import { db } from "../index";
import { StoreId } from "../../Constants";
import axios from "axios";

export default async function placeAsk(token, mode, data) {
  // console.log(data);
  // Input validations
  if (isNaN(Number(data.price))) {
    throw new Error("Please provide a valid asking price.");
  }

  // only sneakers for now
  if (data.category !== "sneakers") {
    throw new Error("Marketplace only supports sneakers for now");
  }

  if (data.payout < 0) {
    throw new Error("Payout can't be negative");
  }

  // const storeSnap = await getDoc(doc(db, "Stores", StoreId));
  // const storeData = storeSnap.data();

  // console.log(storeData);

  // const resp = await axios.post(
  //   `http://localhost:80/marketplace/ask/${data.sku}`,
  //   {
  //     mode: mode,
  //     askId: data.askId ?? null,
  //     size: data.size,
  //     price: data.price,
  //     storeId: StoreId,
  //     itemId: data.id,
  //     stripe_seller_id: storeData.stripe_seller_id,
  //     sellerShippingFees: data.shippingFee,
  //   }
  // );

  // console.log({
  //   mode: mode,
  //   askId: data.askId ?? null,
  //   size: data.size,
  //   sku: data.sku,
  //   price: data.price,
  //   storeId,
  //   payout: data.payout,
  //   stripe_seller_id: storeData.stripe_seller_id,
  // });

  const resp = await axios.post(
    `https://us-central1-ingenium-337101.cloudfunctions.net/placeAsk`,
    {
      sku: data.sku,
      token: token,
      mode: mode,
      askId: data.askId ?? null,
      size: data.size,
      price: data.price,
      itemId: data.id,
      sellerShippingFees: data.shippingFee,
    }
  );

  // console.log(resp);

  if (!resp.data.success) {
    // // roll back changes if error in placing ask
    // await deleteDoc(doc(db, "Stores", storeId, "ListedItems", data.id));
    // await setDoc(
    //   doc(db, "Stores", storeId, "Inventory", data.id),
    //   {
    //     askId: deleteField(),
    //     isAsked: deleteField(),
    //     askedPrice: deleteField(),
    //     listedAt: deleteField(),
    //   },
    //   { merge: true }
    // );
    throw new Error(resp.data.error);
  } else {
    await setDoc(
      doc(db, "Stores", StoreId, "Inventory", data.id),
      {
        askId: resp.data.askId,
        isAsked: true,
        askedPrice: data.price,
        listedAt: new Date().toLocaleString(),
      },
      { merge: true }
    );

    delete data.condition;
    delete data.conditionCP;
    delete data.markup;
    delete data.minCP;
    delete data.platform;
    delete data.sizes;
    delete data.sizesCustom;
    delete data.stocks;
    delete data.stocksCP;

    await setDoc(
      doc(db, "Stores", StoreId, "ListedItems", data.id),
      {
        ...data,
        askId: resp.data.askId,
        isAsked: true,
        askedPrice: data.price,
        sold: false,
        listedAt: new Date().toLocaleString(),
      },
      { merge: true }
    );
  }
}
