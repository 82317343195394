import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import _, { isNull } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import BellIcon from "../../assets/bell.svg";
import Close from "../../assets/close.svg";
import Delete from "../../assets/delete.svg";
import DeleteDark from "../../assets/deletedark.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import { db } from "../../firebase";
import constants from "../../firebase/constants";
import batchCreateInventory from "../../firebase/inventory/batchCreateInventory";
import createUIproduct from "../../firebase/products/createUIproduct";
import updatePriceAlerts from "../../firebase/inventory/updatePriceAlerts";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import Loader from "../general/Loader";
import Select from "../general/Select";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import NewPriceItems from "./NewPriceItems";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

export default function TransitionsModal({ dark }) {
  const initialValues = {
    alertType: "",
    priceUpBy: "",
    priceDownBy: "",
    email: "",
    phoneNumber: "",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const {
    rates,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);

  let localCurrency = localStorage.getItem("currency");
  let localSizing = localStorage.getItem("sizing");

  const [savedSettings, updateSavedSettings] = useState(initialValues);
  const [isDisabled, updateDisabled] = useState(false);
  const [fields, setFields] = useState(initialValues);
  const [loading, toggleLoading] = useState(false);

  useEffect(() => {
    let docRef = doc(db, "Stores", StoreId, "PriceAlerts", "Settings");
    const storeListener = onSnapshot(docRef, async (snapShot) => {
      if (snapShot.exists()) {
        let data = snapShot.data();
        setFields({
          alertType: data.alertType,
          priceUpBy: data.priceUpBy,
          priceDownBy: data.priceDownBy,
          email: data.email,
          phoneNumber: data.phoneNumber,
        });
        updateSavedSettings({
          alertType: data.alertType,
          priceUpBy: data.priceUpBy,
          priceDownBy: data.priceDownBy,
          email: data.email,
          phoneNumber: data.phoneNumber,
        });
      }
    });
    return () => {
      storeListener();
    };
  }, []);

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  //Handle the save action
  const saveHandler = async () => {
    toggleLoading(true);
    try {
      await updatePriceAlerts(StoreId, {
        alertType: fields.alertType,
        priceUpBy: Number(parseFloat(fields.priceUpBy).toFixed(1)),
        priceDownBy: Number(parseFloat(fields.priceDownBy).toFixed(1)),
        email: fields.email,
        phoneNumber: fields.phoneNumber,
      });
    } catch (e) {
      ErrorToast(e.message);
    }
    toggleLoading(false);
  };

  const clearValues = () => {
    setFields(initialValues);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFields(savedSettings);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        customClass={"mr-14"}
        imgSrc={<img src={BellIcon} alt="refresh Icon" />}
        text={translate("Price Alert")}
      />
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="header">
              <div>{translate("Setting Price Alert")}...</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className="three-grid">
              <div>
                <div className="label">{translate("Alert Type")}</div>
                <Select
                  dark={dark}
                  name="alertType"
                  value={fields.alertType}
                  updateValue={handleChange}
                  options={[
                    {
                      label: "Daily",
                      value: "daily",
                    },
                    {
                      label: "Monthly",
                      value: "monthly",
                    },
                    {
                      label: "3-Months",
                      value: "threeMonths",
                    },
                    {
                      label: "6-Months",
                      value: "sixMonths",
                    },
                    {
                      label: "Yearly",
                      value: "yearly",
                    },
                  ]}
                  placeholderText={GetTranslateString("Choose Type")}
                  none={false}
                />
              </div>

              <div>
                <div className="label">
                  {GetTranslateString("Price Up By (%)")}
                </div>
                <Input
                  name="priceUpBy"
                  type={"number"}
                  value={fields.priceUpBy}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterNumber")}
                />
              </div>

              <div>
                <div className="label">{translate("Price Down By (%)")}</div>
                <Input
                  dark={dark}
                  name="priceDownBy"
                  type={"number"}
                  value={fields.priceDownBy}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterNumber")}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("email")}</div>
                <Input
                  dark={dark}
                  name="email"
                  type={"email"}
                  value={fields.email}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterEmail")}
                  none={false}
                />
              </div>

              <div>
                <div className="label">{translate("Phone Number")}</div>
                <Input
                  dark={dark}
                  name="phoneNumber"
                  type={"text"}
                  value={fields.phoneNumber}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("Enter No.")}
                />
              </div>
              <div />
            </div>

            <div className="w-full jus-start pt-10">
              <div className="saved-alert">Alert Type: </div>
              {savedSettings.alertType}
              <div className="saved-alert">Price Up By:</div>
              {savedSettings.priceUpBy}%
              <div className="saved-alert">Price Down By: </div>
              {savedSettings.priceDownBy}%
            </div>
            <div className="w-full jus-start pt-10">
              <div className="saved-alert">Alert by Email: </div>
              {savedSettings.email}
            </div>
            <div className="w-full jus-start pt-10">
              <div className="saved-alert">Alert by Phone SMS: </div>
              {savedSettings.phoneNumber}
            </div>

            <div className="btns w-full jus-end mt-10">
              <div>
                <Loader loading={loading} />
              </div>
              <div className="ml-10">
                <Button
                  onClick={() => {
                    clearValues();
                  }}
                  text={translate("clearAll")}
                  imgSrc={<img src={dark ? DeleteDark : Delete} alt="delete" />}
                />
              </div>
              <div className="ml-10">
                <Button
                  imgSrc={<img src={dark ? AddDark : Add} alt="add" />}
                  onClick={async () => {
                    await saveHandler();
                  }}
                  text={translate("save")}
                />
              </div>
            </div>

            <div className="table-container">
              <NewPriceItems settings={savedSettings} />
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
