import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import add from "../assets/add.svg";
import addDark from "../assets/addark.svg";
import Button from "../components/general/Button";
import CustomCheckbox from "../components/general/Checkbox";
import InfoDiv from "../components/general/InfoDiv";
import SelectLabels from "../components/general/Select";
import {
  ErrorToast,
  SuccessToast,
} from "../components/general/ToastNotification";
import Topbar from "../components/general/Topbar";
import { StoreId } from "../Constants";
import { db } from "../firebase";
import "./css/Settings.scoped.css";
import {
  editStaffSettings,
  editAllStaffSettings,
} from "../firebase/staff/editStaffSettings";
import NavSettings from "../firebase/customization/navSettings";
import CustomerViewing from "./CustomerViewing";
import { LOCALES } from "../i18n/locales";
import { transform } from "lodash";
import translate from "../i18n/translate";
import GetTranslateString from "../i18n/translateString";
import Loader from "../components/general/Loader";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";

const Settings = ({
  currency,
  setCurrency,
  language,
  setLanguage,
  mode,
  setMode,
  sizing,
  setSizing,
  user,
  dark,
}) => {
  const isOwner = localStorage.getItem("owner");
  const [katchStatus, setKatchStatus] = useState(false);
  const [staff, setStaff] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState("");
  const [genStaffSettings, setGenStaffSettings] = useState({
    hideDashBtns: false,
    hideInvImport: false,
    hideInvDownload: false,
    hideInvBtns: false,
    hideProductQr: false,
    hidePayoutConfimation: false,
    hideConsigneeBtns: false,
    hideConsigneeInvBtns: false,
    hideReceiptSettings: false,
    hideExpensePage: false,
  });

  const [specificStaffSettings, setSpecificStaffSettings] = useState({
    hideDashBtns: false,
    hideInvImport: false,
    hideInvDownload: false,
    hideInvBtns: false,
    hideProductQr: false,
    hidePayoutConfimation: false,
    hideConsigneeBtns: false,
    hideConsigneeInvBtns: false,
    hideReceiptSettings: false,
    hideExpensePage: false,
  });

  const [navSettings, setNavSettings] = useState({
    isMarketHidden: false,
    isInventoryHidden: false,
    isProductLabelsHidden: false,
    isConsignmentHidden: false,
    isSalesHidden: false,
    isExpenseHidden: false,
  });

  const auth = getAuth();
  // console.log(auth);
  useEffect(() => {
    const authListener = onIdTokenChanged(auth, async (user) => {
      // console.log(auth)
      if (user) {
        const token = await user.getIdToken();
        localStorage.setItem("token", token);
      }
    });
    return () => {
      authListener();
    };
  }, []);

  useEffect(() => {
    if (selectedStaff) {
      (async () => {
        const docRef = doc(
          db,
          "Stores",
          StoreId,
          "Staff",
          "Staffs",
          "Accounts",
          selectedStaff
        );
        const docSnap = await getDoc(docRef);
        let data = { ...docSnap.data().settings };
        setSpecificStaffSettings({
          hideDashBtns: data?.hideDashBtns ?? false,
          hideInvImport: data?.hideInvImport ?? false,
          hideInvDownload: data?.hideInvDownload ?? false,
          hideInvBtns: data?.hideInvBtns ?? false,
          hideProductQr: data?.hideProductQr ?? false,
          hidePayoutConfimation: data?.hidePayoutConfimation ?? false,
          hideConsigneeBtns: data?.hideConsigneeBtns ?? false,
          hideConsigneeInvBtns: data?.hideConsigneeInvBtns ?? false,
          hideReceiptSettings: data?.hideReceiptSettings ?? false,
          hideExpensePage: data?.hideExpensePage ?? false,
        });
      })();
    }
  }, [selectedStaff]);

  useEffect(() => {
    const navListener = onSnapshot(
      doc(db, "Stores", StoreId, "Customization", "NavSettings"),
      async (snapShot) => {
        const settingsData = {
          ...snapShot.data(),
        };

        setNavSettings(settingsData);
      }
    );
    return () => {
      navListener();
    };
  }, []);

  useEffect(() => {
    const navListener = onSnapshot(
      doc(db, "Stores", StoreId),
      async (snapShot) => {
        setKatchStatus(snapShot.data().katch ?? false);
      }
    );
    return () => {
      navListener();
    };
  }, []);

  useEffect(() => {
    const navListener = onSnapshot(
      doc(db, "Stores", StoreId, "Customization", "GenStaffSettings"),
      async (snapShot) => {
        const settingsData = {
          ...snapShot.data(),
        };

        setGenStaffSettings(settingsData);
      }
    );
    return () => {
      navListener();
    };
  }, []);

  useEffect(() => {
    const staffListener = onSnapshot(
      collection(db, "Stores", StoreId, "Staff", "Staffs", "Accounts"),
      async (snapShot) => {
        const staffList = snapShot.docs.map((e) => {
          return {
            label: e.id,
            value: e.id,
          };
        });
        setStaff(staffList);
      }
    );
    return () => {
      staffListener();
    };
  }, []);

  return (
    <div>
      <div className="page">
        <Topbar dark={dark} pagename={translate("settings")} user={user} />
        <div className="content px">
          <div className="px-15">
            <div className="settings-container">
              <div className="flex al-ce jc-sp headersection">
                <div className="smtext">{translate("settingOptions")}</div>
              </div>
              <div className="twogrid">
                <div className="px-25 ">
                  <div className="wrap">
                    <div className="select-container">
                      <div className="label">{translate("currency")}</div>
                      <SelectLabels
                        disabled={!isOwner}
                        dark={dark}
                        name={"currency"}
                        value={currency}
                        updateValue={(e) => {
                          setCurrency(e.target.value);
                          localStorage.setItem("currency", e.target.value);
                        }}
                        placeholderText={"Choose Currency"}
                        options={[
                          {
                            label: "CNY",
                            value: "cny",
                          },
                          {
                            label: "EUR",
                            value: "eur",
                          },
                          {
                            label: "SGD",
                            value: "sgd",
                          },
                          {
                            label: "USD",
                            value: "usd",
                          },
                        ]}
                      />
                    </div>
                    <div className="select-container">
                      <div className="label">{translate("language")}</div>
                      <SelectLabels
                        dark={dark}
                        name={"language"}
                        value={language}
                        updateValue={(e) => {
                          setLanguage(e.target.value);
                          localStorage.setItem("language", e.target.value);
                        }}
                        placeholderText={"Choose Language"}
                        options={[
                          {
                            label: "English",
                            value: LOCALES.ENGLISH,
                          },
                          {
                            label: "Français",
                            value: LOCALES.FRENCH,
                          },
                          {
                            label: "Español",
                            value: LOCALES.SPANISH,
                          },
                          {
                            label: "中文",
                            value: LOCALES.CHINESE,
                          },
                          {
                            label: "한국어",
                            value: LOCALES.KOREAN,
                          },
                        ]}
                      />
                    </div>
                    <div className="select-container">
                      <div className="label">{translate("sizing")}</div>
                      <SelectLabels
                        disabled={!isOwner}
                        dark={dark}
                        name={"sizing"}
                        value={sizing}
                        updateValue={(e) => {
                          setSizing(e.target.value);
                          localStorage.setItem("sizing", e.target.value);
                        }}
                        placeholderText={"EU/UK/US"}
                        options={[
                          {
                            label: "EU",
                            value: "eu",
                          },
                          {
                            label: "UK",
                            value: "uk",
                          },
                          {
                            label: "US",
                            value: "us",
                          },
                        ]}
                      />
                    </div>
                    <div className="select-container">
                      <div className="label">{translate("mode")}</div>
                      <SelectLabels
                        dark={dark}
                        name={"mode"}
                        value={mode}
                        updateValue={(e) => {
                          setMode(e.target.value);
                          localStorage.setItem("mode", e.target.value);
                        }}
                        placeholderText={"Choose Mode"}
                        options={[
                          {
                            label: translate("light"),
                            value: "light",
                          },
                          {
                            label: translate("dark"),
                            value: "dark",
                          },
                        ]}
                      />
                    </div>
                  </div>

                  <div className="wrap">
                    <div className="flex end btncontainer">
                      <Button
                        text={translate("save")}
                        imgSrc={
                          <img src={dark ? addDark : add} alt="add icon" />
                        }
                        onClick={() =>
                          SuccessToast(
                            translate("success"),
                            translate("settingsSaved")
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div />
              </div>

              {isOwner && (
                <>
                  <div className="flex al-ce jc-sp headersection">
                    <div className="smtext">
                      {translate("staffNavigationBarSettings")}
                    </div>
                  </div>
                  <div className="twogrid">
                    <div className="px-25 ">
                      <div className="wrap">
                        <InfoDiv
                          customClass={"settings"}
                          text={
                            <div
                              className="w-100 cp flex al-ce jc-sp"
                              onClick={() => {
                                setNavSettings({
                                  ...navSettings,
                                  isMarketHidden: !navSettings.isMarketHidden,
                                });
                              }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0 12C0 21.882 2.118 24 12 24C21.882 24 24 21.882 24 12C24 2.118 21.882 0 12 0C2.118 0 0 2.118 0 12ZM2 12C2 14.4249 2.13254 16.2369 2.43771 17.6101C2.73783 18.9605 3.17768 19.7608 3.70846 20.2915C4.23924 20.8223 5.03949 21.2622 6.38993 21.5623C7.76307 21.8675 9.57515 22 12 22C14.4249 22 16.2369 21.8675 17.6101 21.5623C18.9605 21.2622 19.7608 20.8223 20.2915 20.2915C20.8223 19.7608 21.2622 18.9605 21.5623 17.6101C21.8675 16.2369 22 14.4249 22 12C22 9.57515 21.8675 7.76307 21.5623 6.38993C21.2622 5.03949 20.8223 4.23924 20.2915 3.70846C19.7608 3.17768 18.9605 2.73783 17.6101 2.43771C16.2369 2.13254 14.4249 2 12 2C9.57515 2 7.76307 2.13254 6.38993 2.43771C5.03949 2.73783 4.23924 3.17768 3.70846 3.70846C3.17768 4.23924 2.73783 5.03949 2.43771 6.38993C2.13254 7.76307 2 9.57515 2 12Z"
                                  fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                />
                                <path
                                  d="M13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V7Z"
                                  fill="#767BFA"
                                />
                                <path
                                  d="M8 13C8 12.4477 7.55231 12 7 12C6.44769 12 6 12.4477 6 13V17C6 17.5523 6.44769 18 7 18C7.55231 18 8 17.5523 8 17V13Z"
                                  fill="#767BFA"
                                />
                                <path
                                  d="M17 10C17.5523 10 18 10.4477 18 11V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V11C16 10.4477 16.4477 10 17 10Z"
                                  fill="#767BFA"
                                />
                              </svg>
                              <div className="text">
                                {translate("marketLookUp")}
                              </div>
                              {navSettings.isMarketHidden ? (
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.09776 7.70711C6.69105 7.31658 6.69105 6.68342 7.09776 6.29289C7.50448 5.90237 8.16389 5.90237 8.57061 6.29289C10.594 8.2357 13.4061 8.2357 15.4295 6.29289C15.8362 5.90237 16.4956 5.90237 16.9023 6.29289C17.309 6.68342 17.309 7.31658 16.9023 7.70711C14.0655 10.431 9.93455 10.431 7.09776 7.70711Z"
                                    fill="#767BFA"
                                  />

                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.441643 5.89359C-0.147214 6.53437 -0.147214 7.46563 0.441643 8.10641C2.25469 10.0793 6.54403 14 12 14C17.456 14 21.7453 10.0793 23.5584 8.10641C24.1472 7.46563 24.1472 6.53437 23.5584 5.89359C21.7453 3.92067 17.456 0 12 0C6.54403 0 2.25469 3.92067 0.441643 5.89359ZM12 2C7.68339 2 4.04578 5.05757 2.20582 7C4.04578 8.94243 7.68339 12 12 12C16.3166 12 19.9542 8.94243 21.7942 7C19.9542 5.05757 16.3166 2 12 2Z"
                                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.09774 7.70711C6.69102 7.31658 6.69102 6.68342 7.09774 6.29289C7.50445 5.90237 8.16387 5.90237 8.57059 6.29289C10.5939 8.2357 13.4061 8.2357 15.4294 6.29289C15.8361 5.90237 16.4956 5.90237 16.9023 6.29289C17.309 6.68342 17.309 7.31658 16.9023 7.70711C14.0655 10.431 9.93453 10.431 7.09774 7.70711Z"
                                    fill="#767BFA"
                                  />
                                  <path
                                    d="M16.9023 6.04289C17.309 6.43342 17.309 7.06658 16.9023 7.45711C16.4956 7.84763 15.8361 7.84763 15.4294 7.45711C13.4061 5.5143 10.5939 5.5143 8.57059 7.45711C8.16387 7.84763 7.50445 7.84763 7.09774 7.45711C6.69102 7.06658 6.69102 6.43342 7.09774 6.04289C9.93453 3.31904 14.0655 3.31904 16.9023 6.04289Z"
                                    fill="#767BFA"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.441643 5.89359C-0.147214 6.53437 -0.147214 7.46563 0.441643 8.10641C2.25469 10.0793 6.54403 14 12 14C17.456 14 21.7453 10.0793 23.5584 8.10641C24.1472 7.46563 24.1472 6.53437 23.5584 5.89359C21.7453 3.92067 17.456 0 12 0C6.54403 0 2.25469 3.92067 0.441643 5.89359ZM12 2C7.68339 2 4.04578 5.05757 2.20582 7C4.04578 8.94243 7.68339 12 12 12C16.3166 12 19.9542 8.94243 21.7942 7C19.9542 5.05757 16.3166 2 12 2Z"
                                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                  />
                                </svg>
                              )}
                            </div>
                          }
                        />

                        <InfoDiv
                          customClass={"settings"}
                          text={
                            <div
                              className="w-100 cp flex al-ce jc-sp"
                              onClick={() => {
                                setNavSettings({
                                  ...navSettings,
                                  isInventoryHidden: !navSettings.isInventoryHidden,
                                });
                              }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 6C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6C6 4.06198 6.7367 2.53498 7.89814 1.50259C9.04117 0.486564 10.5369 0 12 0C13.4631 0 14.9588 0.486564 16.1019 1.50259C17.2633 2.53498 18 4.06198 18 6C18 6.55228 17.5523 7 17 7C16.4477 7 16 6.55228 16 6C16 4.60469 15.4867 3.63169 14.7731 2.99741C14.0412 2.34677 13.0369 2 12 2C10.9631 2 9.95883 2.34677 9.22686 2.99741C8.5133 3.63169 8 4.60469 8 6Z"
                                  fill="#767BFA"
                                />
                                <path
                                  d="M7 9C6.44772 9 6 9.44771 6 10C6 10.5523 6.44772 11 7 11H17C17.5523 11 18 10.5523 18 10C18 9.44771 17.5523 9 17 9H7Z"
                                  fill="#767BFA"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12 24C21.4928 24 25.4487 23 23.5274 14.5C23.4508 14.2007 23.377 13.9102 23.3054 13.6282C21.5822 6.84356 21.114 5 12 5C2.88602 5 2.41779 6.84353 0.694654 13.628C0.623019 13.9101 0.549216 14.2007 0.472531 14.5C-1.44868 23 2.50713 24 12 24ZM2.63868 14.099C2.56811 14.3766 2.49455 14.666 2.41719 14.9681C1.96831 16.9642 1.91972 18.2994 2.09195 19.191C2.24662 19.9916 2.5749 20.4407 3.07851 20.7822C3.671 21.184 4.63703 21.5188 6.19181 21.729C7.72683 21.9365 9.64142 22 12 22C14.3586 22 16.2732 21.9365 17.8082 21.729C19.363 21.5188 20.329 21.184 20.9215 20.7822C21.4251 20.4407 21.7534 19.9916 21.9081 19.191C22.0803 18.2994 22.0317 16.9642 21.5828 14.9681C21.5054 14.6661 21.4319 14.3767 21.3614 14.0992C20.9832 12.6114 20.6911 11.4621 20.3603 10.5046C19.9745 9.38767 19.6092 8.76698 19.1975 8.36029C18.3937 7.5662 16.8159 7 12 7C7.1841 7 5.60631 7.5662 4.8025 8.36029C4.39083 8.76698 4.02554 9.38767 3.63968 10.5046C3.30893 11.462 3.01681 12.6113 2.63868 14.099Z"
                                  fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                />
                              </svg>
                              <div className="text">
                                {translate("inventory")}
                              </div>
                              {navSettings.isInventoryHidden ? (
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.09776 7.70711C6.69105 7.31658 6.69105 6.68342 7.09776 6.29289C7.50448 5.90237 8.16389 5.90237 8.57061 6.29289C10.594 8.2357 13.4061 8.2357 15.4295 6.29289C15.8362 5.90237 16.4956 5.90237 16.9023 6.29289C17.309 6.68342 17.309 7.31658 16.9023 7.70711C14.0655 10.431 9.93455 10.431 7.09776 7.70711Z"
                                    fill="#767BFA"
                                  />

                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.441643 5.89359C-0.147214 6.53437 -0.147214 7.46563 0.441643 8.10641C2.25469 10.0793 6.54403 14 12 14C17.456 14 21.7453 10.0793 23.5584 8.10641C24.1472 7.46563 24.1472 6.53437 23.5584 5.89359C21.7453 3.92067 17.456 0 12 0C6.54403 0 2.25469 3.92067 0.441643 5.89359ZM12 2C7.68339 2 4.04578 5.05757 2.20582 7C4.04578 8.94243 7.68339 12 12 12C16.3166 12 19.9542 8.94243 21.7942 7C19.9542 5.05757 16.3166 2 12 2Z"
                                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.09774 7.70711C6.69102 7.31658 6.69102 6.68342 7.09774 6.29289C7.50445 5.90237 8.16387 5.90237 8.57059 6.29289C10.5939 8.2357 13.4061 8.2357 15.4294 6.29289C15.8361 5.90237 16.4956 5.90237 16.9023 6.29289C17.309 6.68342 17.309 7.31658 16.9023 7.70711C14.0655 10.431 9.93453 10.431 7.09774 7.70711Z"
                                    fill="#767BFA"
                                  />
                                  <path
                                    d="M16.9023 6.04289C17.309 6.43342 17.309 7.06658 16.9023 7.45711C16.4956 7.84763 15.8361 7.84763 15.4294 7.45711C13.4061 5.5143 10.5939 5.5143 8.57059 7.45711C8.16387 7.84763 7.50445 7.84763 7.09774 7.45711C6.69102 7.06658 6.69102 6.43342 7.09774 6.04289C9.93453 3.31904 14.0655 3.31904 16.9023 6.04289Z"
                                    fill="#767BFA"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.441643 5.89359C-0.147214 6.53437 -0.147214 7.46563 0.441643 8.10641C2.25469 10.0793 6.54403 14 12 14C17.456 14 21.7453 10.0793 23.5584 8.10641C24.1472 7.46563 24.1472 6.53437 23.5584 5.89359C21.7453 3.92067 17.456 0 12 0C6.54403 0 2.25469 3.92067 0.441643 5.89359ZM12 2C7.68339 2 4.04578 5.05757 2.20582 7C4.04578 8.94243 7.68339 12 12 12C16.3166 12 19.9542 8.94243 21.7942 7C19.9542 5.05757 16.3166 2 12 2Z"
                                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                  />
                                </svg>
                              )}
                            </div>
                          }
                        />

                        <InfoDiv
                          customClass={"settings"}
                          text={
                            <div
                              className="w-100 cp flex al-ce jc-sp"
                              onClick={() => {
                                setNavSettings({
                                  ...navSettings,
                                  isProductLabelsHidden: !navSettings.isProductLabelsHidden,
                                });
                              }}
                            >
                              <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.83447 13.7601C1.80099 13.2686 1.39833 12.8793 0.905678 12.8793C0.385396 12.8793 -0.0325008 13.315 0.00199242 13.8341C0.39146 19.6958 2.30418 21.6085 8.16583 21.998C8.68497 22.0325 9.12072 21.6146 9.12072 21.0943C9.12072 20.6017 8.73136 20.199 8.23985 20.1655C7.28991 20.1008 6.48456 19.9952 5.79822 19.8426C4.55511 19.5663 3.81763 19.1613 3.32818 18.6718C2.83873 18.1824 2.43365 17.4449 2.15738 16.2018C2.00484 15.5154 1.89919 14.7101 1.83447 13.7601Z"
                                  fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                />
                                <path
                                  d="M20.0868 13.7601C20.1203 13.2686 20.5229 12.8793 21.0156 12.8793C21.5359 12.8793 21.9538 13.315 21.9193 13.8342C21.5298 19.6958 19.6171 21.6085 13.7554 21.998C13.2363 22.0325 12.8005 21.6146 12.8005 21.0943C12.8005 20.6017 13.1899 20.199 13.6814 20.1655C14.6313 20.1008 15.4367 19.9952 16.123 19.8426C17.3661 19.5663 18.1036 19.1613 18.5931 18.6718C19.0825 18.1824 19.4876 17.4449 19.7639 16.2018C19.9164 15.5154 20.0221 14.7101 20.0868 13.7601Z"
                                  fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                />
                                <path
                                  d="M22 11.0815C22 11.0675 22 11.0534 22 11.0394C22 11.0253 22 11.0113 22 10.9973V11.0815Z"
                                  fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                />
                                <path
                                  d="M21.9193 8.24459C21.9538 8.76373 21.5359 9.19948 21.0156 9.19948C20.5229 9.19948 20.1203 8.81012 20.0868 8.31861C20.0221 7.36866 19.9164 6.5633 19.7639 5.87697C19.4876 4.63385 19.0825 3.89637 18.5931 3.40692C18.1036 2.91747 17.3661 2.51239 16.123 2.23612C15.4367 2.08358 14.6313 1.97793 13.6814 1.91321C13.1899 1.87973 12.8005 1.47706 12.8005 0.984417C12.8005 0.464135 13.2363 0.0462391 13.7554 0.0807319C19.6171 0.470198 21.5298 2.38292 21.9193 8.24459Z"
                                  fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                />
                                <path
                                  d="M1.83447 8.31861C1.80099 8.81012 1.39833 9.19948 0.905678 9.19948C0.385396 9.19948 -0.0325008 8.76373 0.00199232 8.24459C0.39146 2.38293 2.30418 0.470204 8.16584 0.0807339C8.68497 0.0462405 9.12072 0.464137 9.12072 0.984419C9.12072 1.47707 8.73136 1.87973 8.23985 1.91321C7.28991 1.97793 6.48456 2.08358 5.79822 2.23612C4.55511 2.51239 3.81763 2.91747 3.32818 3.40692C2.83873 3.89637 2.43365 4.63385 2.15738 5.87697C2.00484 6.5633 1.89919 7.36866 1.83447 8.31861Z"
                                  fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                />
                                <rect
                                  x="6.4165"
                                  y="6.49552"
                                  width="9.16646"
                                  height="1.83329"
                                  rx="0.916646"
                                  fill="#767BFA"
                                />
                                <rect
                                  x="8.24976"
                                  y="13.8287"
                                  width="5.49988"
                                  height="1.83329"
                                  rx="0.916646"
                                  fill="#767BFA"
                                />
                                <rect
                                  x="4.58325"
                                  y="10.1621"
                                  width="12.833"
                                  height="1.83329"
                                  rx="0.916646"
                                  fill="#767BFA"
                                />
                              </svg>

                              <div className="text">
                                {translate("productLabels")}
                              </div>
                              {navSettings.isProductLabelsHidden ? (
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.09776 7.70711C6.69105 7.31658 6.69105 6.68342 7.09776 6.29289C7.50448 5.90237 8.16389 5.90237 8.57061 6.29289C10.594 8.2357 13.4061 8.2357 15.4295 6.29289C15.8362 5.90237 16.4956 5.90237 16.9023 6.29289C17.309 6.68342 17.309 7.31658 16.9023 7.70711C14.0655 10.431 9.93455 10.431 7.09776 7.70711Z"
                                    fill="#767BFA"
                                  />

                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.441643 5.89359C-0.147214 6.53437 -0.147214 7.46563 0.441643 8.10641C2.25469 10.0793 6.54403 14 12 14C17.456 14 21.7453 10.0793 23.5584 8.10641C24.1472 7.46563 24.1472 6.53437 23.5584 5.89359C21.7453 3.92067 17.456 0 12 0C6.54403 0 2.25469 3.92067 0.441643 5.89359ZM12 2C7.68339 2 4.04578 5.05757 2.20582 7C4.04578 8.94243 7.68339 12 12 12C16.3166 12 19.9542 8.94243 21.7942 7C19.9542 5.05757 16.3166 2 12 2Z"
                                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.09774 7.70711C6.69102 7.31658 6.69102 6.68342 7.09774 6.29289C7.50445 5.90237 8.16387 5.90237 8.57059 6.29289C10.5939 8.2357 13.4061 8.2357 15.4294 6.29289C15.8361 5.90237 16.4956 5.90237 16.9023 6.29289C17.309 6.68342 17.309 7.31658 16.9023 7.70711C14.0655 10.431 9.93453 10.431 7.09774 7.70711Z"
                                    fill="#767BFA"
                                  />
                                  <path
                                    d="M16.9023 6.04289C17.309 6.43342 17.309 7.06658 16.9023 7.45711C16.4956 7.84763 15.8361 7.84763 15.4294 7.45711C13.4061 5.5143 10.5939 5.5143 8.57059 7.45711C8.16387 7.84763 7.50445 7.84763 7.09774 7.45711C6.69102 7.06658 6.69102 6.43342 7.09774 6.04289C9.93453 3.31904 14.0655 3.31904 16.9023 6.04289Z"
                                    fill="#767BFA"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.441643 5.89359C-0.147214 6.53437 -0.147214 7.46563 0.441643 8.10641C2.25469 10.0793 6.54403 14 12 14C17.456 14 21.7453 10.0793 23.5584 8.10641C24.1472 7.46563 24.1472 6.53437 23.5584 5.89359C21.7453 3.92067 17.456 0 12 0C6.54403 0 2.25469 3.92067 0.441643 5.89359ZM12 2C7.68339 2 4.04578 5.05757 2.20582 7C4.04578 8.94243 7.68339 12 12 12C16.3166 12 19.9542 8.94243 21.7942 7C19.9542 5.05757 16.3166 2 12 2Z"
                                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                  />
                                </svg>
                              )}
                            </div>
                          }
                        />

                        <div />

                        <InfoDiv
                          customClass={"settings"}
                          text={
                            <div
                              className="w-100 cp flex al-ce jc-sp"
                              onClick={() => {
                                setNavSettings({
                                  ...navSettings,
                                  isConsignmentHidden: !navSettings.isConsignmentHidden,
                                });
                              }}
                            >
                              <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0 11C0 20.0585 1.9415 22 11 22C20.0585 22 22 20.0585 22 11C22 1.9415 20.0585 0 11 0C1.9415 0 0 1.9415 0 11ZM1.83333 11C1.83333 13.2228 1.95483 14.8838 2.23457 16.1426C2.50968 17.3805 2.91288 18.114 3.39943 18.6006C3.88597 19.0871 4.61954 19.4903 5.85743 19.7654C7.11615 20.0452 8.77722 20.1667 11 20.1667C12.0559 20.1667 12.9851 20.1392 13.8046 20.0805C13.8013 19.916 13.7927 19.7399 13.7829 19.54C13.7774 19.4276 13.7715 19.3074 13.766 19.1777C13.749 18.7774 13.7382 18.3205 13.7729 17.8519C13.8418 16.9203 14.098 15.8359 14.9518 14.9795C15.8082 14.1204 16.9253 13.8572 17.8662 13.7798C18.3435 13.7406 18.8069 13.7467 19.2094 13.7607C19.365 13.7661 19.5045 13.7722 19.6323 13.7778C19.7984 13.785 19.9448 13.7914 20.0812 13.7943C20.1395 12.9773 20.1667 12.0515 20.1667 11C20.1667 8.77722 20.0452 7.11615 19.7654 5.85743C19.4903 4.61954 19.0871 3.88597 18.6006 3.39943C18.114 2.91288 17.3805 2.50968 16.1426 2.23457C14.8838 1.95483 13.2228 1.83333 11 1.83333C8.77722 1.83333 7.11615 1.95483 5.85743 2.23457C4.61954 2.50968 3.88597 2.91288 3.39943 3.39943C2.91288 3.88597 2.50968 4.61954 2.23457 5.85743C1.95483 7.11615 1.83333 8.77722 1.83333 11ZM19.8681 15.6221C19.7467 15.6179 19.6207 15.6125 19.4958 15.6071C19.3766 15.6019 19.2583 15.5968 19.146 15.5929C18.7684 15.5798 18.388 15.5764 18.0165 15.607C17.2598 15.6692 16.6596 15.8631 16.2501 16.2738C15.838 16.6872 15.655 17.2608 15.6012 17.9873C15.5745 18.3478 15.5815 18.7199 15.5976 19.1C15.6018 19.1972 15.6067 19.2985 15.6118 19.4012C15.6193 19.5556 15.6271 19.7134 15.6324 19.8663C15.8094 19.8353 15.9794 19.8017 16.1426 19.7654C17.3805 19.4903 18.114 19.0871 18.6006 18.6006C19.0871 18.114 19.4903 17.3805 19.7654 16.1426C19.8024 15.9762 19.8366 15.8028 19.8681 15.6221Z"
                                  fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                />
                                <path
                                  d="M8.25 6.41667C8.25 5.9104 8.66041 5.5 9.16667 5.5H12.8333C13.3396 5.5 13.75 5.9104 13.75 6.41667C13.75 6.92293 13.3396 7.33333 12.8333 7.33333H9.16667C8.66041 7.33333 8.25 6.92293 8.25 6.41667Z"
                                  fill="#767BFA"
                                />
                                <path
                                  d="M6.41667 10.0833C5.91041 10.0833 5.5 10.4937 5.5 11C5.5 11.5063 5.91041 11.9167 6.41667 11.9167H15.5833C16.0896 11.9167 16.5 11.5063 16.5 11C16.5 10.4937 16.0896 10.0833 15.5833 10.0833H6.41667Z"
                                  fill="#767BFA"
                                />
                              </svg>

                              <div className="text">
                                {translate("consignment")}
                              </div>
                              {navSettings.isConsignmentHidden ? (
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.09776 7.70711C6.69105 7.31658 6.69105 6.68342 7.09776 6.29289C7.50448 5.90237 8.16389 5.90237 8.57061 6.29289C10.594 8.2357 13.4061 8.2357 15.4295 6.29289C15.8362 5.90237 16.4956 5.90237 16.9023 6.29289C17.309 6.68342 17.309 7.31658 16.9023 7.70711C14.0655 10.431 9.93455 10.431 7.09776 7.70711Z"
                                    fill="#767BFA"
                                  />

                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.441643 5.89359C-0.147214 6.53437 -0.147214 7.46563 0.441643 8.10641C2.25469 10.0793 6.54403 14 12 14C17.456 14 21.7453 10.0793 23.5584 8.10641C24.1472 7.46563 24.1472 6.53437 23.5584 5.89359C21.7453 3.92067 17.456 0 12 0C6.54403 0 2.25469 3.92067 0.441643 5.89359ZM12 2C7.68339 2 4.04578 5.05757 2.20582 7C4.04578 8.94243 7.68339 12 12 12C16.3166 12 19.9542 8.94243 21.7942 7C19.9542 5.05757 16.3166 2 12 2Z"
                                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.09774 7.70711C6.69102 7.31658 6.69102 6.68342 7.09774 6.29289C7.50445 5.90237 8.16387 5.90237 8.57059 6.29289C10.5939 8.2357 13.4061 8.2357 15.4294 6.29289C15.8361 5.90237 16.4956 5.90237 16.9023 6.29289C17.309 6.68342 17.309 7.31658 16.9023 7.70711C14.0655 10.431 9.93453 10.431 7.09774 7.70711Z"
                                    fill="#767BFA"
                                  />
                                  <path
                                    d="M16.9023 6.04289C17.309 6.43342 17.309 7.06658 16.9023 7.45711C16.4956 7.84763 15.8361 7.84763 15.4294 7.45711C13.4061 5.5143 10.5939 5.5143 8.57059 7.45711C8.16387 7.84763 7.50445 7.84763 7.09774 7.45711C6.69102 7.06658 6.69102 6.43342 7.09774 6.04289C9.93453 3.31904 14.0655 3.31904 16.9023 6.04289Z"
                                    fill="#767BFA"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.441643 5.89359C-0.147214 6.53437 -0.147214 7.46563 0.441643 8.10641C2.25469 10.0793 6.54403 14 12 14C17.456 14 21.7453 10.0793 23.5584 8.10641C24.1472 7.46563 24.1472 6.53437 23.5584 5.89359C21.7453 3.92067 17.456 0 12 0C6.54403 0 2.25469 3.92067 0.441643 5.89359ZM12 2C7.68339 2 4.04578 5.05757 2.20582 7C4.04578 8.94243 7.68339 12 12 12C16.3166 12 19.9542 8.94243 21.7942 7C19.9542 5.05757 16.3166 2 12 2Z"
                                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                  />
                                </svg>
                              )}
                            </div>
                          }
                        />

                        <InfoDiv
                          customClass={"settings"}
                          text={
                            <div
                              className="w-100 cp flex al-ce jc-sp"
                              onClick={() => {
                                setNavSettings({
                                  ...navSettings,
                                  isSalesHidden: !navSettings.isSalesHidden,
                                });
                              }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14.6835 1.33293C13.3359 -0.44431 10.6641 -0.44431 9.31652 1.33293L8.19449 2.81269L6.35475 2.55974C4.14516 2.25593 2.25593 4.14516 2.55974 6.35475L2.81269 8.19449L1.33293 9.31652C-0.44431 10.6641 -0.44431 13.3359 1.33293 14.6835L2.81269 15.8055L2.55973 17.6453C2.25593 19.8548 4.14516 21.7441 6.35475 21.4403L8.19449 21.1873L9.31652 22.6671C10.6641 24.4443 13.3359 24.4443 14.6835 22.6671L15.8055 21.1873L17.6453 21.4403C19.8548 21.7441 21.7441 19.8548 21.4403 17.6453L21.1873 15.8055L22.6671 14.6835C24.4443 13.3359 24.4443 10.6641 22.6671 9.31652L21.1873 8.19449L21.4403 6.35475C21.7441 4.14516 19.8548 2.25593 17.6453 2.55973L15.8055 2.81269L14.6835 1.33293ZM11.1055 2.68943C11.5547 2.09702 12.4453 2.09702 12.8945 2.68943L14.0165 4.16919C14.5067 4.81567 15.3076 5.1474 16.1113 5.03688L17.9511 4.78393C18.6876 4.68266 19.3173 5.31241 19.2161 6.04894L18.9631 7.88868C18.8526 8.69243 19.1843 9.49328 19.8308 9.98348L21.3106 11.1055C21.903 11.5547 21.903 12.4453 21.3106 12.8945L19.8308 14.0165C19.1843 14.5067 18.8526 15.3076 18.9631 16.1113L19.2161 17.9511C19.3173 18.6876 18.6876 19.3173 17.9511 19.2161L16.1113 18.9631C15.3076 18.8526 14.5067 19.1843 14.0165 19.8308L12.8945 21.3106C12.4453 21.903 11.5547 21.903 11.1055 21.3106L9.98348 19.8308C9.49328 19.1843 8.69243 18.8526 7.88868 18.9631L6.04894 19.2161C5.31241 19.3173 4.68266 18.6876 4.78393 17.9511L5.03689 16.1113C5.1474 15.3076 4.81567 14.5067 4.16919 14.0165L2.68943 12.8945C2.09702 12.4453 2.09702 11.5547 2.68943 11.1055L4.16919 9.98348C4.81567 9.49328 5.1474 8.69243 5.03689 7.88868L4.78393 6.04894C4.68266 5.31241 5.31241 4.68266 6.04894 4.78393L7.88868 5.03688C8.69243 5.1474 9.49328 4.81567 9.98348 4.16919L11.1055 2.68943Z"
                                  fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                />
                                <path
                                  d="M9 11C8.44772 11 8 10.5523 8 10L8 9C8 8.44772 8.44772 8 9 8C9.55228 8 10 8.44772 10 9V10C10 10.5523 9.55228 11 9 11Z"
                                  fill="#767BFA"
                                />
                                <path
                                  d="M8.30602 15.694C7.89799 15.286 7.89799 14.6244 8.30602 14.2164L14.2164 8.30602C14.6244 7.89799 15.286 7.89799 15.694 8.30602C16.102 8.71405 16.102 9.37559 15.694 9.78361L9.78361 15.694C9.37559 16.102 8.71405 16.102 8.30602 15.694Z"
                                  fill="#767BFA"
                                />
                                <path
                                  d="M14 15C14 15.5523 14.4477 16 15 16C15.5523 16 16 15.5523 16 15V14C16 13.4477 15.5523 13 15 13C14.4477 13 14 13.4477 14 14V15Z"
                                  fill="#767BFA"
                                />
                              </svg>

                              <div className="text">{translate("sales")}</div>
                              {navSettings.isSalesHidden ? (
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.09776 7.70711C6.69105 7.31658 6.69105 6.68342 7.09776 6.29289C7.50448 5.90237 8.16389 5.90237 8.57061 6.29289C10.594 8.2357 13.4061 8.2357 15.4295 6.29289C15.8362 5.90237 16.4956 5.90237 16.9023 6.29289C17.309 6.68342 17.309 7.31658 16.9023 7.70711C14.0655 10.431 9.93455 10.431 7.09776 7.70711Z"
                                    fill="#767BFA"
                                  />

                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.441643 5.89359C-0.147214 6.53437 -0.147214 7.46563 0.441643 8.10641C2.25469 10.0793 6.54403 14 12 14C17.456 14 21.7453 10.0793 23.5584 8.10641C24.1472 7.46563 24.1472 6.53437 23.5584 5.89359C21.7453 3.92067 17.456 0 12 0C6.54403 0 2.25469 3.92067 0.441643 5.89359ZM12 2C7.68339 2 4.04578 5.05757 2.20582 7C4.04578 8.94243 7.68339 12 12 12C16.3166 12 19.9542 8.94243 21.7942 7C19.9542 5.05757 16.3166 2 12 2Z"
                                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.09774 7.70711C6.69102 7.31658 6.69102 6.68342 7.09774 6.29289C7.50445 5.90237 8.16387 5.90237 8.57059 6.29289C10.5939 8.2357 13.4061 8.2357 15.4294 6.29289C15.8361 5.90237 16.4956 5.90237 16.9023 6.29289C17.309 6.68342 17.309 7.31658 16.9023 7.70711C14.0655 10.431 9.93453 10.431 7.09774 7.70711Z"
                                    fill="#767BFA"
                                  />
                                  <path
                                    d="M16.9023 6.04289C17.309 6.43342 17.309 7.06658 16.9023 7.45711C16.4956 7.84763 15.8361 7.84763 15.4294 7.45711C13.4061 5.5143 10.5939 5.5143 8.57059 7.45711C8.16387 7.84763 7.50445 7.84763 7.09774 7.45711C6.69102 7.06658 6.69102 6.43342 7.09774 6.04289C9.93453 3.31904 14.0655 3.31904 16.9023 6.04289Z"
                                    fill="#767BFA"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.441643 5.89359C-0.147214 6.53437 -0.147214 7.46563 0.441643 8.10641C2.25469 10.0793 6.54403 14 12 14C17.456 14 21.7453 10.0793 23.5584 8.10641C24.1472 7.46563 24.1472 6.53437 23.5584 5.89359C21.7453 3.92067 17.456 0 12 0C6.54403 0 2.25469 3.92067 0.441643 5.89359ZM12 2C7.68339 2 4.04578 5.05757 2.20582 7C4.04578 8.94243 7.68339 12 12 12C16.3166 12 19.9542 8.94243 21.7942 7C19.9542 5.05757 16.3166 2 12 2Z"
                                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                  />
                                </svg>
                              )}
                            </div>
                          }
                        />

                        <InfoDiv
                          customClass={"settings"}
                          text={
                            <div
                              className="w-100 cp flex al-ce jc-sp"
                              onClick={() => {
                                setNavSettings({
                                  ...navSettings,
                                  isExpenseHidden: !navSettings.isExpenseHidden,
                                });
                              }}
                            >
                              <svg
                                width="21"
                                height="22"
                                viewBox="0 0 21 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.60066 0.109822C1.05439 -0.146584 0.390135 0.0612903 0.11697 0.574102C-0.156132 1.08688 0.0652861 1.71047 0.611553 1.96688L1.72127 2.48775C2.06382 2.64853 2.29338 2.96424 2.32807 3.32228C2.35513 3.60154 2.38136 3.87825 2.40727 4.15224L2.41034 4.18485C3.0317 10.7546 3.50076 15.7143 11.5566 15.7143C19.9614 15.7143 20.5175 10.1148 21 5.2381L19.5554 5.54048C19.1367 5.62814 18.8207 5.9726 18.7691 6.39731C18.3239 10.0545 17.7371 13.619 11.5566 13.619C6.47771 13.619 5.38616 11.1131 4.92301 7.37917C4.85044 6.79416 5.315 6.28572 5.9045 6.28572H19.99C20.503 6.28572 20.9329 5.89744 20.9848 5.38703L21 5.2381C21 4.65952 20.5297 4.19048 19.9494 4.19048H5.54448C5.02508 4.19048 4.59326 3.79273 4.54425 3.27565C4.5398 3.22863 4.53528 3.18152 4.53071 3.13432C4.42664 2.0602 3.73802 1.11307 2.71038 0.630722L1.60066 0.109822Z"
                                  fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                />
                                <path
                                  d="M7.35413 22C8.51457 22 9.45532 21.062 9.45532 19.9048C9.45532 18.7476 8.51457 17.8096 7.35413 17.8096C6.19368 17.8096 5.25293 18.7476 5.25293 19.9048C5.25293 21.062 6.19368 22 7.35413 22Z"
                                  fill="#767BFA"
                                />
                                <path
                                  d="M17.8601 19.9048C17.8601 21.062 16.9193 22 15.7589 22C14.5985 22 13.6577 21.062 13.6577 19.9048C13.6577 18.7476 14.5985 17.8096 15.7589 17.8096C16.9193 17.8096 17.8601 18.7476 17.8601 19.9048Z"
                                  fill="#767BFA"
                                />
                              </svg>

                              <div className="text">
                                {translate("expenses")}
                              </div>
                              {navSettings.isExpenseHidden ? (
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.09776 7.70711C6.69105 7.31658 6.69105 6.68342 7.09776 6.29289C7.50448 5.90237 8.16389 5.90237 8.57061 6.29289C10.594 8.2357 13.4061 8.2357 15.4295 6.29289C15.8362 5.90237 16.4956 5.90237 16.9023 6.29289C17.309 6.68342 17.309 7.31658 16.9023 7.70711C14.0655 10.431 9.93455 10.431 7.09776 7.70711Z"
                                    fill="#767BFA"
                                  />

                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.441643 5.89359C-0.147214 6.53437 -0.147214 7.46563 0.441643 8.10641C2.25469 10.0793 6.54403 14 12 14C17.456 14 21.7453 10.0793 23.5584 8.10641C24.1472 7.46563 24.1472 6.53437 23.5584 5.89359C21.7453 3.92067 17.456 0 12 0C6.54403 0 2.25469 3.92067 0.441643 5.89359ZM12 2C7.68339 2 4.04578 5.05757 2.20582 7C4.04578 8.94243 7.68339 12 12 12C16.3166 12 19.9542 8.94243 21.7942 7C19.9542 5.05757 16.3166 2 12 2Z"
                                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.09774 7.70711C6.69102 7.31658 6.69102 6.68342 7.09774 6.29289C7.50445 5.90237 8.16387 5.90237 8.57059 6.29289C10.5939 8.2357 13.4061 8.2357 15.4294 6.29289C15.8361 5.90237 16.4956 5.90237 16.9023 6.29289C17.309 6.68342 17.309 7.31658 16.9023 7.70711C14.0655 10.431 9.93453 10.431 7.09774 7.70711Z"
                                    fill="#767BFA"
                                  />
                                  <path
                                    d="M16.9023 6.04289C17.309 6.43342 17.309 7.06658 16.9023 7.45711C16.4956 7.84763 15.8361 7.84763 15.4294 7.45711C13.4061 5.5143 10.5939 5.5143 8.57059 7.45711C8.16387 7.84763 7.50445 7.84763 7.09774 7.45711C6.69102 7.06658 6.69102 6.43342 7.09774 6.04289C9.93453 3.31904 14.0655 3.31904 16.9023 6.04289Z"
                                    fill="#767BFA"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.441643 5.89359C-0.147214 6.53437 -0.147214 7.46563 0.441643 8.10641C2.25469 10.0793 6.54403 14 12 14C17.456 14 21.7453 10.0793 23.5584 8.10641C24.1472 7.46563 24.1472 6.53437 23.5584 5.89359C21.7453 3.92067 17.456 0 12 0C6.54403 0 2.25469 3.92067 0.441643 5.89359ZM12 2C7.68339 2 4.04578 5.05757 2.20582 7C4.04578 8.94243 7.68339 12 12 12C16.3166 12 19.9542 8.94243 21.7942 7C19.9542 5.05757 16.3166 2 12 2Z"
                                    fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                                  />
                                </svg>
                              )}
                            </div>
                          }
                        />

                        <div className="flex end al-fe">
                          <Button
                            text={translate("save")}
                            imgSrc={<img src={add} alt="add icon" />}
                            onClick={() => {
                              try {
                                NavSettings(StoreId, {
                                  ...navSettings,
                                });
                                SuccessToast(
                                  translate("success"),
                                  translate("settingsSaved")
                                );
                              } catch (e) {
                                ErrorToast(translate("error"), e.message);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div />
                  </div>
                </>
              )}

              {isOwner && (
                <div className="two-grid mt-custom">
                  <div>
                    <div className="flex al-ce jc-sp headersection">
                      <div className="smtext">
                        {translate("generalStaffsPermissions")}
                      </div>
                    </div>
                    <div className="px-25">
                      <CustomCheckbox
                        checked={genStaffSettings.hideDashBtns}
                        handleChange={() =>
                          setGenStaffSettings({
                            ...genStaffSettings,
                            hideDashBtns: !genStaffSettings.hideDashBtns,
                          })
                        }
                        label={translate(
                          "dashboardStoreInformationHideCreateEditDeleteDiscountCodeButton"
                        )}
                        dark={dark}
                      />
                      <CustomCheckbox
                        checked={genStaffSettings.hideInvImport}
                        handleChange={() =>
                          setGenStaffSettings({
                            ...genStaffSettings,
                            hideInvImport: !genStaffSettings.hideInvImport,
                          })
                        }
                        label={translate("inventoryHideImportButton")}
                        dark={dark}
                      />
                      <CustomCheckbox
                        checked={genStaffSettings.hideInvDownload}
                        handleChange={() =>
                          setGenStaffSettings({
                            ...genStaffSettings,
                            hideInvDownload: !genStaffSettings.hideInvDownload,
                          })
                        }
                        label={translate("inventoryHideDownloadButton")}
                        dark={dark}
                      />
                      <CustomCheckbox
                        checked={genStaffSettings.hideInvBtns}
                        handleChange={() =>
                          setGenStaffSettings({
                            ...genStaffSettings,
                            hideInvBtns: !genStaffSettings.hideInvBtns,
                          })
                        }
                        label={translate("inventoryHideAddNewEditDeleteButton")}
                        dark={dark}
                      />{" "}
                      <CustomCheckbox
                        checked={genStaffSettings.hideProductQr}
                        handleChange={() =>
                          setGenStaffSettings({
                            ...genStaffSettings,
                            hideProductQr: !genStaffSettings.hideProductQr,
                          })
                        }
                        label={translate("productLabelsHideqrCodeSettingsPage")}
                        dark={dark}
                      />{" "}
                      <CustomCheckbox
                        checked={genStaffSettings.hidePayoutConfimation}
                        handleChange={() =>
                          setGenStaffSettings({
                            ...genStaffSettings,
                            hidePayoutConfimation: !genStaffSettings.hidePayoutConfimation,
                          })
                        }
                        label={translate(
                          "consignmentsSummaryHidePayoutConfirmationButton"
                        )}
                        dark={dark}
                      />{" "}
                      <CustomCheckbox
                        checked={genStaffSettings.hideConsigneeBtns}
                        handleChange={() =>
                          setGenStaffSettings({
                            ...genStaffSettings,
                            hideConsigneeBtns: !genStaffSettings.hideConsigneeBtns,
                          })
                        }
                        label={translate(
                          "consignmentsConsigneeHideAddNewEditDeleteButton"
                        )}
                        dark={dark}
                      />{" "}
                      <CustomCheckbox
                        checked={genStaffSettings.hideConsigneeInvBtns}
                        handleChange={() =>
                          setGenStaffSettings({
                            ...genStaffSettings,
                            hideConsigneeInvBtns: !genStaffSettings.hideConsigneeInvBtns,
                          })
                        }
                        label={translate(
                          "consignmentsInventoryHideAddNewEditDeleteButton"
                        )}
                        dark={dark}
                      />{" "}
                      <CustomCheckbox
                        checked={genStaffSettings.hideReceiptSettings}
                        handleChange={() =>
                          setGenStaffSettings({
                            ...genStaffSettings,
                            hideReceiptSettings: !genStaffSettings.hideReceiptSettings,
                          })
                        }
                        label={translate("salesHideRecieptSettingsPage")}
                        dark={dark}
                      />{" "}
                      <CustomCheckbox
                        checked={genStaffSettings.hideExpensePage}
                        handleChange={() =>
                          setGenStaffSettings({
                            ...genStaffSettings,
                            hideExpensePage: !genStaffSettings.hideExpensePage,
                          })
                        }
                        label={translate("hideExpensesPage")}
                        dark={dark}
                      />
                      <div className="flex end btncontainer">
                        <Button
                          text={translate("save")}
                          imgSrc={<img src={add} alt="add icon" />}
                          onClick={() => {
                            try {
                              editAllStaffSettings(StoreId, {
                                ...genStaffSettings,
                              });
                              SuccessToast(
                                translate("success"),
                                translate("settingsSaved")
                              );
                            } catch (e) {
                              ErrorToast(translate("error"), e.message);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="flex al-ce jc-sp headersection">
                      <div className="smtext">
                        {translate("specificStaffPermission")}
                      </div>
                      <div className="select-container abs">
                        <div className="label">{translate("staffId")}</div>
                        <SelectLabels
                          disabled={!isOwner}
                          dark={dark}
                          name={"staff"}
                          value={selectedStaff}
                          updateValue={(e) => {
                            setSelectedStaff(e.target.value);
                          }}
                          placeholderText={GetTranslateString("staff")}
                          options={[...staff]}
                        />
                      </div>
                    </div>
                    <div className="px-25">
                      <CustomCheckbox
                        checked={specificStaffSettings.hideDashBtns}
                        handleChange={() =>
                          setSpecificStaffSettings({
                            ...specificStaffSettings,
                            hideDashBtns: !specificStaffSettings.hideDashBtns,
                          })
                        }
                        label={translate(
                          "dashboardStoreInformationHideCreateEditDeleteDiscountCodeButton"
                        )}
                        dark={dark}
                      />
                      <CustomCheckbox
                        checked={specificStaffSettings.hideInvImport}
                        handleChange={() =>
                          setSpecificStaffSettings({
                            ...specificStaffSettings,
                            hideInvImport: !specificStaffSettings.hideInvImport,
                          })
                        }
                        label={translate("inventoryHideImportButton")}
                        dark={dark}
                      />
                      <CustomCheckbox
                        checked={specificStaffSettings.hideInvDownload}
                        handleChange={() =>
                          setSpecificStaffSettings({
                            ...specificStaffSettings,
                            hideInvDownload: !specificStaffSettings.hideInvDownload,
                          })
                        }
                        label={translate("inventoryHideDownloadButton")}
                        dark={dark}
                      />
                      <CustomCheckbox
                        checked={specificStaffSettings.hideInvBtns}
                        handleChange={() =>
                          setSpecificStaffSettings({
                            ...specificStaffSettings,
                            hideInvBtns: !specificStaffSettings.hideInvBtns,
                          })
                        }
                        label={translate("inventoryHideAddNewEditDeleteButton")}
                        dark={dark}
                      />{" "}
                      <CustomCheckbox
                        checked={specificStaffSettings.hideProductQr}
                        handleChange={() =>
                          setSpecificStaffSettings({
                            ...specificStaffSettings,
                            hideProductQr: !specificStaffSettings.hideProductQr,
                          })
                        }
                        label={translate("productLabelsHideqrCodeSettingsPage")}
                        dark={dark}
                      />{" "}
                      <CustomCheckbox
                        checked={specificStaffSettings.hidePayoutConfimation}
                        handleChange={() =>
                          setSpecificStaffSettings({
                            ...specificStaffSettings,
                            hidePayoutConfimation: !specificStaffSettings.hidePayoutConfimation,
                          })
                        }
                        label={translate(
                          "consignmentsSummaryHidePayoutConfirmationButton"
                        )}
                        dark={dark}
                      />{" "}
                      <CustomCheckbox
                        checked={specificStaffSettings.hideConsigneeBtns}
                        handleChange={() =>
                          setSpecificStaffSettings({
                            ...specificStaffSettings,
                            hideConsigneeBtns: !specificStaffSettings.hideConsigneeBtns,
                          })
                        }
                        label={translate(
                          "consignmentsConsigneeHideAddNewEditDeleteButton"
                        )}
                        dark={dark}
                      />{" "}
                      <CustomCheckbox
                        checked={specificStaffSettings.hideConsigneeInvBtns}
                        handleChange={() =>
                          setSpecificStaffSettings({
                            ...specificStaffSettings,
                            hideConsigneeInvBtns: !specificStaffSettings.hideConsigneeInvBtns,
                          })
                        }
                        label={translate(
                          "consignmentsInventoryHideAddNewEditDeleteButton"
                        )}
                        dark={dark}
                      />{" "}
                      <CustomCheckbox
                        checked={specificStaffSettings.hideReceiptSettings}
                        handleChange={() =>
                          setSpecificStaffSettings({
                            ...specificStaffSettings,
                            hideReceiptSettings: !specificStaffSettings.hideReceiptSettings,
                          })
                        }
                        label={translate("salesHideRecieptSettingsPage")}
                        dark={dark}
                      />{" "}
                      <CustomCheckbox
                        checked={specificStaffSettings.hideExpensePage}
                        handleChange={() =>
                          setSpecificStaffSettings({
                            ...specificStaffSettings,
                            hideExpensePage: !specificStaffSettings.hideExpensePage,
                          })
                        }
                        label={translate("hideExpensesPage")}
                        dark={dark}
                      />
                      <div className="flex end btncontainer pr-15">
                        <Button
                          text={translate("save")}
                          imgSrc={<img src={add} alt="add icon" />}
                          onClick={() => {
                            if (selectedStaff) {
                              try {
                                editStaffSettings(StoreId, selectedStaff, {
                                  ...genStaffSettings,
                                  ...specificStaffSettings,
                                });
                                SuccessToast(
                                  translate("success"),
                                  translate("settingsSaved")
                                );
                              } catch (e) {
                                ErrorToast(translate("error"), e.message);
                                console.log(e);
                              }
                            } else {
                              ErrorToast(
                                translate("error"),
                                translate("selectAStaff")
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ marginBottom: "1rem" }}>
                <div className="flex al-ce jc-sp headersection">
                  <div className="smtext">Payment Options</div>
                </div>
                <div className="px-25 two-grid">
                  <div className="flex">
                    <p className="pad-5">
                      Configure payment methods, or manage plans{" "}
                    </p>
                    <Button
                      text={"Open Panel"}
                      customClass={"openPanel-btn"}
                      onClick={() => {
                        window.open(
                          `https://us-central1-ingenium-337101.cloudfunctions.net/planUpdate?customer=${localStorage.getItem(
                            "customer"
                          )}&type=restaurant`,
                          "_blank"
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <div className="flex al-ce jc-sp headersection">
                  <div className="smtext">Sell on Katch</div>
                </div>
                <div className="px-25">
                  <div className="">
                    <p className="pad-5">
                      Get started selling on Katch with our partner stripe.
                      <br />
                      Use your business existing stripe account or create a new
                      one.
                    </p>
                    <br />
                    <div className="flex">
                      <img
                        style={{
                          height: "25px",
                          width: "25px",
                          marginRight: "10px",
                        }}
                        src={
                          katchStatus === "ready"
                            ? "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png"
                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/Antu_application-exit.svg/1920px-Antu_application-exit.svg.png"
                        }
                      />
                      <p>
                        {katchStatus
                          ? katchStatus === "ready"
                            ? "Your account is setup and ready to sell on Katch."
                            : "Your account is not ready to sell on Katch"
                          : "Your account is not validated"}
                      </p>
                    </div>
                    <br />
                    <Button
                      text={"Onboard Stripe"}
                      customClass={"openPanel-btn"}
                      onClick={() => {
                        window.open(
                          `https://katch-358301.ew.r.appspot.com/partner/create?token=${localStorage.getItem(
                            "token"
                          )}`,
                          "_blank"
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  paddingBottom: "1rem",
                }}
              >
                <div className="headersection">
                  <div className="smtext">
                    {translate("customerViewingScreenSettings")}
                  </div>
                </div>
                <div className="px-25">
                  <CustomerViewing />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
