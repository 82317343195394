import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import Close from "../../assets/close.svg";
import { StoreId } from "../../Constants";
import clockingIn from "../../firebase/staff/logs/clockingIn";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import { getTodaysDate } from "../utils/getTodayDate";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

export default function CheckInModal({ green, setGreen, dark }) {
  const initialValues = {
    mail: "",
    staffId: "",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "32.25rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",

    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  useEffect(() => {
    let clockedIn = localStorage.getItem("clockedIn");

    if (clockedIn) {
      setGreen(clockedIn);
    } else {
      localStorage.setItem("clockedIn", "false");
      setGreen("false");
    }
  }, []);

  const [fields, setFields] = useState(initialValues);
  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const clearValues = () => {
    setFields(initialValues);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setFields(initialValues);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        imgSrc={
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.15479 8.99999C4.11546 12.6346 5.32688 13.8461 9.00049 13.8461C12.6741 13.8461 13.8462 12.6346 13.8462 8.99999C13.8462 5.36538 12.6741 4.15384 9.00049 4.15384C5.32688 4.15384 4.19413 5.36538 4.15479 8.99999ZM5.53933 9.01498C5.52049 10.7555 5.82416 11.4668 6.1644 11.807C6.509 12.1517 7.23526 12.4615 9.00049 12.4615C10.7652 12.4615 11.4793 12.1518 11.8186 11.8102C12.1601 11.4665 12.4616 10.7498 12.4616 8.99999C12.4616 7.25014 12.1601 6.5335 11.8186 6.18976C11.4793 5.8482 10.7652 5.53845 9.00049 5.53845C7.23633 5.53845 6.53433 5.84806 6.20028 6.18654C5.85757 6.5338 5.55837 7.25574 5.53933 9.01498Z"
              fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
            />
            <path
              d="M9.69231 0.692308C9.69231 0.309957 9.38235 0 9 0C8.61765 0 8.30769 0.309957 8.30769 0.692308V2.07692C8.30769 2.45927 8.61765 2.76923 9 2.76923C9.38235 2.76923 9.69231 2.45927 9.69231 2.07692V0.692308Z"
              fill="#767BFA"
            />
            <path
              d="M9.69231 15.9231C9.69231 15.5407 9.38235 15.2308 9 15.2308C8.61765 15.2308 8.30769 15.5407 8.30769 15.9231V17.3077C8.30769 17.69 8.61765 18 9 18C9.38235 18 9.69231 17.69 9.69231 17.3077V15.9231Z"
              fill="#767BFA"
            />
            <path
              d="M18 9C18 9.38235 17.69 9.69231 17.3077 9.69231H15.9231C15.5407 9.69231 15.2308 9.38235 15.2308 9C15.2308 8.61765 15.5407 8.30769 15.9231 8.30769H17.3077C17.69 8.30769 18 8.61765 18 9Z"
              fill="#767BFA"
            />
            <path
              d="M2.07692 9.69231C2.45927 9.69231 2.76923 9.38235 2.76923 9C2.76923 8.61765 2.45927 8.30769 2.07692 8.30769H0.692308C0.309957 8.30769 0 8.61765 0 9C0 9.38235 0.309957 9.69231 0.692308 9.69231H2.07692Z"
              fill="#767BFA"
            />
            <path
              d="M2.11997 2.11997C2.39033 1.84961 2.82868 1.84961 3.09904 2.11997L4.0028 3.02373C4.27316 3.29409 4.27316 3.73244 4.0028 4.0028C3.73244 4.27316 3.29409 4.27316 3.02373 4.0028L2.11997 3.09904C1.84961 2.82868 1.84961 2.39033 2.11997 2.11997Z"
              fill="#767BFA"
            />
            <path
              d="M3.09904 16.1447C2.82868 16.4151 2.39033 16.4151 2.11997 16.1447C1.84961 15.8744 1.84961 15.436 2.11997 15.1656L3.02373 14.2619C3.29409 13.9915 3.73244 13.9915 4.0028 14.2619C4.27316 14.5322 4.27316 14.9706 4.0028 15.241L3.09904 16.1447Z"
              fill="#767BFA"
            />
            <path
              d="M16.1447 2.11997C15.8744 1.84961 15.436 1.84961 15.1656 2.11997L14.2619 3.02373C13.9915 3.29409 13.9915 3.73244 14.2619 4.0028C14.5322 4.27316 14.9706 4.27316 15.241 4.0028L16.1447 3.09904C16.4151 2.82868 16.4151 2.39033 16.1447 2.11997Z"
              fill="#767BFA"
            />
            <path
              d="M15.1656 16.1447C15.436 16.4151 15.8744 16.4151 16.1447 16.1447C16.4151 15.8744 16.4151 15.436 16.1447 15.1656L15.241 14.2619C14.9706 13.9915 14.5322 13.9915 14.2619 14.2619C13.9915 14.5322 13.9915 14.9706 14.2619 15.241L15.1656 16.1447Z"
              fill="#767BFA"
            />
          </svg>
        }
        text={"Clock In"}
      />
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="header">
              <div>Clocking In... </div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className="mb-20 input">
              <Input
                name="staffId"
                type={"text"}
                value={fields.staffId}
                updateValue={handleChange}
                placeholder={GetTranslateString("staffid")}
              />
            </div>
            <div className="mb-20 input">
              <Input
                name="mail"
                type={"email"}
                value={fields.mail}
                updateValue={handleChange}
                placeholder={GetTranslateString("email")}
              />
            </div>

            <div className="w-full jc-ce">
              <div className="px-20">
                <Button
                  imgSrc={
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.15479 8.99999C4.11546 12.6346 5.32688 13.8461 9.00049 13.8461C12.6741 13.8461 13.8462 12.6346 13.8462 8.99999C13.8462 5.36538 12.6741 4.15384 9.00049 4.15384C5.32688 4.15384 4.19413 5.36538 4.15479 8.99999ZM5.53933 9.01498C5.52049 10.7555 5.82416 11.4668 6.1644 11.807C6.509 12.1517 7.23526 12.4615 9.00049 12.4615C10.7652 12.4615 11.4793 12.1518 11.8186 11.8102C12.1601 11.4665 12.4616 10.7498 12.4616 8.99999C12.4616 7.25014 12.1601 6.5335 11.8186 6.18976C11.4793 5.8482 10.7652 5.53845 9.00049 5.53845C7.23633 5.53845 6.53433 5.84806 6.20028 6.18654C5.85757 6.5338 5.55837 7.25574 5.53933 9.01498Z"
                        fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
                      />
                      <path
                        d="M9.69231 0.692308C9.69231 0.309957 9.38235 0 9 0C8.61765 0 8.30769 0.309957 8.30769 0.692308V2.07692C8.30769 2.45927 8.61765 2.76923 9 2.76923C9.38235 2.76923 9.69231 2.45927 9.69231 2.07692V0.692308Z"
                        fill="#767BFA"
                      />
                      <path
                        d="M9.69231 15.9231C9.69231 15.5407 9.38235 15.2308 9 15.2308C8.61765 15.2308 8.30769 15.5407 8.30769 15.9231V17.3077C8.30769 17.69 8.61765 18 9 18C9.38235 18 9.69231 17.69 9.69231 17.3077V15.9231Z"
                        fill="#767BFA"
                      />
                      <path
                        d="M18 9C18 9.38235 17.69 9.69231 17.3077 9.69231H15.9231C15.5407 9.69231 15.2308 9.38235 15.2308 9C15.2308 8.61765 15.5407 8.30769 15.9231 8.30769H17.3077C17.69 8.30769 18 8.61765 18 9Z"
                        fill="#767BFA"
                      />
                      <path
                        d="M2.07692 9.69231C2.45927 9.69231 2.76923 9.38235 2.76923 9C2.76923 8.61765 2.45927 8.30769 2.07692 8.30769H0.692308C0.309957 8.30769 0 8.61765 0 9C0 9.38235 0.309957 9.69231 0.692308 9.69231H2.07692Z"
                        fill="#767BFA"
                      />
                      <path
                        d="M2.11997 2.11997C2.39033 1.84961 2.82868 1.84961 3.09904 2.11997L4.0028 3.02373C4.27316 3.29409 4.27316 3.73244 4.0028 4.0028C3.73244 4.27316 3.29409 4.27316 3.02373 4.0028L2.11997 3.09904C1.84961 2.82868 1.84961 2.39033 2.11997 2.11997Z"
                        fill="#767BFA"
                      />
                      <path
                        d="M3.09904 16.1447C2.82868 16.4151 2.39033 16.4151 2.11997 16.1447C1.84961 15.8744 1.84961 15.436 2.11997 15.1656L3.02373 14.2619C3.29409 13.9915 3.73244 13.9915 4.0028 14.2619C4.27316 14.5322 4.27316 14.9706 4.0028 15.241L3.09904 16.1447Z"
                        fill="#767BFA"
                      />
                      <path
                        d="M16.1447 2.11997C15.8744 1.84961 15.436 1.84961 15.1656 2.11997L14.2619 3.02373C13.9915 3.29409 13.9915 3.73244 14.2619 4.0028C14.5322 4.27316 14.9706 4.27316 15.241 4.0028L16.1447 3.09904C16.4151 2.82868 16.4151 2.39033 16.1447 2.11997Z"
                        fill="#767BFA"
                      />
                      <path
                        d="M15.1656 16.1447C15.436 16.4151 15.8744 16.4151 16.1447 16.1447C16.4151 15.8744 16.4151 15.436 16.1447 15.1656L15.241 14.2619C14.9706 13.9915 14.5322 13.9915 14.2619 14.2619C13.9915 14.5322 13.9915 14.9706 14.2619 15.241L15.1656 16.1447Z"
                        fill="#767BFA"
                      />
                    </svg>
                  }
                  onClick={async () => {
                    try {
                      if (green === "true") {
                        ErrorToast(translate("error"), translate("alreadyClockedIn"));
                      } else {
                        await clockingIn(
                          StoreId,
                          fields.staffId.trim(),
                          fields.mail.trim(),
                          getTodaysDate()
                        );
                        SuccessToast(translate("success"), translate("clockedIn"));
                        handleClose();
                        localStorage.setItem("clockedIn", "true");
                        setGreen("true");
                        clearValues();
                      }
                    } catch (e) {
                      ErrorToast(translate("error"), e.message);
                    }
                  }}
                  text={
                    green === "true" ? (
                      <span className="green">Clocked In</span>
                    ) : (
                      "Clock In"
                    )
                  }
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
