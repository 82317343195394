import { collection, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { searchAllSoldInventory } from "../../algolia/sales";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import { db } from "../../firebase/index";
import "../css/ConsignmentSalesTable.scoped.css";
import _ from "lodash";
import translate from "../../i18n/translate";
const ConsignmentSalesTable = ({ searchString, setSelectedSaleSummary }) => {
  const [data, updateData] = useState([]);
  const {
    sign,
    rates,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  let localSizing = localStorage.getItem("sizing");

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "Stores", StoreId, "SoldItems"),
      async (snapShot) => {
        let invData = [];
        if (searchString == "") {
          invData = snapShot.docs.map((e) => {
            return {
              ...e.data(),
              id: e.id,
            };
          });
        } else {
          let hits = await searchAllSoldInventory(searchString);

          invData = hits.map((e) => ({
            ...e,
            id: e.objectID.split("/")[1],
          }));

          snapShot.docChanges().forEach((change) => {
            const changedDoc = change.doc.data();
            const id = change.doc.id;

            if (change.type === "removed") {
              //let us remove from INVdata if this exists
              _.remove(invData, (k) => k.id === id);
            }

            if (change.type === "modified") {
              const exists = _.findIndex(invData, (k) => k.id === id);
              if (exists !== -1) {
                //This element was modified
                invData[exists] = {
                  ...changedDoc,
                  id: id,
                };
              }
            }
          });
        }

        updateData(invData);
      }
    );
    return () => {
      inventoryListener();
    };
  }, [searchString]);

  return (
    <>
      <div className="headersgrid">
        <div />
        <div>{translate("items")}</div>
        <div>{translate("style")}</div>
        <div>{translate("sizes")}</div>
        <div>{translate("consignment")}</div>
        <div>{translate("dateSold")}</div>
        <div>{translate("profitsLosses")}</div>
        <div>{translate("orderId")}</div>
      </div>
      <>
        <div className="items-container">
          <div className="items">
            {data.map((e, i) => {
              const name =
                e._highlightResult && e._highlightResult.name
                  ? e._highlightResult.name.value
                  : e.name;
              return (
                <div
                  key={i}
                  className="item"
                  onClick={() => {
                    setSelectedSaleSummary(e);
                  }}
                >
                  <img src={e.image} className="shoeimg bgwhite" alt="" />
                  <div
                    className="name"
                    dangerouslySetInnerHTML={{ __html: name }}
                  />

                  <div>{e.sku}</div>
                  <div>
                    {(e.category === "sneakers") 
                      ? sizingValues[localSizing][e.size]
                        ? sizingValues[localSizing][e.size]
                        : womenSizingValues[localSizing][e.size]
                        ? womenSizingValues[localSizing][e.size]
                        : girlSizingValues[localSizing][e.size]
                        ? girlSizingValues[localSizing][e.size]
                        : toddler[localSizing][e.size]
                        ? toddler[localSizing][e.size]
                        : young[localSizing][e.size]
                      : e.size}
                  </div>
                  <div>{e.consigneeID ? e.consigneeID : "-"}</div>
                  <div>
                    {new Date(e.dateSold)
                      .toDateString()
                      .split(" ")
                      .slice(1, 4)
                      .join(" ")}
                  </div>
                  <div
                    className={`${
                      e.soldPrice - e.costPrice >= 0 ? "green" : "red"
                    }`}
                  >
                    {e.soldPrice - e.costPrice >= 0 ? "+" : "-"}
                    {sign[localCurrency]}
                    {(
                      (e.soldPrice - e.costPrice) *
                      rates?.[localCurrency?.toUpperCase()]
                    ).toFixed(2)}
                  </div>
                  <div>{e.orderId}</div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    </>
  );
};

export default ConsignmentSalesTable;
