import React, { useContext, useRef } from "react";
import ReactToPrint from "react-to-print";
import Printer from "../../assets/printer.svg";
import "../css/ProductCardNoQR.scoped.css";
import Button from "../general/Button";
import { CurrencyContext } from "../../context/CurrencyContext";
import PrinterDark from "../../assets/printerdark.svg";
import { useBarcode } from "react-barcodes";
import translate from "../../i18n/translate";
export default function ProductCard({
  id,
  shoeImgnew,
  title,
  heading,
  prodTitle,
  newstyle,
  size,
  category,
  consignment,
  dark,
}) {
  const componentRef = useRef();

  // const name =
  //   item._highlightResult && item._highlightResult.name
  //     ? item._highlightResult.name.value
  //     : item.name;
  const {
    rates,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);
  let localSizing = localStorage.getItem("sizing");

  const { inputRef } = useBarcode({
    value: id,
    options: {
      text: `${prodTitle}-${size}`,
      height: 64,
      background: "#ffff",
      margin: 0,
      marginBottom: 10,
      textMargin: 3,
      fontSize: 14,
      font: "Inter",
    },
  });

  return (
    <>
      <div className="productWrapper">
        <div className="productBoxWrapper">
          <div className={`categoryBox ${category}`}>
            {category?.charAt(0)?.toUpperCase() + category?.slice(1)
              ? category?.charAt(0)?.toUpperCase() + category?.slice(1)
              : "Unknown"}
          </div>
          <div className="whiteBoxWrapnew">
            <img className="shoeImgnew" src={shoeImgnew} alt="show img" />
            <div className="prodTitle1">{title}</div>
            <div className="heading">{heading}</div>
            <div className="sideDiv" ref={componentRef}>
              <div className="newstyle">
                <span>{translate("style")}:</span>
                {newstyle}
              </div>
              <div className="size">
                <span>{translate("size")}:</span>
                {category === "sneakers"
                  ? sizingValues[localSizing][size]
                    ? sizingValues[localSizing][size]
                    : womenSizingValues[localSizing][size]
                    ? womenSizingValues[localSizing][size]
                    : girlSizingValues[localSizing][size]
                    ? girlSizingValues[localSizing][size]
                    : toddler[localSizing][size]
                    ? toddler[localSizing][size]
                    : young[localSizing][size]
                  : size}
              </div>
              <div className="consignment">
                <span>{translate("consignment")}:</span>
                {consignment}
              </div>
              <svg className="barcode" ref={inputRef} />
            </div>
            <div className="BtnforCard">
              <ReactToPrint
                bodyClass="qrprint1"
                trigger={() => (
                  <Button
                    customClass={"lightbtn"}
                    imgSrc={
                      <img src={dark ? PrinterDark : Printer} alt="printer" />
                    }
                    text={translate("print")}
                  />
                )}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
