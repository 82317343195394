import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import _, { isNull } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import Close from "../../assets/close.svg";
import Delete from "../../assets/delete.svg";
import DeleteDark from "../../assets/deletedark.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import constants from "../../firebase/constants";
import batchCreateInventory from "../../firebase/inventory/batchCreateInventory";
import createUIproduct from "../../firebase/products/createUIproduct";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import Loader from "../general/Loader";
import Select from "../general/Select";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import NewItemInventoryGrid from "./NewItemInventoryGrid";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

export default function TransitionsModal({ dark }) {
  const initialValues = {
    name: "",
    styleCode: "",
    sizing: "",
    category: "",
    condn: "",
    purPrice: "",
    purDate: "",
    platform: "",
    lastSale: "",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const {
    rates,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);

  let localCurrency = localStorage.getItem("currency");
  let localSizing = localStorage.getItem("sizing");

  const [savedItems, updateSavedItems] = useState([]);
  const [isDisabled, updateDisabled] = useState(false);
  const [fields, setFields] = useState(initialValues);
  const [loading, toggleLoading] = useState(false);
  const [addLoading, toggleAddLoading] = useState(false);

  const sizingOptions = {
    "": [{ label: "NA", value: "" }],
    collectibles: [{ label: "NA", value: "" }],
    arts: [{ label: "NA", value: "" }],
    electronics: [{ label: "NA", value: "" }],
    streetwear: [
      {
        label: "XXS",
        value: "XXS",
      },
      {
        label: "XS",
        value: "XS",
      },
      {
        label: "S",
        value: "S",
      },
      {
        label: "M",
        value: "M",
      },
      {
        label: "L",
        value: "L",
      },
      {
        label: "XL",
        value: "XL",
      },
      {
        label: "XXL",
        value: "XXL",
      },
      {
        label: "XXXL",
        value: "XXXL",
      },
    ],
    sneakers: [
      {
        label: sizingValues[localSizing]["4"],
        value: 4,
      },
      {
        label: sizingValues[localSizing]["4.5"],
        value: 4.5,
      },
      {
        label: sizingValues[localSizing]["5"],
        value: 5,
      },
      {
        label: sizingValues[localSizing]["5.5"],
        value: 5.5,
      },
      {
        label: sizingValues[localSizing]["6"],
        value: 6,
      },
      {
        label: sizingValues[localSizing]["6.5"],
        value: 6.5,
      },
      {
        label: sizingValues[localSizing]["7"],
        value: 7,
      },
      {
        label: sizingValues[localSizing]["7.5"],
        value: 7.5,
      },
      {
        label: sizingValues[localSizing]["8"],
        value: 8,
      },
      {
        label: sizingValues[localSizing]["8.5"],
        value: 8.5,
      },
      {
        label: sizingValues[localSizing]["9"],
        value: 9,
      },
      {
        label: sizingValues[localSizing]["9.5"],
        value: 9.5,
      },
      {
        label: sizingValues[localSizing]["10"],
        value: 10,
      },
      {
        label: sizingValues[localSizing]["10.5"],
        value: 10.5,
      },
      {
        label: sizingValues[localSizing]["11"],
        value: 11,
      },
      {
        label: sizingValues[localSizing]["11.5"],
        value: 11.5,
      },
      {
        label: sizingValues[localSizing]["12"],
        value: 12,
      },
      {
        label: sizingValues[localSizing]["12.5"],
        value: 12.5,
      },
      {
        label: sizingValues[localSizing]["13"],
        value: 13,
      },
      {
        label: sizingValues[localSizing]["13.5"],
        value: 13.5,
      },
      {
        label: sizingValues[localSizing]["14"],
        value: 14,
      },
      {
        label: sizingValues[localSizing]["14.5"],
        value: 14.5,
      },
      {
        label: sizingValues[localSizing]["15"],
        value: 15,
      },
      {
        label: sizingValues[localSizing]["16"],
        value: 16,
      },
      {
        label: sizingValues[localSizing]["17"],
        value: 17,
      },
      {
        label: womenSizingValues[localSizing]["5W"],
        value: "5W",
      },
      {
        label: womenSizingValues[localSizing]["5.5W"],
        value: "5.5W",
      },
      {
        label: womenSizingValues[localSizing]["6W"],
        value: "6W",
      },
      {
        label: womenSizingValues[localSizing]["6.5W"],
        value: "6.5W",
      },
      {
        label: womenSizingValues[localSizing]["7W"],
        value: "7W",
      },
      {
        label: womenSizingValues[localSizing]["7.5W"],
        value: "7.5W",
      },
      {
        label: womenSizingValues[localSizing]["8W"],
        value: "8W",
      },
      {
        label: womenSizingValues[localSizing]["8.5W"],
        value: "8.5W",
      },
      {
        label: womenSizingValues[localSizing]["9W"],
        value: "9W",
      },
      {
        label: womenSizingValues[localSizing]["9.5W"],
        value: "9.5W",
      },
      {
        label: womenSizingValues[localSizing]["10W"],
        value: "10W",
      },
      {
        label: womenSizingValues[localSizing]["10.5W"],
        value: "10.5W",
      },
      {
        label: womenSizingValues[localSizing]["11W"],
        value: "11W",
      },
      {
        label: womenSizingValues[localSizing]["11.5W"],
        value: "11.5W",
      },
      {
        label: womenSizingValues[localSizing]["12W"],
        value: "12W",
      },
      {
        label: womenSizingValues[localSizing]["12.5W"],
        value: "12.5W",
      },
      {
        label: womenSizingValues[localSizing]["13W"],
        value: "13W",
      },
      {
        label: womenSizingValues[localSizing]["13.5W"],
        value: "13.5W",
      },
      {
        label: womenSizingValues[localSizing]["14W"],
        value: "14W",
      },
      {
        label: womenSizingValues[localSizing]["14.5W"],
        value: "14.5W",
      },
      {
        label: womenSizingValues[localSizing]["15W"],
        value: "15W",
      },

      {
        label: girlSizingValues[localSizing]["3.5Y"],
        value: "3.5Y",
      },
      {
        label: girlSizingValues[localSizing]["4Y"],
        value: "4Y",
      },
      {
        label: girlSizingValues[localSizing]["4.5Y"],
        value: "4.5Y",
      },
      {
        label: girlSizingValues[localSizing]["5Y"],
        value: "5Y",
      },
      {
        label: girlSizingValues[localSizing]["5.5Y"],
        value: "5.5Y",
      },
      {
        label: girlSizingValues[localSizing]["6Y"],
        value: "6Y",
      },
      {
        label: girlSizingValues[localSizing]["6.5Y"],
        value: "6.5Y",
      },
      {
        label: girlSizingValues[localSizing]["7Y"],
        value: "7Y",
      },
      { value: "1Y", label: young[localSizing]["1Y"] },
      { value: "1.5Y", label: young[localSizing]["1.5Y"] },
      { value: "2Y", label: young[localSizing]["2Y"] },
      { value: "2.5Y", label: young[localSizing]["2.5Y"] },
      { value: "3Y", label: young[localSizing]["3Y"] },
      { value: "3.5Y", label: young[localSizing]["3.5Y"] },
      { value: "4Y", label: young[localSizing]["4Y"] },
      { value: "4.5Y", label: young[localSizing]["4.5Y"] },
      { value: "5Y", label: young[localSizing]["5Y"] },
      { value: "5.5Y", label: young[localSizing]["5.5Y"] },
      { value: "6Y", label: young[localSizing]["6Y"] },
      { value: "6.5Y", label: young[localSizing]["6.5Y"] },

      { value: "1C", label: toddler[localSizing]["1C"] },
      { value: "2C", label: toddler[localSizing]["2C"] },
      { value: "3C", label: toddler[localSizing]["3C"] },
      { value: "4C", label: toddler[localSizing]["4C"] },
      { value: "5C", label: toddler[localSizing]["5C"] },
      { value: "6C", label: toddler[localSizing]["6C"] },
      { value: "7C", label: toddler[localSizing]["7C"] },
      { value: "8C", label: toddler[localSizing]["8C"] },
      { value: "9C", label: toddler[localSizing]["9C"] },
      { value: "10C", label: toddler[localSizing]["10C"] },
      { value: "10.5C", label: toddler[localSizing]["10.5C"] },
      { value: "11C", label: toddler[localSizing]["11C"] },
      { value: "12C", label: toddler[localSizing]["12C"] },
      { value: "13C", label: toddler[localSizing]["13C"] },
    ],
  };
  // useEffect(() => {
  //   console.log(importData);
  //   if (importData) {
  //     for (let i = 0; i < importData.length; i++) {
  //       (async () => {
  //         try {
  //           setLoadingScreen(true);
  //           await saveHandler1(importData[i]);
  //         } catch (e) {
  //           console.log(e);
  //           setLoadingScreen(false);
  //         }
  //       })();
  //     }
  //   }
  // }, [importData]);

  // useEffect(() => {
  //   if (savedItems1.length > 0) {
  //     (async () => {
  //       try {
  //         await addHandler1();
  //         updateSavedItems1([]);
  //         setLoadingScreen(false);
  //       } catch (e) {
  //         console.log(e);
  //         setLoadingScreen(false);
  //       }
  //     })();
  //   }
  // }, [savedItems1]);

  const handleChange = (e) => {
    setFields({
      ...fields,
      sizing: fields.sizing === "" ? "-" : fields.sizing,
      [e.target.name]: e.target.value,
    });
  };

  //Handle the save action
  const saveHandler = async () => {
    toggleLoading(true);
    try {
      const styleCode = fields.styleCode;
      const searchTerm = styleCode;
      if (
        !fields.condn ||
        !fields.platform ||
        !fields.purDate ||
        !fields.category ||
        (!fields.sizing &&
          (fields.category === "streetwear" ||
            fields.category === "sneakers")) ||
        !styleCode ||
        !fields.name ||
        !fields.purPrice
      ) {
        throw new Error("Required fields are missing or invalid values.");
      }
      const product = await createUIproduct(StoreId, searchTerm, {
        lastSale:
          fields.platform === "custom" ? parseInt(fields.lastSale) : null,
        platform: fields.platform,
        size: fields.sizing,
        category: fields.category,
      });
      updateSavedItems([
        ...savedItems,
        {
          image: product.image,
          initLastSale: product.sizes[fields.sizing] ?? 0,
          condition: fields.condn,
          name: fields.name.trim(),
          searchTerm: searchTerm,
          platform: fields.platform,
          purchaseDate: fields.purDate,
          category: fields.category,
          size: fields.sizing,
          sku: product.styleCode.trim().toUpperCase(),
          quantity: 0,
          markUp: 0,
          lastSale: parseFloat(
            fields.lastSale / rates?.[localCurrency?.toUpperCase()]
          ),
          purchasePrice: parseFloat(
            fields.purPrice / rates?.[localCurrency?.toUpperCase()]
          ),
        },
      ]);
    } catch (e) {
      ErrorToast(e.message);
    }
    toggleLoading(false);
  };

  const addHandler = async () => {
    let batchInput = [];
    for (let item of savedItems) {
      const inputObj = _.omit(item, "quantity");
      for (let i = 0; i < item.quantity; i++) {
        batchInput.push(inputObj);
      }
    }

    await batchCreateInventory(StoreId, batchInput);
    //Batch create here
  };

  // const saveHandler1 = async (field) => {
  //   try {
  //     const styleCode = field.styleCode;
  //     if (
  //       !field.condn ||
  //       !field.platform ||
  //       !field.purDate ||
  //       !field.sizing ||
  //       !styleCode ||
  //       !field.name ||
  //       !field.purPrice
  //     ) {
  //       throw new Error("Required fields are missing or invalid values.");
  //     }

  //     const product = await createUIproduct(StoreId, styleCode, {
  //       lastSale: field.platform === "custom" ? parseInt(field.lastSale) : null,
  //       platform: field.platform,
  //       size: field.sizing,
  //     });
  //     updateSavedItems1([
  //       ...savedItems1,
  //       {
  //         image: product.image,
  //         initLastSale: product.sizes[field.sizing] ?? 0,
  //         condition: field.condn,
  //         name: field.name.trim(),
  //         platform: field.platform,
  //         purchaseDate: field.purDate,
  //         size: field.sizing,
  //         sku: styleCode.trim(),
  //         quantity: field.quantity,
  //         markUp: field.markUp,
  //         lastSale: parseFloat(
  //           field.lastSale / rates?.[localCurrency?.toUpperCase()]
  //         ),
  //         purchasePrice: parseFloat(
  //           field.purPrice / rates?.[localCurrency?.toUpperCase()]
  //         ),
  //       },
  //     ]);

  //   } catch (e) {
  //     ErrorToast(e.message);
  //     setLoadingScreen(false);
  //   }
  // };

  // const addHandler1 = async () => {
  //   let batchInput = [];
  //   for (let item of savedItems1) {
  //     const inputObj = _.omit(item, "quantity");
  //     for (let i = 0; i < item.quantity; i++) {
  //       batchInput.push(inputObj);
  //     }
  //   }
  //   await batchCreateInventory(StoreId, batchInput);
  // };

  const clearValues = () => {
    setFields(initialValues);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFields(initialValues);
    updateSavedItems([]);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        imgSrc={
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.66675 5.33334C8.66675 4.96515 8.36827 4.66667 8.00008 4.66667C7.63189 4.66667 7.33341 4.96515 7.33341 5.33334V7.33334H5.33341C4.96522 7.33334 4.66675 7.63182 4.66675 8C4.66675 8.36819 4.96523 8.66667 5.33341 8.66667H7.33341V10.6667C7.33341 11.0349 7.63189 11.3333 8.00008 11.3333C8.36827 11.3333 8.66675 11.0349 8.66675 10.6667V8.66667H10.6667C11.0349 8.66667 11.3334 8.36819 11.3334 8C11.3334 7.63182 11.0349 7.33334 10.6667 7.33334H8.66675V5.33334Z"
              fill="#767BFA"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 8C0 14.588 1.412 16 8 16C14.588 16 16 14.588 16 8C16 1.412 14.588 0 8 0C1.412 0 0 1.412 0 8ZM1.33333 8C1.33333 9.61657 1.42169 10.8246 1.62514 11.74C1.82522 12.6403 2.11845 13.1738 2.47231 13.5277C2.82616 13.8815 3.35966 14.1748 4.25995 14.3749C5.17538 14.5783 6.38343 14.6667 8 14.6667C9.61657 14.6667 10.8246 14.5783 11.74 14.3749C12.6403 14.1748 13.1738 13.8815 13.5277 13.5277C13.8815 13.1738 14.1748 12.6403 14.3749 11.74C14.5783 10.8246 14.6667 9.61657 14.6667 8C14.6667 6.38343 14.5783 5.17538 14.3749 4.25995C14.1748 3.35966 13.8815 2.82616 13.5277 2.47231C13.1738 2.11846 12.6403 1.82522 11.74 1.62514C10.8246 1.42169 9.61657 1.33333 8 1.33333C6.38343 1.33333 5.17538 1.42169 4.25995 1.62514C3.35966 1.82522 2.82616 2.11846 2.47231 2.47231C2.11845 2.82616 1.82522 3.35966 1.62514 4.25995C1.42169 5.17538 1.33333 6.38343 1.33333 8Z"
              fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
            />
          </svg>
        }
        text={translate("addNew")}
      />
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="header">
              <div>{translate("addingNewInventoryItems")}...</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className="three-grid">
              <div>
                <div className="label">{translate("itemName")}</div>
                <Input
                  name="name"
                  type={"text"}
                  value={fields.name}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterName")}
                />
              </div>

              <div>
                <div className="label">
                  {GetTranslateString("marketIdentifier")}
                </div>
                <Input
                  name="styleCode"
                  type={"text"}
                  value={fields.styleCode}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterStyleCode")}
                />
              </div>

              <div>
                <div className="label">{translate("purchasedDate")}</div>
                <Input
                  dark={dark}
                  name="purDate"
                  type={"date"}
                  value={fields.purDate}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterDate")}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("categories")}</div>
                <Select
                  dark={dark}
                  name="category"
                  value={fields.category}
                  updateValue={handleChange}
                  options={[
                    {
                      label: "Sneakers",
                      value: "sneakers",
                    },
                    {
                      label: "Streetwear",
                      value: "streetwear",
                    },
                    {
                      label: "Electronics",
                      value: "electronics",
                    },
                    {
                      label: "Arts",
                      value: "arts",
                    },
                    {
                      label: "Collectibles",
                      value: "collectibles",
                    },
                  ]}
                  placeholderText={GetTranslateString("chooseCategories")}
                  none={false}
                />
              </div>

              <div>
                <div className="label">{translate("sizing")}</div>
                <Select
                  dark={dark}
                  name="sizing"
                  disabled={
                    fields.category === "sneakers" ||
                    fields.category === "streetwear"
                      ? false
                      : true
                  }
                  value={
                    fields.category !== "" &&
                    !_.map(sizingOptions[fields.category], "value").includes(
                      fields.sizing
                    )
                      ? ""
                      : fields.sizing
                  }
                  render={
                    fields.sizing !== ""
                      ? fields.category === "sneakers" &&
                        _.map(sizingOptions["sneakers"], "value").includes(
                          fields.sizing
                        )
                        ? () =>
                            sizingValues[localSizing][fields.sizing]
                              ? sizingValues[localSizing][fields.sizing]
                              : womenSizingValues[localSizing][fields.sizing]
                              ? womenSizingValues[localSizing][fields.sizing]
                              : girlSizingValues[localSizing][fields.sizing]
                              ? girlSizingValues[localSizing][fields.sizing]
                              : toddler[localSizing][fields.sizing]
                              ? toddler[localSizing][fields.sizing]
                              : young[localSizing][fields.sizing]
                        : () => fields.sizing
                      : ""
                  }
                  updateValue={handleChange}
                  options={sizingOptions[fields.category]}
                  placeholderText={GetTranslateString("chooseSize")}
                  none={false}
                />
              </div>
              <div>
                <div className="label">{translate("condition")}</div>
                <Select
                  dark={dark}
                  name="condn"
                  value={fields.condn}
                  updateValue={handleChange}
                  options={[
                    {
                      label: GetTranslateString("new"),
                      value: constants.NEW,
                    },
                    {
                      label: GetTranslateString("used"),
                      value: constants.USED,
                    },
                  ]}
                  placeholderText={GetTranslateString("chooseCondition")}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("purchasedPrice")}</div>
                <Input
                  name="purPrice"
                  type={"number"}
                  value={fields.purPrice}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterAmount")}
                />
              </div>

              <div>
                <div className="label">{translate("platform")}</div>
                <Select
                  dark={dark}
                  name={"platform"}
                  value={fields.platform}
                  updateValue={handleChange}
                  options={[
                    {
                      label: "Novelship",
                      value: constants.STOCKX,
                    },
                    {
                      label: translate("goat"),
                      value: constants.GOAT,
                    },
                    {
                      label: translate("custom"),
                      value: constants.CUSTOM,
                    },
                  ]}
                  placeholderText={GetTranslateString("choosePlatform")}
                />
              </div>

              <div>
                <div className="label">{translate("lastSalePrice")}</div>
                <Input
                  name="lastSale"
                  type={"number"}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("autoDetect")}
                  readOnly={fields.platform !== "custom"}
                  value={fields.platform === "custom" ? fields.lastSale : ""}
                />
              </div>
            </div>

            <div className="btns w-full jus-end mt-10">
              <div>
                <Loader loading={loading} />
              </div>
              <div className="ml-10">
                <Button
                  onClick={() => {
                    clearValues();
                    updateSavedItems([]);
                  }}
                  text={translate("clearAll")}
                  imgSrc={<img src={dark ? DeleteDark : Delete} alt="delete" />}
                />
              </div>
              <div className="ml-10">
                <Button
                  imgSrc={<img src={dark ? AddDark : Add} alt="add" />}
                  onClick={() => {
                    saveHandler();
                  }}
                  text={translate("saveItems")}
                />
              </div>
            </div>

            <div className="table-container">
              <NewItemInventoryGrid
                savedItems={savedItems}
                updateSavedItems={updateSavedItems}
              />
            </div>

            <div className="w-full jus-end">
              <div>
                <Loader loading={addLoading} />
              </div>
              <div className="mt-10 px-20">
                <Button
                  disabled={isDisabled}
                  imgSrc={<img src={dark ? AddDark : Add} alt="add img" />}
                  onClick={async () => {
                    toggleAddLoading(true);
                    updateDisabled(true);
                    setTimeout(() => {
                      updateDisabled(false);
                    }, 2000);
                    try {
                      if (savedItems.length === 0) {
                        throw new Error("Saved items cannot be empty.");
                      }
                      savedItems.map((e) => {
                        if (
                          e.initLastSale === 0 &&
                          (e.platform === constants.STOCKX ||
                            e.platform === constants.GOAT)
                        ) {
                          throw new Error("Lastsale cannot be 0.");
                        }
                      });
                      await addHandler();
                      handleClose();
                      clearValues();
                      SuccessToast(
                        translate("success"),
                        translate("itemCreated")
                      );
                    } catch (e) {
                      ErrorToast(translate("error"), e.message);
                    } finally {
                      toggleAddLoading(false);
                    }
                  }}
                  text={translate("addNew")}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
