import { useContext, useEffect, useState } from "react";
import checked from "../../assets/checked.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import updateShippingInfo from "../../firebase/cart/updateShippingInfo";
import "../css/SalesDetails.scoped.css";
import Input from "../general/Input";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import greenCheck from "../../assets/greenChecked.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

const SalesDetails = ({ selectedSaleSummary }) => {
  const [imgType, setImgType] = useState(checked);
  const [shipInfo, setShipInfo] = useState();

  useEffect(() => {
    setShipInfo(
      selectedSaleSummary?.shippingInfo ? selectedSaleSummary?.shippingInfo : ""
    );
  }, [selectedSaleSummary]);

  useEffect(() => {
    if (selectedSaleSummary?.shippingInfo) {
      setImgType(greenCheck);
    } else {
      setImgType(checked);
    }
  }, [selectedSaleSummary]);

  const {
    sign,
    rates,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  let localSizing = localStorage.getItem("sizing");

  return (
    <>
      {selectedSaleSummary && (
        <div className="pt-4">
          <div className="twogrid px-20 mb-10">
            <img
              className="shoeimg"
              src={selectedSaleSummary?.image ? selectedSaleSummary?.image : ""}
              alt=""
            />
            <div>
              <div className="name">{selectedSaleSummary.name}</div>
              {/* <div className="label">{Beluga Reflective}</div> */}
              <div>
                <span className="label">{translate("style")}:</span>
                <span className="value">{selectedSaleSummary?.sku}</span>
              </div>
              <div className="flex">
                <div>
                  <span className="label">{translate("condition")}:</span>
                  <span className="value">
                    {selectedSaleSummary?.condition?.toUpperCase()}
                  </span>
                </div>
                {/* {selectedSaleSummary.type !== "consignment" && ( */}
                <div className="ml-4">
                  <span className="label">{translate("size")}:</span>
                  <span className="value">
                    {(selectedSaleSummary.category === "sneakers") 
                      ? sizingValues[localSizing][selectedSaleSummary.size]
                        ? sizingValues[localSizing][selectedSaleSummary.size]
                        : womenSizingValues[localSizing][selectedSaleSummary.size]
                        ? womenSizingValues[localSizing][selectedSaleSummary.size]
                        : girlSizingValues[localSizing][selectedSaleSummary.size]
                        ? girlSizingValues[localSizing][selectedSaleSummary.size]
                        : toddler[localSizing][selectedSaleSummary.size]
                        ? toddler[localSizing][selectedSaleSummary.size]
                        : young[localSizing][selectedSaleSummary.size]
                      : selectedSaleSummary.size}
                  </span>
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
          <div className="px-20 mb-10">
            <div className="twogrid equal mb-10">
              <div>
                <span className="label">{translate("listingPrice")}:</span>
                <span className="value">
                  {sign[localCurrency]}
                  {(
                    selectedSaleSummary?.costPrice *
                    rates?.[localCurrency?.toUpperCase()]
                  ).toFixed(2)}
                </span>
              </div>
              <div>
                <span className="label">{translate("profitsLosses")}:</span>
                <span
                  className={`value ${
                    selectedSaleSummary?.soldPrice -
                      selectedSaleSummary?.costPrice >=
                    0
                      ? "green"
                      : "red"
                  }`}
                >
                  {selectedSaleSummary?.soldPrice -
                    selectedSaleSummary?.costPrice >=
                  0
                    ? "+"
                    : "-"}
                  {sign[localCurrency]}
                  {(
                    (selectedSaleSummary?.soldPrice -
                      selectedSaleSummary?.costPrice) *
                    rates?.[localCurrency?.toUpperCase()]
                  ).toFixed(2)}
                </span>
              </div>
            </div>
            <div className="twogrid equal">
              <div>
                <span className="label">{translate("soldDate")}:</span>
                <span className="value">
                  {new Date(selectedSaleSummary?.dateSold)
                    .toDateString()
                    .split(" ")
                    .slice(1, 4)
                    .join(" ")}
                </span>
              </div>
              <div>
                <span className="label">{translate("soldBy")}:</span>
                <span className="value green">0001-G</span>
              </div>
            </div>
          </div>
          <div className="px-20 mb-10">
            <span className="label">{translate("purchasedBy")}:</span>
            <span className="value">{selectedSaleSummary?.email}</span>
          </div>
          <div className="px-20 mb-10">
            <span className="label">{translate("paymentMethod")}:</span>
            <span className="value capit">
              {selectedSaleSummary?.paymentMethod?.split("_").join(" ")}
            </span>
          </div>

          {selectedSaleSummary.type !== "consignment" ? (
            <div className="px-20 mb-10">
              <span className="label">Delivery:</span>
              <span className="value">
                {selectedSaleSummary?.shippingAddress}
              </span>
            </div>
          ) : (
            <div className="px-20 mb-10">
              <span className="label">{translate("consignment")}:</span>
              <span className="value">{selectedSaleSummary?.consigneeID}</span>
            </div>
          )}

          {selectedSaleSummary.type === "consignment" ? (
            <div className="px-20 mb-10">
              <span className="label">{translate("payoutPrice")}:</span>
              <span className="value">
                {sign[localCurrency]}
                {(
                  selectedSaleSummary?.costPrice *
                  rates?.[localCurrency?.toUpperCase()]
                ).toFixed(2)}
              </span>
            </div>
          ) : selectedSaleSummary?.delivery !== "Instore" ? (
            <div className="px-20 mb-10 flex">
              <span className="label">Shipping Info:</span>
              <div
                style={{
                  maxWidth: "270px",
                  width: "100%",
                  marginRight: "14px",
                }}
              >
                <Input
                  name={"shipInfo"}
                  customClass={"smtext"}
                  type={"text"}
                  placeholder={GetTranslateString("shippingInfo")}
                  value={shipInfo}
                  updateValue={(e) => setShipInfo(e.target.value)}
                />
              </div>
              <img
                style={{
                  cursor: "pointer",
                }}
                src={imgType}
                alt=""
                onClick={() => {
                  try {
                    updateShippingInfo(
                      StoreId,
                      selectedSaleSummary?.id,
                      shipInfo
                    );
                    SuccessToast(translate("successfullyUpdated"), shipInfo);
                    if (shipInfo) {
                      setImgType(greenCheck);
                    } else {
                      setImgType(checked);
                    }
                  } catch (e) {
                    ErrorToast(e.message);
                  }
                }}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default SalesDetails;
