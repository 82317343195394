import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React, { useContext, useState } from "react";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import Close from "../../assets/close.svg";
import edit from "../../assets/edit.svg";
import { CurrencyContext } from "../../context/CurrencyContext";
// import createExpense from "../../firebase/expense/createExpense";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import Loader from "../general/Loader";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import editStaff from "../../firebase/staff/editStaff";
import { StoreId } from "../../Constants";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

export default function EditStaff({ setUpdated, data, prefix, dark }) {
  let localCurrency = localStorage.getItem("currency");
  const { rates } = useContext(CurrencyContext);
  const initialValues = {
    staffName: data.staffName,
    staffId: data.staffId,
    staffEmail: data.staffEmail,
    dateJoin: data.dateJoin,
    monday: data.monday,
    tuesday: data.tuesday,
    wednesday: data.wednesday,
    thursday: data.thursday,
    friday: data.friday,
    saturday: data.saturday,
    sunday: data.sunday,
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const [isDisabled, updateDisabled] = useState(false);
  const [fields, setFields] = useState(initialValues);
  const [loading, toggleLoading] = useState(false);

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const clearValues = () => {
    setFields({
      staffName: "",
      staffId: "",
      staffEmail: "",
      dateJoin: "",
      monday: "",
      tuesday: "",
      wednesday: "",
      thursday: "",
      friday: "",
      saturday: "",
      sunday: "",
    });
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setFields(initialValues);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={() => {
          if (Object.keys(data).length > 1) {
            handleOpen();
          } else {
            ErrorToast(translate("error"), translate("pleaseSelectAStaff"));
          }
        }}
        imgSrc={<img src={edit} alt={translate("Edit")} />}
        text={translate("edit")}
      />
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="header">
              <div>{translate("editingStaff")}...</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("staffName")}</div>
                <Input
                  name="staffName"
                  type={"text"}
                  value={fields.staffName}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterName")}
                />
              </div>

              <div>
                <div className="label">{translate("staffId")}</div>
                <Input
                  name="staffId"
                  type={"text"}
                  readOnly={true}
                  value={fields.staffId}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterId")}
                />
              </div>

              <div>
                <div className="label">{translate("email")}</div>
                <Input
                  name="staffEmail"
                  type={"email"}
                  value={fields.staffEmail}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterEmail")}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("dateJoin")}</div>
                <Input
                  name="dateJoin"
                  type={"date"}
                  dark={dark}
                  value={fields.dateJoin}
                  updateValue={handleChange}
                  placeholder={"Jan 00,0000"}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("monday")}</div>
                <Input
                  name="monday"
                  type={"text"}
                  value={fields.monday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>

              <div>
                <div className="label">{translate("tuesday")}</div>
                <Input
                  name="tuesday"
                  type={"text"}
                  value={fields.tuesday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>

              <div>
                <div className="label">{translate("wednesday")}</div>
                <Input
                  name="wednesday"
                  type={"text"}
                  value={fields.wednesday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("thursday")}</div>
                <Input
                  name="thursday"
                  type={"text"}
                  value={fields.thursday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>

              <div>
                <div className="label">{translate("friday")}</div>
                <Input
                  name="friday"
                  type={"text"}
                  value={fields.friday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>

              <div>
                <div className="label">{translate("saturday")}</div>
                <Input
                  name="saturday"
                  type={"text"}
                  value={fields.saturday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">Sunday</div>
                <Input
                  name="sunday"
                  type={"text"}
                  value={fields.sunday}
                  updateValue={handleChange}
                  placeholder={"x:xx AM - x:xx PM"}
                />
              </div>
            </div>

            <div className="btns w-full jus-end mt-10">
              <div>
                <Loader loading={loading} />
              </div>

              <div className="ml-10">
                <Button
                  disabled={isDisabled}
                  imgSrc={<img src={dark ? AddDark : Add} alt="add" />}
                  onClick={async () => {
                    updateDisabled(true);
                    try {
                      toggleLoading(true);
                      //   await createExpense(StoreId, {
                      //     name: fields.name.trim(),
                      //     spentDate: fields.spentDate,
                      //     amountSpent: parseFloat(
                      //       (
                      //         fields.amountSpent /
                      //         rates?.[localCurrency?.toUpperCase()]
                      //       ).toFixed(2)
                      //     ),
                      //     tax: parseFloat(fields.tax),
                      //     shipping: parseFloat(
                      //       (
                      //         fields.shipping /
                      //         rates?.[localCurrency?.toUpperCase()]
                      //       ).toFixed(2)
                      //     ),
                      //     duration: fields.duration,
                      //     quantity: parseInt(fields.quantity),
                      //     transactionId: fields.transactionId.trim(),
                      //     notes: fields.notes.trim(),
                      //   });

                      await editStaff(StoreId, {
                        staffName: fields.staffName.trim(),
                        staffEmail: fields.staffEmail,
                        staffId: fields.staffId.trim(),
                        dateJoin: fields.dateJoin,
                        monday: fields.monday.trim(),
                        tuesday: fields.tuesday.trim(),
                        wednesday: fields.wednesday.trim(),
                        thursday: fields.thursday.trim(),
                        friday: fields.friday.trim(),
                        saturday: fields.saturday.trim(),
                        sunday: fields.sunday.trim(),
                        staffID: data.staffId,
                      });
                      setUpdated(Date.now());
                    } catch (e) {
                      ErrorToast(translate("error"), e.message);
                      toggleLoading(false);
                      updateDisabled(false);
                      return;
                    }
                    SuccessToast(translate("success"), translate("staffEdited"));
                    handleClose();
                    clearValues();
                    updateDisabled(false);
                    toggleLoading(false);
                  }}
                  text={translate("save")}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
