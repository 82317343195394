import { format } from "date-fns";
import React from "react";
import "../css/Wallet.scoped.css";

export default function Cashout({ cashoutData, bankAccountNames }) {
  const statusColors = {
    verifying: "purple",
    pending: "orange",
    failed: "red",
    successful: "green",
  };

  return (
    <div className="cashoutgrid">
      <div className="headersgrid">
        <div>Account Name</div>
        <div>Amount</div>
        <div>Date</div>
        <div className="center">Status</div>
      </div>
      <div className="items">
        {cashoutData.map((data) => {
          const formattedDate = format(
            new Date(data.date.seconds * 1000),
            "yyyy-MM-dd HH:mm:ss"
          );
          return (
            <div className="item" key={data.id}>
              <div>{bankAccountNames.get(data.accountId)}</div>
              <div>${(data.amount / 100).toFixed(2)}</div>
              <div>
                {new Date(data.date.seconds * 1000).toLocaleString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </div>
              <div className={`center ${statusColors[(data?.status)]}`}>
                {data.status}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
