import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React, { useContext, useEffect, useState } from "react";
import Close from "../../assets/close.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import payCart from "../../firebase/cart/payCart";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import Loader from "../general/Loader";
import Select from "../general/Select";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import InfoDiv from "../general/InfoDiv";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

export default function TransitionsModal({
  open,
  setOpen,
  activeCart,
  cartTotal,
  discountPercent,
  createNewCart,
  cartRef,
  dark,
}) {
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  const initialValues = {
    userName: "",
    email: "",
    staffId: "",
    paymentMethod: "",
    cc: "",
    contact: "",
    amountReceived: "",
    changes: "",
    delivery: "",
    shippingAddress: "",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "47.1875rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const [isDisabled, updateDisabled] = useState(false);
  const [fields, setFields] = useState(initialValues);
  const [loading, toggleLoading] = useState(false);

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setFields({ ...fields, amountReceived: "" });
  }, [fields.paymentMethod]);

  const clearValues = () => {
    setFields(initialValues);
  };

  const handleClose = () => {
    setOpen(false);
    setFields(initialValues);
  };

  return (
    <div>
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="header">
              <div>{translate("confirmingPayment")}...</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className="three-grid">
              <div>
                <div className="label">{translate("customerName")}</div>
                <Input
                  name="userName"
                  type={"text"}
                  value={fields.userName}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterName")}
                />
              </div>

              <div>
                <div className="label">{translate("customerEmail")}</div>
                <Input
                  name="email"
                  type={"email"}
                  value={fields.email}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterEmail")}
                />
              </div>

              <div>
                <div className="label">{translate("staffId")}</div>
                <Input
                  name="staffId"
                  type={"text"}
                  value={fields.staffId}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterId")}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("paymentMethod")}</div>
                <Select
                  dark={dark}
                  name="paymentMethod"
                  value={fields.paymentMethod}
                  updateValue={handleChange}
                  options={[
                    {
                      label: GetTranslateString("cash"),
                      value: "cash",
                    },
                    {
                      label: GetTranslateString("cheque"),
                      value: "cheque",
                    },
                    {
                      label: GetTranslateString("creditCard"),
                      value: "card",
                    },
                    {
                      label: GetTranslateString("payNow"),
                      value: "pay_now",
                    },
                  ]}
                  placeholderText={GetTranslateString("chooseMethod")}
                  none={false}
                />
              </div>

              {fields.paymentMethod === "card" && (
                <div>
                  <div className="label">{translate("ccLast4Digits")}</div>
                  <Input
                    name="cc"
                    type={"number"}
                    value={fields.cc}
                    updateValue={handleChange}
                    placeholder={GetTranslateString("enterLast4Digits")}
                  />
                </div>
              )}

              <div>
                <div className="label">{translate("contact")}</div>
                <Input
                  name="contact"
                  type={"text"}
                  value={fields.contact}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterNumber")}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("amountReceived")}</div>
                <Input
                  readOnly={fields.paymentMethod !== "cash"}
                  name="amountReceived"
                  type={"number"}
                  value={fields.amountReceived}
                  updateValue={handleChange}
                  placeholder={"N/A"}
                />
              </div>

              <div>
                <div className="label">{translate("change")}</div>
                <Select
                  dark={dark}
                  name="changes"
                  value={fields.changes}
                  updateValue={handleChange}
                  options={[
                    {
                      label: "Auto detect",
                      value: "auto",
                    },
                  ]}
                  placeholderText="N/A"
                  none={false}
                />
              </div>
              {fields.paymentMethod === "cash" && (
                <div className="flex al-fe">
                  <InfoDiv
                    customClass={"whitetext"}
                    text={
                      fields.amountReceived
                        ? (
                            fields.amountReceived -
                            (cartTotal - discountPercent)
                          ).toFixed(2)
                        : (cartTotal - discountPercent).toFixed(2)
                    }
                  />
                </div>
              )}
            </div>

            <div className="three-grid">
              <div>
                <div className="label">
                  {translate("inStore")}/{translate("delivery")}
                </div>
                <Select
                  dark={dark}
                  name={"delivery"}
                  value={fields.delivery}
                  updateValue={handleChange}
                  options={[
                    {
                      label: "Instore",
                      value: "Instore",
                    },
                    {
                      label: "Delivery",
                      value: "Delivery",
                    },
                  ]}
                  placeholderText={GetTranslateString("choose")}
                />
              </div>

              <div className="span-2">
                <div className="label">{translate("shippingAddress")}</div>
                <Input
                  name="shippingAddress"
                  type={"text"}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterAddress")}
                  value={fields.shippingAddress}
                />
              </div>
            </div>

            <div className="w-full flex al-ce ml-10 jus-end">
              <div className="mt-10">
                <Loader loading={loading} />
              </div>
              <div className="mt-10 px-20">
                <Button
                  disabled={isDisabled}
                  onClick={async () => {
                    updateDisabled(true);
                    try {
                      await payCart(StoreId, activeCart, {
                        userName: fields.userName.trim(),
                        email: fields.email.trim(),
                        staffId: fields.staffId.trim(),
                        paymentMethod: fields.paymentMethod,
                        cc: parseInt(fields.cc),
                        contact: parseInt(fields.contact),
                        amountReceived: parseFloat(fields.amountReceived),
                        delivery: fields.delivery.trim(),
                        shippingAddress: fields.shippingAddress.trim(),
                        discountPercent: discountPercent,
                        changes: fields.changes.trim(),
                        change: parseFloat(
                          fields.amountReceived
                            ? (fields.amountReceived -
                                (cartTotal - discountPercent)) /
                                rates?.[localCurrency?.toUpperCase()]
                            : 0
                        ).toFixed(2),
                        subTotal: parseFloat(
                          (
                            cartTotal / rates?.[localCurrency?.toUpperCase()]
                          ).toFixed(2)
                        ),
                        total: parseFloat(
                          (
                            (cartTotal - discountPercent) /
                            rates?.[localCurrency?.toUpperCase()]
                          ).toFixed(2)
                        ),
                      });
                      window.open(
                        `https://ingenium-bill.web.app/?store=${StoreId}&cartId=${activeCart}`,
                        "_blank"
                      );
                      createNewCart();
                    } catch (e) {
                      updateDisabled(false);
                      ErrorToast(translate("paymentUnsuccessful"), e.message);
                      return;
                    }
                    SuccessToast(
                      translate("success"),
                      translate("paymentSuccessful")
                    );
                    updateDisabled(false);
                    handleClose();
                    clearValues();
                  }}
                  text={<span className="green">{translate("pay")}</span>}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
