import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import Close from "../../assets/close.svg";
import Delete from "../../assets/delete.svg";
import DeleteDark from "../../assets/deletedark.svg";

import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import consigneeIdValidator from "../../firebase/consignee/consigneeIdValidator";
import constants from "../../firebase/constants";
import batchCreateConsignment from "../../firebase/inventory/batchCreateConsignment";
import createProduct from "../../firebase/products/createProduct";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import Loader from "../general/Loader";
import Select from "../general/Select";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import ConsignmentNewItemInventoryGrid from "./ConsignmentNewItemInventoryGrid";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

export default function AddConsignmentInventoryModal({ dark }) {
  const initialValues = {
    name: "",
    styleCode: "",
    sizing: "",
    category: "",
    condn: "",
    payoutPrice: "",
    dateIn: "",
    consigneeID: "",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const {
    rates,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);

  let localCurrency = localStorage.getItem("currency");
  let localSizing = localStorage.getItem("sizing");

  const [savedItems, updateSavedItems] = useState([]);
  const [isDisabled, updateDisabled] = useState(false);
  const [fields, setFields] = useState(initialValues);
  const [loading, toggleLoading] = useState(false);

  const sizingOptions = {
    "":[{label: "NA", value: ''}],
    collectibles :[{label: "NA", value: ''}],
    arts :[{label: "NA", value: ''}],
    electronics :[{label: "NA", value: ''}],
    streetwear: [
      {
        label: "XXS",
        value: "XXS"
      },
      {
        label: "XS",
        value: "XS"
      },
      {
        label: "S",
        value: "S"
      },
      {
        label: "M",
        value: "M"
      },
      {
        label: "L",
        value: "L"
      },
      {
        label: "XL",
        value: "XL"
      },
      {
        label: "XXL",
        value: "XXL"
      },
      {
        label: "XXXL",
        value: "XXXL"
      },
    ],
    sneakers: [
      {
        label: sizingValues[localSizing]["4"],
        value: 4,
      },
      {
        label: sizingValues[localSizing]["4.5"],
        value: 4.5,
      },
      {
        label: sizingValues[localSizing]["5"],
        value: 5,
      },
      {
        label: sizingValues[localSizing]["5.5"],
        value: 5.5,
      },
      {
        label: sizingValues[localSizing]["6"],
        value: 6,
      },
      {
        label: sizingValues[localSizing]["6.5"],
        value: 6.5,
      },
      {
        label: sizingValues[localSizing]["7"],
        value: 7,
      },
      {
        label: sizingValues[localSizing]["7.5"],
        value: 7.5,
      },
      {
        label: sizingValues[localSizing]["8"],
        value: 8,
      },
      {
        label: sizingValues[localSizing]["8.5"],
        value: 8.5,
      },
      {
        label: sizingValues[localSizing]["9"],
        value: 9,
      },
      {
        label: sizingValues[localSizing]["9.5"],
        value: 9.5,
      },
      {
        label: sizingValues[localSizing]["10"],
        value: 10,
      },
      {
        label: sizingValues[localSizing]["10.5"],
        value: 10.5,
      },
      {
        label: sizingValues[localSizing]["11"],
        value: 11,
      },
      {
        label: sizingValues[localSizing]["11.5"],
        value: 11.5,
      },
      {
        label: sizingValues[localSizing]["12"],
        value: 12,
      },
      {
        label: sizingValues[localSizing]["12.5"],
        value: 12.5,
      },
      {
        label: sizingValues[localSizing]["13"],
        value: 13,
      },
      {
        label: sizingValues[localSizing]["13.5"],
        value: 13.5,
      },
      {
        label: sizingValues[localSizing]["14"],
        value: 14,
      },
      {
        label: sizingValues[localSizing]["14.5"],
        value: 14.5,
      },
      {
        label: sizingValues[localSizing]["15"],
        value: 15,
      },
      {
        label: sizingValues[localSizing]["16"],
        value: 16,
      },
      {
        label: sizingValues[localSizing]["17"],
        value: 17,
      }, 
      {
        label: womenSizingValues[localSizing]["5W"],
        value: "5W",
      },
      {
        label: womenSizingValues[localSizing]["5.5W"],
        value: "5.5W",
      },
      {
        label: womenSizingValues[localSizing]["6W"],
        value: "6W",
      },
      {
        label: womenSizingValues[localSizing]["6.5W"],
        value: "6.5W",
      },
      {
        label: womenSizingValues[localSizing]["7W"],
        value: "7W",
      },
      {
        label: womenSizingValues[localSizing]["7.5W"],
        value: "7.5W",
      },
      {
        label: womenSizingValues[localSizing]["8W"],
        value: "8W",
      },
      {
        label: womenSizingValues[localSizing]["8.5W"],
        value: "8.5W",
      },
      {
        label: womenSizingValues[localSizing]["9W"],
        value: "9W",
      },
      {
        label: womenSizingValues[localSizing]["9.5W"],
        value: "9.5W",
      },
      {
        label: womenSizingValues[localSizing]["10W"],
        value: "10W",
      },
      {
        label: womenSizingValues[localSizing]["10.5W"],
        value: "10.5W",
      },
      {
        label: womenSizingValues[localSizing]["11W"],
        value: "11W",
      },
      {
        label: womenSizingValues[localSizing]["11.5W"],
        value: "11.5W",
      },
      {
        label: womenSizingValues[localSizing]["12W"],
        value: "12W",
      },
      {
        label: womenSizingValues[localSizing]["12.5W"],
        value: "12.5W",
      },
      {
        label: womenSizingValues[localSizing]["13W"],
        value: "13W",
      },
      {
        label: womenSizingValues[localSizing]["13.5W"],
        value: "13.5W",
      },
      {
        label: womenSizingValues[localSizing]["14W"],
        value: "14W",
      },
      {
        label: womenSizingValues[localSizing]["14.5W"],
        value: "14.5W",
      },
      {
        label: womenSizingValues[localSizing]["15W"],
        value: "15W",
      },

      {
        label: girlSizingValues[localSizing]["3.5Y"],
        value: "3.5Y",
      },
      {
        label: girlSizingValues[localSizing]["4Y"],
        value: "4Y",
      },
      {
        label: girlSizingValues[localSizing]["4.5Y"],
        value: "4.5Y",
      },
      {
        label: girlSizingValues[localSizing]["5Y"],
        value: "5Y",
      },
      {
        label: girlSizingValues[localSizing]["5.5Y"],
        value: "5.5Y",
      },
      {
        label: girlSizingValues[localSizing]["6Y"],
        value: "6Y",
      },
      {
        label: girlSizingValues[localSizing]["6.5Y"],
        value: "6.5Y",
      },
      {
        label: girlSizingValues[localSizing]["7Y"],
        value: "7Y",
      },
      { value: "1Y", label: young[localSizing]["1Y"] },
      { value: "1.5Y", label: young[localSizing]["1.5Y"] },
      { value: "2Y", label: young[localSizing]["2Y"] },
      { value: "2.5Y", label: young[localSizing]["2.5Y"] },
      { value: "3Y", label: young[localSizing]["3Y"] },
      { value: "3.5Y", label: young[localSizing]["3.5Y"] },
      { value: "4Y", label: young[localSizing]["4Y"] },
      { value: "4.5Y", label: young[localSizing]["4.5Y"] },
      { value: "5Y", label: young[localSizing]["5Y"] },
      { value: "5.5Y", label: young[localSizing]["5.5Y"] },
      { value: "6Y", label: young[localSizing]["6Y"] },
      { value: "6.5Y", label: young[localSizing]["6.5Y"] },

      { value: "1C", label: toddler[localSizing]["1C"] },
      { value: "2C", label: toddler[localSizing]["2C"] },
      { value: "3C", label: toddler[localSizing]["3C"] },
      { value: "4C", label: toddler[localSizing]["4C"] },
      { value: "5C", label: toddler[localSizing]["5C"] },
      { value: "6C", label: toddler[localSizing]["6C"] },
      { value: "7C", label: toddler[localSizing]["7C"] },
      { value: "8C", label: toddler[localSizing]["8C"] },
      { value: "9C", label: toddler[localSizing]["9C"] },
      { value: "10C", label: toddler[localSizing]["10C"] },
      { value: "10.5C", label: toddler[localSizing]["10.5C"] },
      { value: "11C", label: toddler[localSizing]["11C"] },
      { value: "12C", label: toddler[localSizing]["12C"] },
      { value: "13C", label: toddler[localSizing]["13C"] },
    ]
  
  }

  const handleChange = (e) => {
    setFields({
      ...fields,
      sizing: fields.sizing === '' ? "-" : fields.sizing,
      [e.target.name]: e.target.value,
    });
  };

  const clearValues = () => {
    setFields(initialValues);
  };

  //Handle the save action
  const saveHandler = async () => {
    toggleLoading(true);
    try {
      const styleCode = fields.styleCode.trim();
      const searchTerm = styleCode;
      if (
        !fields.name.trim() ||
        !fields.category ||
        !fields.dateIn ||
        (!fields.sizing && (fields.category === "streetwear" || fields.category === "sneakers")) ||
        !styleCode ||
        !fields.condn ||
        !fields.consigneeID.trim() ||
        !fields.payoutPrice
      ) {
        throw new Error("Required fields are missing.");
      }
      let consignee = await consigneeIdValidator(
        StoreId,
        fields.consigneeID.trim()
      );
      const product = await createProduct(StoreId, styleCode, fields);
      updateSavedItems([
        ...savedItems,
        {
          consigneeEmail: consignee.email,
          consigneeID: fields.consigneeID.trim(),
          image: product.image,
          condition: fields.condn,
          name: fields.name.trim(),
          searchTerm: searchTerm,
          size: fields.sizing,
          category: fields.category,
          sku: product.styleCode.trim().toUpperCase(),
          dateIn: fields.dateIn,
          quantity: 1,
          payoutPrice: parseFloat(
            (
              fields.payoutPrice / rates?.[localCurrency?.toUpperCase()]
            ).toFixed(2)
          ),
        },
      ]);
    } catch (e) {
      ErrorToast(e.message);
    }
    toggleLoading(false);
  };

  const addHandler = async () => {
    let batchInput = [];
    for (let item of savedItems) {
      const inputObj = _.omit(item, "quantity");
      for (let i = 0; i < item.quantity; i++) {
        batchInput.push(inputObj);
      }
    }
    await batchCreateConsignment(StoreId, batchInput);
    //Batch create here
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFields(initialValues);
    updateSavedItems([]);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        imgSrc={
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.66675 5.33334C8.66675 4.96515 8.36827 4.66667 8.00008 4.66667C7.63189 4.66667 7.33341 4.96515 7.33341 5.33334V7.33334H5.33341C4.96522 7.33334 4.66675 7.63182 4.66675 8C4.66675 8.36819 4.96523 8.66667 5.33341 8.66667H7.33341V10.6667C7.33341 11.0349 7.63189 11.3333 8.00008 11.3333C8.36827 11.3333 8.66675 11.0349 8.66675 10.6667V8.66667H10.6667C11.0349 8.66667 11.3334 8.36819 11.3334 8C11.3334 7.63182 11.0349 7.33334 10.6667 7.33334H8.66675V5.33334Z"
              fill="#767BFA"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 8C0 14.588 1.412 16 8 16C14.588 16 16 14.588 16 8C16 1.412 14.588 0 8 0C1.412 0 0 1.412 0 8ZM1.33333 8C1.33333 9.61657 1.42169 10.8246 1.62514 11.74C1.82522 12.6403 2.11845 13.1738 2.47231 13.5277C2.82616 13.8815 3.35966 14.1748 4.25995 14.3749C5.17538 14.5783 6.38343 14.6667 8 14.6667C9.61657 14.6667 10.8246 14.5783 11.74 14.3749C12.6403 14.1748 13.1738 13.8815 13.5277 13.5277C13.8815 13.1738 14.1748 12.6403 14.3749 11.74C14.5783 10.8246 14.6667 9.61657 14.6667 8C14.6667 6.38343 14.5783 5.17538 14.3749 4.25995C14.1748 3.35966 13.8815 2.82616 13.5277 2.47231C13.1738 2.11846 12.6403 1.82522 11.74 1.62514C10.8246 1.42169 9.61657 1.33333 8 1.33333C6.38343 1.33333 5.17538 1.42169 4.25995 1.62514C3.35966 1.82522 2.82616 2.11846 2.47231 2.47231C2.11845 2.82616 1.82522 3.35966 1.62514 4.25995C1.42169 5.17538 1.33333 6.38343 1.33333 8Z"
              fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
            />
          </svg>
        }
        text={translate("addNew")}
      />
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="header">
              <div>{translate("addingNewConsignmentItems")}</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div className="three-grid">
              <div>
                <div className="label">{translate("itemName")}</div>
                <Input
                  name="name"
                  type={"text"}
                  value={fields.name}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterName")}
                />
              </div>

              <div>
                <div className="label">{translate("marketIdentifier")}</div>
                <Input
                  name="styleCode"
                  type={"text"}
                  value={fields.styleCode}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterStyleCode")}
                />
              </div>

              <div>
                <div className="label">{translate("dateIn")}</div>
                <Input
                  name="dateIn"
                  type={"date"}
                  dark={dark}
                  value={fields.dateIn}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterDate")}
                />
              </div>
            </div>

            <div className="three-grid">
              
            <div>
                <div className="label">{translate("categories")}</div>
                <Select
                  dark={dark}
                  name="category"
                  value={fields.category}
                  updateValue={handleChange}
                  options={[
                    {
                      label: "Sneakers",
                      value: "sneakers",
                    },
                    {
                      label: "Streetwear",
                      value: "streetwear",
                    },
                    {
                      label: "Electronics",
                      value: "electronics",
                    },
                    {
                      label: "Arts",
                      value: "arts",
                    },
                    {
                      label: "Collectibles",
                      value: "collectibles",
                    },
                  ]}
                  placeholderText={GetTranslateString("chooseCategories")}
                  none={false}
                />
              </div>

              <div>
                <div className="label">{translate("sizing")}</div>
                <Select
                  dark={dark}
                  disabled={(fields.category === "sneakers" || fields.category === "streetwear") ? false : true}
                  name="sizing"
                  value={(fields.category !== "" && !_.map(sizingOptions[fields.category], 'value').includes(fields.sizing))? '' : fields.sizing}
                  render={
                    (fields.sizing !== '')
                      ? (fields.category === "sneakers" && _.map(sizingOptions["sneakers"], 'value').includes(fields.sizing))
                        ? () =>
                          sizingValues[localSizing][fields.sizing]
                            ? sizingValues[localSizing][fields.sizing]
                            : womenSizingValues[localSizing][fields.sizing]
                            ? womenSizingValues[localSizing][fields.sizing]
                            : girlSizingValues[localSizing][fields.sizing]
                            ? girlSizingValues[localSizing][fields.sizing]
                            : toddler[localSizing][fields.sizing]
                            ? toddler[localSizing][fields.sizing]
                            : young[localSizing][fields.sizing]
                        : () => fields.sizing
                      : ""
                  }
                  updateValue={handleChange}
                  options={sizingOptions[fields.category]}
                  placeholderText={GetTranslateString("chooseSize")}
                  none={false}
                />
              </div>

              <div>
                <div className="label">{translate("condition")}</div>
                <Select
                  dark={dark}
                  name="condn"
                  value={fields.condn}
                  updateValue={handleChange}
                  options={[
                    {
                      label: GetTranslateString("new"),
                      value: constants.NEW,
                    },
                    {
                      label: GetTranslateString("used"),
                      value: constants.USED,
                    },
                  ]}
                  placeholderText={GetTranslateString("chooseCondition")}
                />
              </div>
            </div>

            <div className="three-grid">
              <div>
                <div className="label">{translate("consigneeId")}</div>
                <Input
                  name="consigneeID"
                  type={"text"}
                  value={fields.consigneeID}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterId")}
                />
              </div>

              <div>
                <div className="label">{translate("payoutPrice")}</div>
                <Input
                  name="payoutPrice"
                  value={fields.payoutPrice}
                  type={"number"}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterAmount")}
                />
              </div>
            </div>

            <div className="btns w-full jus-end mt-10">
              <div>
                <Loader loading={loading} />
              </div>
              <div className="ml-10">
                <Button
                  onClick={() => {
                    clearValues();
                    updateSavedItems([]);
                  }}
                  text={translate("clearAll")}
                  imgSrc={
                    <img src={dark ? DeleteDark : Delete} alt="delete img" />
                  }
                />
              </div>
              <div className="ml-10">
                <Button
                  imgSrc={<img src={dark ? AddDark : Add} alt="add img" />}
                  onClick={() => {
                    saveHandler();
                  }}
                  text={translate("saveItems")}
                />
              </div>
            </div>

            <div className="table-container">
              <ConsignmentNewItemInventoryGrid
                savedItems={savedItems}
                updateSavedItems={updateSavedItems}
              />
            </div>

            <div className="w-full jus-end">
              <div className="mt-10 px-20">
                <Button
                  disabled={isDisabled}
                  imgSrc={<img src={dark ? AddDark : Add} alt="add img" />}
                  onClick={async () => {
                    updateDisabled(true);
                    try {
                      if (savedItems.length === 0) {
                        throw new Error("Saved items cannot be empty.");
                      }
                      await addHandler();
                    } catch (e) {
                      ErrorToast(translate("error"), e.message);
                      updateDisabled(false);
                      return;
                    }
                    SuccessToast(translate("success"), translate("itemCreated"));
                    handleClose();
                    updateDisabled(false);
                    clearValues();
                  }}
                  text={translate("addNew")}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
