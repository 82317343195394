import "../css/Sidebar.scoped.css";
import { Link } from "react-router-dom";
import logodark from "../../assets/logodark.png";
import { useLocation } from "react-router-dom";
import translate from "../../i18n/translate";

const Sidebar = ({ dark }) => {
  let location = useLocation();
  let hidden = [
    false,
    localStorage.getItem("isMarketHidden"),
    localStorage.getItem("isInventoryHidden"),
    localStorage.getItem("isProductLabelsHidden"),
    localStorage.getItem("isConsignmentHidden"),
    localStorage.getItem("isSalesHidden"),
    localStorage.getItem("isExpenseHidden") ||
      localStorage.getItem("hideExpensePage"),
    false,
  ];

  const isOwner = localStorage.getItem("owner");

  let Links = [
    {
      to: "/dash",
      name: translate("dashboard"),
      svg: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.221326 8.99248C-0.0147588 10.5601 -0.292667 14.7333 0.735171 19.4303C1.3021 22.0211 2.77266 23.2096 5.87555 23.6054C10 24.1315 14 24.1315 18.2127 23.6054C21.3166 23.2177 22.8394 22.0397 23.3531 19.4303C24.2569 14.8392 23.9842 10.7454 23.8747 9.1023L23.8674 8.99248C23.7934 7.87377 17.7777 3.28332 14.357 0.759246C12.9796 -0.257135 11.1125 -0.252513 9.73942 0.769785C6.34725 3.2954 0.389923 7.87297 0.221326 8.99248ZM21.4194 9.00872C21.6372 9.22633 21.7935 9.39745 21.8981 9.52338C22.0116 11.2838 22.1941 14.9627 21.3909 19.0423C21.1992 20.0165 20.8747 20.4887 20.5033 20.7871C20.0812 21.1262 19.3444 21.4442 17.9653 21.6164C13.9151 22.1222 10.0831 22.1216 6.12807 21.6171C4.75547 21.442 4.03091 21.1223 3.61281 20.7831C3.23681 20.478 2.90581 19.9928 2.68874 19.0009C1.78797 14.8846 1.96138 11.1972 2.15652 9.60453C2.27081 9.46564 2.44992 9.27105 2.70496 9.01969C3.27852 8.45441 4.09291 7.74149 5.04459 6.95432C6.93905 5.38734 9.24385 3.63575 10.9321 2.37876C11.6001 1.88142 12.5021 1.87961 13.1712 2.37334C14.8733 3.62932 17.1974 5.38214 19.0952 6.94967C20.0492 7.73762 20.8582 8.44824 21.4194 9.00872Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M9 21.896C8.33504 21.852 7.66893 21.7935 7 21.7204V16.114C7 14.4535 8.34315 13.1074 10 13.1074H14C15.6568 13.1074 17 14.4535 17 16.114L17 21.7278C16.3285 21.7989 15.6624 21.8558 15 21.8985L15 16.114C15 15.5605 14.5523 15.1118 14 15.1118L10 15.1118C9.44772 15.1118 9 15.5605 9 16.114V21.896Z"
            fill="#767BFA"
          />
        </svg>
      ),
    },
    {
      to: "/wallet",
      name: translate("Wallet"),
      svg: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 11C16.4477 11 16 11.4477 16 12C16 12.5523 16.4477 13 17 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H17Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M21.7694 7H17.0453C16.3827 7 15.7267 7.12933 15.1146 7.38062C14.5024 7.63187 13.9463 8.00018 13.4777 8.46448C13.0092 8.92877 12.6376 9.47995 12.3841 10.0866C12.1305 10.6932 12 11.3434 12 12C12 12.6566 12.1305 13.3068 12.3841 13.9134C12.6376 14.5201 13.0092 15.0712 13.4777 15.5355C13.9463 15.9998 14.5024 16.3681 15.1146 16.6194C15.7267 16.8707 16.3827 17 17.0453 17H21.7694C21.8716 16.4088 21.9476 15.7457 22 15H17.0453C16.6478 15 16.2541 14.9224 15.8869 14.7716C15.5196 14.6209 15.1859 14.3999 14.9048 14.1213C14.6237 13.8427 14.4007 13.512 14.2486 13.148C14.0964 12.7841 14.0181 12.394 14.0181 12C14.0181 11.606 14.0964 11.2159 14.2486 10.852C14.4007 10.488 14.6237 10.1573 14.9048 9.87869C15.1859 9.6001 15.5196 9.37912 15.8869 9.22836C16.2541 9.07761 16.6478 9 17.0453 9H22C21.9476 8.25433 21.8716 7.59122 21.7694 7Z"
            fill="#767BFA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 12C0 21.882 2.118 24 12 24C21.882 24 24 21.882 24 12C24 2.118 21.882 0 12 0C2.118 0 0 2.118 0 12ZM2 12C2 14.4249 2.13254 16.2369 2.43771 17.6101C2.73783 18.9605 3.17768 19.7608 3.70846 20.2915C4.23924 20.8223 5.03949 21.2622 6.38993 21.5623C7.76307 21.8675 9.57515 22 12 22C14.4249 22 16.2369 21.8675 17.6101 21.5623C18.9605 21.2622 19.7608 20.8223 20.2915 20.2915C20.8223 19.7608 21.2622 18.9605 21.5623 17.6101C21.8675 16.2369 22 14.4249 22 12C22 9.57515 21.8675 7.76307 21.5623 6.38993C21.2622 5.03949 20.8223 4.23924 20.2915 3.70846C19.7608 3.17768 18.9605 2.73783 17.6101 2.43771C16.2369 2.13254 14.4249 2 12 2C9.57515 2 7.76307 2.13254 6.38993 2.43771C5.03949 2.73783 4.23924 3.17768 3.70846 3.70846C3.17768 4.23924 2.73783 5.03949 2.43771 6.38993C2.13254 7.76307 2 9.57515 2 12Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
        </svg>
      ),
    },
    // {
    //   to: "/market",
    //   name: translate("marketLookUp"),
    //   svg: (
    //     <svg
    //       width="24"
    //       height="24"
    //       viewBox="0 0 24 24"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //         d="M0 12C0 21.882 2.118 24 12 24C21.882 24 24 21.882 24 12C24 2.118 21.882 0 12 0C2.118 0 0 2.118 0 12ZM2 12C2 14.4249 2.13254 16.2369 2.43771 17.6101C2.73783 18.9605 3.17768 19.7608 3.70846 20.2915C4.23924 20.8223 5.03949 21.2622 6.38993 21.5623C7.76307 21.8675 9.57515 22 12 22C14.4249 22 16.2369 21.8675 17.6101 21.5623C18.9605 21.2622 19.7608 20.8223 20.2915 20.2915C20.8223 19.7608 21.2622 18.9605 21.5623 17.6101C21.8675 16.2369 22 14.4249 22 12C22 9.57515 21.8675 7.76307 21.5623 6.38993C21.2622 5.03949 20.8223 4.23924 20.2915 3.70846C19.7608 3.17768 18.9605 2.73783 17.6101 2.43771C16.2369 2.13254 14.4249 2 12 2C9.57515 2 7.76307 2.13254 6.38993 2.43771C5.03949 2.73783 4.23924 3.17768 3.70846 3.70846C3.17768 4.23924 2.73783 5.03949 2.43771 6.38993C2.13254 7.76307 2 9.57515 2 12Z"
    //         className="active-white"
    //         fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
    //       />
    //       <path
    //         d="M13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V7Z"
    //         fill="#767BFA"
    //       />
    //       <path
    //         d="M8 13C8 12.4477 7.55231 12 7 12C6.44769 12 6 12.4477 6 13V17C6 17.5523 6.44769 18 7 18C7.55231 18 8 17.5523 8 17V13Z"
    //         fill="#767BFA"
    //       />
    //       <path
    //         d="M17 10C17.5523 10 18 10.4477 18 11V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V11C16 10.4477 16.4477 10 17 10Z"
    //         fill="#767BFA"
    //       />
    //     </svg>
    //   ),
    // },
    {
      to: "/inventory",
      name: translate("inventory"),
      svg: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 6C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6C6 4.06198 6.7367 2.53498 7.89814 1.50259C9.04117 0.486564 10.5369 0 12 0C13.4631 0 14.9588 0.486564 16.1019 1.50259C17.2633 2.53498 18 4.06198 18 6C18 6.55228 17.5523 7 17 7C16.4477 7 16 6.55228 16 6C16 4.60469 15.4867 3.63169 14.7731 2.99741C14.0412 2.34677 13.0369 2 12 2C10.9631 2 9.95883 2.34677 9.22686 2.99741C8.5133 3.63169 8 4.60469 8 6Z"
            fill="#767BFA"
          />
          <path
            d="M7 9C6.44772 9 6 9.44771 6 10C6 10.5523 6.44772 11 7 11H17C17.5523 11 18 10.5523 18 10C18 9.44771 17.5523 9 17 9H7Z"
            fill="#767BFA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 24C21.4928 24 25.4487 23 23.5274 14.5C23.4508 14.2007 23.377 13.9102 23.3054 13.6282C21.5822 6.84356 21.114 5 12 5C2.88602 5 2.41779 6.84353 0.694654 13.628C0.623019 13.9101 0.549216 14.2007 0.472531 14.5C-1.44868 23 2.50713 24 12 24ZM2.63868 14.099C2.56811 14.3766 2.49455 14.666 2.41719 14.9681C1.96831 16.9642 1.91972 18.2994 2.09195 19.191C2.24662 19.9916 2.5749 20.4407 3.07851 20.7822C3.671 21.184 4.63703 21.5188 6.19181 21.729C7.72683 21.9365 9.64142 22 12 22C14.3586 22 16.2732 21.9365 17.8082 21.729C19.363 21.5188 20.329 21.184 20.9215 20.7822C21.4251 20.4407 21.7534 19.9916 21.9081 19.191C22.0803 18.2994 22.0317 16.9642 21.5828 14.9681C21.5054 14.6661 21.4319 14.3767 21.3614 14.0992C20.9832 12.6114 20.6911 11.4621 20.3603 10.5046C19.9745 9.38767 19.6092 8.76698 19.1975 8.36029C18.3937 7.5662 16.8159 7 12 7C7.1841 7 5.60631 7.5662 4.8025 8.36029C4.39083 8.76698 4.02554 9.38767 3.63968 10.5046C3.30893 11.462 3.01681 12.6113 2.63868 14.099Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
        </svg>
      ),
    },
    {
      to: "/product",
      name: translate("productLabels"),
      svg: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.83447 13.7601C1.80099 13.2686 1.39833 12.8793 0.905678 12.8793C0.385396 12.8793 -0.0325008 13.315 0.00199242 13.8341C0.39146 19.6958 2.30418 21.6085 8.16583 21.998C8.68497 22.0325 9.12072 21.6146 9.12072 21.0943C9.12072 20.6017 8.73136 20.199 8.23985 20.1655C7.28991 20.1008 6.48456 19.9952 5.79822 19.8426C4.55511 19.5663 3.81763 19.1613 3.32818 18.6718C2.83873 18.1824 2.43365 17.4449 2.15738 16.2018C2.00484 15.5154 1.89919 14.7101 1.83447 13.7601Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M20.0868 13.7601C20.1203 13.2686 20.5229 12.8793 21.0156 12.8793C21.5359 12.8793 21.9538 13.315 21.9193 13.8342C21.5298 19.6958 19.6171 21.6085 13.7554 21.998C13.2363 22.0325 12.8005 21.6146 12.8005 21.0943C12.8005 20.6017 13.1899 20.199 13.6814 20.1655C14.6313 20.1008 15.4367 19.9952 16.123 19.8426C17.3661 19.5663 18.1036 19.1613 18.5931 18.6718C19.0825 18.1824 19.4876 17.4449 19.7639 16.2018C19.9164 15.5154 20.0221 14.7101 20.0868 13.7601Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M22 11.0815C22 11.0675 22 11.0534 22 11.0394C22 11.0253 22 11.0113 22 10.9973V11.0815Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M21.9193 8.24459C21.9538 8.76373 21.5359 9.19948 21.0156 9.19948C20.5229 9.19948 20.1203 8.81012 20.0868 8.31861C20.0221 7.36866 19.9164 6.5633 19.7639 5.87697C19.4876 4.63385 19.0825 3.89637 18.5931 3.40692C18.1036 2.91747 17.3661 2.51239 16.123 2.23612C15.4367 2.08358 14.6313 1.97793 13.6814 1.91321C13.1899 1.87973 12.8005 1.47706 12.8005 0.984417C12.8005 0.464135 13.2363 0.0462391 13.7554 0.0807319C19.6171 0.470198 21.5298 2.38292 21.9193 8.24459Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M1.83447 8.31861C1.80099 8.81012 1.39833 9.19948 0.905678 9.19948C0.385396 9.19948 -0.0325008 8.76373 0.00199232 8.24459C0.39146 2.38293 2.30418 0.470204 8.16584 0.0807339C8.68497 0.0462405 9.12072 0.464137 9.12072 0.984419C9.12072 1.47707 8.73136 1.87973 8.23985 1.91321C7.28991 1.97793 6.48456 2.08358 5.79822 2.23612C4.55511 2.51239 3.81763 2.91747 3.32818 3.40692C2.83873 3.89637 2.43365 4.63385 2.15738 5.87697C2.00484 6.5633 1.89919 7.36866 1.83447 8.31861Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <rect
            x="6.4165"
            y="6.49561"
            width="9.16646"
            height="1.83329"
            rx="0.916646"
            fill="#767BFA"
          />
          <rect
            x="8.25"
            y="13.8286"
            width="5.49988"
            height="1.83329"
            rx="0.916646"
            fill="#767BFA"
          />
          <rect
            x="4.58301"
            y="10.1621"
            width="12.833"
            height="1.83329"
            rx="0.916646"
            fill="#767BFA"
          />
        </svg>
      ),
    },
    {
      to: "/consignment",
      name: translate("consignment"),
      svg: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 11C0 20.0585 1.9415 22 11 22C20.0585 22 22 20.0585 22 11C22 1.9415 20.0585 0 11 0C1.9415 0 0 1.9415 0 11ZM1.83333 11C1.83333 13.2228 1.95483 14.8838 2.23457 16.1426C2.50968 17.3805 2.91288 18.114 3.39943 18.6006C3.88597 19.0871 4.61954 19.4903 5.85743 19.7654C7.11615 20.0452 8.77722 20.1667 11 20.1667C12.0559 20.1667 12.9851 20.1392 13.8046 20.0805C13.8013 19.916 13.7927 19.7399 13.7829 19.54C13.7774 19.4276 13.7715 19.3074 13.766 19.1777C13.749 18.7774 13.7382 18.3205 13.7729 17.8519C13.8418 16.9203 14.098 15.8359 14.9518 14.9795C15.8082 14.1204 16.9253 13.8572 17.8662 13.7798C18.3435 13.7406 18.8069 13.7467 19.2094 13.7607C19.365 13.7661 19.5045 13.7722 19.6323 13.7778C19.7984 13.785 19.9448 13.7914 20.0812 13.7943C20.1395 12.9773 20.1667 12.0515 20.1667 11C20.1667 8.77722 20.0452 7.11615 19.7654 5.85743C19.4903 4.61954 19.0871 3.88597 18.6006 3.39943C18.114 2.91288 17.3805 2.50968 16.1426 2.23457C14.8838 1.95483 13.2228 1.83333 11 1.83333C8.77722 1.83333 7.11615 1.95483 5.85743 2.23457C4.61954 2.50968 3.88597 2.91288 3.39943 3.39943C2.91288 3.88597 2.50968 4.61954 2.23457 5.85743C1.95483 7.11615 1.83333 8.77722 1.83333 11ZM19.8681 15.6221C19.7467 15.6179 19.6207 15.6125 19.4958 15.6071C19.3766 15.6019 19.2583 15.5968 19.146 15.5929C18.7684 15.5798 18.388 15.5764 18.0165 15.607C17.2598 15.6692 16.6596 15.8631 16.2501 16.2738C15.838 16.6872 15.655 17.2608 15.6012 17.9873C15.5745 18.3478 15.5815 18.7199 15.5976 19.1C15.6018 19.1972 15.6067 19.2985 15.6118 19.4012C15.6193 19.5556 15.6271 19.7134 15.6324 19.8663C15.8094 19.8353 15.9794 19.8017 16.1426 19.7654C17.3805 19.4903 18.114 19.0871 18.6006 18.6006C19.0871 18.114 19.4903 17.3805 19.7654 16.1426C19.8024 15.9762 19.8366 15.8028 19.8681 15.6221Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M8.25 6.41667C8.25 5.9104 8.66041 5.5 9.16667 5.5H12.8333C13.3396 5.5 13.75 5.9104 13.75 6.41667C13.75 6.92293 13.3396 7.33333 12.8333 7.33333H9.16667C8.66041 7.33333 8.25 6.92293 8.25 6.41667Z"
            fill="#767BFA"
          />
          <path
            d="M6.41667 10.0833C5.91041 10.0833 5.5 10.4937 5.5 11C5.5 11.5063 5.91041 11.9167 6.41667 11.9167H15.5833C16.0896 11.9167 16.5 11.5063 16.5 11C16.5 10.4937 16.0896 10.0833 15.5833 10.0833H6.41667Z"
            fill="#767BFA"
          />
        </svg>
      ),
    },
    {
      to: "/sales",
      name: translate("sales"),
      svg: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6835 1.33293C13.3359 -0.44431 10.6641 -0.44431 9.31652 1.33293L8.19449 2.81269L6.35475 2.55974C4.14516 2.25593 2.25593 4.14516 2.55974 6.35475L2.81269 8.19449L1.33293 9.31652C-0.44431 10.6641 -0.44431 13.3359 1.33293 14.6835L2.81269 15.8055L2.55973 17.6453C2.25593 19.8548 4.14516 21.7441 6.35475 21.4403L8.19449 21.1873L9.31652 22.6671C10.6641 24.4443 13.3359 24.4443 14.6835 22.6671L15.8055 21.1873L17.6453 21.4403C19.8548 21.7441 21.7441 19.8548 21.4403 17.6453L21.1873 15.8055L22.6671 14.6835C24.4443 13.3359 24.4443 10.6641 22.6671 9.31652L21.1873 8.19449L21.4403 6.35475C21.7441 4.14516 19.8548 2.25593 17.6453 2.55973L15.8055 2.81269L14.6835 1.33293ZM11.1055 2.68943C11.5547 2.09702 12.4453 2.09702 12.8945 2.68943L14.0165 4.16919C14.5067 4.81567 15.3076 5.1474 16.1113 5.03688L17.9511 4.78393C18.6876 4.68266 19.3173 5.31241 19.2161 6.04894L18.9631 7.88868C18.8526 8.69243 19.1843 9.49328 19.8308 9.98348L21.3106 11.1055C21.903 11.5547 21.903 12.4453 21.3106 12.8945L19.8308 14.0165C19.1843 14.5067 18.8526 15.3076 18.9631 16.1113L19.2161 17.9511C19.3173 18.6876 18.6876 19.3173 17.9511 19.2161L16.1113 18.9631C15.3076 18.8526 14.5067 19.1843 14.0165 19.8308L12.8945 21.3106C12.4453 21.903 11.5547 21.903 11.1055 21.3106L9.98348 19.8308C9.49328 19.1843 8.69243 18.8526 7.88868 18.9631L6.04894 19.2161C5.31241 19.3173 4.68266 18.6876 4.78393 17.9511L5.03689 16.1113C5.1474 15.3076 4.81567 14.5067 4.16919 14.0165L2.68943 12.8945C2.09702 12.4453 2.09702 11.5547 2.68943 11.1055L4.16919 9.98348C4.81567 9.49328 5.1474 8.69243 5.03689 7.88868L4.78393 6.04894C4.68266 5.31241 5.31241 4.68266 6.04894 4.78393L7.88868 5.03688C8.69243 5.1474 9.49328 4.81567 9.98348 4.16919L11.1055 2.68943Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M9 11C8.44772 11 8 10.5523 8 10L8 9C8 8.44772 8.44772 8 9 8C9.55228 8 10 8.44772 10 9V10C10 10.5523 9.55228 11 9 11Z"
            fill="#767BFA"
          />
          <path
            d="M8.30602 15.694C7.89799 15.286 7.89799 14.6244 8.30602 14.2164L14.2164 8.30602C14.6244 7.89799 15.286 7.89799 15.694 8.30602C16.102 8.71405 16.102 9.37559 15.694 9.78361L9.78361 15.694C9.37559 16.102 8.71405 16.102 8.30602 15.694Z"
            fill="#767BFA"
          />
          <path
            d="M14 15C14 15.5523 14.4477 16 15 16C15.5523 16 16 15.5523 16 15V14C16 13.4477 15.5523 13 15 13C14.4477 13 14 13.4477 14 14V15Z"
            fill="#767BFA"
          />
        </svg>
      ),
    },
    {
      to: "/expenses",
      name: translate("expenses"),
      svg: (
        <svg
          width="21"
          height="22"
          viewBox="0 0 21 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.60066 0.109822C1.05439 -0.146584 0.390135 0.0612903 0.11697 0.574102C-0.156132 1.08688 0.0652861 1.71047 0.611553 1.96688L1.72127 2.48775C2.06382 2.64853 2.29338 2.96424 2.32807 3.32228C2.35513 3.60154 2.38136 3.87825 2.40727 4.15224L2.41034 4.18485C3.0317 10.7546 3.50076 15.7143 11.5566 15.7143C19.9614 15.7143 20.5175 10.1148 21 5.2381L19.5554 5.54048C19.1367 5.62814 18.8207 5.9726 18.7691 6.39731C18.3239 10.0545 17.7371 13.619 11.5566 13.619C6.47771 13.619 5.38616 11.1131 4.92301 7.37917C4.85044 6.79416 5.315 6.28572 5.9045 6.28572H19.99C20.503 6.28572 20.9329 5.89744 20.9848 5.38703L21 5.2381C21 4.65952 20.5297 4.19048 19.9494 4.19048H5.54448C5.02508 4.19048 4.59326 3.79273 4.54425 3.27565C4.5398 3.22863 4.53528 3.18152 4.53071 3.13432C4.42664 2.0602 3.73802 1.11307 2.71038 0.630722L1.60066 0.109822Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            d="M7.35413 22C8.51457 22 9.45532 21.062 9.45532 19.9048C9.45532 18.7476 8.51457 17.8096 7.35413 17.8096C6.19368 17.8096 5.25293 18.7476 5.25293 19.9048C5.25293 21.062 6.19368 22 7.35413 22Z"
            fill="#767BFA"
          />
          <path
            d="M17.8601 19.9048C17.8601 21.062 16.9193 22 15.7589 22C14.5985 22 13.6577 21.062 13.6577 19.9048C13.6577 18.7476 14.5985 17.8096 15.7589 17.8096C16.9193 17.8096 17.8601 18.7476 17.8601 19.9048Z"
            fill="#767BFA"
          />
        </svg>
      ),
    },
    {
      to: "/settings",
      name: translate("settings"),
      svg: (
        <svg
          width="22"
          height="24"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.60643 3.65554C6.55267 3.94423 6.25616 4.11326 5.98413 4.0171C1.30599 2.36352 -2.16181 8.42001 1.58941 11.6377C1.81102 11.8278 1.81102 12.1722 1.58941 12.3623C-2.16181 15.58 1.30599 21.6365 5.98413 19.9829C6.25616 19.8867 6.55267 20.0558 6.60643 20.3445C7.51399 25.2185 14.486 25.2185 15.3936 20.3445C15.4473 20.0558 15.7438 19.8867 16.0159 19.9829C20.694 21.6365 24.1618 15.58 20.4106 12.3623C20.189 12.1722 20.189 11.8278 20.4106 11.6377C24.1618 8.42001 20.694 2.36352 16.0159 4.0171C15.7438 4.11326 15.4473 3.94423 15.3936 3.65554C14.486 -1.21851 7.51399 -1.21851 6.60643 3.65554ZM8.57055 4.02202C9.07256 1.32599 12.9274 1.32599 13.4295 4.02202C13.7076 5.5159 15.2508 6.40856 16.6811 5.90298C19.2624 4.99056 21.1899 8.33543 19.1106 10.119C17.9584 11.1073 17.9584 12.8927 19.1106 13.881C21.1899 15.6646 19.2624 19.0094 16.6811 18.097C15.2508 17.5914 13.7076 18.4841 13.4295 19.978C12.9274 22.674 9.07256 22.674 8.57055 19.978C8.29239 18.4841 6.74923 17.5914 5.3189 18.097C2.73757 19.0094 0.810125 15.6646 2.88945 13.881C4.04161 12.8927 4.04161 11.1073 2.88945 10.119C0.810125 8.33543 2.73757 4.99056 5.3189 5.90298C6.74923 6.40856 8.29239 5.5159 8.57055 4.02202Z"
            className="active-white"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.00391 12C7.00391 14.2091 8.79293 16 10.9998 16C13.2067 16 14.9957 14.2091 14.9957 12C14.9957 9.79086 13.2067 8 10.9998 8C8.79293 8 7.00391 9.79086 7.00391 12ZM9.00186 12C9.00186 13.1046 9.89637 14 10.9998 14C12.1032 14 12.9978 13.1046 12.9978 12C12.9978 10.8954 12.1032 10 10.9998 10C9.89637 10 9.00186 10.8954 9.00186 12Z"
            fill="#767BFA"
          />
        </svg>
      ),
    },
  ];

  return (
    <div className="sidebar-container">
      <div className="sidebar">
        <div className="logo">
          <img src={logodark} alt="" />
        </div>
        {Links.map((link, i) =>
          isOwner ? (
            <Link
              className={`nav-link ${
                location.pathname === link.to ? "active-link" : ""
              }`}
              key={i}
              to={link.to}
            >
              {link.svg}
              {link.name}
            </Link>
          ) : (
            !hidden[i] && (
              <Link
                className={`nav-link ${
                  location.pathname === link.to ? "active-link" : ""
                }`}
                key={i}
                to={link.to}
              >
                {link.svg}
                {link.name}
              </Link>
            )
          )
        )}
        <div
          className={`nav-link`}
          style={{ cursor: "pointer" }}
          onClick={() =>
            window.open("https://discord.gg/wP4ZQpbkZw", "__blank")
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="24"
            viewBox="0 0 22 27"
          >
            <path
              fill="#6766DB"
              d="M9.024,9.38c-0.902,0-1.644,1.041-1.644,2.307s0.742,2.306,1.644,2.306s1.643-1.041,1.643-2.306 S9.925,9.38,9.024,9.38z"
            />
            <path
              fill="#6766DB"
              d="M14.966,9.38c-0.902,0-1.644,1.041-1.644,2.307s0.742,2.306,1.644,2.306 c0.902,0,1.643-1.041,1.643-2.306S15.867,9.38,14.966,9.38z"
            />
            <path
              d="M20.271,6.263c-0.006-0.01-0.012-0.02-0.019-0.029c-0.367-0.547-0.887-0.965-1.503-1.208 C17.605,4.573,16.62,4.271,15.737,4.1c-0.797-0.15-1.581,0.195-1.978,0.84c-1.479-0.135-2.759-0.067-3.52,0.005 C9.806,4.29,9.034,3.953,8.271,4.1C7.385,4.271,6.399,4.575,5.257,5.027c-0.615,0.244-1.135,0.662-1.502,1.21 c-2.202,3.286-3.065,6.831-2.638,10.836c0.032,0.306,0.204,0.58,0.465,0.743c1.648,1.027,3.143,1.763,4.568,2.25 c0.827,0.282,1.736-0.034,2.218-0.766l0.516-0.782c0.101,0.024,0.207,0.035,0.308,0.057c0.93,0.265,1.891,0.41,2.859,0.41 c1.025,0,2.04-0.169,3.021-0.465c0.008-0.002,0.017-0.003,0.025-0.005l0.55,0.809c0.365,0.539,0.97,0.846,1.593,0.846 c0.204,0,0.409-0.033,0.609-0.101c1.43-0.488,2.927-1.225,4.578-2.254c0.261-0.163,0.433-0.437,0.465-0.743 C23.317,13.079,22.461,9.542,20.271,6.263z M20.948,16.376c-1.327,0.797-2.535,1.381-3.68,1.778l-0.286-0.421 c0.092-0.049,0.19-0.085,0.281-0.137c0.48-0.274,0.647-0.884,0.373-1.364c-0.272-0.48-0.884-0.649-1.363-0.373 c-0.577,0.329-1.193,0.574-1.824,0.756c-0.805,0.186-1.615,0.285-2.438,0.302c-0.806-0.016-1.592-0.136-2.362-0.303 c-0.63-0.182-1.245-0.426-1.821-0.755c-0.481-0.276-1.092-0.107-1.363,0.373c-0.274,0.48-0.107,1.09,0.373,1.364 c0.063,0.036,0.132,0.061,0.195,0.095L6.73,18.151c-1.156-0.401-2.334-0.97-3.67-1.774c-0.258-3.322,0.515-6.279,2.356-9.027 C5.558,7.14,5.756,6.98,5.993,6.886c1.025-0.406,1.896-0.675,2.584-0.83L8.823,6.5c0.203,0.365,0.619,0.563,1.031,0.503 c0.211-0.032,2.138-0.301,4.384,0.003c0.456,0.062,0.885-0.188,1.059-0.608l0.133-0.32c0.742,0.15,1.589,0.415,2.584,0.808 c0.23,0.091,0.425,0.245,0.565,0.445c0.004,0.007,0.009,0.014,0.013,0.021C20.434,10.098,21.206,13.056,20.948,16.376z"
              fill="#ABAFD1"
            />
          </svg>
          Join our Discord
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
