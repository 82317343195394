import { useContext } from "react";
import addIcon from "../../assets/add.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import editCart from "../../firebase/cart/editCart";
import encodeKey from "../../firebase/util/encodeKey";
import "../css/PostItem.scoped.css";
import Button from "../general/Button";
import { ErrorToast } from "../general/ToastNotification";
import AddDark from "../../assets/addark.svg";
import translate from "../../i18n/translate";

const PosItem = ({ type, data, activeCart, cartIds, dark }) => {
  const {
    rates,
    sign,
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  let localSizing = localStorage.getItem("sizing");

  let Price = (data?.price * rates?.[localCurrency?.toUpperCase()]).toFixed(2);
  try {
    if (data?.type === "normal") {
      if (data?.platform[encodeKey(data?.size)] === "custom") {
        Price = (
          (data?.sizesCustom[encodeKey(data?.size)] +
            (data?.sizesCustom[encodeKey(data?.size)] * data?.markUp) / 100) *
          rates?.[localCurrency?.toUpperCase()]
        ).toFixed(2);
      } else {
        Price = (
          (data?.sizes[data?.size] +
            (data?.sizes[data?.size] * data?.markUp) / 100) *
          rates?.[localCurrency?.toUpperCase()]
        ).toFixed(2);
      }
    }
  } catch (e) {
    console.log(e);
  }

  const name =
    data?._highlightResult && data?._highlightResult.name
      ? data?._highlightResult.name.value
      : data?.name;

  return (
    <div className="item">
      <div className={`header ${type}`}>{type}</div>
      <div>
        <img src={data?.image} className="shoeimg" alt="product img" />
        <div className="name" dangerouslySetInnerHTML={{ __html: name }} />
        <div className="sku my-5">{data?.colorway}</div>
        <div className="my-5">
          <span className="query">{translate("style")}:</span>
          <span className="value">{data?.sku}</span>
        </div>
        <div className="my-5">
          <span className="query">{translate("condition")}:</span>
          <span className="value cap">
            {data?.invCondition ? data?.invCondition : "New"}
          </span>
        </div>
        <div className="my-5 select">
          <span className="mt-0 query">{translate("size")}:</span>
          <span className="value">
            {((data?.category) === "sneakers") 
              ? sizingValues[localSizing][(data?.size)]
                ? sizingValues[localSizing][(data?.size)]
                : womenSizingValues[localSizing][(data?.size)]
                ? womenSizingValues[localSizing][(data?.size)]
                : girlSizingValues[localSizing][(data?.size)]
                ? girlSizingValues[localSizing][(data?.size)]
                : toddler[localSizing][(data?.size)]
                ? toddler[localSizing][(data?.size)]
                : young[localSizing][(data?.size)]
              : (data?.size)}
          </span>
        </div>
        <div className="my-5">
          <span className="query">{translate("consignment")}:</span>
          <span className="value">
            {data?.consigneeID ? data?.consigneeID : "-"}
          </span>
        </div>
        <div className="price">
          <span>{translate("price")}:</span>
          <span>
            {sign[localCurrency]}
            {Price ? Price : 0}
          </span>
        </div>
      </div>
      <div className={`${cartIds?.includes(data?.id) ? "incart" : "addcart"}`}>
        {cartIds?.includes(data?.id) ? (
          "In Cart"
        ) : (
          <Button
            imgSrc={<img src={dark ? AddDark : addIcon} alt="add" />}
            text={translate("addtocart")}
            onClick={() => {
              try {
                editCart(
                  StoreId,
                  activeCart,
                  data?.id
                  /*   {
                  // platform: data?.platform ? data?.platform : "",
                  // consigneeID: data?.consigneeID ? data?.consigneeID : "-",
                  // condition: data?.condition,
                  // dateIn: data?.dateIn ? data?.dateIn : "",
                  id: data?.id,
                  // image: data?.image,
                  // name: data?.name,
                  // payoutPrice: data?.payoutPrice ? data?.payoutPrice : "",
                  // price: data?.price,
                  // style: data?.style,
                  // styleCode: data?.styleCode,
                  // type: data?.type,
                  // url: data?.url,
                  // sku: data?.sku,
                  // size: data?.size,
                  // purchasePrice: data?.purchasePrice ? data?.purchasePrice : "",
                  // colorway: data?.colorway ? data?.colorway : "",
                } */
                );
              } catch (e) {
                ErrorToast(translate("addtocartfailed"), e.message);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PosItem;
