import { db } from "../index";
import { doc, collection, query, where, getDocs, updateDoc } from "firebase/firestore";

export default async function updateNormalCount(storeId) {
    const q = query(collection(db, "Stores", storeId, "Inventory"), where("type", "==", "normal"));

    const querySnapshot = await getDocs(q);
    let totalItems = 0
    let totalValue = 0
    querySnapshot.forEach((doc) => {
        const docData = doc.data()
        totalItems++
        totalValue += docData.purchasePrice
    });

    const docRef = doc(db, "Stores", storeId)
    const result = {
        "totalItems": totalItems,
        "totalValue": totalValue,
    }
    updateDoc(docRef, result)
    return result
}
