import { doc, onSnapshot, query, collection } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import refreshIcon from "../../assets/refresh.svg";
import refreshIconDark from "../../assets/refreshdark.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import { db } from "../../firebase/index";
import updateNormalCount from "../../firebase/util/updateNormalCount";
import encodeKey from "../../firebase/util/encodeKey";
import "../css/InenvtoryContainer.scoped.css";
import Button from "../general/Button";
import numberWithCommas from "../utils/numberWithCommas";
import PriceAlertModal from "./PriceAlertModal";
import InventoryGrid from "./InventoryGrid";
import translate from "../../i18n/translate";
import ListedGrid from "./ListedGrid";
import ListedTicketContainer from "./ListedTicketContainer";
const ListedContainer = ({ searchString, dark, sortBy, catagories }) => {
  const [data, updateData] = useState([]);

  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  const [totalItems, setTotalItems] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [totalPayout, setTotalPayout] = useState(0);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      query(collection(db, "Stores", StoreId, "ListedItems")),
      async (snapShot) => {
        //check if a search string exists
        let invData = [];
        invData = snapShot.docs.map((e) => {
          // console.log(e.data());
          return {
            ...e.data(),
            id: e.id,
          };
        });

        console.log(invData);

        updateData(
          invData.filter(
            (e) =>
              !searchString ||
              e.name.toLowerCase().search(searchString.toLowerCase()) ||
              e.sku.toLowerCase().search(searchString.toLowerCase())
          )
        );

        setTotalPayout(
          invData
            .filter((e) => e.sold)
            .reduce((prev, next) => prev.payout ?? 0 + next.payout ?? 0)
        );
        setTotalItems(invData.length);

        setTotalValue(
          invData
            // .filter((e) => !e.sold)
            .map((e) => e.askedPrice)
            .reduce((p, n) => p + n)
        );

        console.log(searchString, !searchString);
      }
    );

    return () => {
      inventoryListener();
    };
  }, [searchString]);

  // useEffect(() => {
  //   const cleanup = onSnapshot(doc(db, "Stores", StoreId), (doc) => {
  //     setTotal(doc.data());
  //   });
  //   return () => {
  //     cleanup();
  //   };
  // }, []);

  return (
    <>
      {catagories === "shoes" ? (
        <div className="inventory-container ">
          <div className="flex al-ce jc-sp headersection">
            <div className="smtext">{translate("Listed and Sold")}</div>
            <div className="flex al-ce">
              <div className="query flex al-ce">
                <span className="purple">
                  {translate("Total Listed Value")}:
                </span>
                <span className="queryvalue">
                  {sign[localCurrency]}
                  {numberWithCommas(
                    (
                      totalValue * rates?.[localCurrency?.toUpperCase()]
                    ).toFixed(2)
                  )}
                </span>
              </div>
              <div className="query">
                <span className="purple">{translate("totalItems")}:</span>
                <span className="queryvalue">
                  {numberWithCommas(totalItems)}
                </span>
              </div>
              <div className="query">
                <span className="purple">{translate("Total Payout")}:</span>
                <span className={""}>
                  <a href="https://dashboard.stripe.com/login"> Check Stripe</a>{" "}
                </span>
              </div>
            </div>
            <div className="flex">
              <Button
                text={translate("refresh")}
                imgSrc={
                  <img
                    src={dark ? refreshIconDark : refreshIcon}
                    alt="refresh Icon"
                  />
                }
                onClick={async () => {
                  // setTotal(await updateNormalCount(StoreId));
                }}
              />
            </div>
          </div>
          <ListedGrid sortBy={sortBy} dark={dark} data={data} />
        </div>
      ) : (
        <ListedTicketContainer sortBy={sortBy} dark={dark} />
      )}
    </>
  );
};

export default ListedContainer;
