import { doc, updateDoc } from "firebase/firestore";
import { db } from "../index";

export default async function editConsignee(storeId, consigneeId, data) {
  //data.name
  if (!data.name || data.name.length < 3) {
    throw new Error("Name is not a valid value.");
  }

  if (!data.consigneeId) {
    throw new Error("Consignee id is not a valid value.");
  }

  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!data.email.match(validRegex)) {
    throw new Error("Email is not a valid value.");
  }

  if (
    !data.contact ||
    !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
      data.contact
    )
  ) {
    throw new Error("Contact is not a valid value.");
  }

  return updateDoc(
    doc(db, "Stores", storeId, "Consignee", consigneeId),
    {
      ...data,
    },
    { merge: true }
  );
}
