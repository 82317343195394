import { collection, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { db } from "../../firebase/index";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Close from "../../assets/close.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import batchFetch from "../../firebase/util/batchFetch";
import "../css/Modal.scoped.css";
import Input from "../general/Input";
import CloseDark from "../../assets/closedark.svg";
import Button from "../general/Button";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";


export default function MissingItemsModals({ dark, scannedData }) {
  const [search, setSearch] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [data, updateData] = useState([])

  useEffect(()=>{
    const missingListener = onSnapshot(
      collection(db, "Stores", StoreId, "Inventory"),
      async (snapShot) => {
        let missingData = [];
        missingData = snapShot.docs.map((e) => {
          return {
            id: e.id,
            ...e.data()
          }
        });
        //Array to store all scanned Items of the inventory
        let scannedArr = [];
        scannedData.forEach((document) => {
          scannedArr.push(...document.scanned);
        });
        //filter out the un-scanned items from the inventory
        missingData = missingData.filter((e) => !scannedArr.includes(e.id));
        const productRefs = missingData.map((data) =>
          data.style.path ? data.style.path : data.style
        );
        const productData = await batchFetch(productRefs);

        const obtainedData = missingData.map((e, i) => ({
          ...e,
          title: productData[i].title,
        }));
        
        updateData(obtainedData);
      }
    );
    return () => {
      missingListener();
    }
  }, [scannedData])

  return (
    <div>
      <Button
        onClick={handleOpen}
        imgSrc={
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="8.66675"
              y="4.66663"
              width="5.33333"
              height="1.33333"
              rx="0.666667"
              transform="rotate(90 8.66675 4.66663)"
              fill="#FA7676"
            />
            <path
              d="M7.33325 11.3333C7.33325 10.9651 7.63173 10.6666 7.99992 10.6666V10.6666C8.36811 10.6666 8.66659 10.9651 8.66659 11.3333V11.3333C8.66659 11.7015 8.36811 12 7.99992 12V12C7.63173 12 7.33325 11.7015 7.33325 11.3333V11.3333Z"
              fill="#FA7676"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 8C0 14.588 1.412 16 8 16C14.588 16 16 14.588 16 8C16 1.412 14.588 0 8 0C1.412 0 0 1.412 0 8ZM1.33333 8C1.33333 9.61657 1.42169 10.8246 1.62514 11.74C1.82522 12.6403 2.11845 13.1738 2.47231 13.5277C2.82616 13.8815 3.35966 14.1748 4.25995 14.3749C5.17538 14.5783 6.38343 14.6667 8 14.6667C9.61657 14.6667 10.8246 14.5783 11.74 14.3749C12.6403 14.1748 13.1738 13.8815 13.5277 13.5277C13.8815 13.1738 14.1748 12.6403 14.3749 11.74C14.5783 10.8246 14.6667 9.61657 14.6667 8C14.6667 6.38343 14.5783 5.17538 14.3749 4.25995C14.1748 3.35966 13.8815 2.82616 13.5277 2.47231C13.1738 2.11846 12.6403 1.82522 11.74 1.62514C10.8246 1.42169 9.61657 1.33333 8 1.33333C6.38343 1.33333 5.17538 1.42169 4.25995 1.62514C3.35966 1.82522 2.82616 2.11846 2.47231 2.47231C2.11845 2.82616 1.82522 3.35966 1.62514 4.25995C1.42169 5.17538 1.33333 6.38343 1.33333 8Z"
              fill={`${dark ? "#5A5D74" : "#ABAFD1"}`}
            />
          </svg>
        }
        text={translate("missingItem")}
      />
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="missing-container">
              <div className="red lg-header flex al-ce jc-sp">
                <div>{translate("missingItems")}...</div>
                <img
                  onClick={handleClose}
                  src={dark ? CloseDark : Close}
                  alt=""
                />
              </div>
              <div className="search-input-cm">
                <Input
                  placeholder={GetTranslateString("search")}
                  type="text"
                  value={search}
                  updateValue={setSearch}
                  custom
                />
              </div>

              <div className="sm-headers-grid">
                  <div>{translate("items")}</div>
                  <div>{translate("style")}</div>
                  <div>{translate("size")} (US)</div>
                  <div>{translate("barcodeTags")}</div>
                  <div />
              </div>

              <div className="sm-items">
                {data.map((e, i) => (
                  <MissingItemGrid
                    key={i}
                    item={e}
                    dark={dark}
                  />
                ))}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

let MissingItemGrid = ({
  item,
  dark,
}) => {
  const {
    sizingValues,
    womenSizingValues,
    girlSizingValues,
    toddler,
    young,
  } = useContext(CurrencyContext);

  let localSizing = localStorage.getItem("sizing");

  return (
    <div className="sm-item-grid">
      <img 
        src={item.image}
        alt="shoeimg"
      />
      <div className="capitalize">
        {item.sku}
      </div>
      <div>
        {(item.category === "sneakers") 
          ? sizingValues[localSizing][item.size]
            ? sizingValues[localSizing][item.size]
            : womenSizingValues[localSizing][item.size]
            ? womenSizingValues[localSizing][item.size]
            : girlSizingValues[localSizing][item.size]
            ? girlSizingValues[localSizing][item.size]
            : toddler[localSizing][item.size]
            ? toddler[localSizing][item.size]
            : young[localSizing][item.size]
          : item.size}
      </div>
      <div>
        {item.title+"-"+item.size}
      </div>
      <div 
        onClick={() => {
            window.open(`https://scan-barcode.netlify.app/?store=${StoreId}`)
          }
        }
      >
      <svg
        cursor={"pointer"}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        
      >
        <path
          d="M0.988017 9.94963C0.420436 9.94963 -0.0354519 9.47427 0.00217902 8.90794C0.427063 2.51359 2.51368 0.427038 8.9082 0.00217383C9.47453 -0.0354543 9.94989 0.420432 9.94989 0.988013C9.94989 1.52545 9.52514 1.96472 8.98894 2.00124C7.95264 2.07184 7.07406 2.1871 6.32533 2.3535C4.9692 2.65488 4.16467 3.09678 3.63073 3.63071C3.09679 4.16464 2.65489 4.96913 2.3535 6.32522C2.18711 7.07391 2.07185 7.95244 2.00125 8.98869C1.96472 9.52488 1.52545 9.94963 0.988017 9.94963Z"
          fill={`${dark ? "#5A5D74" : "#ABAFD1"}`}
        />
        <path
          d="M0.988011 13.9639C0.420431 13.9639 -0.0354559 14.4392 0.0021735 15.0055C0.427045 21.4 2.51365 23.4865 8.9082 23.9114C9.47453 23.949 9.94989 23.4931 9.94989 22.9256C9.94989 22.3881 9.52513 21.9489 8.98894 21.9123C7.95263 21.8417 7.07406 21.7265 6.32533 21.5601C4.9692 21.2587 4.16467 20.8168 3.63073 20.2829C3.09679 19.7489 2.65489 18.9444 2.3535 17.5884C2.1871 16.8396 2.07184 15.9611 2.00124 14.9248C1.96472 14.3886 1.52545 13.9639 0.988011 13.9639Z"
          fill={`${dark ? "#5A5D74" : "#ABAFD1"}`}
        />
        <path
          d="M22.9261 13.9639C22.3887 13.9639 21.9494 14.3886 21.9129 14.9248C21.8423 15.9611 21.727 16.8396 21.5606 17.5884C21.2592 18.9444 20.8173 19.7489 20.2834 20.2829C19.7494 20.8168 18.9449 21.2587 17.5888 21.5601C16.84 21.7265 15.9615 21.8417 14.9252 21.9123C14.389 21.9489 13.9642 22.3881 13.9642 22.9256C13.9642 23.4931 14.4396 23.949 15.0059 23.9114C21.4004 23.4865 23.4871 21.4 23.9119 15.0055C23.9496 14.4392 23.4937 13.9639 22.9261 13.9639Z"
          fill={`${dark ? "#5A5D74" : "#ABAFD1"}`}
        />
        <path
          d="M24 11.9893C24 11.9785 24 11.9676 24 11.9568C24 11.9459 24 11.9351 24 11.9243V11.9893Z"
          fill={`${dark ? "#5A5D74" : "#ABAFD1"}`}
        />
        <path
          d="M22.9261 9.94963C23.4937 9.94963 23.9496 9.47427 23.9119 8.90794C23.487 2.51359 21.4004 0.427036 15.0059 0.00217334C14.4396 -0.0354547 13.9642 0.420432 13.9642 0.988012C13.9642 1.52545 14.389 1.96472 14.9251 2.00124C15.9615 2.07184 16.84 2.1871 17.5888 2.3535C18.9449 2.65488 19.7494 3.09678 20.2834 3.63071C20.8173 4.16464 21.2592 4.96913 21.5606 6.32522C21.727 7.07391 21.8423 7.95244 21.9129 8.98869C21.9494 9.52488 22.3887 9.94963 22.9261 9.94963Z"
          fill={`${dark ? "#5A5D74" : "#ABAFD1"}`}
        />
        <path
          d="M11.957 23.9995L11.8585 23.9994H12.0556L11.957 23.9995Z"
          fill={`${dark ? "#5A5D74" : "#ABAFD1"}`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9995 11.8281C16.9995 14.5895 14.761 16.828 11.9996 16.828C9.23828 16.828 6.99976 14.5895 6.99976 11.8281C6.99976 9.06677 9.23828 6.82825 11.9996 6.82825C14.761 6.82825 16.9995 9.06677 16.9995 11.8281ZM14.9996 11.8281C14.9996 13.485 13.6565 14.8281 11.9996 14.8281C10.3428 14.8281 8.99971 13.485 8.99971 11.8281C8.99971 10.1713 10.3428 8.8282 11.9996 8.8282C13.6565 8.8282 14.9996 10.1713 14.9996 11.8281Z"
          fill="#767BFA"
        />
      </svg>
      </div>
    </div>
  );
};

